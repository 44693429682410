import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useAppDispatch } from '../redux/store/store'

export const RouterEvents = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  useEffect(() => {
    const handleRouteChange = (_url: string) => {}

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router, dispatch])
  return <></>
}
