import { useRouter } from 'next/router'
import React, { ReactElement } from 'react'

import { UserProfileMetaTags } from '../../pages/user/components/UserProfileMetaTags'
import UserSidePanel from '../../pages/user/components/UserSidePanel'
import userApi from '../../redux/api/userApi'

interface UserProfileLayoutProps {
  children: JSX.Element
  username: string
}

const UserProfileLayoutInner = ({ children, username }: UserProfileLayoutProps) => {
  const { data: user } = userApi.useGetUserDetailsQuery({ username })

  return (
    <>
      {user && <UserProfileMetaTags user={user} />}
      <div className="flex size-full flex-col overflow-y-scroll scrollbar-hide md:flex-row" id="scrolling-grid">
        <div className="md:scrollbar-primary shrink-0 p-6 pt-0 md:sticky md:top-0 md:w-96 md:overflow-y-scroll md:pt-6">
          <UserSidePanel userId={user?.user_id} />
        </div>
        <div className="relative size-full">
          <div className="relative p-1.5" style={{ overflow: 'unset' }}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

const UserProfileLayout = ({ children }: { children: ReactElement }) => {
  const router = useRouter()
  const isUserPage = router.route.startsWith('/user/')
  const username = Array.isArray(router.query.username) ? router.query.username[0] : router.query.username

  if (!isUserPage || !username) return children
  return <UserProfileLayoutInner username={username}>{children}</UserProfileLayoutInner>
}

export default UserProfileLayout
