/*./.eslintrc.json*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const commands = $root.commands = (() => {

    const commands = {};

    commands.CommandContext = (function() {

        function CommandContext(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        CommandContext.prototype.session = null;
        CommandContext.prototype.referrerContext = null;
        CommandContext.prototype.currentContext = null;
        CommandContext.prototype.eventTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        CommandContext.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.session != null && Object.hasOwnProperty.call(m, "session"))
                $root.globals.Session.encode(m.session, w.uint32(10).fork()).ldelim();
            if (m.referrerContext != null && Object.hasOwnProperty.call(m, "referrerContext"))
                $root.globals.PageContext.encode(m.referrerContext, w.uint32(18).fork()).ldelim();
            if (m.currentContext != null && Object.hasOwnProperty.call(m, "currentContext"))
                $root.globals.PageContext.encode(m.currentContext, w.uint32(26).fork()).ldelim();
            if (m.eventTimestamp != null && Object.hasOwnProperty.call(m, "eventTimestamp"))
                w.uint32(32).uint64(m.eventTimestamp);
            return w;
        };

        CommandContext.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        CommandContext.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.CommandContext();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.session = $root.globals.Session.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.referrerContext = $root.globals.PageContext.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.currentContext = $root.globals.PageContext.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.eventTimestamp = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        CommandContext.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        CommandContext.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.session != null && m.hasOwnProperty("session")) {
                {
                    var e = $root.globals.Session.verify(m.session);
                    if (e)
                        return "session." + e;
                }
            }
            if (m.referrerContext != null && m.hasOwnProperty("referrerContext")) {
                {
                    var e = $root.globals.PageContext.verify(m.referrerContext);
                    if (e)
                        return "referrerContext." + e;
                }
            }
            if (m.currentContext != null && m.hasOwnProperty("currentContext")) {
                {
                    var e = $root.globals.PageContext.verify(m.currentContext);
                    if (e)
                        return "currentContext." + e;
                }
            }
            if (m.eventTimestamp != null && m.hasOwnProperty("eventTimestamp")) {
                if (!$util.isInteger(m.eventTimestamp) && !(m.eventTimestamp && $util.isInteger(m.eventTimestamp.low) && $util.isInteger(m.eventTimestamp.high)))
                    return "eventTimestamp: integer|Long expected";
            }
            return null;
        };

        CommandContext.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.CommandContext)
                return d;
            var m = new $root.commands.CommandContext();
            if (d.session != null) {
                if (typeof d.session !== "object")
                    throw TypeError(".commands.CommandContext.session: object expected");
                m.session = $root.globals.Session.fromObject(d.session);
            }
            if (d.referrerContext != null) {
                if (typeof d.referrerContext !== "object")
                    throw TypeError(".commands.CommandContext.referrerContext: object expected");
                m.referrerContext = $root.globals.PageContext.fromObject(d.referrerContext);
            }
            if (d.currentContext != null) {
                if (typeof d.currentContext !== "object")
                    throw TypeError(".commands.CommandContext.currentContext: object expected");
                m.currentContext = $root.globals.PageContext.fromObject(d.currentContext);
            }
            if (d.eventTimestamp != null) {
                if ($util.Long)
                    (m.eventTimestamp = $util.Long.fromValue(d.eventTimestamp)).unsigned = true;
                else if (typeof d.eventTimestamp === "string")
                    m.eventTimestamp = parseInt(d.eventTimestamp, 10);
                else if (typeof d.eventTimestamp === "number")
                    m.eventTimestamp = d.eventTimestamp;
                else if (typeof d.eventTimestamp === "object")
                    m.eventTimestamp = new $util.LongBits(d.eventTimestamp.low >>> 0, d.eventTimestamp.high >>> 0).toNumber(true);
            }
            return m;
        };

        CommandContext.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.session = null;
                d.referrerContext = null;
                d.currentContext = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.eventTimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.eventTimestamp = o.longs === String ? "0" : 0;
            }
            if (m.session != null && m.hasOwnProperty("session")) {
                d.session = $root.globals.Session.toObject(m.session, o);
            }
            if (m.referrerContext != null && m.hasOwnProperty("referrerContext")) {
                d.referrerContext = $root.globals.PageContext.toObject(m.referrerContext, o);
            }
            if (m.currentContext != null && m.hasOwnProperty("currentContext")) {
                d.currentContext = $root.globals.PageContext.toObject(m.currentContext, o);
            }
            if (m.eventTimestamp != null && m.hasOwnProperty("eventTimestamp")) {
                if (typeof m.eventTimestamp === "number")
                    d.eventTimestamp = o.longs === String ? String(m.eventTimestamp) : m.eventTimestamp;
                else
                    d.eventTimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.eventTimestamp) : o.longs === Number ? new $util.LongBits(m.eventTimestamp.low >>> 0, m.eventTimestamp.high >>> 0).toNumber(true) : m.eventTimestamp;
            }
            return d;
        };

        CommandContext.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CommandContext.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.CommandContext";
        };

        return CommandContext;
    })();

    commands.FollowMix = (function() {

        function FollowMix(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        FollowMix.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        FollowMix.prototype.mixId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        FollowMix.prototype.followType = 0;
        FollowMix.prototype.context = null;

        FollowMix.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.mixId != null && Object.hasOwnProperty.call(m, "mixId"))
                w.uint32(16).uint64(m.mixId);
            if (m.followType != null && Object.hasOwnProperty.call(m, "followType"))
                w.uint32(24).int32(m.followType);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(34).fork()).ldelim();
            return w;
        };

        FollowMix.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        FollowMix.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.FollowMix();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.mixId = r.uint64();
                        break;
                    }
                case 3: {
                        m.followType = r.int32();
                        break;
                    }
                case 4: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        FollowMix.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        FollowMix.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.mixId != null && m.hasOwnProperty("mixId")) {
                if (!$util.isInteger(m.mixId) && !(m.mixId && $util.isInteger(m.mixId.low) && $util.isInteger(m.mixId.high)))
                    return "mixId: integer|Long expected";
            }
            if (m.followType != null && m.hasOwnProperty("followType")) {
                switch (m.followType) {
                default:
                    return "followType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        FollowMix.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.FollowMix)
                return d;
            var m = new $root.commands.FollowMix();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.mixId != null) {
                if ($util.Long)
                    (m.mixId = $util.Long.fromValue(d.mixId)).unsigned = true;
                else if (typeof d.mixId === "string")
                    m.mixId = parseInt(d.mixId, 10);
                else if (typeof d.mixId === "number")
                    m.mixId = d.mixId;
                else if (typeof d.mixId === "object")
                    m.mixId = new $util.LongBits(d.mixId.low >>> 0, d.mixId.high >>> 0).toNumber(true);
            }
            switch (d.followType) {
            default:
                if (typeof d.followType === "number") {
                    m.followType = d.followType;
                    break;
                }
                break;
            case "UNKNOWN_FOLLOW_EVENT_TYPE":
            case 0:
                m.followType = 0;
                break;
            case "FOLLOWED":
            case 1:
                m.followType = 1;
                break;
            case "UNFOLLOWED":
            case 2:
                m.followType = 2;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.FollowMix.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        FollowMix.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.mixId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.mixId = o.longs === String ? "0" : 0;
                d.followType = o.enums === String ? "UNKNOWN_FOLLOW_EVENT_TYPE" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.mixId != null && m.hasOwnProperty("mixId")) {
                if (typeof m.mixId === "number")
                    d.mixId = o.longs === String ? String(m.mixId) : m.mixId;
                else
                    d.mixId = o.longs === String ? $util.Long.prototype.toString.call(m.mixId) : o.longs === Number ? new $util.LongBits(m.mixId.low >>> 0, m.mixId.high >>> 0).toNumber(true) : m.mixId;
            }
            if (m.followType != null && m.hasOwnProperty("followType")) {
                d.followType = o.enums === String ? $root.globals.FollowEventType[m.followType] === undefined ? m.followType : $root.globals.FollowEventType[m.followType] : m.followType;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        FollowMix.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        FollowMix.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.FollowMix";
        };

        return FollowMix;
    })();

    commands.ReportUrlMiscat = (function() {

        function ReportUrlMiscat(p) {
            this.recommendedNodes = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        ReportUrlMiscat.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        ReportUrlMiscat.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        ReportUrlMiscat.prototype.interestKey = "";
        ReportUrlMiscat.prototype.context = null;
        ReportUrlMiscat.prototype.node = "";
        ReportUrlMiscat.prototype.recommendedNodes = $util.emptyArray;

        ReportUrlMiscat.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.interestKey != null && Object.hasOwnProperty.call(m, "interestKey"))
                w.uint32(26).string(m.interestKey);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(34).fork()).ldelim();
            if (m.node != null && Object.hasOwnProperty.call(m, "node"))
                w.uint32(42).string(m.node);
            if (m.recommendedNodes != null && m.recommendedNodes.length) {
                for (var i = 0; i < m.recommendedNodes.length; ++i)
                    w.uint32(50).string(m.recommendedNodes[i]);
            }
            return w;
        };

        ReportUrlMiscat.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ReportUrlMiscat.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.ReportUrlMiscat();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.interestKey = r.string();
                        break;
                    }
                case 4: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        m.node = r.string();
                        break;
                    }
                case 6: {
                        if (!(m.recommendedNodes && m.recommendedNodes.length))
                            m.recommendedNodes = [];
                        m.recommendedNodes.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        ReportUrlMiscat.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ReportUrlMiscat.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.interestKey != null && m.hasOwnProperty("interestKey")) {
                if (!$util.isString(m.interestKey))
                    return "interestKey: string expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.node != null && m.hasOwnProperty("node")) {
                if (!$util.isString(m.node))
                    return "node: string expected";
            }
            if (m.recommendedNodes != null && m.hasOwnProperty("recommendedNodes")) {
                if (!Array.isArray(m.recommendedNodes))
                    return "recommendedNodes: array expected";
                for (var i = 0; i < m.recommendedNodes.length; ++i) {
                    if (!$util.isString(m.recommendedNodes[i]))
                        return "recommendedNodes: string[] expected";
                }
            }
            return null;
        };

        ReportUrlMiscat.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.ReportUrlMiscat)
                return d;
            var m = new $root.commands.ReportUrlMiscat();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.interestKey != null) {
                m.interestKey = String(d.interestKey);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.ReportUrlMiscat.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            if (d.node != null) {
                m.node = String(d.node);
            }
            if (d.recommendedNodes) {
                if (!Array.isArray(d.recommendedNodes))
                    throw TypeError(".commands.ReportUrlMiscat.recommendedNodes: array expected");
                m.recommendedNodes = [];
                for (var i = 0; i < d.recommendedNodes.length; ++i) {
                    m.recommendedNodes[i] = String(d.recommendedNodes[i]);
                }
            }
            return m;
        };

        ReportUrlMiscat.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.recommendedNodes = [];
            }
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.interestKey = "";
                d.context = null;
                d.node = "";
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.interestKey != null && m.hasOwnProperty("interestKey")) {
                d.interestKey = m.interestKey;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.node != null && m.hasOwnProperty("node")) {
                d.node = m.node;
            }
            if (m.recommendedNodes && m.recommendedNodes.length) {
                d.recommendedNodes = [];
                for (var j = 0; j < m.recommendedNodes.length; ++j) {
                    d.recommendedNodes[j] = m.recommendedNodes[j];
                }
            }
            return d;
        };

        ReportUrlMiscat.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ReportUrlMiscat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.ReportUrlMiscat";
        };

        return ReportUrlMiscat;
    })();

    commands.TrackContentView = (function() {

        function TrackContentView(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentView.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentView.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentView.prototype.viewType = 0;
        TrackContentView.prototype.context = null;

        TrackContentView.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.viewType != null && Object.hasOwnProperty.call(m, "viewType"))
                w.uint32(24).int32(m.viewType);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(34).fork()).ldelim();
            return w;
        };

        TrackContentView.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentView.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentView();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.viewType = r.int32();
                        break;
                    }
                case 4: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentView.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentView.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.viewType != null && m.hasOwnProperty("viewType")) {
                switch (m.viewType) {
                default:
                    return "viewType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackContentView.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentView)
                return d;
            var m = new $root.commands.TrackContentView();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            switch (d.viewType) {
            default:
                if (typeof d.viewType === "number") {
                    m.viewType = d.viewType;
                    break;
                }
                break;
            case "UNKNOWN_CONTENT_VIEW_TYPE":
            case 0:
                m.viewType = 0;
                break;
            case "CARD_VIEW":
            case 1:
                m.viewType = 1;
                break;
            case "CONTENT_VIEW":
            case 2:
                m.viewType = 2;
                break;
            case "GRID_VIEW":
            case 3:
                m.viewType = 3;
                break;
            case "IN_APP_BROWSER_VIEW":
            case 4:
                m.viewType = 4;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentView.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackContentView.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.viewType = o.enums === String ? "UNKNOWN_CONTENT_VIEW_TYPE" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.viewType != null && m.hasOwnProperty("viewType")) {
                d.viewType = o.enums === String ? $root.globals.ContentViewType[m.viewType] === undefined ? m.viewType : $root.globals.ContentViewType[m.viewType] : m.viewType;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackContentView.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentView.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentView";
        };

        return TrackContentView;
    })();

    commands.TrackContentInfoView = (function() {

        function TrackContentInfoView(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentInfoView.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentInfoView.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentInfoView.prototype.context = null;

        TrackContentInfoView.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            return w;
        };

        TrackContentInfoView.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentInfoView.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentInfoView();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentInfoView.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentInfoView.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackContentInfoView.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentInfoView)
                return d;
            var m = new $root.commands.TrackContentInfoView();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentInfoView.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackContentInfoView.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackContentInfoView.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentInfoView.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentInfoView";
        };

        return TrackContentInfoView;
    })();

    commands.TrackGridViews = (function() {

        function TrackGridViews(p) {
            this.gridViews = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackGridViews.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackGridViews.prototype.gridViews = $util.emptyArray;
        TrackGridViews.prototype.context = null;

        TrackGridViews.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.gridViews != null && m.gridViews.length) {
                for (var i = 0; i < m.gridViews.length; ++i)
                    $root.commands.TrackGridViews.GridView.encode(m.gridViews[i], w.uint32(18).fork()).ldelim();
            }
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            return w;
        };

        TrackGridViews.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackGridViews.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackGridViews();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        if (!(m.gridViews && m.gridViews.length))
                            m.gridViews = [];
                        m.gridViews.push($root.commands.TrackGridViews.GridView.decode(r, r.uint32()));
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackGridViews.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackGridViews.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.gridViews != null && m.hasOwnProperty("gridViews")) {
                if (!Array.isArray(m.gridViews))
                    return "gridViews: array expected";
                for (var i = 0; i < m.gridViews.length; ++i) {
                    {
                        var e = $root.commands.TrackGridViews.GridView.verify(m.gridViews[i]);
                        if (e)
                            return "gridViews." + e;
                    }
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackGridViews.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackGridViews)
                return d;
            var m = new $root.commands.TrackGridViews();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.gridViews) {
                if (!Array.isArray(d.gridViews))
                    throw TypeError(".commands.TrackGridViews.gridViews: array expected");
                m.gridViews = [];
                for (var i = 0; i < d.gridViews.length; ++i) {
                    if (typeof d.gridViews[i] !== "object")
                        throw TypeError(".commands.TrackGridViews.gridViews: object expected");
                    m.gridViews[i] = $root.commands.TrackGridViews.GridView.fromObject(d.gridViews[i]);
                }
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackGridViews.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackGridViews.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.gridViews = [];
            }
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.gridViews && m.gridViews.length) {
                d.gridViews = [];
                for (var j = 0; j < m.gridViews.length; ++j) {
                    d.gridViews[j] = $root.commands.TrackGridViews.GridView.toObject(m.gridViews[j], o);
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackGridViews.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackGridViews.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackGridViews";
        };

        TrackGridViews.GridView = (function() {

            function GridView(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            GridView.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            GridView.prototype.position = 0;

            GridView.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                    w.uint32(8).uint64(m.urlId);
                if (m.position != null && Object.hasOwnProperty.call(m, "position"))
                    w.uint32(16).int32(m.position);
                return w;
            };

            GridView.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            GridView.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackGridViews.GridView();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.urlId = r.uint64();
                            break;
                        }
                    case 2: {
                            m.position = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            GridView.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            GridView.verify = function verify(m) {
                if (typeof m !== "object" || m === null)
                    return "object expected";
                if (m.urlId != null && m.hasOwnProperty("urlId")) {
                    if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                        return "urlId: integer|Long expected";
                }
                if (m.position != null && m.hasOwnProperty("position")) {
                    if (!$util.isInteger(m.position))
                        return "position: integer expected";
                }
                return null;
            };

            GridView.fromObject = function fromObject(d) {
                if (d instanceof $root.commands.TrackGridViews.GridView)
                    return d;
                var m = new $root.commands.TrackGridViews.GridView();
                if (d.urlId != null) {
                    if ($util.Long)
                        (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                    else if (typeof d.urlId === "string")
                        m.urlId = parseInt(d.urlId, 10);
                    else if (typeof d.urlId === "number")
                        m.urlId = d.urlId;
                    else if (typeof d.urlId === "object")
                        m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
                }
                if (d.position != null) {
                    m.position = d.position | 0;
                }
                return m;
            };

            GridView.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, true);
                        d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.urlId = o.longs === String ? "0" : 0;
                    d.position = 0;
                }
                if (m.urlId != null && m.hasOwnProperty("urlId")) {
                    if (typeof m.urlId === "number")
                        d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                    else
                        d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
                }
                if (m.position != null && m.hasOwnProperty("position")) {
                    d.position = m.position;
                }
                return d;
            };

            GridView.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GridView.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/commands.TrackGridViews.GridView";
            };

            return GridView;
        })();

        return TrackGridViews;
    })();

    commands.TrackUserSession = (function() {

        function TrackUserSession(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackUserSession.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackUserSession.prototype.eventType = 0;
        TrackUserSession.prototype.clientSession = null;
        TrackUserSession.prototype.context = null;

        TrackUserSession.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.eventType != null && Object.hasOwnProperty.call(m, "eventType"))
                w.uint32(16).int32(m.eventType);
            if (m.clientSession != null && Object.hasOwnProperty.call(m, "clientSession"))
                $root.globals.ClientSession.encode(m.clientSession, w.uint32(26).fork()).ldelim();
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(34).fork()).ldelim();
            return w;
        };

        TrackUserSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackUserSession.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackUserSession();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.eventType = r.int32();
                        break;
                    }
                case 3: {
                        m.clientSession = $root.globals.ClientSession.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackUserSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackUserSession.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.eventType != null && m.hasOwnProperty("eventType")) {
                switch (m.eventType) {
                default:
                    return "eventType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (m.clientSession != null && m.hasOwnProperty("clientSession")) {
                {
                    var e = $root.globals.ClientSession.verify(m.clientSession);
                    if (e)
                        return "clientSession." + e;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackUserSession.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackUserSession)
                return d;
            var m = new $root.commands.TrackUserSession();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            switch (d.eventType) {
            default:
                if (typeof d.eventType === "number") {
                    m.eventType = d.eventType;
                    break;
                }
                break;
            case "UNKNOWN_SESSION_EVENT_TYPE":
            case 0:
                m.eventType = 0;
                break;
            case "SESSION_STARTED":
            case 1:
                m.eventType = 1;
                break;
            case "SESSION_ENDED":
            case 2:
                m.eventType = 2;
                break;
            case "SESSION_UPDATED":
            case 3:
                m.eventType = 3;
                break;
            }
            if (d.clientSession != null) {
                if (typeof d.clientSession !== "object")
                    throw TypeError(".commands.TrackUserSession.clientSession: object expected");
                m.clientSession = $root.globals.ClientSession.fromObject(d.clientSession);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackUserSession.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackUserSession.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.eventType = o.enums === String ? "UNKNOWN_SESSION_EVENT_TYPE" : 0;
                d.clientSession = null;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.eventType != null && m.hasOwnProperty("eventType")) {
                d.eventType = o.enums === String ? $root.globals.SessionEventType[m.eventType] === undefined ? m.eventType : $root.globals.SessionEventType[m.eventType] : m.eventType;
            }
            if (m.clientSession != null && m.hasOwnProperty("clientSession")) {
                d.clientSession = $root.globals.ClientSession.toObject(m.clientSession, o);
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackUserSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackUserSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackUserSession";
        };

        return TrackUserSession;
    })();

    commands.TrackStreamProfileView = (function() {

        function TrackStreamProfileView(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackStreamProfileView.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackStreamProfileView.prototype.streamType = 0;
        TrackStreamProfileView.prototype.context = null;

        TrackStreamProfileView.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.streamType != null && Object.hasOwnProperty.call(m, "streamType"))
                w.uint32(16).int32(m.streamType);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            return w;
        };

        TrackStreamProfileView.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackStreamProfileView.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackStreamProfileView();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.streamType = r.int32();
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackStreamProfileView.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackStreamProfileView.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.streamType != null && m.hasOwnProperty("streamType")) {
                switch (m.streamType) {
                default:
                    return "streamType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackStreamProfileView.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackStreamProfileView)
                return d;
            var m = new $root.commands.TrackStreamProfileView();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            switch (d.streamType) {
            default:
                if (typeof d.streamType === "number") {
                    m.streamType = d.streamType;
                    break;
                }
                break;
            case "UNKNOWN_STREAM_TYPE":
            case 0:
                m.streamType = 0;
                break;
            case "TOP_FOR_YOU_STREAM":
            case 1:
                m.streamType = 1;
                break;
            case "CATEGORY_STREAM":
            case 2:
                m.streamType = 2;
                break;
            case "TOPIC_STREAM":
            case 3:
                m.streamType = 3;
                break;
            case "MIX_STREAM":
            case 4:
                m.streamType = 4;
                break;
            case "SHARE_STREAM":
            case 5:
                m.streamType = 5;
                break;
            case "USER_MIXED_STREAM":
            case 6:
                m.streamType = 6;
                break;
            case "MIX_DIGEST_STREAM":
            case 7:
                m.streamType = 7;
                break;
            case "ALL_OF_MIX_STREAM":
            case 8:
                m.streamType = 8;
                break;
            case "FEATURED_COLLECTION_CAROUSEL":
            case 9:
                m.streamType = 9;
                break;
            case "FEATURED_ARTICLE_CAROUSEL":
            case 10:
                m.streamType = 10;
                break;
            case "RELATED_ARTICLE_STREAM":
            case 11:
                m.streamType = 11;
                break;
            case "SEARCH_POSTS_STREAM":
            case 12:
                m.streamType = 12;
                break;
            case "SEARCH_COLLECTIONS_STREAM":
            case 13:
                m.streamType = 13;
                break;
            case "SEARCH_INTERESTS_STREAM":
            case 14:
                m.streamType = 14;
                break;
            case "SEARCH_PEOPLE_STREAM":
            case 15:
                m.streamType = 15;
                break;
            case "SEARCH_ALL_STREAM":
            case 16:
                m.streamType = 16;
                break;
            case "SLUG_STREAM":
            case 17:
                m.streamType = 17;
                break;
            case "NODE_STREAM":
            case 18:
                m.streamType = 18;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackStreamProfileView.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackStreamProfileView.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.streamType = o.enums === String ? "UNKNOWN_STREAM_TYPE" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.streamType != null && m.hasOwnProperty("streamType")) {
                d.streamType = o.enums === String ? $root.globals.StreamType[m.streamType] === undefined ? m.streamType : $root.globals.StreamType[m.streamType] : m.streamType;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackStreamProfileView.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackStreamProfileView.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackStreamProfileView";
        };

        return TrackStreamProfileView;
    })();

    commands.TrackUserProfileView = (function() {

        function TrackUserProfileView(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackUserProfileView.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackUserProfileView.prototype.profileUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackUserProfileView.prototype.context = null;

        TrackUserProfileView.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.profileUserId != null && Object.hasOwnProperty.call(m, "profileUserId"))
                w.uint32(16).uint64(m.profileUserId);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            return w;
        };

        TrackUserProfileView.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackUserProfileView.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackUserProfileView();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.profileUserId = r.uint64();
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackUserProfileView.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackUserProfileView.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.profileUserId != null && m.hasOwnProperty("profileUserId")) {
                if (!$util.isInteger(m.profileUserId) && !(m.profileUserId && $util.isInteger(m.profileUserId.low) && $util.isInteger(m.profileUserId.high)))
                    return "profileUserId: integer|Long expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackUserProfileView.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackUserProfileView)
                return d;
            var m = new $root.commands.TrackUserProfileView();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.profileUserId != null) {
                if ($util.Long)
                    (m.profileUserId = $util.Long.fromValue(d.profileUserId)).unsigned = true;
                else if (typeof d.profileUserId === "string")
                    m.profileUserId = parseInt(d.profileUserId, 10);
                else if (typeof d.profileUserId === "number")
                    m.profileUserId = d.profileUserId;
                else if (typeof d.profileUserId === "object")
                    m.profileUserId = new $util.LongBits(d.profileUserId.low >>> 0, d.profileUserId.high >>> 0).toNumber(true);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackUserProfileView.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackUserProfileView.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.profileUserId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.profileUserId = o.longs === String ? "0" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.profileUserId != null && m.hasOwnProperty("profileUserId")) {
                if (typeof m.profileUserId === "number")
                    d.profileUserId = o.longs === String ? String(m.profileUserId) : m.profileUserId;
                else
                    d.profileUserId = o.longs === String ? $util.Long.prototype.toString.call(m.profileUserId) : o.longs === Number ? new $util.LongBits(m.profileUserId.low >>> 0, m.profileUserId.high >>> 0).toNumber(true) : m.profileUserId;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackUserProfileView.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackUserProfileView.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackUserProfileView";
        };

        return TrackUserProfileView;
    })();

    commands.TrackMixProfileView = (function() {

        function TrackMixProfileView(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackMixProfileView.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackMixProfileView.prototype.viewedMixId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackMixProfileView.prototype.context = null;

        TrackMixProfileView.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.viewedMixId != null && Object.hasOwnProperty.call(m, "viewedMixId"))
                w.uint32(16).uint64(m.viewedMixId);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            return w;
        };

        TrackMixProfileView.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackMixProfileView.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackMixProfileView();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.viewedMixId = r.uint64();
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackMixProfileView.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackMixProfileView.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.viewedMixId != null && m.hasOwnProperty("viewedMixId")) {
                if (!$util.isInteger(m.viewedMixId) && !(m.viewedMixId && $util.isInteger(m.viewedMixId.low) && $util.isInteger(m.viewedMixId.high)))
                    return "viewedMixId: integer|Long expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackMixProfileView.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackMixProfileView)
                return d;
            var m = new $root.commands.TrackMixProfileView();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.viewedMixId != null) {
                if ($util.Long)
                    (m.viewedMixId = $util.Long.fromValue(d.viewedMixId)).unsigned = true;
                else if (typeof d.viewedMixId === "string")
                    m.viewedMixId = parseInt(d.viewedMixId, 10);
                else if (typeof d.viewedMixId === "number")
                    m.viewedMixId = d.viewedMixId;
                else if (typeof d.viewedMixId === "object")
                    m.viewedMixId = new $util.LongBits(d.viewedMixId.low >>> 0, d.viewedMixId.high >>> 0).toNumber(true);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackMixProfileView.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackMixProfileView.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.viewedMixId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.viewedMixId = o.longs === String ? "0" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.viewedMixId != null && m.hasOwnProperty("viewedMixId")) {
                if (typeof m.viewedMixId === "number")
                    d.viewedMixId = o.longs === String ? String(m.viewedMixId) : m.viewedMixId;
                else
                    d.viewedMixId = o.longs === String ? $util.Long.prototype.toString.call(m.viewedMixId) : o.longs === Number ? new $util.LongBits(m.viewedMixId.low >>> 0, m.viewedMixId.high >>> 0).toNumber(true) : m.viewedMixId;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackMixProfileView.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackMixProfileView.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackMixProfileView";
        };

        return TrackMixProfileView;
    })();

    commands.TrackTopicProfileView = (function() {

        function TrackTopicProfileView(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackTopicProfileView.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackTopicProfileView.prototype.topicKey = "";
        TrackTopicProfileView.prototype.context = null;

        TrackTopicProfileView.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.topicKey != null && Object.hasOwnProperty.call(m, "topicKey"))
                w.uint32(18).string(m.topicKey);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            return w;
        };

        TrackTopicProfileView.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackTopicProfileView.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackTopicProfileView();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.topicKey = r.string();
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackTopicProfileView.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackTopicProfileView.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.topicKey != null && m.hasOwnProperty("topicKey")) {
                if (!$util.isString(m.topicKey))
                    return "topicKey: string expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackTopicProfileView.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackTopicProfileView)
                return d;
            var m = new $root.commands.TrackTopicProfileView();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.topicKey != null) {
                m.topicKey = String(d.topicKey);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackTopicProfileView.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackTopicProfileView.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.topicKey = "";
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.topicKey != null && m.hasOwnProperty("topicKey")) {
                d.topicKey = m.topicKey;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackTopicProfileView.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackTopicProfileView.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackTopicProfileView";
        };

        return TrackTopicProfileView;
    })();

    commands.TrackSlugProfileView = (function() {

        function TrackSlugProfileView(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackSlugProfileView.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackSlugProfileView.prototype.slugKey = "";
        TrackSlugProfileView.prototype.context = null;

        TrackSlugProfileView.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.slugKey != null && Object.hasOwnProperty.call(m, "slugKey"))
                w.uint32(18).string(m.slugKey);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            return w;
        };

        TrackSlugProfileView.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackSlugProfileView.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackSlugProfileView();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.slugKey = r.string();
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackSlugProfileView.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackSlugProfileView.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.slugKey != null && m.hasOwnProperty("slugKey")) {
                if (!$util.isString(m.slugKey))
                    return "slugKey: string expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackSlugProfileView.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackSlugProfileView)
                return d;
            var m = new $root.commands.TrackSlugProfileView();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.slugKey != null) {
                m.slugKey = String(d.slugKey);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackSlugProfileView.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackSlugProfileView.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.slugKey = "";
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.slugKey != null && m.hasOwnProperty("slugKey")) {
                d.slugKey = m.slugKey;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackSlugProfileView.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackSlugProfileView.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackSlugProfileView";
        };

        return TrackSlugProfileView;
    })();

    commands.TrackNodeProfileView = (function() {

        function TrackNodeProfileView(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackNodeProfileView.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackNodeProfileView.prototype.nodeKey = "";
        TrackNodeProfileView.prototype.context = null;

        TrackNodeProfileView.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.nodeKey != null && Object.hasOwnProperty.call(m, "nodeKey"))
                w.uint32(18).string(m.nodeKey);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            return w;
        };

        TrackNodeProfileView.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackNodeProfileView.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackNodeProfileView();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.nodeKey = r.string();
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackNodeProfileView.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackNodeProfileView.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.nodeKey != null && m.hasOwnProperty("nodeKey")) {
                if (!$util.isString(m.nodeKey))
                    return "nodeKey: string expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackNodeProfileView.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackNodeProfileView)
                return d;
            var m = new $root.commands.TrackNodeProfileView();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.nodeKey != null) {
                m.nodeKey = String(d.nodeKey);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackNodeProfileView.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackNodeProfileView.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.nodeKey = "";
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.nodeKey != null && m.hasOwnProperty("nodeKey")) {
                d.nodeKey = m.nodeKey;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackNodeProfileView.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackNodeProfileView.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackNodeProfileView";
        };

        return TrackNodeProfileView;
    })();

    commands.TrackUserProfileEvent = (function() {

        function TrackUserProfileEvent(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackUserProfileEvent.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackUserProfileEvent.prototype.eventType = 0;
        TrackUserProfileEvent.prototype.context = null;

        TrackUserProfileEvent.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.eventType != null && Object.hasOwnProperty.call(m, "eventType"))
                w.uint32(16).int32(m.eventType);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            return w;
        };

        TrackUserProfileEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackUserProfileEvent.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackUserProfileEvent();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.eventType = r.int32();
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackUserProfileEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackUserProfileEvent.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.eventType != null && m.hasOwnProperty("eventType")) {
                switch (m.eventType) {
                default:
                    return "eventType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackUserProfileEvent.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackUserProfileEvent)
                return d;
            var m = new $root.commands.TrackUserProfileEvent();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            switch (d.eventType) {
            default:
                if (typeof d.eventType === "number") {
                    m.eventType = d.eventType;
                    break;
                }
                break;
            case "UNKNOWN_USER_PROFILE_EVENT_TYPE":
            case 0:
                m.eventType = 0;
                break;
            case "USER_PROFILE_CREATED":
            case 1:
                m.eventType = 1;
                break;
            case "USER_PROFILE_DELETED":
            case 2:
                m.eventType = 2;
                break;
            case "USER_PROFILE_UPDATED":
            case 3:
                m.eventType = 3;
                break;
            case "USER_PROFILE_COMPLETED":
            case 4:
                m.eventType = 4;
                break;
            case "USER_PROFILE_ANONYMOUS_PROMOTED":
            case 5:
                m.eventType = 5;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackUserProfileEvent.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackUserProfileEvent.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.eventType = o.enums === String ? "UNKNOWN_USER_PROFILE_EVENT_TYPE" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.eventType != null && m.hasOwnProperty("eventType")) {
                d.eventType = o.enums === String ? $root.globals.UserProfileEventType[m.eventType] === undefined ? m.eventType : $root.globals.UserProfileEventType[m.eventType] : m.eventType;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackUserProfileEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackUserProfileEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackUserProfileEvent";
        };

        return TrackUserProfileEvent;
    })();

    commands.TrackExternalUrlPrivateShareEvent = (function() {

        function TrackExternalUrlPrivateShareEvent(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackExternalUrlPrivateShareEvent.prototype.senderUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackExternalUrlPrivateShareEvent.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackExternalUrlPrivateShareEvent.prototype.shareType = 0;
        TrackExternalUrlPrivateShareEvent.prototype.context = null;

        TrackExternalUrlPrivateShareEvent.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.senderUserId != null && Object.hasOwnProperty.call(m, "senderUserId"))
                w.uint32(8).uint64(m.senderUserId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.shareType != null && Object.hasOwnProperty.call(m, "shareType"))
                w.uint32(24).int32(m.shareType);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(34).fork()).ldelim();
            return w;
        };

        TrackExternalUrlPrivateShareEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackExternalUrlPrivateShareEvent.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackExternalUrlPrivateShareEvent();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.senderUserId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.shareType = r.int32();
                        break;
                    }
                case 4: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackExternalUrlPrivateShareEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackExternalUrlPrivateShareEvent.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.senderUserId != null && m.hasOwnProperty("senderUserId")) {
                if (!$util.isInteger(m.senderUserId) && !(m.senderUserId && $util.isInteger(m.senderUserId.low) && $util.isInteger(m.senderUserId.high)))
                    return "senderUserId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.shareType != null && m.hasOwnProperty("shareType")) {
                switch (m.shareType) {
                default:
                    return "shareType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackExternalUrlPrivateShareEvent.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackExternalUrlPrivateShareEvent)
                return d;
            var m = new $root.commands.TrackExternalUrlPrivateShareEvent();
            if (d.senderUserId != null) {
                if ($util.Long)
                    (m.senderUserId = $util.Long.fromValue(d.senderUserId)).unsigned = true;
                else if (typeof d.senderUserId === "string")
                    m.senderUserId = parseInt(d.senderUserId, 10);
                else if (typeof d.senderUserId === "number")
                    m.senderUserId = d.senderUserId;
                else if (typeof d.senderUserId === "object")
                    m.senderUserId = new $util.LongBits(d.senderUserId.low >>> 0, d.senderUserId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            switch (d.shareType) {
            default:
                if (typeof d.shareType === "number") {
                    m.shareType = d.shareType;
                    break;
                }
                break;
            case "UNKNOWN_SHARE_TYPE":
            case 0:
                m.shareType = 0;
                break;
            case "INTERNAL_SHARE":
            case 1:
                m.shareType = 1;
                break;
            case "FB_SHARE":
            case 2:
                m.shareType = 2;
                break;
            case "TWITTER_SHARE":
            case 3:
                m.shareType = 3;
                break;
            case "WHATSAPP_SHARE":
            case 4:
                m.shareType = 4;
                break;
            case "SMS_SHARE":
            case 5:
                m.shareType = 5;
                break;
            case "EMAIL_SHARE":
            case 6:
                m.shareType = 6;
                break;
            case "EXTERNAL_SHARE":
            case 7:
                m.shareType = 7;
                break;
            case "PINTEREST_SHARE":
            case 8:
                m.shareType = 8;
                break;
            case "SIGNAL_SHARE":
            case 9:
                m.shareType = 9;
                break;
            case "COPY_LINK":
            case 10:
                m.shareType = 10;
                break;
            case "INSTAGRAM_SHARE":
            case 11:
                m.shareType = 11;
                break;
            case "FB_MESSENGER_SHARE":
            case 12:
                m.shareType = 12;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackExternalUrlPrivateShareEvent.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackExternalUrlPrivateShareEvent.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.senderUserId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.senderUserId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.shareType = o.enums === String ? "UNKNOWN_SHARE_TYPE" : 0;
                d.context = null;
            }
            if (m.senderUserId != null && m.hasOwnProperty("senderUserId")) {
                if (typeof m.senderUserId === "number")
                    d.senderUserId = o.longs === String ? String(m.senderUserId) : m.senderUserId;
                else
                    d.senderUserId = o.longs === String ? $util.Long.prototype.toString.call(m.senderUserId) : o.longs === Number ? new $util.LongBits(m.senderUserId.low >>> 0, m.senderUserId.high >>> 0).toNumber(true) : m.senderUserId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.shareType != null && m.hasOwnProperty("shareType")) {
                d.shareType = o.enums === String ? $root.globals.ShareType[m.shareType] === undefined ? m.shareType : $root.globals.ShareType[m.shareType] : m.shareType;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackExternalUrlPrivateShareEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackExternalUrlPrivateShareEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackExternalUrlPrivateShareEvent";
        };

        return TrackExternalUrlPrivateShareEvent;
    })();

    commands.TrackExternalUserShareEvent = (function() {

        function TrackExternalUserShareEvent(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackExternalUserShareEvent.prototype.senderUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackExternalUserShareEvent.prototype.sharedUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackExternalUserShareEvent.prototype.shareType = 0;
        TrackExternalUserShareEvent.prototype.context = null;

        TrackExternalUserShareEvent.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.senderUserId != null && Object.hasOwnProperty.call(m, "senderUserId"))
                w.uint32(8).uint64(m.senderUserId);
            if (m.sharedUserId != null && Object.hasOwnProperty.call(m, "sharedUserId"))
                w.uint32(16).uint64(m.sharedUserId);
            if (m.shareType != null && Object.hasOwnProperty.call(m, "shareType"))
                w.uint32(24).int32(m.shareType);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(34).fork()).ldelim();
            return w;
        };

        TrackExternalUserShareEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackExternalUserShareEvent.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackExternalUserShareEvent();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.senderUserId = r.uint64();
                        break;
                    }
                case 2: {
                        m.sharedUserId = r.uint64();
                        break;
                    }
                case 3: {
                        m.shareType = r.int32();
                        break;
                    }
                case 4: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackExternalUserShareEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackExternalUserShareEvent.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.senderUserId != null && m.hasOwnProperty("senderUserId")) {
                if (!$util.isInteger(m.senderUserId) && !(m.senderUserId && $util.isInteger(m.senderUserId.low) && $util.isInteger(m.senderUserId.high)))
                    return "senderUserId: integer|Long expected";
            }
            if (m.sharedUserId != null && m.hasOwnProperty("sharedUserId")) {
                if (!$util.isInteger(m.sharedUserId) && !(m.sharedUserId && $util.isInteger(m.sharedUserId.low) && $util.isInteger(m.sharedUserId.high)))
                    return "sharedUserId: integer|Long expected";
            }
            if (m.shareType != null && m.hasOwnProperty("shareType")) {
                switch (m.shareType) {
                default:
                    return "shareType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackExternalUserShareEvent.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackExternalUserShareEvent)
                return d;
            var m = new $root.commands.TrackExternalUserShareEvent();
            if (d.senderUserId != null) {
                if ($util.Long)
                    (m.senderUserId = $util.Long.fromValue(d.senderUserId)).unsigned = true;
                else if (typeof d.senderUserId === "string")
                    m.senderUserId = parseInt(d.senderUserId, 10);
                else if (typeof d.senderUserId === "number")
                    m.senderUserId = d.senderUserId;
                else if (typeof d.senderUserId === "object")
                    m.senderUserId = new $util.LongBits(d.senderUserId.low >>> 0, d.senderUserId.high >>> 0).toNumber(true);
            }
            if (d.sharedUserId != null) {
                if ($util.Long)
                    (m.sharedUserId = $util.Long.fromValue(d.sharedUserId)).unsigned = true;
                else if (typeof d.sharedUserId === "string")
                    m.sharedUserId = parseInt(d.sharedUserId, 10);
                else if (typeof d.sharedUserId === "number")
                    m.sharedUserId = d.sharedUserId;
                else if (typeof d.sharedUserId === "object")
                    m.sharedUserId = new $util.LongBits(d.sharedUserId.low >>> 0, d.sharedUserId.high >>> 0).toNumber(true);
            }
            switch (d.shareType) {
            default:
                if (typeof d.shareType === "number") {
                    m.shareType = d.shareType;
                    break;
                }
                break;
            case "UNKNOWN_SHARE_TYPE":
            case 0:
                m.shareType = 0;
                break;
            case "INTERNAL_SHARE":
            case 1:
                m.shareType = 1;
                break;
            case "FB_SHARE":
            case 2:
                m.shareType = 2;
                break;
            case "TWITTER_SHARE":
            case 3:
                m.shareType = 3;
                break;
            case "WHATSAPP_SHARE":
            case 4:
                m.shareType = 4;
                break;
            case "SMS_SHARE":
            case 5:
                m.shareType = 5;
                break;
            case "EMAIL_SHARE":
            case 6:
                m.shareType = 6;
                break;
            case "EXTERNAL_SHARE":
            case 7:
                m.shareType = 7;
                break;
            case "PINTEREST_SHARE":
            case 8:
                m.shareType = 8;
                break;
            case "SIGNAL_SHARE":
            case 9:
                m.shareType = 9;
                break;
            case "COPY_LINK":
            case 10:
                m.shareType = 10;
                break;
            case "INSTAGRAM_SHARE":
            case 11:
                m.shareType = 11;
                break;
            case "FB_MESSENGER_SHARE":
            case 12:
                m.shareType = 12;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackExternalUserShareEvent.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackExternalUserShareEvent.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.senderUserId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.senderUserId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.sharedUserId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.sharedUserId = o.longs === String ? "0" : 0;
                d.shareType = o.enums === String ? "UNKNOWN_SHARE_TYPE" : 0;
                d.context = null;
            }
            if (m.senderUserId != null && m.hasOwnProperty("senderUserId")) {
                if (typeof m.senderUserId === "number")
                    d.senderUserId = o.longs === String ? String(m.senderUserId) : m.senderUserId;
                else
                    d.senderUserId = o.longs === String ? $util.Long.prototype.toString.call(m.senderUserId) : o.longs === Number ? new $util.LongBits(m.senderUserId.low >>> 0, m.senderUserId.high >>> 0).toNumber(true) : m.senderUserId;
            }
            if (m.sharedUserId != null && m.hasOwnProperty("sharedUserId")) {
                if (typeof m.sharedUserId === "number")
                    d.sharedUserId = o.longs === String ? String(m.sharedUserId) : m.sharedUserId;
                else
                    d.sharedUserId = o.longs === String ? $util.Long.prototype.toString.call(m.sharedUserId) : o.longs === Number ? new $util.LongBits(m.sharedUserId.low >>> 0, m.sharedUserId.high >>> 0).toNumber(true) : m.sharedUserId;
            }
            if (m.shareType != null && m.hasOwnProperty("shareType")) {
                d.shareType = o.enums === String ? $root.globals.ShareType[m.shareType] === undefined ? m.shareType : $root.globals.ShareType[m.shareType] : m.shareType;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackExternalUserShareEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackExternalUserShareEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackExternalUserShareEvent";
        };

        return TrackExternalUserShareEvent;
    })();

    commands.TrackExternalMixShareEvent = (function() {

        function TrackExternalMixShareEvent(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackExternalMixShareEvent.prototype.senderUserId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackExternalMixShareEvent.prototype.mixId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackExternalMixShareEvent.prototype.shareType = 0;
        TrackExternalMixShareEvent.prototype.context = null;

        TrackExternalMixShareEvent.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.senderUserId != null && Object.hasOwnProperty.call(m, "senderUserId"))
                w.uint32(8).uint64(m.senderUserId);
            if (m.mixId != null && Object.hasOwnProperty.call(m, "mixId"))
                w.uint32(16).uint64(m.mixId);
            if (m.shareType != null && Object.hasOwnProperty.call(m, "shareType"))
                w.uint32(24).int32(m.shareType);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(34).fork()).ldelim();
            return w;
        };

        TrackExternalMixShareEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackExternalMixShareEvent.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackExternalMixShareEvent();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.senderUserId = r.uint64();
                        break;
                    }
                case 2: {
                        m.mixId = r.uint64();
                        break;
                    }
                case 3: {
                        m.shareType = r.int32();
                        break;
                    }
                case 4: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackExternalMixShareEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackExternalMixShareEvent.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.senderUserId != null && m.hasOwnProperty("senderUserId")) {
                if (!$util.isInteger(m.senderUserId) && !(m.senderUserId && $util.isInteger(m.senderUserId.low) && $util.isInteger(m.senderUserId.high)))
                    return "senderUserId: integer|Long expected";
            }
            if (m.mixId != null && m.hasOwnProperty("mixId")) {
                if (!$util.isInteger(m.mixId) && !(m.mixId && $util.isInteger(m.mixId.low) && $util.isInteger(m.mixId.high)))
                    return "mixId: integer|Long expected";
            }
            if (m.shareType != null && m.hasOwnProperty("shareType")) {
                switch (m.shareType) {
                default:
                    return "shareType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackExternalMixShareEvent.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackExternalMixShareEvent)
                return d;
            var m = new $root.commands.TrackExternalMixShareEvent();
            if (d.senderUserId != null) {
                if ($util.Long)
                    (m.senderUserId = $util.Long.fromValue(d.senderUserId)).unsigned = true;
                else if (typeof d.senderUserId === "string")
                    m.senderUserId = parseInt(d.senderUserId, 10);
                else if (typeof d.senderUserId === "number")
                    m.senderUserId = d.senderUserId;
                else if (typeof d.senderUserId === "object")
                    m.senderUserId = new $util.LongBits(d.senderUserId.low >>> 0, d.senderUserId.high >>> 0).toNumber(true);
            }
            if (d.mixId != null) {
                if ($util.Long)
                    (m.mixId = $util.Long.fromValue(d.mixId)).unsigned = true;
                else if (typeof d.mixId === "string")
                    m.mixId = parseInt(d.mixId, 10);
                else if (typeof d.mixId === "number")
                    m.mixId = d.mixId;
                else if (typeof d.mixId === "object")
                    m.mixId = new $util.LongBits(d.mixId.low >>> 0, d.mixId.high >>> 0).toNumber(true);
            }
            switch (d.shareType) {
            default:
                if (typeof d.shareType === "number") {
                    m.shareType = d.shareType;
                    break;
                }
                break;
            case "UNKNOWN_SHARE_TYPE":
            case 0:
                m.shareType = 0;
                break;
            case "INTERNAL_SHARE":
            case 1:
                m.shareType = 1;
                break;
            case "FB_SHARE":
            case 2:
                m.shareType = 2;
                break;
            case "TWITTER_SHARE":
            case 3:
                m.shareType = 3;
                break;
            case "WHATSAPP_SHARE":
            case 4:
                m.shareType = 4;
                break;
            case "SMS_SHARE":
            case 5:
                m.shareType = 5;
                break;
            case "EMAIL_SHARE":
            case 6:
                m.shareType = 6;
                break;
            case "EXTERNAL_SHARE":
            case 7:
                m.shareType = 7;
                break;
            case "PINTEREST_SHARE":
            case 8:
                m.shareType = 8;
                break;
            case "SIGNAL_SHARE":
            case 9:
                m.shareType = 9;
                break;
            case "COPY_LINK":
            case 10:
                m.shareType = 10;
                break;
            case "INSTAGRAM_SHARE":
            case 11:
                m.shareType = 11;
                break;
            case "FB_MESSENGER_SHARE":
            case 12:
                m.shareType = 12;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackExternalMixShareEvent.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackExternalMixShareEvent.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.senderUserId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.senderUserId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.mixId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.mixId = o.longs === String ? "0" : 0;
                d.shareType = o.enums === String ? "UNKNOWN_SHARE_TYPE" : 0;
                d.context = null;
            }
            if (m.senderUserId != null && m.hasOwnProperty("senderUserId")) {
                if (typeof m.senderUserId === "number")
                    d.senderUserId = o.longs === String ? String(m.senderUserId) : m.senderUserId;
                else
                    d.senderUserId = o.longs === String ? $util.Long.prototype.toString.call(m.senderUserId) : o.longs === Number ? new $util.LongBits(m.senderUserId.low >>> 0, m.senderUserId.high >>> 0).toNumber(true) : m.senderUserId;
            }
            if (m.mixId != null && m.hasOwnProperty("mixId")) {
                if (typeof m.mixId === "number")
                    d.mixId = o.longs === String ? String(m.mixId) : m.mixId;
                else
                    d.mixId = o.longs === String ? $util.Long.prototype.toString.call(m.mixId) : o.longs === Number ? new $util.LongBits(m.mixId.low >>> 0, m.mixId.high >>> 0).toNumber(true) : m.mixId;
            }
            if (m.shareType != null && m.hasOwnProperty("shareType")) {
                d.shareType = o.enums === String ? $root.globals.ShareType[m.shareType] === undefined ? m.shareType : $root.globals.ShareType[m.shareType] : m.shareType;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackExternalMixShareEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackExternalMixShareEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackExternalMixShareEvent";
        };

        return TrackExternalMixShareEvent;
    })();

    commands.TrackContentDwell = (function() {

        function TrackContentDwell(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentDwell.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentDwell.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentDwell.prototype.timeSpent = 0;
        TrackContentDwell.prototype.contentTimeTotal = 0;
        TrackContentDwell.prototype.context = null;
        TrackContentDwell.prototype.perceivedMediaType = 0;
        TrackContentDwell.prototype.maxProgressPercentage = 0;
        TrackContentDwell.prototype.timeSpentMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentDwell.prototype.contentTimeTotalMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        TrackContentDwell.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.timeSpent != null && Object.hasOwnProperty.call(m, "timeSpent"))
                w.uint32(24).uint32(m.timeSpent);
            if (m.contentTimeTotal != null && Object.hasOwnProperty.call(m, "contentTimeTotal"))
                w.uint32(32).uint32(m.contentTimeTotal);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(42).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(48).int32(m.perceivedMediaType);
            if (m.maxProgressPercentage != null && Object.hasOwnProperty.call(m, "maxProgressPercentage"))
                w.uint32(61).float(m.maxProgressPercentage);
            if (m.timeSpentMsec != null && Object.hasOwnProperty.call(m, "timeSpentMsec"))
                w.uint32(64).uint64(m.timeSpentMsec);
            if (m.contentTimeTotalMsec != null && Object.hasOwnProperty.call(m, "contentTimeTotalMsec"))
                w.uint32(72).uint64(m.contentTimeTotalMsec);
            return w;
        };

        TrackContentDwell.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentDwell.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentDwell();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.timeSpent = r.uint32();
                        break;
                    }
                case 4: {
                        m.contentTimeTotal = r.uint32();
                        break;
                    }
                case 5: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                case 7: {
                        m.maxProgressPercentage = r.float();
                        break;
                    }
                case 8: {
                        m.timeSpentMsec = r.uint64();
                        break;
                    }
                case 9: {
                        m.contentTimeTotalMsec = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentDwell.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentDwell.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.timeSpent != null && m.hasOwnProperty("timeSpent")) {
                if (!$util.isInteger(m.timeSpent))
                    return "timeSpent: integer expected";
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                if (!$util.isInteger(m.contentTimeTotal))
                    return "contentTimeTotal: integer expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.maxProgressPercentage != null && m.hasOwnProperty("maxProgressPercentage")) {
                if (typeof m.maxProgressPercentage !== "number")
                    return "maxProgressPercentage: number expected";
            }
            if (m.timeSpentMsec != null && m.hasOwnProperty("timeSpentMsec")) {
                if (!$util.isInteger(m.timeSpentMsec) && !(m.timeSpentMsec && $util.isInteger(m.timeSpentMsec.low) && $util.isInteger(m.timeSpentMsec.high)))
                    return "timeSpentMsec: integer|Long expected";
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (!$util.isInteger(m.contentTimeTotalMsec) && !(m.contentTimeTotalMsec && $util.isInteger(m.contentTimeTotalMsec.low) && $util.isInteger(m.contentTimeTotalMsec.high)))
                    return "contentTimeTotalMsec: integer|Long expected";
            }
            return null;
        };

        TrackContentDwell.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentDwell)
                return d;
            var m = new $root.commands.TrackContentDwell();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.timeSpent != null) {
                m.timeSpent = d.timeSpent >>> 0;
            }
            if (d.contentTimeTotal != null) {
                m.contentTimeTotal = d.contentTimeTotal >>> 0;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentDwell.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            if (d.maxProgressPercentage != null) {
                m.maxProgressPercentage = Number(d.maxProgressPercentage);
            }
            if (d.timeSpentMsec != null) {
                if ($util.Long)
                    (m.timeSpentMsec = $util.Long.fromValue(d.timeSpentMsec)).unsigned = true;
                else if (typeof d.timeSpentMsec === "string")
                    m.timeSpentMsec = parseInt(d.timeSpentMsec, 10);
                else if (typeof d.timeSpentMsec === "number")
                    m.timeSpentMsec = d.timeSpentMsec;
                else if (typeof d.timeSpentMsec === "object")
                    m.timeSpentMsec = new $util.LongBits(d.timeSpentMsec.low >>> 0, d.timeSpentMsec.high >>> 0).toNumber(true);
            }
            if (d.contentTimeTotalMsec != null) {
                if ($util.Long)
                    (m.contentTimeTotalMsec = $util.Long.fromValue(d.contentTimeTotalMsec)).unsigned = true;
                else if (typeof d.contentTimeTotalMsec === "string")
                    m.contentTimeTotalMsec = parseInt(d.contentTimeTotalMsec, 10);
                else if (typeof d.contentTimeTotalMsec === "number")
                    m.contentTimeTotalMsec = d.contentTimeTotalMsec;
                else if (typeof d.contentTimeTotalMsec === "object")
                    m.contentTimeTotalMsec = new $util.LongBits(d.contentTimeTotalMsec.low >>> 0, d.contentTimeTotalMsec.high >>> 0).toNumber(true);
            }
            return m;
        };

        TrackContentDwell.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.timeSpent = 0;
                d.contentTimeTotal = 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
                d.maxProgressPercentage = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timeSpentMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timeSpentMsec = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.contentTimeTotalMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentTimeTotalMsec = o.longs === String ? "0" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.timeSpent != null && m.hasOwnProperty("timeSpent")) {
                d.timeSpent = m.timeSpent;
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                d.contentTimeTotal = m.contentTimeTotal;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            if (m.maxProgressPercentage != null && m.hasOwnProperty("maxProgressPercentage")) {
                d.maxProgressPercentage = o.json && !isFinite(m.maxProgressPercentage) ? String(m.maxProgressPercentage) : m.maxProgressPercentage;
            }
            if (m.timeSpentMsec != null && m.hasOwnProperty("timeSpentMsec")) {
                if (typeof m.timeSpentMsec === "number")
                    d.timeSpentMsec = o.longs === String ? String(m.timeSpentMsec) : m.timeSpentMsec;
                else
                    d.timeSpentMsec = o.longs === String ? $util.Long.prototype.toString.call(m.timeSpentMsec) : o.longs === Number ? new $util.LongBits(m.timeSpentMsec.low >>> 0, m.timeSpentMsec.high >>> 0).toNumber(true) : m.timeSpentMsec;
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (typeof m.contentTimeTotalMsec === "number")
                    d.contentTimeTotalMsec = o.longs === String ? String(m.contentTimeTotalMsec) : m.contentTimeTotalMsec;
                else
                    d.contentTimeTotalMsec = o.longs === String ? $util.Long.prototype.toString.call(m.contentTimeTotalMsec) : o.longs === Number ? new $util.LongBits(m.contentTimeTotalMsec.low >>> 0, m.contentTimeTotalMsec.high >>> 0).toNumber(true) : m.contentTimeTotalMsec;
            }
            return d;
        };

        TrackContentDwell.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentDwell.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentDwell";
        };

        return TrackContentDwell;
    })();

    commands.TrackContentPlay = (function() {

        function TrackContentPlay(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentPlay.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentPlay.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentPlay.prototype.timeOffset = 0;
        TrackContentPlay.prototype.contentTimeTotal = 0;
        TrackContentPlay.prototype.autoplay = false;
        TrackContentPlay.prototype.replayCount = 0;
        TrackContentPlay.prototype.context = null;
        TrackContentPlay.prototype.perceivedMediaType = 0;
        TrackContentPlay.prototype.timeOffsetMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentPlay.prototype.contentTimeTotalMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        TrackContentPlay.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.timeOffset != null && Object.hasOwnProperty.call(m, "timeOffset"))
                w.uint32(24).uint32(m.timeOffset);
            if (m.contentTimeTotal != null && Object.hasOwnProperty.call(m, "contentTimeTotal"))
                w.uint32(32).uint32(m.contentTimeTotal);
            if (m.autoplay != null && Object.hasOwnProperty.call(m, "autoplay"))
                w.uint32(40).bool(m.autoplay);
            if (m.replayCount != null && Object.hasOwnProperty.call(m, "replayCount"))
                w.uint32(48).uint32(m.replayCount);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(58).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(64).int32(m.perceivedMediaType);
            if (m.timeOffsetMsec != null && Object.hasOwnProperty.call(m, "timeOffsetMsec"))
                w.uint32(72).uint64(m.timeOffsetMsec);
            if (m.contentTimeTotalMsec != null && Object.hasOwnProperty.call(m, "contentTimeTotalMsec"))
                w.uint32(80).uint64(m.contentTimeTotalMsec);
            return w;
        };

        TrackContentPlay.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentPlay.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentPlay();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.timeOffset = r.uint32();
                        break;
                    }
                case 4: {
                        m.contentTimeTotal = r.uint32();
                        break;
                    }
                case 5: {
                        m.autoplay = r.bool();
                        break;
                    }
                case 6: {
                        m.replayCount = r.uint32();
                        break;
                    }
                case 7: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                case 9: {
                        m.timeOffsetMsec = r.uint64();
                        break;
                    }
                case 10: {
                        m.contentTimeTotalMsec = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentPlay.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentPlay.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.timeOffset != null && m.hasOwnProperty("timeOffset")) {
                if (!$util.isInteger(m.timeOffset))
                    return "timeOffset: integer expected";
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                if (!$util.isInteger(m.contentTimeTotal))
                    return "contentTimeTotal: integer expected";
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                if (typeof m.autoplay !== "boolean")
                    return "autoplay: boolean expected";
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                if (!$util.isInteger(m.replayCount))
                    return "replayCount: integer expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.timeOffsetMsec != null && m.hasOwnProperty("timeOffsetMsec")) {
                if (!$util.isInteger(m.timeOffsetMsec) && !(m.timeOffsetMsec && $util.isInteger(m.timeOffsetMsec.low) && $util.isInteger(m.timeOffsetMsec.high)))
                    return "timeOffsetMsec: integer|Long expected";
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (!$util.isInteger(m.contentTimeTotalMsec) && !(m.contentTimeTotalMsec && $util.isInteger(m.contentTimeTotalMsec.low) && $util.isInteger(m.contentTimeTotalMsec.high)))
                    return "contentTimeTotalMsec: integer|Long expected";
            }
            return null;
        };

        TrackContentPlay.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentPlay)
                return d;
            var m = new $root.commands.TrackContentPlay();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.timeOffset != null) {
                m.timeOffset = d.timeOffset >>> 0;
            }
            if (d.contentTimeTotal != null) {
                m.contentTimeTotal = d.contentTimeTotal >>> 0;
            }
            if (d.autoplay != null) {
                m.autoplay = Boolean(d.autoplay);
            }
            if (d.replayCount != null) {
                m.replayCount = d.replayCount >>> 0;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentPlay.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            if (d.timeOffsetMsec != null) {
                if ($util.Long)
                    (m.timeOffsetMsec = $util.Long.fromValue(d.timeOffsetMsec)).unsigned = true;
                else if (typeof d.timeOffsetMsec === "string")
                    m.timeOffsetMsec = parseInt(d.timeOffsetMsec, 10);
                else if (typeof d.timeOffsetMsec === "number")
                    m.timeOffsetMsec = d.timeOffsetMsec;
                else if (typeof d.timeOffsetMsec === "object")
                    m.timeOffsetMsec = new $util.LongBits(d.timeOffsetMsec.low >>> 0, d.timeOffsetMsec.high >>> 0).toNumber(true);
            }
            if (d.contentTimeTotalMsec != null) {
                if ($util.Long)
                    (m.contentTimeTotalMsec = $util.Long.fromValue(d.contentTimeTotalMsec)).unsigned = true;
                else if (typeof d.contentTimeTotalMsec === "string")
                    m.contentTimeTotalMsec = parseInt(d.contentTimeTotalMsec, 10);
                else if (typeof d.contentTimeTotalMsec === "number")
                    m.contentTimeTotalMsec = d.contentTimeTotalMsec;
                else if (typeof d.contentTimeTotalMsec === "object")
                    m.contentTimeTotalMsec = new $util.LongBits(d.contentTimeTotalMsec.low >>> 0, d.contentTimeTotalMsec.high >>> 0).toNumber(true);
            }
            return m;
        };

        TrackContentPlay.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.timeOffset = 0;
                d.contentTimeTotal = 0;
                d.autoplay = false;
                d.replayCount = 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timeOffsetMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timeOffsetMsec = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.contentTimeTotalMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentTimeTotalMsec = o.longs === String ? "0" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.timeOffset != null && m.hasOwnProperty("timeOffset")) {
                d.timeOffset = m.timeOffset;
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                d.contentTimeTotal = m.contentTimeTotal;
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                d.autoplay = m.autoplay;
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                d.replayCount = m.replayCount;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            if (m.timeOffsetMsec != null && m.hasOwnProperty("timeOffsetMsec")) {
                if (typeof m.timeOffsetMsec === "number")
                    d.timeOffsetMsec = o.longs === String ? String(m.timeOffsetMsec) : m.timeOffsetMsec;
                else
                    d.timeOffsetMsec = o.longs === String ? $util.Long.prototype.toString.call(m.timeOffsetMsec) : o.longs === Number ? new $util.LongBits(m.timeOffsetMsec.low >>> 0, m.timeOffsetMsec.high >>> 0).toNumber(true) : m.timeOffsetMsec;
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (typeof m.contentTimeTotalMsec === "number")
                    d.contentTimeTotalMsec = o.longs === String ? String(m.contentTimeTotalMsec) : m.contentTimeTotalMsec;
                else
                    d.contentTimeTotalMsec = o.longs === String ? $util.Long.prototype.toString.call(m.contentTimeTotalMsec) : o.longs === Number ? new $util.LongBits(m.contentTimeTotalMsec.low >>> 0, m.contentTimeTotalMsec.high >>> 0).toNumber(true) : m.contentTimeTotalMsec;
            }
            return d;
        };

        TrackContentPlay.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentPlay.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentPlay";
        };

        return TrackContentPlay;
    })();

    commands.TrackContentEnded = (function() {

        function TrackContentEnded(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentEnded.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentEnded.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentEnded.prototype.contentTimeTotal = 0;
        TrackContentEnded.prototype.autoplay = false;
        TrackContentEnded.prototype.replayCount = 0;
        TrackContentEnded.prototype.context = null;
        TrackContentEnded.prototype.perceivedMediaType = 0;
        TrackContentEnded.prototype.contentTimeTotalMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        TrackContentEnded.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.contentTimeTotal != null && Object.hasOwnProperty.call(m, "contentTimeTotal"))
                w.uint32(24).uint32(m.contentTimeTotal);
            if (m.autoplay != null && Object.hasOwnProperty.call(m, "autoplay"))
                w.uint32(32).bool(m.autoplay);
            if (m.replayCount != null && Object.hasOwnProperty.call(m, "replayCount"))
                w.uint32(40).uint32(m.replayCount);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(50).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(56).int32(m.perceivedMediaType);
            if (m.contentTimeTotalMsec != null && Object.hasOwnProperty.call(m, "contentTimeTotalMsec"))
                w.uint32(64).uint64(m.contentTimeTotalMsec);
            return w;
        };

        TrackContentEnded.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentEnded.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentEnded();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.contentTimeTotal = r.uint32();
                        break;
                    }
                case 4: {
                        m.autoplay = r.bool();
                        break;
                    }
                case 5: {
                        m.replayCount = r.uint32();
                        break;
                    }
                case 6: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                case 8: {
                        m.contentTimeTotalMsec = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentEnded.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentEnded.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                if (!$util.isInteger(m.contentTimeTotal))
                    return "contentTimeTotal: integer expected";
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                if (typeof m.autoplay !== "boolean")
                    return "autoplay: boolean expected";
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                if (!$util.isInteger(m.replayCount))
                    return "replayCount: integer expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (!$util.isInteger(m.contentTimeTotalMsec) && !(m.contentTimeTotalMsec && $util.isInteger(m.contentTimeTotalMsec.low) && $util.isInteger(m.contentTimeTotalMsec.high)))
                    return "contentTimeTotalMsec: integer|Long expected";
            }
            return null;
        };

        TrackContentEnded.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentEnded)
                return d;
            var m = new $root.commands.TrackContentEnded();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.contentTimeTotal != null) {
                m.contentTimeTotal = d.contentTimeTotal >>> 0;
            }
            if (d.autoplay != null) {
                m.autoplay = Boolean(d.autoplay);
            }
            if (d.replayCount != null) {
                m.replayCount = d.replayCount >>> 0;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentEnded.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            if (d.contentTimeTotalMsec != null) {
                if ($util.Long)
                    (m.contentTimeTotalMsec = $util.Long.fromValue(d.contentTimeTotalMsec)).unsigned = true;
                else if (typeof d.contentTimeTotalMsec === "string")
                    m.contentTimeTotalMsec = parseInt(d.contentTimeTotalMsec, 10);
                else if (typeof d.contentTimeTotalMsec === "number")
                    m.contentTimeTotalMsec = d.contentTimeTotalMsec;
                else if (typeof d.contentTimeTotalMsec === "object")
                    m.contentTimeTotalMsec = new $util.LongBits(d.contentTimeTotalMsec.low >>> 0, d.contentTimeTotalMsec.high >>> 0).toNumber(true);
            }
            return m;
        };

        TrackContentEnded.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.contentTimeTotal = 0;
                d.autoplay = false;
                d.replayCount = 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.contentTimeTotalMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentTimeTotalMsec = o.longs === String ? "0" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                d.contentTimeTotal = m.contentTimeTotal;
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                d.autoplay = m.autoplay;
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                d.replayCount = m.replayCount;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (typeof m.contentTimeTotalMsec === "number")
                    d.contentTimeTotalMsec = o.longs === String ? String(m.contentTimeTotalMsec) : m.contentTimeTotalMsec;
                else
                    d.contentTimeTotalMsec = o.longs === String ? $util.Long.prototype.toString.call(m.contentTimeTotalMsec) : o.longs === Number ? new $util.LongBits(m.contentTimeTotalMsec.low >>> 0, m.contentTimeTotalMsec.high >>> 0).toNumber(true) : m.contentTimeTotalMsec;
            }
            return d;
        };

        TrackContentEnded.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentEnded.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentEnded";
        };

        return TrackContentEnded;
    })();

    commands.TrackContentTimeWatched = (function() {

        function TrackContentTimeWatched(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentTimeWatched.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentTimeWatched.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentTimeWatched.prototype.timeSpent = 0;
        TrackContentTimeWatched.prototype.contentTimeTotal = 0;
        TrackContentTimeWatched.prototype.autoplay = false;
        TrackContentTimeWatched.prototype.replayCount = 0;
        TrackContentTimeWatched.prototype.context = null;
        TrackContentTimeWatched.prototype.perceivedMediaType = 0;
        TrackContentTimeWatched.prototype.timeSpentMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentTimeWatched.prototype.contentTimeTotalMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        TrackContentTimeWatched.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.timeSpent != null && Object.hasOwnProperty.call(m, "timeSpent"))
                w.uint32(24).uint32(m.timeSpent);
            if (m.contentTimeTotal != null && Object.hasOwnProperty.call(m, "contentTimeTotal"))
                w.uint32(32).uint32(m.contentTimeTotal);
            if (m.autoplay != null && Object.hasOwnProperty.call(m, "autoplay"))
                w.uint32(40).bool(m.autoplay);
            if (m.replayCount != null && Object.hasOwnProperty.call(m, "replayCount"))
                w.uint32(48).uint32(m.replayCount);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(58).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(64).int32(m.perceivedMediaType);
            if (m.timeSpentMsec != null && Object.hasOwnProperty.call(m, "timeSpentMsec"))
                w.uint32(72).uint64(m.timeSpentMsec);
            if (m.contentTimeTotalMsec != null && Object.hasOwnProperty.call(m, "contentTimeTotalMsec"))
                w.uint32(80).uint64(m.contentTimeTotalMsec);
            return w;
        };

        TrackContentTimeWatched.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentTimeWatched.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentTimeWatched();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.timeSpent = r.uint32();
                        break;
                    }
                case 4: {
                        m.contentTimeTotal = r.uint32();
                        break;
                    }
                case 5: {
                        m.autoplay = r.bool();
                        break;
                    }
                case 6: {
                        m.replayCount = r.uint32();
                        break;
                    }
                case 7: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                case 9: {
                        m.timeSpentMsec = r.uint64();
                        break;
                    }
                case 10: {
                        m.contentTimeTotalMsec = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentTimeWatched.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentTimeWatched.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.timeSpent != null && m.hasOwnProperty("timeSpent")) {
                if (!$util.isInteger(m.timeSpent))
                    return "timeSpent: integer expected";
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                if (!$util.isInteger(m.contentTimeTotal))
                    return "contentTimeTotal: integer expected";
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                if (typeof m.autoplay !== "boolean")
                    return "autoplay: boolean expected";
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                if (!$util.isInteger(m.replayCount))
                    return "replayCount: integer expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.timeSpentMsec != null && m.hasOwnProperty("timeSpentMsec")) {
                if (!$util.isInteger(m.timeSpentMsec) && !(m.timeSpentMsec && $util.isInteger(m.timeSpentMsec.low) && $util.isInteger(m.timeSpentMsec.high)))
                    return "timeSpentMsec: integer|Long expected";
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (!$util.isInteger(m.contentTimeTotalMsec) && !(m.contentTimeTotalMsec && $util.isInteger(m.contentTimeTotalMsec.low) && $util.isInteger(m.contentTimeTotalMsec.high)))
                    return "contentTimeTotalMsec: integer|Long expected";
            }
            return null;
        };

        TrackContentTimeWatched.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentTimeWatched)
                return d;
            var m = new $root.commands.TrackContentTimeWatched();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.timeSpent != null) {
                m.timeSpent = d.timeSpent >>> 0;
            }
            if (d.contentTimeTotal != null) {
                m.contentTimeTotal = d.contentTimeTotal >>> 0;
            }
            if (d.autoplay != null) {
                m.autoplay = Boolean(d.autoplay);
            }
            if (d.replayCount != null) {
                m.replayCount = d.replayCount >>> 0;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentTimeWatched.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            if (d.timeSpentMsec != null) {
                if ($util.Long)
                    (m.timeSpentMsec = $util.Long.fromValue(d.timeSpentMsec)).unsigned = true;
                else if (typeof d.timeSpentMsec === "string")
                    m.timeSpentMsec = parseInt(d.timeSpentMsec, 10);
                else if (typeof d.timeSpentMsec === "number")
                    m.timeSpentMsec = d.timeSpentMsec;
                else if (typeof d.timeSpentMsec === "object")
                    m.timeSpentMsec = new $util.LongBits(d.timeSpentMsec.low >>> 0, d.timeSpentMsec.high >>> 0).toNumber(true);
            }
            if (d.contentTimeTotalMsec != null) {
                if ($util.Long)
                    (m.contentTimeTotalMsec = $util.Long.fromValue(d.contentTimeTotalMsec)).unsigned = true;
                else if (typeof d.contentTimeTotalMsec === "string")
                    m.contentTimeTotalMsec = parseInt(d.contentTimeTotalMsec, 10);
                else if (typeof d.contentTimeTotalMsec === "number")
                    m.contentTimeTotalMsec = d.contentTimeTotalMsec;
                else if (typeof d.contentTimeTotalMsec === "object")
                    m.contentTimeTotalMsec = new $util.LongBits(d.contentTimeTotalMsec.low >>> 0, d.contentTimeTotalMsec.high >>> 0).toNumber(true);
            }
            return m;
        };

        TrackContentTimeWatched.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.timeSpent = 0;
                d.contentTimeTotal = 0;
                d.autoplay = false;
                d.replayCount = 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timeSpentMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timeSpentMsec = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.contentTimeTotalMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentTimeTotalMsec = o.longs === String ? "0" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.timeSpent != null && m.hasOwnProperty("timeSpent")) {
                d.timeSpent = m.timeSpent;
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                d.contentTimeTotal = m.contentTimeTotal;
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                d.autoplay = m.autoplay;
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                d.replayCount = m.replayCount;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            if (m.timeSpentMsec != null && m.hasOwnProperty("timeSpentMsec")) {
                if (typeof m.timeSpentMsec === "number")
                    d.timeSpentMsec = o.longs === String ? String(m.timeSpentMsec) : m.timeSpentMsec;
                else
                    d.timeSpentMsec = o.longs === String ? $util.Long.prototype.toString.call(m.timeSpentMsec) : o.longs === Number ? new $util.LongBits(m.timeSpentMsec.low >>> 0, m.timeSpentMsec.high >>> 0).toNumber(true) : m.timeSpentMsec;
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (typeof m.contentTimeTotalMsec === "number")
                    d.contentTimeTotalMsec = o.longs === String ? String(m.contentTimeTotalMsec) : m.contentTimeTotalMsec;
                else
                    d.contentTimeTotalMsec = o.longs === String ? $util.Long.prototype.toString.call(m.contentTimeTotalMsec) : o.longs === Number ? new $util.LongBits(m.contentTimeTotalMsec.low >>> 0, m.contentTimeTotalMsec.high >>> 0).toNumber(true) : m.contentTimeTotalMsec;
            }
            return d;
        };

        TrackContentTimeWatched.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentTimeWatched.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentTimeWatched";
        };

        return TrackContentTimeWatched;
    })();

    commands.TrackContentTimePlayed = (function() {

        function TrackContentTimePlayed(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentTimePlayed.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentTimePlayed.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentTimePlayed.prototype.timeSpent = 0;
        TrackContentTimePlayed.prototype.contentTimeTotal = 0;
        TrackContentTimePlayed.prototype.autoplay = false;
        TrackContentTimePlayed.prototype.replayCount = 0;
        TrackContentTimePlayed.prototype.context = null;
        TrackContentTimePlayed.prototype.perceivedMediaType = 0;
        TrackContentTimePlayed.prototype.timeSpentMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentTimePlayed.prototype.contentTimeTotalMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        TrackContentTimePlayed.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.timeSpent != null && Object.hasOwnProperty.call(m, "timeSpent"))
                w.uint32(24).uint32(m.timeSpent);
            if (m.contentTimeTotal != null && Object.hasOwnProperty.call(m, "contentTimeTotal"))
                w.uint32(32).uint32(m.contentTimeTotal);
            if (m.autoplay != null && Object.hasOwnProperty.call(m, "autoplay"))
                w.uint32(40).bool(m.autoplay);
            if (m.replayCount != null && Object.hasOwnProperty.call(m, "replayCount"))
                w.uint32(48).uint32(m.replayCount);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(58).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(64).int32(m.perceivedMediaType);
            if (m.timeSpentMsec != null && Object.hasOwnProperty.call(m, "timeSpentMsec"))
                w.uint32(72).uint64(m.timeSpentMsec);
            if (m.contentTimeTotalMsec != null && Object.hasOwnProperty.call(m, "contentTimeTotalMsec"))
                w.uint32(80).uint64(m.contentTimeTotalMsec);
            return w;
        };

        TrackContentTimePlayed.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentTimePlayed.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentTimePlayed();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.timeSpent = r.uint32();
                        break;
                    }
                case 4: {
                        m.contentTimeTotal = r.uint32();
                        break;
                    }
                case 5: {
                        m.autoplay = r.bool();
                        break;
                    }
                case 6: {
                        m.replayCount = r.uint32();
                        break;
                    }
                case 7: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                case 9: {
                        m.timeSpentMsec = r.uint64();
                        break;
                    }
                case 10: {
                        m.contentTimeTotalMsec = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentTimePlayed.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentTimePlayed.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.timeSpent != null && m.hasOwnProperty("timeSpent")) {
                if (!$util.isInteger(m.timeSpent))
                    return "timeSpent: integer expected";
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                if (!$util.isInteger(m.contentTimeTotal))
                    return "contentTimeTotal: integer expected";
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                if (typeof m.autoplay !== "boolean")
                    return "autoplay: boolean expected";
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                if (!$util.isInteger(m.replayCount))
                    return "replayCount: integer expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.timeSpentMsec != null && m.hasOwnProperty("timeSpentMsec")) {
                if (!$util.isInteger(m.timeSpentMsec) && !(m.timeSpentMsec && $util.isInteger(m.timeSpentMsec.low) && $util.isInteger(m.timeSpentMsec.high)))
                    return "timeSpentMsec: integer|Long expected";
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (!$util.isInteger(m.contentTimeTotalMsec) && !(m.contentTimeTotalMsec && $util.isInteger(m.contentTimeTotalMsec.low) && $util.isInteger(m.contentTimeTotalMsec.high)))
                    return "contentTimeTotalMsec: integer|Long expected";
            }
            return null;
        };

        TrackContentTimePlayed.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentTimePlayed)
                return d;
            var m = new $root.commands.TrackContentTimePlayed();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.timeSpent != null) {
                m.timeSpent = d.timeSpent >>> 0;
            }
            if (d.contentTimeTotal != null) {
                m.contentTimeTotal = d.contentTimeTotal >>> 0;
            }
            if (d.autoplay != null) {
                m.autoplay = Boolean(d.autoplay);
            }
            if (d.replayCount != null) {
                m.replayCount = d.replayCount >>> 0;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentTimePlayed.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            if (d.timeSpentMsec != null) {
                if ($util.Long)
                    (m.timeSpentMsec = $util.Long.fromValue(d.timeSpentMsec)).unsigned = true;
                else if (typeof d.timeSpentMsec === "string")
                    m.timeSpentMsec = parseInt(d.timeSpentMsec, 10);
                else if (typeof d.timeSpentMsec === "number")
                    m.timeSpentMsec = d.timeSpentMsec;
                else if (typeof d.timeSpentMsec === "object")
                    m.timeSpentMsec = new $util.LongBits(d.timeSpentMsec.low >>> 0, d.timeSpentMsec.high >>> 0).toNumber(true);
            }
            if (d.contentTimeTotalMsec != null) {
                if ($util.Long)
                    (m.contentTimeTotalMsec = $util.Long.fromValue(d.contentTimeTotalMsec)).unsigned = true;
                else if (typeof d.contentTimeTotalMsec === "string")
                    m.contentTimeTotalMsec = parseInt(d.contentTimeTotalMsec, 10);
                else if (typeof d.contentTimeTotalMsec === "number")
                    m.contentTimeTotalMsec = d.contentTimeTotalMsec;
                else if (typeof d.contentTimeTotalMsec === "object")
                    m.contentTimeTotalMsec = new $util.LongBits(d.contentTimeTotalMsec.low >>> 0, d.contentTimeTotalMsec.high >>> 0).toNumber(true);
            }
            return m;
        };

        TrackContentTimePlayed.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.timeSpent = 0;
                d.contentTimeTotal = 0;
                d.autoplay = false;
                d.replayCount = 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timeSpentMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timeSpentMsec = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.contentTimeTotalMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentTimeTotalMsec = o.longs === String ? "0" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.timeSpent != null && m.hasOwnProperty("timeSpent")) {
                d.timeSpent = m.timeSpent;
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                d.contentTimeTotal = m.contentTimeTotal;
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                d.autoplay = m.autoplay;
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                d.replayCount = m.replayCount;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            if (m.timeSpentMsec != null && m.hasOwnProperty("timeSpentMsec")) {
                if (typeof m.timeSpentMsec === "number")
                    d.timeSpentMsec = o.longs === String ? String(m.timeSpentMsec) : m.timeSpentMsec;
                else
                    d.timeSpentMsec = o.longs === String ? $util.Long.prototype.toString.call(m.timeSpentMsec) : o.longs === Number ? new $util.LongBits(m.timeSpentMsec.low >>> 0, m.timeSpentMsec.high >>> 0).toNumber(true) : m.timeSpentMsec;
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (typeof m.contentTimeTotalMsec === "number")
                    d.contentTimeTotalMsec = o.longs === String ? String(m.contentTimeTotalMsec) : m.contentTimeTotalMsec;
                else
                    d.contentTimeTotalMsec = o.longs === String ? $util.Long.prototype.toString.call(m.contentTimeTotalMsec) : o.longs === Number ? new $util.LongBits(m.contentTimeTotalMsec.low >>> 0, m.contentTimeTotalMsec.high >>> 0).toNumber(true) : m.contentTimeTotalMsec;
            }
            return d;
        };

        TrackContentTimePlayed.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentTimePlayed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentTimePlayed";
        };

        return TrackContentTimePlayed;
    })();

    commands.TrackContentPlayError = (function() {

        function TrackContentPlayError(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentPlayError.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentPlayError.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentPlayError.prototype.timeOffset = 0;
        TrackContentPlayError.prototype.contentTimeTotal = 0;
        TrackContentPlayError.prototype.autoplay = false;
        TrackContentPlayError.prototype.replayCount = 0;
        TrackContentPlayError.prototype.errorReason = 0;
        TrackContentPlayError.prototype.context = null;
        TrackContentPlayError.prototype.perceivedMediaType = 0;
        TrackContentPlayError.prototype.timeOffsetMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentPlayError.prototype.contentTimeTotalMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        TrackContentPlayError.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.timeOffset != null && Object.hasOwnProperty.call(m, "timeOffset"))
                w.uint32(24).uint32(m.timeOffset);
            if (m.contentTimeTotal != null && Object.hasOwnProperty.call(m, "contentTimeTotal"))
                w.uint32(32).uint32(m.contentTimeTotal);
            if (m.autoplay != null && Object.hasOwnProperty.call(m, "autoplay"))
                w.uint32(40).bool(m.autoplay);
            if (m.replayCount != null && Object.hasOwnProperty.call(m, "replayCount"))
                w.uint32(48).uint32(m.replayCount);
            if (m.errorReason != null && Object.hasOwnProperty.call(m, "errorReason"))
                w.uint32(56).int32(m.errorReason);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(66).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(72).int32(m.perceivedMediaType);
            if (m.timeOffsetMsec != null && Object.hasOwnProperty.call(m, "timeOffsetMsec"))
                w.uint32(80).uint64(m.timeOffsetMsec);
            if (m.contentTimeTotalMsec != null && Object.hasOwnProperty.call(m, "contentTimeTotalMsec"))
                w.uint32(88).uint64(m.contentTimeTotalMsec);
            return w;
        };

        TrackContentPlayError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentPlayError.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentPlayError();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.timeOffset = r.uint32();
                        break;
                    }
                case 4: {
                        m.contentTimeTotal = r.uint32();
                        break;
                    }
                case 5: {
                        m.autoplay = r.bool();
                        break;
                    }
                case 6: {
                        m.replayCount = r.uint32();
                        break;
                    }
                case 7: {
                        m.errorReason = r.int32();
                        break;
                    }
                case 8: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 9: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                case 10: {
                        m.timeOffsetMsec = r.uint64();
                        break;
                    }
                case 11: {
                        m.contentTimeTotalMsec = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentPlayError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentPlayError.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.timeOffset != null && m.hasOwnProperty("timeOffset")) {
                if (!$util.isInteger(m.timeOffset))
                    return "timeOffset: integer expected";
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                if (!$util.isInteger(m.contentTimeTotal))
                    return "contentTimeTotal: integer expected";
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                if (typeof m.autoplay !== "boolean")
                    return "autoplay: boolean expected";
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                if (!$util.isInteger(m.replayCount))
                    return "replayCount: integer expected";
            }
            if (m.errorReason != null && m.hasOwnProperty("errorReason")) {
                switch (m.errorReason) {
                default:
                    return "errorReason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.timeOffsetMsec != null && m.hasOwnProperty("timeOffsetMsec")) {
                if (!$util.isInteger(m.timeOffsetMsec) && !(m.timeOffsetMsec && $util.isInteger(m.timeOffsetMsec.low) && $util.isInteger(m.timeOffsetMsec.high)))
                    return "timeOffsetMsec: integer|Long expected";
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (!$util.isInteger(m.contentTimeTotalMsec) && !(m.contentTimeTotalMsec && $util.isInteger(m.contentTimeTotalMsec.low) && $util.isInteger(m.contentTimeTotalMsec.high)))
                    return "contentTimeTotalMsec: integer|Long expected";
            }
            return null;
        };

        TrackContentPlayError.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentPlayError)
                return d;
            var m = new $root.commands.TrackContentPlayError();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.timeOffset != null) {
                m.timeOffset = d.timeOffset >>> 0;
            }
            if (d.contentTimeTotal != null) {
                m.contentTimeTotal = d.contentTimeTotal >>> 0;
            }
            if (d.autoplay != null) {
                m.autoplay = Boolean(d.autoplay);
            }
            if (d.replayCount != null) {
                m.replayCount = d.replayCount >>> 0;
            }
            switch (d.errorReason) {
            default:
                if (typeof d.errorReason === "number") {
                    m.errorReason = d.errorReason;
                    break;
                }
                break;
            case "UNKNOWN_LOAD_ERROR_REASON":
            case 0:
                m.errorReason = 0;
                break;
            case "FAILED_TO_GET_STREAM":
            case 1:
                m.errorReason = 1;
                break;
            case "FAILED_TO_PLAY_CONTENT":
            case 2:
                m.errorReason = 2;
                break;
            case "FAILED_TO_GET_CONTENT":
            case 3:
                m.errorReason = 3;
                break;
            case "FAILED_TO_DISPLAY_CONTENT":
            case 4:
                m.errorReason = 4;
                break;
            case "FAILED_TO_CONNECT":
            case 5:
                m.errorReason = 5;
                break;
            case "HTTP_CLIENT_ERROR":
            case 6:
                m.errorReason = 6;
                break;
            case "HTTP_SERVER_ERROR":
            case 7:
                m.errorReason = 7;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentPlayError.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            if (d.timeOffsetMsec != null) {
                if ($util.Long)
                    (m.timeOffsetMsec = $util.Long.fromValue(d.timeOffsetMsec)).unsigned = true;
                else if (typeof d.timeOffsetMsec === "string")
                    m.timeOffsetMsec = parseInt(d.timeOffsetMsec, 10);
                else if (typeof d.timeOffsetMsec === "number")
                    m.timeOffsetMsec = d.timeOffsetMsec;
                else if (typeof d.timeOffsetMsec === "object")
                    m.timeOffsetMsec = new $util.LongBits(d.timeOffsetMsec.low >>> 0, d.timeOffsetMsec.high >>> 0).toNumber(true);
            }
            if (d.contentTimeTotalMsec != null) {
                if ($util.Long)
                    (m.contentTimeTotalMsec = $util.Long.fromValue(d.contentTimeTotalMsec)).unsigned = true;
                else if (typeof d.contentTimeTotalMsec === "string")
                    m.contentTimeTotalMsec = parseInt(d.contentTimeTotalMsec, 10);
                else if (typeof d.contentTimeTotalMsec === "number")
                    m.contentTimeTotalMsec = d.contentTimeTotalMsec;
                else if (typeof d.contentTimeTotalMsec === "object")
                    m.contentTimeTotalMsec = new $util.LongBits(d.contentTimeTotalMsec.low >>> 0, d.contentTimeTotalMsec.high >>> 0).toNumber(true);
            }
            return m;
        };

        TrackContentPlayError.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.timeOffset = 0;
                d.contentTimeTotal = 0;
                d.autoplay = false;
                d.replayCount = 0;
                d.errorReason = o.enums === String ? "UNKNOWN_LOAD_ERROR_REASON" : 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timeOffsetMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timeOffsetMsec = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.contentTimeTotalMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentTimeTotalMsec = o.longs === String ? "0" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.timeOffset != null && m.hasOwnProperty("timeOffset")) {
                d.timeOffset = m.timeOffset;
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                d.contentTimeTotal = m.contentTimeTotal;
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                d.autoplay = m.autoplay;
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                d.replayCount = m.replayCount;
            }
            if (m.errorReason != null && m.hasOwnProperty("errorReason")) {
                d.errorReason = o.enums === String ? $root.globals.ContentLoadErrorReason[m.errorReason] === undefined ? m.errorReason : $root.globals.ContentLoadErrorReason[m.errorReason] : m.errorReason;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            if (m.timeOffsetMsec != null && m.hasOwnProperty("timeOffsetMsec")) {
                if (typeof m.timeOffsetMsec === "number")
                    d.timeOffsetMsec = o.longs === String ? String(m.timeOffsetMsec) : m.timeOffsetMsec;
                else
                    d.timeOffsetMsec = o.longs === String ? $util.Long.prototype.toString.call(m.timeOffsetMsec) : o.longs === Number ? new $util.LongBits(m.timeOffsetMsec.low >>> 0, m.timeOffsetMsec.high >>> 0).toNumber(true) : m.timeOffsetMsec;
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (typeof m.contentTimeTotalMsec === "number")
                    d.contentTimeTotalMsec = o.longs === String ? String(m.contentTimeTotalMsec) : m.contentTimeTotalMsec;
                else
                    d.contentTimeTotalMsec = o.longs === String ? $util.Long.prototype.toString.call(m.contentTimeTotalMsec) : o.longs === Number ? new $util.LongBits(m.contentTimeTotalMsec.low >>> 0, m.contentTimeTotalMsec.high >>> 0).toNumber(true) : m.contentTimeTotalMsec;
            }
            return d;
        };

        TrackContentPlayError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentPlayError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentPlayError";
        };

        return TrackContentPlayError;
    })();

    commands.TrackContentPause = (function() {

        function TrackContentPause(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentPause.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentPause.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentPause.prototype.timeOffset = 0;
        TrackContentPause.prototype.contentTimeTotal = 0;
        TrackContentPause.prototype.autoplay = false;
        TrackContentPause.prototype.replayCount = 0;
        TrackContentPause.prototype.context = null;
        TrackContentPause.prototype.perceivedMediaType = 0;
        TrackContentPause.prototype.timeOffsetMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentPause.prototype.contentTimeTotalMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        TrackContentPause.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.timeOffset != null && Object.hasOwnProperty.call(m, "timeOffset"))
                w.uint32(24).uint32(m.timeOffset);
            if (m.contentTimeTotal != null && Object.hasOwnProperty.call(m, "contentTimeTotal"))
                w.uint32(32).uint32(m.contentTimeTotal);
            if (m.autoplay != null && Object.hasOwnProperty.call(m, "autoplay"))
                w.uint32(40).bool(m.autoplay);
            if (m.replayCount != null && Object.hasOwnProperty.call(m, "replayCount"))
                w.uint32(48).uint32(m.replayCount);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(58).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(64).int32(m.perceivedMediaType);
            if (m.timeOffsetMsec != null && Object.hasOwnProperty.call(m, "timeOffsetMsec"))
                w.uint32(72).uint64(m.timeOffsetMsec);
            if (m.contentTimeTotalMsec != null && Object.hasOwnProperty.call(m, "contentTimeTotalMsec"))
                w.uint32(80).uint64(m.contentTimeTotalMsec);
            return w;
        };

        TrackContentPause.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentPause.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentPause();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.timeOffset = r.uint32();
                        break;
                    }
                case 4: {
                        m.contentTimeTotal = r.uint32();
                        break;
                    }
                case 5: {
                        m.autoplay = r.bool();
                        break;
                    }
                case 6: {
                        m.replayCount = r.uint32();
                        break;
                    }
                case 7: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                case 9: {
                        m.timeOffsetMsec = r.uint64();
                        break;
                    }
                case 10: {
                        m.contentTimeTotalMsec = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentPause.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentPause.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.timeOffset != null && m.hasOwnProperty("timeOffset")) {
                if (!$util.isInteger(m.timeOffset))
                    return "timeOffset: integer expected";
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                if (!$util.isInteger(m.contentTimeTotal))
                    return "contentTimeTotal: integer expected";
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                if (typeof m.autoplay !== "boolean")
                    return "autoplay: boolean expected";
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                if (!$util.isInteger(m.replayCount))
                    return "replayCount: integer expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.timeOffsetMsec != null && m.hasOwnProperty("timeOffsetMsec")) {
                if (!$util.isInteger(m.timeOffsetMsec) && !(m.timeOffsetMsec && $util.isInteger(m.timeOffsetMsec.low) && $util.isInteger(m.timeOffsetMsec.high)))
                    return "timeOffsetMsec: integer|Long expected";
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (!$util.isInteger(m.contentTimeTotalMsec) && !(m.contentTimeTotalMsec && $util.isInteger(m.contentTimeTotalMsec.low) && $util.isInteger(m.contentTimeTotalMsec.high)))
                    return "contentTimeTotalMsec: integer|Long expected";
            }
            return null;
        };

        TrackContentPause.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentPause)
                return d;
            var m = new $root.commands.TrackContentPause();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.timeOffset != null) {
                m.timeOffset = d.timeOffset >>> 0;
            }
            if (d.contentTimeTotal != null) {
                m.contentTimeTotal = d.contentTimeTotal >>> 0;
            }
            if (d.autoplay != null) {
                m.autoplay = Boolean(d.autoplay);
            }
            if (d.replayCount != null) {
                m.replayCount = d.replayCount >>> 0;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentPause.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            if (d.timeOffsetMsec != null) {
                if ($util.Long)
                    (m.timeOffsetMsec = $util.Long.fromValue(d.timeOffsetMsec)).unsigned = true;
                else if (typeof d.timeOffsetMsec === "string")
                    m.timeOffsetMsec = parseInt(d.timeOffsetMsec, 10);
                else if (typeof d.timeOffsetMsec === "number")
                    m.timeOffsetMsec = d.timeOffsetMsec;
                else if (typeof d.timeOffsetMsec === "object")
                    m.timeOffsetMsec = new $util.LongBits(d.timeOffsetMsec.low >>> 0, d.timeOffsetMsec.high >>> 0).toNumber(true);
            }
            if (d.contentTimeTotalMsec != null) {
                if ($util.Long)
                    (m.contentTimeTotalMsec = $util.Long.fromValue(d.contentTimeTotalMsec)).unsigned = true;
                else if (typeof d.contentTimeTotalMsec === "string")
                    m.contentTimeTotalMsec = parseInt(d.contentTimeTotalMsec, 10);
                else if (typeof d.contentTimeTotalMsec === "number")
                    m.contentTimeTotalMsec = d.contentTimeTotalMsec;
                else if (typeof d.contentTimeTotalMsec === "object")
                    m.contentTimeTotalMsec = new $util.LongBits(d.contentTimeTotalMsec.low >>> 0, d.contentTimeTotalMsec.high >>> 0).toNumber(true);
            }
            return m;
        };

        TrackContentPause.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.timeOffset = 0;
                d.contentTimeTotal = 0;
                d.autoplay = false;
                d.replayCount = 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timeOffsetMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timeOffsetMsec = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.contentTimeTotalMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentTimeTotalMsec = o.longs === String ? "0" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.timeOffset != null && m.hasOwnProperty("timeOffset")) {
                d.timeOffset = m.timeOffset;
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                d.contentTimeTotal = m.contentTimeTotal;
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                d.autoplay = m.autoplay;
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                d.replayCount = m.replayCount;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            if (m.timeOffsetMsec != null && m.hasOwnProperty("timeOffsetMsec")) {
                if (typeof m.timeOffsetMsec === "number")
                    d.timeOffsetMsec = o.longs === String ? String(m.timeOffsetMsec) : m.timeOffsetMsec;
                else
                    d.timeOffsetMsec = o.longs === String ? $util.Long.prototype.toString.call(m.timeOffsetMsec) : o.longs === Number ? new $util.LongBits(m.timeOffsetMsec.low >>> 0, m.timeOffsetMsec.high >>> 0).toNumber(true) : m.timeOffsetMsec;
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (typeof m.contentTimeTotalMsec === "number")
                    d.contentTimeTotalMsec = o.longs === String ? String(m.contentTimeTotalMsec) : m.contentTimeTotalMsec;
                else
                    d.contentTimeTotalMsec = o.longs === String ? $util.Long.prototype.toString.call(m.contentTimeTotalMsec) : o.longs === Number ? new $util.LongBits(m.contentTimeTotalMsec.low >>> 0, m.contentTimeTotalMsec.high >>> 0).toNumber(true) : m.contentTimeTotalMsec;
            }
            return d;
        };

        TrackContentPause.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentPause.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentPause";
        };

        return TrackContentPause;
    })();

    commands.TrackContentSeek = (function() {

        function TrackContentSeek(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentSeek.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentSeek.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentSeek.prototype.fromTime = 0;
        TrackContentSeek.prototype.toTime = 0;
        TrackContentSeek.prototype.contentTimeTotal = 0;
        TrackContentSeek.prototype.autoplay = false;
        TrackContentSeek.prototype.replayCount = 0;
        TrackContentSeek.prototype.timeSpent = 0;
        TrackContentSeek.prototype.context = null;
        TrackContentSeek.prototype.perceivedMediaType = 0;
        TrackContentSeek.prototype.timeSpentMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentSeek.prototype.fromTimeMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentSeek.prototype.toTimeMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentSeek.prototype.contentTimeTotalMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        TrackContentSeek.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.fromTime != null && Object.hasOwnProperty.call(m, "fromTime"))
                w.uint32(24).uint32(m.fromTime);
            if (m.toTime != null && Object.hasOwnProperty.call(m, "toTime"))
                w.uint32(32).uint32(m.toTime);
            if (m.contentTimeTotal != null && Object.hasOwnProperty.call(m, "contentTimeTotal"))
                w.uint32(40).uint32(m.contentTimeTotal);
            if (m.autoplay != null && Object.hasOwnProperty.call(m, "autoplay"))
                w.uint32(48).bool(m.autoplay);
            if (m.replayCount != null && Object.hasOwnProperty.call(m, "replayCount"))
                w.uint32(56).uint32(m.replayCount);
            if (m.timeSpent != null && Object.hasOwnProperty.call(m, "timeSpent"))
                w.uint32(64).uint32(m.timeSpent);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(74).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(80).int32(m.perceivedMediaType);
            if (m.timeSpentMsec != null && Object.hasOwnProperty.call(m, "timeSpentMsec"))
                w.uint32(88).uint64(m.timeSpentMsec);
            if (m.fromTimeMsec != null && Object.hasOwnProperty.call(m, "fromTimeMsec"))
                w.uint32(96).uint64(m.fromTimeMsec);
            if (m.toTimeMsec != null && Object.hasOwnProperty.call(m, "toTimeMsec"))
                w.uint32(104).uint64(m.toTimeMsec);
            if (m.contentTimeTotalMsec != null && Object.hasOwnProperty.call(m, "contentTimeTotalMsec"))
                w.uint32(112).uint64(m.contentTimeTotalMsec);
            return w;
        };

        TrackContentSeek.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentSeek.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentSeek();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.fromTime = r.uint32();
                        break;
                    }
                case 4: {
                        m.toTime = r.uint32();
                        break;
                    }
                case 5: {
                        m.contentTimeTotal = r.uint32();
                        break;
                    }
                case 6: {
                        m.autoplay = r.bool();
                        break;
                    }
                case 7: {
                        m.replayCount = r.uint32();
                        break;
                    }
                case 8: {
                        m.timeSpent = r.uint32();
                        break;
                    }
                case 9: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 10: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                case 11: {
                        m.timeSpentMsec = r.uint64();
                        break;
                    }
                case 12: {
                        m.fromTimeMsec = r.uint64();
                        break;
                    }
                case 13: {
                        m.toTimeMsec = r.uint64();
                        break;
                    }
                case 14: {
                        m.contentTimeTotalMsec = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentSeek.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentSeek.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.fromTime != null && m.hasOwnProperty("fromTime")) {
                if (!$util.isInteger(m.fromTime))
                    return "fromTime: integer expected";
            }
            if (m.toTime != null && m.hasOwnProperty("toTime")) {
                if (!$util.isInteger(m.toTime))
                    return "toTime: integer expected";
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                if (!$util.isInteger(m.contentTimeTotal))
                    return "contentTimeTotal: integer expected";
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                if (typeof m.autoplay !== "boolean")
                    return "autoplay: boolean expected";
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                if (!$util.isInteger(m.replayCount))
                    return "replayCount: integer expected";
            }
            if (m.timeSpent != null && m.hasOwnProperty("timeSpent")) {
                if (!$util.isInteger(m.timeSpent))
                    return "timeSpent: integer expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.timeSpentMsec != null && m.hasOwnProperty("timeSpentMsec")) {
                if (!$util.isInteger(m.timeSpentMsec) && !(m.timeSpentMsec && $util.isInteger(m.timeSpentMsec.low) && $util.isInteger(m.timeSpentMsec.high)))
                    return "timeSpentMsec: integer|Long expected";
            }
            if (m.fromTimeMsec != null && m.hasOwnProperty("fromTimeMsec")) {
                if (!$util.isInteger(m.fromTimeMsec) && !(m.fromTimeMsec && $util.isInteger(m.fromTimeMsec.low) && $util.isInteger(m.fromTimeMsec.high)))
                    return "fromTimeMsec: integer|Long expected";
            }
            if (m.toTimeMsec != null && m.hasOwnProperty("toTimeMsec")) {
                if (!$util.isInteger(m.toTimeMsec) && !(m.toTimeMsec && $util.isInteger(m.toTimeMsec.low) && $util.isInteger(m.toTimeMsec.high)))
                    return "toTimeMsec: integer|Long expected";
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (!$util.isInteger(m.contentTimeTotalMsec) && !(m.contentTimeTotalMsec && $util.isInteger(m.contentTimeTotalMsec.low) && $util.isInteger(m.contentTimeTotalMsec.high)))
                    return "contentTimeTotalMsec: integer|Long expected";
            }
            return null;
        };

        TrackContentSeek.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentSeek)
                return d;
            var m = new $root.commands.TrackContentSeek();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.fromTime != null) {
                m.fromTime = d.fromTime >>> 0;
            }
            if (d.toTime != null) {
                m.toTime = d.toTime >>> 0;
            }
            if (d.contentTimeTotal != null) {
                m.contentTimeTotal = d.contentTimeTotal >>> 0;
            }
            if (d.autoplay != null) {
                m.autoplay = Boolean(d.autoplay);
            }
            if (d.replayCount != null) {
                m.replayCount = d.replayCount >>> 0;
            }
            if (d.timeSpent != null) {
                m.timeSpent = d.timeSpent >>> 0;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentSeek.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            if (d.timeSpentMsec != null) {
                if ($util.Long)
                    (m.timeSpentMsec = $util.Long.fromValue(d.timeSpentMsec)).unsigned = true;
                else if (typeof d.timeSpentMsec === "string")
                    m.timeSpentMsec = parseInt(d.timeSpentMsec, 10);
                else if (typeof d.timeSpentMsec === "number")
                    m.timeSpentMsec = d.timeSpentMsec;
                else if (typeof d.timeSpentMsec === "object")
                    m.timeSpentMsec = new $util.LongBits(d.timeSpentMsec.low >>> 0, d.timeSpentMsec.high >>> 0).toNumber(true);
            }
            if (d.fromTimeMsec != null) {
                if ($util.Long)
                    (m.fromTimeMsec = $util.Long.fromValue(d.fromTimeMsec)).unsigned = true;
                else if (typeof d.fromTimeMsec === "string")
                    m.fromTimeMsec = parseInt(d.fromTimeMsec, 10);
                else if (typeof d.fromTimeMsec === "number")
                    m.fromTimeMsec = d.fromTimeMsec;
                else if (typeof d.fromTimeMsec === "object")
                    m.fromTimeMsec = new $util.LongBits(d.fromTimeMsec.low >>> 0, d.fromTimeMsec.high >>> 0).toNumber(true);
            }
            if (d.toTimeMsec != null) {
                if ($util.Long)
                    (m.toTimeMsec = $util.Long.fromValue(d.toTimeMsec)).unsigned = true;
                else if (typeof d.toTimeMsec === "string")
                    m.toTimeMsec = parseInt(d.toTimeMsec, 10);
                else if (typeof d.toTimeMsec === "number")
                    m.toTimeMsec = d.toTimeMsec;
                else if (typeof d.toTimeMsec === "object")
                    m.toTimeMsec = new $util.LongBits(d.toTimeMsec.low >>> 0, d.toTimeMsec.high >>> 0).toNumber(true);
            }
            if (d.contentTimeTotalMsec != null) {
                if ($util.Long)
                    (m.contentTimeTotalMsec = $util.Long.fromValue(d.contentTimeTotalMsec)).unsigned = true;
                else if (typeof d.contentTimeTotalMsec === "string")
                    m.contentTimeTotalMsec = parseInt(d.contentTimeTotalMsec, 10);
                else if (typeof d.contentTimeTotalMsec === "number")
                    m.contentTimeTotalMsec = d.contentTimeTotalMsec;
                else if (typeof d.contentTimeTotalMsec === "object")
                    m.contentTimeTotalMsec = new $util.LongBits(d.contentTimeTotalMsec.low >>> 0, d.contentTimeTotalMsec.high >>> 0).toNumber(true);
            }
            return m;
        };

        TrackContentSeek.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.fromTime = 0;
                d.toTime = 0;
                d.contentTimeTotal = 0;
                d.autoplay = false;
                d.replayCount = 0;
                d.timeSpent = 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timeSpentMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timeSpentMsec = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.fromTimeMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.fromTimeMsec = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.toTimeMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.toTimeMsec = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.contentTimeTotalMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentTimeTotalMsec = o.longs === String ? "0" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.fromTime != null && m.hasOwnProperty("fromTime")) {
                d.fromTime = m.fromTime;
            }
            if (m.toTime != null && m.hasOwnProperty("toTime")) {
                d.toTime = m.toTime;
            }
            if (m.contentTimeTotal != null && m.hasOwnProperty("contentTimeTotal")) {
                d.contentTimeTotal = m.contentTimeTotal;
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                d.autoplay = m.autoplay;
            }
            if (m.replayCount != null && m.hasOwnProperty("replayCount")) {
                d.replayCount = m.replayCount;
            }
            if (m.timeSpent != null && m.hasOwnProperty("timeSpent")) {
                d.timeSpent = m.timeSpent;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            if (m.timeSpentMsec != null && m.hasOwnProperty("timeSpentMsec")) {
                if (typeof m.timeSpentMsec === "number")
                    d.timeSpentMsec = o.longs === String ? String(m.timeSpentMsec) : m.timeSpentMsec;
                else
                    d.timeSpentMsec = o.longs === String ? $util.Long.prototype.toString.call(m.timeSpentMsec) : o.longs === Number ? new $util.LongBits(m.timeSpentMsec.low >>> 0, m.timeSpentMsec.high >>> 0).toNumber(true) : m.timeSpentMsec;
            }
            if (m.fromTimeMsec != null && m.hasOwnProperty("fromTimeMsec")) {
                if (typeof m.fromTimeMsec === "number")
                    d.fromTimeMsec = o.longs === String ? String(m.fromTimeMsec) : m.fromTimeMsec;
                else
                    d.fromTimeMsec = o.longs === String ? $util.Long.prototype.toString.call(m.fromTimeMsec) : o.longs === Number ? new $util.LongBits(m.fromTimeMsec.low >>> 0, m.fromTimeMsec.high >>> 0).toNumber(true) : m.fromTimeMsec;
            }
            if (m.toTimeMsec != null && m.hasOwnProperty("toTimeMsec")) {
                if (typeof m.toTimeMsec === "number")
                    d.toTimeMsec = o.longs === String ? String(m.toTimeMsec) : m.toTimeMsec;
                else
                    d.toTimeMsec = o.longs === String ? $util.Long.prototype.toString.call(m.toTimeMsec) : o.longs === Number ? new $util.LongBits(m.toTimeMsec.low >>> 0, m.toTimeMsec.high >>> 0).toNumber(true) : m.toTimeMsec;
            }
            if (m.contentTimeTotalMsec != null && m.hasOwnProperty("contentTimeTotalMsec")) {
                if (typeof m.contentTimeTotalMsec === "number")
                    d.contentTimeTotalMsec = o.longs === String ? String(m.contentTimeTotalMsec) : m.contentTimeTotalMsec;
                else
                    d.contentTimeTotalMsec = o.longs === String ? $util.Long.prototype.toString.call(m.contentTimeTotalMsec) : o.longs === Number ? new $util.LongBits(m.contentTimeTotalMsec.low >>> 0, m.contentTimeTotalMsec.high >>> 0).toNumber(true) : m.contentTimeTotalMsec;
            }
            return d;
        };

        TrackContentSeek.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentSeek.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentSeek";
        };

        return TrackContentSeek;
    })();

    commands.TrackContentLoadError = (function() {

        function TrackContentLoadError(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentLoadError.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentLoadError.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentLoadError.prototype.autoload = false;
        TrackContentLoadError.prototype.errorReason = 0;
        TrackContentLoadError.prototype.context = null;
        TrackContentLoadError.prototype.perceivedMediaType = 0;

        TrackContentLoadError.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.autoload != null && Object.hasOwnProperty.call(m, "autoload"))
                w.uint32(24).bool(m.autoload);
            if (m.errorReason != null && Object.hasOwnProperty.call(m, "errorReason"))
                w.uint32(32).int32(m.errorReason);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(42).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(48).int32(m.perceivedMediaType);
            return w;
        };

        TrackContentLoadError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentLoadError.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentLoadError();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.autoload = r.bool();
                        break;
                    }
                case 4: {
                        m.errorReason = r.int32();
                        break;
                    }
                case 5: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentLoadError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentLoadError.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.autoload != null && m.hasOwnProperty("autoload")) {
                if (typeof m.autoload !== "boolean")
                    return "autoload: boolean expected";
            }
            if (m.errorReason != null && m.hasOwnProperty("errorReason")) {
                switch (m.errorReason) {
                default:
                    return "errorReason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            return null;
        };

        TrackContentLoadError.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentLoadError)
                return d;
            var m = new $root.commands.TrackContentLoadError();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.autoload != null) {
                m.autoload = Boolean(d.autoload);
            }
            switch (d.errorReason) {
            default:
                if (typeof d.errorReason === "number") {
                    m.errorReason = d.errorReason;
                    break;
                }
                break;
            case "UNKNOWN_LOAD_ERROR_REASON":
            case 0:
                m.errorReason = 0;
                break;
            case "FAILED_TO_GET_STREAM":
            case 1:
                m.errorReason = 1;
                break;
            case "FAILED_TO_PLAY_CONTENT":
            case 2:
                m.errorReason = 2;
                break;
            case "FAILED_TO_GET_CONTENT":
            case 3:
                m.errorReason = 3;
                break;
            case "FAILED_TO_DISPLAY_CONTENT":
            case 4:
                m.errorReason = 4;
                break;
            case "FAILED_TO_CONNECT":
            case 5:
                m.errorReason = 5;
                break;
            case "HTTP_CLIENT_ERROR":
            case 6:
                m.errorReason = 6;
                break;
            case "HTTP_SERVER_ERROR":
            case 7:
                m.errorReason = 7;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentLoadError.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            return m;
        };

        TrackContentLoadError.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.autoload = false;
                d.errorReason = o.enums === String ? "UNKNOWN_LOAD_ERROR_REASON" : 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.autoload != null && m.hasOwnProperty("autoload")) {
                d.autoload = m.autoload;
            }
            if (m.errorReason != null && m.hasOwnProperty("errorReason")) {
                d.errorReason = o.enums === String ? $root.globals.ContentLoadErrorReason[m.errorReason] === undefined ? m.errorReason : $root.globals.ContentLoadErrorReason[m.errorReason] : m.errorReason;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            return d;
        };

        TrackContentLoadError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentLoadError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentLoadError";
        };

        return TrackContentLoadError;
    })();

    commands.TrackContentOrientationChange = (function() {

        function TrackContentOrientationChange(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentOrientationChange.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentOrientationChange.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentOrientationChange.prototype.contentOrientation = 0;
        TrackContentOrientationChange.prototype.deviceOrientation = 0;
        TrackContentOrientationChange.prototype.context = null;
        TrackContentOrientationChange.prototype.perceivedMediaType = 0;

        TrackContentOrientationChange.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.contentOrientation != null && Object.hasOwnProperty.call(m, "contentOrientation"))
                w.uint32(24).int32(m.contentOrientation);
            if (m.deviceOrientation != null && Object.hasOwnProperty.call(m, "deviceOrientation"))
                w.uint32(32).int32(m.deviceOrientation);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(42).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(48).int32(m.perceivedMediaType);
            return w;
        };

        TrackContentOrientationChange.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentOrientationChange.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentOrientationChange();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.contentOrientation = r.int32();
                        break;
                    }
                case 4: {
                        m.deviceOrientation = r.int32();
                        break;
                    }
                case 5: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentOrientationChange.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentOrientationChange.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.contentOrientation != null && m.hasOwnProperty("contentOrientation")) {
                switch (m.contentOrientation) {
                default:
                    return "contentOrientation: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            }
            if (m.deviceOrientation != null && m.hasOwnProperty("deviceOrientation")) {
                switch (m.deviceOrientation) {
                default:
                    return "deviceOrientation: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            return null;
        };

        TrackContentOrientationChange.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentOrientationChange)
                return d;
            var m = new $root.commands.TrackContentOrientationChange();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            switch (d.contentOrientation) {
            default:
                if (typeof d.contentOrientation === "number") {
                    m.contentOrientation = d.contentOrientation;
                    break;
                }
                break;
            case "UNKNOWN_DISPLAY_ORIENTATION":
            case 0:
                m.contentOrientation = 0;
                break;
            case "DISPLAY_ORIENTATION_LANDSCAPE":
            case 1:
                m.contentOrientation = 1;
                break;
            case "DISPLAY_ORIENTATION_PORTRAIT":
            case 2:
                m.contentOrientation = 2;
                break;
            }
            switch (d.deviceOrientation) {
            default:
                if (typeof d.deviceOrientation === "number") {
                    m.deviceOrientation = d.deviceOrientation;
                    break;
                }
                break;
            case "UNKNOWN_DISPLAY_ORIENTATION":
            case 0:
                m.deviceOrientation = 0;
                break;
            case "DISPLAY_ORIENTATION_LANDSCAPE":
            case 1:
                m.deviceOrientation = 1;
                break;
            case "DISPLAY_ORIENTATION_PORTRAIT":
            case 2:
                m.deviceOrientation = 2;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentOrientationChange.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            return m;
        };

        TrackContentOrientationChange.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.contentOrientation = o.enums === String ? "UNKNOWN_DISPLAY_ORIENTATION" : 0;
                d.deviceOrientation = o.enums === String ? "UNKNOWN_DISPLAY_ORIENTATION" : 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.contentOrientation != null && m.hasOwnProperty("contentOrientation")) {
                d.contentOrientation = o.enums === String ? $root.globals.DisplayOrientation[m.contentOrientation] === undefined ? m.contentOrientation : $root.globals.DisplayOrientation[m.contentOrientation] : m.contentOrientation;
            }
            if (m.deviceOrientation != null && m.hasOwnProperty("deviceOrientation")) {
                d.deviceOrientation = o.enums === String ? $root.globals.DisplayOrientation[m.deviceOrientation] === undefined ? m.deviceOrientation : $root.globals.DisplayOrientation[m.deviceOrientation] : m.deviceOrientation;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            return d;
        };

        TrackContentOrientationChange.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentOrientationChange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentOrientationChange";
        };

        return TrackContentOrientationChange;
    })();

    commands.TrackContentMute = (function() {

        function TrackContentMute(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentMute.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentMute.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentMute.prototype.mute = false;
        TrackContentMute.prototype.autoplay = false;
        TrackContentMute.prototype.context = null;
        TrackContentMute.prototype.perceivedMediaType = 0;

        TrackContentMute.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.mute != null && Object.hasOwnProperty.call(m, "mute"))
                w.uint32(24).bool(m.mute);
            if (m.autoplay != null && Object.hasOwnProperty.call(m, "autoplay"))
                w.uint32(32).bool(m.autoplay);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(42).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(48).int32(m.perceivedMediaType);
            return w;
        };

        TrackContentMute.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentMute.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentMute();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.mute = r.bool();
                        break;
                    }
                case 4: {
                        m.autoplay = r.bool();
                        break;
                    }
                case 5: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentMute.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentMute.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.mute != null && m.hasOwnProperty("mute")) {
                if (typeof m.mute !== "boolean")
                    return "mute: boolean expected";
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                if (typeof m.autoplay !== "boolean")
                    return "autoplay: boolean expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            return null;
        };

        TrackContentMute.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentMute)
                return d;
            var m = new $root.commands.TrackContentMute();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.mute != null) {
                m.mute = Boolean(d.mute);
            }
            if (d.autoplay != null) {
                m.autoplay = Boolean(d.autoplay);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentMute.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            return m;
        };

        TrackContentMute.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.mute = false;
                d.autoplay = false;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.mute != null && m.hasOwnProperty("mute")) {
                d.mute = m.mute;
            }
            if (m.autoplay != null && m.hasOwnProperty("autoplay")) {
                d.autoplay = m.autoplay;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            return d;
        };

        TrackContentMute.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentMute.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentMute";
        };

        return TrackContentMute;
    })();

    commands.TrackContentZoom = (function() {

        function TrackContentZoom(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentZoom.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentZoom.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentZoom.prototype.zoomPercent = 0;
        TrackContentZoom.prototype.xOffset = 0;
        TrackContentZoom.prototype.yOffset = 0;
        TrackContentZoom.prototype.contentWidth = 0;
        TrackContentZoom.prototype.contentHeight = 0;
        TrackContentZoom.prototype.context = null;
        TrackContentZoom.prototype.timeSpentZooming = 0;
        TrackContentZoom.prototype.zoomOutMax = 0;
        TrackContentZoom.prototype.zoomInMax = 0;
        TrackContentZoom.prototype.contentFit = 0;
        TrackContentZoom.prototype.perceivedMediaType = 0;
        TrackContentZoom.prototype.timeSpentZoomingMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        TrackContentZoom.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.zoomPercent != null && Object.hasOwnProperty.call(m, "zoomPercent"))
                w.uint32(29).float(m.zoomPercent);
            if (m.xOffset != null && Object.hasOwnProperty.call(m, "xOffset"))
                w.uint32(32).uint32(m.xOffset);
            if (m.yOffset != null && Object.hasOwnProperty.call(m, "yOffset"))
                w.uint32(40).uint32(m.yOffset);
            if (m.contentWidth != null && Object.hasOwnProperty.call(m, "contentWidth"))
                w.uint32(48).uint32(m.contentWidth);
            if (m.contentHeight != null && Object.hasOwnProperty.call(m, "contentHeight"))
                w.uint32(56).uint32(m.contentHeight);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(66).fork()).ldelim();
            if (m.timeSpentZooming != null && Object.hasOwnProperty.call(m, "timeSpentZooming"))
                w.uint32(72).uint32(m.timeSpentZooming);
            if (m.zoomOutMax != null && Object.hasOwnProperty.call(m, "zoomOutMax"))
                w.uint32(85).float(m.zoomOutMax);
            if (m.zoomInMax != null && Object.hasOwnProperty.call(m, "zoomInMax"))
                w.uint32(93).float(m.zoomInMax);
            if (m.contentFit != null && Object.hasOwnProperty.call(m, "contentFit"))
                w.uint32(96).int32(m.contentFit);
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(104).int32(m.perceivedMediaType);
            if (m.timeSpentZoomingMsec != null && Object.hasOwnProperty.call(m, "timeSpentZoomingMsec"))
                w.uint32(112).uint64(m.timeSpentZoomingMsec);
            return w;
        };

        TrackContentZoom.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentZoom.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentZoom();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.zoomPercent = r.float();
                        break;
                    }
                case 4: {
                        m.xOffset = r.uint32();
                        break;
                    }
                case 5: {
                        m.yOffset = r.uint32();
                        break;
                    }
                case 6: {
                        m.contentWidth = r.uint32();
                        break;
                    }
                case 7: {
                        m.contentHeight = r.uint32();
                        break;
                    }
                case 8: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 9: {
                        m.timeSpentZooming = r.uint32();
                        break;
                    }
                case 10: {
                        m.zoomOutMax = r.float();
                        break;
                    }
                case 11: {
                        m.zoomInMax = r.float();
                        break;
                    }
                case 12: {
                        m.contentFit = r.int32();
                        break;
                    }
                case 13: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                case 14: {
                        m.timeSpentZoomingMsec = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentZoom.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentZoom.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.zoomPercent != null && m.hasOwnProperty("zoomPercent")) {
                if (typeof m.zoomPercent !== "number")
                    return "zoomPercent: number expected";
            }
            if (m.xOffset != null && m.hasOwnProperty("xOffset")) {
                if (!$util.isInteger(m.xOffset))
                    return "xOffset: integer expected";
            }
            if (m.yOffset != null && m.hasOwnProperty("yOffset")) {
                if (!$util.isInteger(m.yOffset))
                    return "yOffset: integer expected";
            }
            if (m.contentWidth != null && m.hasOwnProperty("contentWidth")) {
                if (!$util.isInteger(m.contentWidth))
                    return "contentWidth: integer expected";
            }
            if (m.contentHeight != null && m.hasOwnProperty("contentHeight")) {
                if (!$util.isInteger(m.contentHeight))
                    return "contentHeight: integer expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.timeSpentZooming != null && m.hasOwnProperty("timeSpentZooming")) {
                if (!$util.isInteger(m.timeSpentZooming))
                    return "timeSpentZooming: integer expected";
            }
            if (m.zoomOutMax != null && m.hasOwnProperty("zoomOutMax")) {
                if (typeof m.zoomOutMax !== "number")
                    return "zoomOutMax: number expected";
            }
            if (m.zoomInMax != null && m.hasOwnProperty("zoomInMax")) {
                if (typeof m.zoomInMax !== "number")
                    return "zoomInMax: number expected";
            }
            if (m.contentFit != null && m.hasOwnProperty("contentFit")) {
                switch (m.contentFit) {
                default:
                    return "contentFit: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.timeSpentZoomingMsec != null && m.hasOwnProperty("timeSpentZoomingMsec")) {
                if (!$util.isInteger(m.timeSpentZoomingMsec) && !(m.timeSpentZoomingMsec && $util.isInteger(m.timeSpentZoomingMsec.low) && $util.isInteger(m.timeSpentZoomingMsec.high)))
                    return "timeSpentZoomingMsec: integer|Long expected";
            }
            return null;
        };

        TrackContentZoom.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentZoom)
                return d;
            var m = new $root.commands.TrackContentZoom();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.zoomPercent != null) {
                m.zoomPercent = Number(d.zoomPercent);
            }
            if (d.xOffset != null) {
                m.xOffset = d.xOffset >>> 0;
            }
            if (d.yOffset != null) {
                m.yOffset = d.yOffset >>> 0;
            }
            if (d.contentWidth != null) {
                m.contentWidth = d.contentWidth >>> 0;
            }
            if (d.contentHeight != null) {
                m.contentHeight = d.contentHeight >>> 0;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentZoom.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            if (d.timeSpentZooming != null) {
                m.timeSpentZooming = d.timeSpentZooming >>> 0;
            }
            if (d.zoomOutMax != null) {
                m.zoomOutMax = Number(d.zoomOutMax);
            }
            if (d.zoomInMax != null) {
                m.zoomInMax = Number(d.zoomInMax);
            }
            switch (d.contentFit) {
            default:
                if (typeof d.contentFit === "number") {
                    m.contentFit = d.contentFit;
                    break;
                }
                break;
            case "UNKNOWN_CONTENT_FIT":
            case 0:
                m.contentFit = 0;
                break;
            case "FIT_FILL":
            case 1:
                m.contentFit = 1;
                break;
            case "FIT_CONTAIN":
            case 2:
                m.contentFit = 2;
                break;
            case "FIT_COVER":
            case 3:
                m.contentFit = 3;
                break;
            case "FIT_SCALE_DOWN":
            case 4:
                m.contentFit = 4;
                break;
            case "FIT_NONE":
            case 5:
                m.contentFit = 5;
                break;
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            if (d.timeSpentZoomingMsec != null) {
                if ($util.Long)
                    (m.timeSpentZoomingMsec = $util.Long.fromValue(d.timeSpentZoomingMsec)).unsigned = true;
                else if (typeof d.timeSpentZoomingMsec === "string")
                    m.timeSpentZoomingMsec = parseInt(d.timeSpentZoomingMsec, 10);
                else if (typeof d.timeSpentZoomingMsec === "number")
                    m.timeSpentZoomingMsec = d.timeSpentZoomingMsec;
                else if (typeof d.timeSpentZoomingMsec === "object")
                    m.timeSpentZoomingMsec = new $util.LongBits(d.timeSpentZoomingMsec.low >>> 0, d.timeSpentZoomingMsec.high >>> 0).toNumber(true);
            }
            return m;
        };

        TrackContentZoom.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.zoomPercent = 0;
                d.xOffset = 0;
                d.yOffset = 0;
                d.contentWidth = 0;
                d.contentHeight = 0;
                d.context = null;
                d.timeSpentZooming = 0;
                d.zoomOutMax = 0;
                d.zoomInMax = 0;
                d.contentFit = o.enums === String ? "UNKNOWN_CONTENT_FIT" : 0;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timeSpentZoomingMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timeSpentZoomingMsec = o.longs === String ? "0" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.zoomPercent != null && m.hasOwnProperty("zoomPercent")) {
                d.zoomPercent = o.json && !isFinite(m.zoomPercent) ? String(m.zoomPercent) : m.zoomPercent;
            }
            if (m.xOffset != null && m.hasOwnProperty("xOffset")) {
                d.xOffset = m.xOffset;
            }
            if (m.yOffset != null && m.hasOwnProperty("yOffset")) {
                d.yOffset = m.yOffset;
            }
            if (m.contentWidth != null && m.hasOwnProperty("contentWidth")) {
                d.contentWidth = m.contentWidth;
            }
            if (m.contentHeight != null && m.hasOwnProperty("contentHeight")) {
                d.contentHeight = m.contentHeight;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.timeSpentZooming != null && m.hasOwnProperty("timeSpentZooming")) {
                d.timeSpentZooming = m.timeSpentZooming;
            }
            if (m.zoomOutMax != null && m.hasOwnProperty("zoomOutMax")) {
                d.zoomOutMax = o.json && !isFinite(m.zoomOutMax) ? String(m.zoomOutMax) : m.zoomOutMax;
            }
            if (m.zoomInMax != null && m.hasOwnProperty("zoomInMax")) {
                d.zoomInMax = o.json && !isFinite(m.zoomInMax) ? String(m.zoomInMax) : m.zoomInMax;
            }
            if (m.contentFit != null && m.hasOwnProperty("contentFit")) {
                d.contentFit = o.enums === String ? $root.globals.ContentFit[m.contentFit] === undefined ? m.contentFit : $root.globals.ContentFit[m.contentFit] : m.contentFit;
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            if (m.timeSpentZoomingMsec != null && m.hasOwnProperty("timeSpentZoomingMsec")) {
                if (typeof m.timeSpentZoomingMsec === "number")
                    d.timeSpentZoomingMsec = o.longs === String ? String(m.timeSpentZoomingMsec) : m.timeSpentZoomingMsec;
                else
                    d.timeSpentZoomingMsec = o.longs === String ? $util.Long.prototype.toString.call(m.timeSpentZoomingMsec) : o.longs === Number ? new $util.LongBits(m.timeSpentZoomingMsec.low >>> 0, m.timeSpentZoomingMsec.high >>> 0).toNumber(true) : m.timeSpentZoomingMsec;
            }
            return d;
        };

        TrackContentZoom.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentZoom.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentZoom";
        };

        return TrackContentZoom;
    })();

    commands.TrackContentScroll = (function() {

        function TrackContentScroll(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentScroll.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentScroll.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentScroll.prototype.offsetX = 0;
        TrackContentScroll.prototype.offsetY = 0;
        TrackContentScroll.prototype.contentWidth = 0;
        TrackContentScroll.prototype.contentHeight = 0;
        TrackContentScroll.prototype.scrollContainerWidth = 0;
        TrackContentScroll.prototype.scrollContainerHeight = 0;
        TrackContentScroll.prototype.context = null;
        TrackContentScroll.prototype.perceivedMediaType = 0;
        TrackContentScroll.prototype.scrollPercentage = 0;

        TrackContentScroll.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.offsetX != null && Object.hasOwnProperty.call(m, "offsetX"))
                w.uint32(24).uint32(m.offsetX);
            if (m.offsetY != null && Object.hasOwnProperty.call(m, "offsetY"))
                w.uint32(32).uint32(m.offsetY);
            if (m.contentWidth != null && Object.hasOwnProperty.call(m, "contentWidth"))
                w.uint32(40).uint32(m.contentWidth);
            if (m.contentHeight != null && Object.hasOwnProperty.call(m, "contentHeight"))
                w.uint32(48).uint32(m.contentHeight);
            if (m.scrollContainerWidth != null && Object.hasOwnProperty.call(m, "scrollContainerWidth"))
                w.uint32(56).uint32(m.scrollContainerWidth);
            if (m.scrollContainerHeight != null && Object.hasOwnProperty.call(m, "scrollContainerHeight"))
                w.uint32(64).uint32(m.scrollContainerHeight);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(74).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(80).int32(m.perceivedMediaType);
            if (m.scrollPercentage != null && Object.hasOwnProperty.call(m, "scrollPercentage"))
                w.uint32(93).float(m.scrollPercentage);
            return w;
        };

        TrackContentScroll.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentScroll.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentScroll();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.offsetX = r.uint32();
                        break;
                    }
                case 4: {
                        m.offsetY = r.uint32();
                        break;
                    }
                case 5: {
                        m.contentWidth = r.uint32();
                        break;
                    }
                case 6: {
                        m.contentHeight = r.uint32();
                        break;
                    }
                case 7: {
                        m.scrollContainerWidth = r.uint32();
                        break;
                    }
                case 8: {
                        m.scrollContainerHeight = r.uint32();
                        break;
                    }
                case 9: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 10: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                case 11: {
                        m.scrollPercentage = r.float();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentScroll.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentScroll.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.offsetX != null && m.hasOwnProperty("offsetX")) {
                if (!$util.isInteger(m.offsetX))
                    return "offsetX: integer expected";
            }
            if (m.offsetY != null && m.hasOwnProperty("offsetY")) {
                if (!$util.isInteger(m.offsetY))
                    return "offsetY: integer expected";
            }
            if (m.contentWidth != null && m.hasOwnProperty("contentWidth")) {
                if (!$util.isInteger(m.contentWidth))
                    return "contentWidth: integer expected";
            }
            if (m.contentHeight != null && m.hasOwnProperty("contentHeight")) {
                if (!$util.isInteger(m.contentHeight))
                    return "contentHeight: integer expected";
            }
            if (m.scrollContainerWidth != null && m.hasOwnProperty("scrollContainerWidth")) {
                if (!$util.isInteger(m.scrollContainerWidth))
                    return "scrollContainerWidth: integer expected";
            }
            if (m.scrollContainerHeight != null && m.hasOwnProperty("scrollContainerHeight")) {
                if (!$util.isInteger(m.scrollContainerHeight))
                    return "scrollContainerHeight: integer expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.scrollPercentage != null && m.hasOwnProperty("scrollPercentage")) {
                if (typeof m.scrollPercentage !== "number")
                    return "scrollPercentage: number expected";
            }
            return null;
        };

        TrackContentScroll.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentScroll)
                return d;
            var m = new $root.commands.TrackContentScroll();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.offsetX != null) {
                m.offsetX = d.offsetX >>> 0;
            }
            if (d.offsetY != null) {
                m.offsetY = d.offsetY >>> 0;
            }
            if (d.contentWidth != null) {
                m.contentWidth = d.contentWidth >>> 0;
            }
            if (d.contentHeight != null) {
                m.contentHeight = d.contentHeight >>> 0;
            }
            if (d.scrollContainerWidth != null) {
                m.scrollContainerWidth = d.scrollContainerWidth >>> 0;
            }
            if (d.scrollContainerHeight != null) {
                m.scrollContainerHeight = d.scrollContainerHeight >>> 0;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentScroll.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            if (d.scrollPercentage != null) {
                m.scrollPercentage = Number(d.scrollPercentage);
            }
            return m;
        };

        TrackContentScroll.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.offsetX = 0;
                d.offsetY = 0;
                d.contentWidth = 0;
                d.contentHeight = 0;
                d.scrollContainerWidth = 0;
                d.scrollContainerHeight = 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
                d.scrollPercentage = 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.offsetX != null && m.hasOwnProperty("offsetX")) {
                d.offsetX = m.offsetX;
            }
            if (m.offsetY != null && m.hasOwnProperty("offsetY")) {
                d.offsetY = m.offsetY;
            }
            if (m.contentWidth != null && m.hasOwnProperty("contentWidth")) {
                d.contentWidth = m.contentWidth;
            }
            if (m.contentHeight != null && m.hasOwnProperty("contentHeight")) {
                d.contentHeight = m.contentHeight;
            }
            if (m.scrollContainerWidth != null && m.hasOwnProperty("scrollContainerWidth")) {
                d.scrollContainerWidth = m.scrollContainerWidth;
            }
            if (m.scrollContainerHeight != null && m.hasOwnProperty("scrollContainerHeight")) {
                d.scrollContainerHeight = m.scrollContainerHeight;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            if (m.scrollPercentage != null && m.hasOwnProperty("scrollPercentage")) {
                d.scrollPercentage = o.json && !isFinite(m.scrollPercentage) ? String(m.scrollPercentage) : m.scrollPercentage;
            }
            return d;
        };

        TrackContentScroll.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentScroll.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentScroll";
        };

        return TrackContentScroll;
    })();

    commands.TrackContentViewExternal = (function() {

        function TrackContentViewExternal(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentViewExternal.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentViewExternal.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentViewExternal.prototype.context = null;
        TrackContentViewExternal.prototype.perceivedMediaType = 0;

        TrackContentViewExternal.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(32).int32(m.perceivedMediaType);
            return w;
        };

        TrackContentViewExternal.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentViewExternal.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentViewExternal();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentViewExternal.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentViewExternal.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            return null;
        };

        TrackContentViewExternal.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentViewExternal)
                return d;
            var m = new $root.commands.TrackContentViewExternal();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentViewExternal.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            return m;
        };

        TrackContentViewExternal.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            return d;
        };

        TrackContentViewExternal.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentViewExternal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentViewExternal";
        };

        return TrackContentViewExternal;
    })();

    commands.TrackContentViewInternal = (function() {

        function TrackContentViewInternal(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentViewInternal.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentViewInternal.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentViewInternal.prototype.context = null;
        TrackContentViewInternal.prototype.perceivedMediaType = 0;

        TrackContentViewInternal.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(26).fork()).ldelim();
            if (m.perceivedMediaType != null && Object.hasOwnProperty.call(m, "perceivedMediaType"))
                w.uint32(32).int32(m.perceivedMediaType);
            return w;
        };

        TrackContentViewInternal.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentViewInternal.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentViewInternal();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.perceivedMediaType = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentViewInternal.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentViewInternal.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                switch (m.perceivedMediaType) {
                default:
                    return "perceivedMediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            return null;
        };

        TrackContentViewInternal.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentViewInternal)
                return d;
            var m = new $root.commands.TrackContentViewInternal();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentViewInternal.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.perceivedMediaType) {
            default:
                if (typeof d.perceivedMediaType === "number") {
                    m.perceivedMediaType = d.perceivedMediaType;
                    break;
                }
                break;
            case "UNKNOWN_MEDIA_TYPE":
            case 0:
                m.perceivedMediaType = 0;
                break;
            case "WEBSITE":
            case 1:
                m.perceivedMediaType = 1;
                break;
            case "ARTICLE":
            case 2:
                m.perceivedMediaType = 2;
                break;
            case "IMAGE":
            case 3:
                m.perceivedMediaType = 3;
                break;
            case "VIDEO":
            case 4:
                m.perceivedMediaType = 4;
                break;
            case "AUDIO":
            case 5:
                m.perceivedMediaType = 5;
                break;
            case "PDF":
            case 6:
                m.perceivedMediaType = 6;
                break;
            case "NEWS":
            case 7:
                m.perceivedMediaType = 7;
                break;
            case "GAME":
            case 8:
                m.perceivedMediaType = 8;
                break;
            case "GIF":
            case 9:
                m.perceivedMediaType = 9;
                break;
            case "APP":
            case 10:
                m.perceivedMediaType = 10;
                break;
            case "GALLERY":
            case 11:
                m.perceivedMediaType = 11;
                break;
            }
            return m;
        };

        TrackContentViewInternal.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.context = null;
                d.perceivedMediaType = o.enums === String ? "UNKNOWN_MEDIA_TYPE" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.perceivedMediaType != null && m.hasOwnProperty("perceivedMediaType")) {
                d.perceivedMediaType = o.enums === String ? $root.globals.MediaType[m.perceivedMediaType] === undefined ? m.perceivedMediaType : $root.globals.MediaType[m.perceivedMediaType] : m.perceivedMediaType;
            }
            return d;
        };

        TrackContentViewInternal.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentViewInternal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentViewInternal";
        };

        return TrackContentViewInternal;
    })();

    commands.TrackContentSelection = (function() {

        function TrackContentSelection(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackContentSelection.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentSelection.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackContentSelection.prototype.selectedIndex = 0;
        TrackContentSelection.prototype.maxIndexSeen = 0;
        TrackContentSelection.prototype.numItemsInViewport = 0;
        TrackContentSelection.prototype.context = null;
        TrackContentSelection.prototype.selectionType = 0;

        TrackContentSelection.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.selectedIndex != null && Object.hasOwnProperty.call(m, "selectedIndex"))
                w.uint32(24).uint32(m.selectedIndex);
            if (m.maxIndexSeen != null && Object.hasOwnProperty.call(m, "maxIndexSeen"))
                w.uint32(32).uint32(m.maxIndexSeen);
            if (m.numItemsInViewport != null && Object.hasOwnProperty.call(m, "numItemsInViewport"))
                w.uint32(40).uint32(m.numItemsInViewport);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(50).fork()).ldelim();
            if (m.selectionType != null && Object.hasOwnProperty.call(m, "selectionType"))
                w.uint32(56).int32(m.selectionType);
            return w;
        };

        TrackContentSelection.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackContentSelection.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackContentSelection();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.selectedIndex = r.uint32();
                        break;
                    }
                case 4: {
                        m.maxIndexSeen = r.uint32();
                        break;
                    }
                case 5: {
                        m.numItemsInViewport = r.uint32();
                        break;
                    }
                case 6: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.selectionType = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackContentSelection.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackContentSelection.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.selectedIndex != null && m.hasOwnProperty("selectedIndex")) {
                if (!$util.isInteger(m.selectedIndex))
                    return "selectedIndex: integer expected";
            }
            if (m.maxIndexSeen != null && m.hasOwnProperty("maxIndexSeen")) {
                if (!$util.isInteger(m.maxIndexSeen))
                    return "maxIndexSeen: integer expected";
            }
            if (m.numItemsInViewport != null && m.hasOwnProperty("numItemsInViewport")) {
                if (!$util.isInteger(m.numItemsInViewport))
                    return "numItemsInViewport: integer expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.selectionType != null && m.hasOwnProperty("selectionType")) {
                switch (m.selectionType) {
                default:
                    return "selectionType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            }
            return null;
        };

        TrackContentSelection.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackContentSelection)
                return d;
            var m = new $root.commands.TrackContentSelection();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            if (d.selectedIndex != null) {
                m.selectedIndex = d.selectedIndex >>> 0;
            }
            if (d.maxIndexSeen != null) {
                m.maxIndexSeen = d.maxIndexSeen >>> 0;
            }
            if (d.numItemsInViewport != null) {
                m.numItemsInViewport = d.numItemsInViewport >>> 0;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackContentSelection.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            switch (d.selectionType) {
            default:
                if (typeof d.selectionType === "number") {
                    m.selectionType = d.selectionType;
                    break;
                }
                break;
            case "UNKNOWN_CONTENT_SELECTION_TYPE":
            case 0:
                m.selectionType = 0;
                break;
            case "SINGLE_PRESS":
            case 1:
                m.selectionType = 1;
                break;
            case "LONG_PRESS":
            case 2:
                m.selectionType = 2;
                break;
            }
            return m;
        };

        TrackContentSelection.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.selectedIndex = 0;
                d.maxIndexSeen = 0;
                d.numItemsInViewport = 0;
                d.context = null;
                d.selectionType = o.enums === String ? "UNKNOWN_CONTENT_SELECTION_TYPE" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.selectedIndex != null && m.hasOwnProperty("selectedIndex")) {
                d.selectedIndex = m.selectedIndex;
            }
            if (m.maxIndexSeen != null && m.hasOwnProperty("maxIndexSeen")) {
                d.maxIndexSeen = m.maxIndexSeen;
            }
            if (m.numItemsInViewport != null && m.hasOwnProperty("numItemsInViewport")) {
                d.numItemsInViewport = m.numItemsInViewport;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.selectionType != null && m.hasOwnProperty("selectionType")) {
                d.selectionType = o.enums === String ? $root.globals.ContentSelectionType[m.selectionType] === undefined ? m.selectionType : $root.globals.ContentSelectionType[m.selectionType] : m.selectionType;
            }
            return d;
        };

        TrackContentSelection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackContentSelection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackContentSelection";
        };

        return TrackContentSelection;
    })();

    commands.TrackCalloutView = (function() {

        function TrackCalloutView(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackCalloutView.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackCalloutView.prototype.calloutId = "";
        TrackCalloutView.prototype.calloutType = 0;
        TrackCalloutView.prototype.triggerReason = 0;
        TrackCalloutView.prototype.context = null;

        TrackCalloutView.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.calloutId != null && Object.hasOwnProperty.call(m, "calloutId"))
                w.uint32(18).string(m.calloutId);
            if (m.calloutType != null && Object.hasOwnProperty.call(m, "calloutType"))
                w.uint32(24).int32(m.calloutType);
            if (m.triggerReason != null && Object.hasOwnProperty.call(m, "triggerReason"))
                w.uint32(32).int32(m.triggerReason);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(42).fork()).ldelim();
            return w;
        };

        TrackCalloutView.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackCalloutView.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackCalloutView();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.calloutId = r.string();
                        break;
                    }
                case 3: {
                        m.calloutType = r.int32();
                        break;
                    }
                case 4: {
                        m.triggerReason = r.int32();
                        break;
                    }
                case 5: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackCalloutView.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackCalloutView.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.calloutId != null && m.hasOwnProperty("calloutId")) {
                if (!$util.isString(m.calloutId))
                    return "calloutId: string expected";
            }
            if (m.calloutType != null && m.hasOwnProperty("calloutType")) {
                switch (m.calloutType) {
                default:
                    return "calloutType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            }
            if (m.triggerReason != null && m.hasOwnProperty("triggerReason")) {
                switch (m.triggerReason) {
                default:
                    return "triggerReason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackCalloutView.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackCalloutView)
                return d;
            var m = new $root.commands.TrackCalloutView();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.calloutId != null) {
                m.calloutId = String(d.calloutId);
            }
            switch (d.calloutType) {
            default:
                if (typeof d.calloutType === "number") {
                    m.calloutType = d.calloutType;
                    break;
                }
                break;
            case "UNKNOWN_CALLOUT_TYPE":
            case 0:
                m.calloutType = 0;
                break;
            case "CALLOUT_TYPE_NODES":
            case 1:
                m.calloutType = 1;
                break;
            case "CALLOUT_TYPE_REC_THEME":
            case 2:
                m.calloutType = 2;
                break;
            case "CALLOUT_TYPE_PASSIVE_USER_PROMPT":
            case 3:
                m.calloutType = 3;
                break;
            case "CALLOUT_TYPE_LOGIN_PROMPT":
            case 4:
                m.calloutType = 4;
                break;
            }
            switch (d.triggerReason) {
            default:
                if (typeof d.triggerReason === "number") {
                    m.triggerReason = d.triggerReason;
                    break;
                }
                break;
            case "UNKNOWN_CALLOUT_TRIGGER_REASON":
            case 0:
                m.triggerReason = 0;
                break;
            case "REASON_RECOMMENDATION":
            case 1:
                m.triggerReason = 1;
                break;
            case "REASON_CONTENT_DWELL":
            case 2:
                m.triggerReason = 2;
                break;
            case "REASON_CONTENT_SCROLL":
            case 3:
                m.triggerReason = 3;
                break;
            case "REASON_CONTENT_INTERACTION":
            case 4:
                m.triggerReason = 4;
                break;
            case "REASON_CONTENT_VIEW":
            case 5:
                m.triggerReason = 5;
                break;
            case "REASON_CONTENT_SHARE":
            case 6:
                m.triggerReason = 6;
                break;
            case "REASON_CONTENT_COMMENT":
            case 7:
                m.triggerReason = 7;
                break;
            case "REASON_CONTENT_LIKE":
            case 8:
                m.triggerReason = 8;
                break;
            case "REASON_CONTENT_DISLIKE":
            case 9:
                m.triggerReason = 9;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackCalloutView.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackCalloutView.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.calloutId = "";
                d.calloutType = o.enums === String ? "UNKNOWN_CALLOUT_TYPE" : 0;
                d.triggerReason = o.enums === String ? "UNKNOWN_CALLOUT_TRIGGER_REASON" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.calloutId != null && m.hasOwnProperty("calloutId")) {
                d.calloutId = m.calloutId;
            }
            if (m.calloutType != null && m.hasOwnProperty("calloutType")) {
                d.calloutType = o.enums === String ? $root.globals.CalloutType[m.calloutType] === undefined ? m.calloutType : $root.globals.CalloutType[m.calloutType] : m.calloutType;
            }
            if (m.triggerReason != null && m.hasOwnProperty("triggerReason")) {
                d.triggerReason = o.enums === String ? $root.globals.CalloutTriggerReason[m.triggerReason] === undefined ? m.triggerReason : $root.globals.CalloutTriggerReason[m.triggerReason] : m.triggerReason;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackCalloutView.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackCalloutView.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackCalloutView";
        };

        return TrackCalloutView;
    })();

    commands.TrackCalloutDismiss = (function() {

        function TrackCalloutDismiss(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackCalloutDismiss.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackCalloutDismiss.prototype.calloutId = "";
        TrackCalloutDismiss.prototype.calloutType = 0;
        TrackCalloutDismiss.prototype.triggerReason = 0;
        TrackCalloutDismiss.prototype.interactionType = 0;
        TrackCalloutDismiss.prototype.context = null;

        TrackCalloutDismiss.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.calloutId != null && Object.hasOwnProperty.call(m, "calloutId"))
                w.uint32(18).string(m.calloutId);
            if (m.calloutType != null && Object.hasOwnProperty.call(m, "calloutType"))
                w.uint32(24).int32(m.calloutType);
            if (m.triggerReason != null && Object.hasOwnProperty.call(m, "triggerReason"))
                w.uint32(32).int32(m.triggerReason);
            if (m.interactionType != null && Object.hasOwnProperty.call(m, "interactionType"))
                w.uint32(40).int32(m.interactionType);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(50).fork()).ldelim();
            return w;
        };

        TrackCalloutDismiss.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackCalloutDismiss.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackCalloutDismiss();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.calloutId = r.string();
                        break;
                    }
                case 3: {
                        m.calloutType = r.int32();
                        break;
                    }
                case 4: {
                        m.triggerReason = r.int32();
                        break;
                    }
                case 5: {
                        m.interactionType = r.int32();
                        break;
                    }
                case 6: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackCalloutDismiss.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackCalloutDismiss.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.calloutId != null && m.hasOwnProperty("calloutId")) {
                if (!$util.isString(m.calloutId))
                    return "calloutId: string expected";
            }
            if (m.calloutType != null && m.hasOwnProperty("calloutType")) {
                switch (m.calloutType) {
                default:
                    return "calloutType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            }
            if (m.triggerReason != null && m.hasOwnProperty("triggerReason")) {
                switch (m.triggerReason) {
                default:
                    return "triggerReason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    break;
                }
            }
            if (m.interactionType != null && m.hasOwnProperty("interactionType")) {
                switch (m.interactionType) {
                default:
                    return "interactionType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackCalloutDismiss.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackCalloutDismiss)
                return d;
            var m = new $root.commands.TrackCalloutDismiss();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.calloutId != null) {
                m.calloutId = String(d.calloutId);
            }
            switch (d.calloutType) {
            default:
                if (typeof d.calloutType === "number") {
                    m.calloutType = d.calloutType;
                    break;
                }
                break;
            case "UNKNOWN_CALLOUT_TYPE":
            case 0:
                m.calloutType = 0;
                break;
            case "CALLOUT_TYPE_NODES":
            case 1:
                m.calloutType = 1;
                break;
            case "CALLOUT_TYPE_REC_THEME":
            case 2:
                m.calloutType = 2;
                break;
            case "CALLOUT_TYPE_PASSIVE_USER_PROMPT":
            case 3:
                m.calloutType = 3;
                break;
            case "CALLOUT_TYPE_LOGIN_PROMPT":
            case 4:
                m.calloutType = 4;
                break;
            }
            switch (d.triggerReason) {
            default:
                if (typeof d.triggerReason === "number") {
                    m.triggerReason = d.triggerReason;
                    break;
                }
                break;
            case "UNKNOWN_CALLOUT_TRIGGER_REASON":
            case 0:
                m.triggerReason = 0;
                break;
            case "REASON_RECOMMENDATION":
            case 1:
                m.triggerReason = 1;
                break;
            case "REASON_CONTENT_DWELL":
            case 2:
                m.triggerReason = 2;
                break;
            case "REASON_CONTENT_SCROLL":
            case 3:
                m.triggerReason = 3;
                break;
            case "REASON_CONTENT_INTERACTION":
            case 4:
                m.triggerReason = 4;
                break;
            case "REASON_CONTENT_VIEW":
            case 5:
                m.triggerReason = 5;
                break;
            case "REASON_CONTENT_SHARE":
            case 6:
                m.triggerReason = 6;
                break;
            case "REASON_CONTENT_COMMENT":
            case 7:
                m.triggerReason = 7;
                break;
            case "REASON_CONTENT_LIKE":
            case 8:
                m.triggerReason = 8;
                break;
            case "REASON_CONTENT_DISLIKE":
            case 9:
                m.triggerReason = 9;
                break;
            }
            switch (d.interactionType) {
            default:
                if (typeof d.interactionType === "number") {
                    m.interactionType = d.interactionType;
                    break;
                }
                break;
            case "UNKNOWN_INTERACTION_TYPE":
            case 0:
                m.interactionType = 0;
                break;
            case "INTERACTION_TYPE_CLICK":
            case 1:
                m.interactionType = 1;
                break;
            case "INTERACTION_TYPE_LONG_PRESS":
            case 2:
                m.interactionType = 2;
                break;
            case "INTERACTION_TYPE_SWIPE":
            case 3:
                m.interactionType = 3;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackCalloutDismiss.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackCalloutDismiss.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.calloutId = "";
                d.calloutType = o.enums === String ? "UNKNOWN_CALLOUT_TYPE" : 0;
                d.triggerReason = o.enums === String ? "UNKNOWN_CALLOUT_TRIGGER_REASON" : 0;
                d.interactionType = o.enums === String ? "UNKNOWN_INTERACTION_TYPE" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.calloutId != null && m.hasOwnProperty("calloutId")) {
                d.calloutId = m.calloutId;
            }
            if (m.calloutType != null && m.hasOwnProperty("calloutType")) {
                d.calloutType = o.enums === String ? $root.globals.CalloutType[m.calloutType] === undefined ? m.calloutType : $root.globals.CalloutType[m.calloutType] : m.calloutType;
            }
            if (m.triggerReason != null && m.hasOwnProperty("triggerReason")) {
                d.triggerReason = o.enums === String ? $root.globals.CalloutTriggerReason[m.triggerReason] === undefined ? m.triggerReason : $root.globals.CalloutTriggerReason[m.triggerReason] : m.triggerReason;
            }
            if (m.interactionType != null && m.hasOwnProperty("interactionType")) {
                d.interactionType = o.enums === String ? $root.globals.InteractionType[m.interactionType] === undefined ? m.interactionType : $root.globals.InteractionType[m.interactionType] : m.interactionType;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackCalloutDismiss.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackCalloutDismiss.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackCalloutDismiss";
        };

        return TrackCalloutDismiss;
    })();

    commands.TrackCalloutSelection = (function() {

        function TrackCalloutSelection(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackCalloutSelection.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackCalloutSelection.prototype.calloutId = "";
        TrackCalloutSelection.prototype.calloutType = 0;
        TrackCalloutSelection.prototype.triggerReason = 0;
        TrackCalloutSelection.prototype.selectionId = "";
        TrackCalloutSelection.prototype.selectionName = "";
        TrackCalloutSelection.prototype.selectionIndex = 0;
        TrackCalloutSelection.prototype.interactionType = 0;
        TrackCalloutSelection.prototype.context = null;

        TrackCalloutSelection.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.calloutId != null && Object.hasOwnProperty.call(m, "calloutId"))
                w.uint32(18).string(m.calloutId);
            if (m.calloutType != null && Object.hasOwnProperty.call(m, "calloutType"))
                w.uint32(24).int32(m.calloutType);
            if (m.triggerReason != null && Object.hasOwnProperty.call(m, "triggerReason"))
                w.uint32(32).int32(m.triggerReason);
            if (m.selectionId != null && Object.hasOwnProperty.call(m, "selectionId"))
                w.uint32(42).string(m.selectionId);
            if (m.selectionName != null && Object.hasOwnProperty.call(m, "selectionName"))
                w.uint32(50).string(m.selectionName);
            if (m.selectionIndex != null && Object.hasOwnProperty.call(m, "selectionIndex"))
                w.uint32(56).uint32(m.selectionIndex);
            if (m.interactionType != null && Object.hasOwnProperty.call(m, "interactionType"))
                w.uint32(64).int32(m.interactionType);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(74).fork()).ldelim();
            return w;
        };

        TrackCalloutSelection.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackCalloutSelection.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackCalloutSelection();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.calloutId = r.string();
                        break;
                    }
                case 3: {
                        m.calloutType = r.int32();
                        break;
                    }
                case 4: {
                        m.triggerReason = r.int32();
                        break;
                    }
                case 5: {
                        m.selectionId = r.string();
                        break;
                    }
                case 6: {
                        m.selectionName = r.string();
                        break;
                    }
                case 7: {
                        m.selectionIndex = r.uint32();
                        break;
                    }
                case 8: {
                        m.interactionType = r.int32();
                        break;
                    }
                case 9: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackCalloutSelection.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackCalloutSelection.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.calloutId != null && m.hasOwnProperty("calloutId")) {
                if (!$util.isString(m.calloutId))
                    return "calloutId: string expected";
            }
            if (m.calloutType != null && m.hasOwnProperty("calloutType")) {
                switch (m.calloutType) {
                default:
                    return "calloutType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            }
            if (m.triggerReason != null && m.hasOwnProperty("triggerReason")) {
                switch (m.triggerReason) {
                default:
                    return "triggerReason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    break;
                }
            }
            if (m.selectionId != null && m.hasOwnProperty("selectionId")) {
                if (!$util.isString(m.selectionId))
                    return "selectionId: string expected";
            }
            if (m.selectionName != null && m.hasOwnProperty("selectionName")) {
                if (!$util.isString(m.selectionName))
                    return "selectionName: string expected";
            }
            if (m.selectionIndex != null && m.hasOwnProperty("selectionIndex")) {
                if (!$util.isInteger(m.selectionIndex))
                    return "selectionIndex: integer expected";
            }
            if (m.interactionType != null && m.hasOwnProperty("interactionType")) {
                switch (m.interactionType) {
                default:
                    return "interactionType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackCalloutSelection.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackCalloutSelection)
                return d;
            var m = new $root.commands.TrackCalloutSelection();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.calloutId != null) {
                m.calloutId = String(d.calloutId);
            }
            switch (d.calloutType) {
            default:
                if (typeof d.calloutType === "number") {
                    m.calloutType = d.calloutType;
                    break;
                }
                break;
            case "UNKNOWN_CALLOUT_TYPE":
            case 0:
                m.calloutType = 0;
                break;
            case "CALLOUT_TYPE_NODES":
            case 1:
                m.calloutType = 1;
                break;
            case "CALLOUT_TYPE_REC_THEME":
            case 2:
                m.calloutType = 2;
                break;
            case "CALLOUT_TYPE_PASSIVE_USER_PROMPT":
            case 3:
                m.calloutType = 3;
                break;
            case "CALLOUT_TYPE_LOGIN_PROMPT":
            case 4:
                m.calloutType = 4;
                break;
            }
            switch (d.triggerReason) {
            default:
                if (typeof d.triggerReason === "number") {
                    m.triggerReason = d.triggerReason;
                    break;
                }
                break;
            case "UNKNOWN_CALLOUT_TRIGGER_REASON":
            case 0:
                m.triggerReason = 0;
                break;
            case "REASON_RECOMMENDATION":
            case 1:
                m.triggerReason = 1;
                break;
            case "REASON_CONTENT_DWELL":
            case 2:
                m.triggerReason = 2;
                break;
            case "REASON_CONTENT_SCROLL":
            case 3:
                m.triggerReason = 3;
                break;
            case "REASON_CONTENT_INTERACTION":
            case 4:
                m.triggerReason = 4;
                break;
            case "REASON_CONTENT_VIEW":
            case 5:
                m.triggerReason = 5;
                break;
            case "REASON_CONTENT_SHARE":
            case 6:
                m.triggerReason = 6;
                break;
            case "REASON_CONTENT_COMMENT":
            case 7:
                m.triggerReason = 7;
                break;
            case "REASON_CONTENT_LIKE":
            case 8:
                m.triggerReason = 8;
                break;
            case "REASON_CONTENT_DISLIKE":
            case 9:
                m.triggerReason = 9;
                break;
            }
            if (d.selectionId != null) {
                m.selectionId = String(d.selectionId);
            }
            if (d.selectionName != null) {
                m.selectionName = String(d.selectionName);
            }
            if (d.selectionIndex != null) {
                m.selectionIndex = d.selectionIndex >>> 0;
            }
            switch (d.interactionType) {
            default:
                if (typeof d.interactionType === "number") {
                    m.interactionType = d.interactionType;
                    break;
                }
                break;
            case "UNKNOWN_INTERACTION_TYPE":
            case 0:
                m.interactionType = 0;
                break;
            case "INTERACTION_TYPE_CLICK":
            case 1:
                m.interactionType = 1;
                break;
            case "INTERACTION_TYPE_LONG_PRESS":
            case 2:
                m.interactionType = 2;
                break;
            case "INTERACTION_TYPE_SWIPE":
            case 3:
                m.interactionType = 3;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackCalloutSelection.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackCalloutSelection.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.calloutId = "";
                d.calloutType = o.enums === String ? "UNKNOWN_CALLOUT_TYPE" : 0;
                d.triggerReason = o.enums === String ? "UNKNOWN_CALLOUT_TRIGGER_REASON" : 0;
                d.selectionId = "";
                d.selectionName = "";
                d.selectionIndex = 0;
                d.interactionType = o.enums === String ? "UNKNOWN_INTERACTION_TYPE" : 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.calloutId != null && m.hasOwnProperty("calloutId")) {
                d.calloutId = m.calloutId;
            }
            if (m.calloutType != null && m.hasOwnProperty("calloutType")) {
                d.calloutType = o.enums === String ? $root.globals.CalloutType[m.calloutType] === undefined ? m.calloutType : $root.globals.CalloutType[m.calloutType] : m.calloutType;
            }
            if (m.triggerReason != null && m.hasOwnProperty("triggerReason")) {
                d.triggerReason = o.enums === String ? $root.globals.CalloutTriggerReason[m.triggerReason] === undefined ? m.triggerReason : $root.globals.CalloutTriggerReason[m.triggerReason] : m.triggerReason;
            }
            if (m.selectionId != null && m.hasOwnProperty("selectionId")) {
                d.selectionId = m.selectionId;
            }
            if (m.selectionName != null && m.hasOwnProperty("selectionName")) {
                d.selectionName = m.selectionName;
            }
            if (m.selectionIndex != null && m.hasOwnProperty("selectionIndex")) {
                d.selectionIndex = m.selectionIndex;
            }
            if (m.interactionType != null && m.hasOwnProperty("interactionType")) {
                d.interactionType = o.enums === String ? $root.globals.InteractionType[m.interactionType] === undefined ? m.interactionType : $root.globals.InteractionType[m.interactionType] : m.interactionType;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackCalloutSelection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackCalloutSelection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackCalloutSelection";
        };

        return TrackCalloutSelection;
    })();

    commands.TrackCalloutDwell = (function() {

        function TrackCalloutDwell(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackCalloutDwell.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackCalloutDwell.prototype.calloutId = "";
        TrackCalloutDwell.prototype.calloutType = 0;
        TrackCalloutDwell.prototype.triggerReason = 0;
        TrackCalloutDwell.prototype.timeSpentMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackCalloutDwell.prototype.calloutTimeTotalMsec = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        TrackCalloutDwell.prototype.sectionsViewed = 0;
        TrackCalloutDwell.prototype.sectionsTotal = 0;
        TrackCalloutDwell.prototype.maxProgressPercentage = 0;
        TrackCalloutDwell.prototype.context = null;

        TrackCalloutDwell.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.calloutId != null && Object.hasOwnProperty.call(m, "calloutId"))
                w.uint32(18).string(m.calloutId);
            if (m.calloutType != null && Object.hasOwnProperty.call(m, "calloutType"))
                w.uint32(24).int32(m.calloutType);
            if (m.triggerReason != null && Object.hasOwnProperty.call(m, "triggerReason"))
                w.uint32(32).int32(m.triggerReason);
            if (m.timeSpentMsec != null && Object.hasOwnProperty.call(m, "timeSpentMsec"))
                w.uint32(40).uint64(m.timeSpentMsec);
            if (m.calloutTimeTotalMsec != null && Object.hasOwnProperty.call(m, "calloutTimeTotalMsec"))
                w.uint32(48).uint64(m.calloutTimeTotalMsec);
            if (m.sectionsViewed != null && Object.hasOwnProperty.call(m, "sectionsViewed"))
                w.uint32(56).uint32(m.sectionsViewed);
            if (m.sectionsTotal != null && Object.hasOwnProperty.call(m, "sectionsTotal"))
                w.uint32(64).uint32(m.sectionsTotal);
            if (m.maxProgressPercentage != null && Object.hasOwnProperty.call(m, "maxProgressPercentage"))
                w.uint32(77).float(m.maxProgressPercentage);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(82).fork()).ldelim();
            return w;
        };

        TrackCalloutDwell.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackCalloutDwell.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.TrackCalloutDwell();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.calloutId = r.string();
                        break;
                    }
                case 3: {
                        m.calloutType = r.int32();
                        break;
                    }
                case 4: {
                        m.triggerReason = r.int32();
                        break;
                    }
                case 5: {
                        m.timeSpentMsec = r.uint64();
                        break;
                    }
                case 6: {
                        m.calloutTimeTotalMsec = r.uint64();
                        break;
                    }
                case 7: {
                        m.sectionsViewed = r.uint32();
                        break;
                    }
                case 8: {
                        m.sectionsTotal = r.uint32();
                        break;
                    }
                case 9: {
                        m.maxProgressPercentage = r.float();
                        break;
                    }
                case 10: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackCalloutDwell.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackCalloutDwell.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.calloutId != null && m.hasOwnProperty("calloutId")) {
                if (!$util.isString(m.calloutId))
                    return "calloutId: string expected";
            }
            if (m.calloutType != null && m.hasOwnProperty("calloutType")) {
                switch (m.calloutType) {
                default:
                    return "calloutType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            }
            if (m.triggerReason != null && m.hasOwnProperty("triggerReason")) {
                switch (m.triggerReason) {
                default:
                    return "triggerReason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    break;
                }
            }
            if (m.timeSpentMsec != null && m.hasOwnProperty("timeSpentMsec")) {
                if (!$util.isInteger(m.timeSpentMsec) && !(m.timeSpentMsec && $util.isInteger(m.timeSpentMsec.low) && $util.isInteger(m.timeSpentMsec.high)))
                    return "timeSpentMsec: integer|Long expected";
            }
            if (m.calloutTimeTotalMsec != null && m.hasOwnProperty("calloutTimeTotalMsec")) {
                if (!$util.isInteger(m.calloutTimeTotalMsec) && !(m.calloutTimeTotalMsec && $util.isInteger(m.calloutTimeTotalMsec.low) && $util.isInteger(m.calloutTimeTotalMsec.high)))
                    return "calloutTimeTotalMsec: integer|Long expected";
            }
            if (m.sectionsViewed != null && m.hasOwnProperty("sectionsViewed")) {
                if (!$util.isInteger(m.sectionsViewed))
                    return "sectionsViewed: integer expected";
            }
            if (m.sectionsTotal != null && m.hasOwnProperty("sectionsTotal")) {
                if (!$util.isInteger(m.sectionsTotal))
                    return "sectionsTotal: integer expected";
            }
            if (m.maxProgressPercentage != null && m.hasOwnProperty("maxProgressPercentage")) {
                if (typeof m.maxProgressPercentage !== "number")
                    return "maxProgressPercentage: number expected";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            return null;
        };

        TrackCalloutDwell.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.TrackCalloutDwell)
                return d;
            var m = new $root.commands.TrackCalloutDwell();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.calloutId != null) {
                m.calloutId = String(d.calloutId);
            }
            switch (d.calloutType) {
            default:
                if (typeof d.calloutType === "number") {
                    m.calloutType = d.calloutType;
                    break;
                }
                break;
            case "UNKNOWN_CALLOUT_TYPE":
            case 0:
                m.calloutType = 0;
                break;
            case "CALLOUT_TYPE_NODES":
            case 1:
                m.calloutType = 1;
                break;
            case "CALLOUT_TYPE_REC_THEME":
            case 2:
                m.calloutType = 2;
                break;
            case "CALLOUT_TYPE_PASSIVE_USER_PROMPT":
            case 3:
                m.calloutType = 3;
                break;
            case "CALLOUT_TYPE_LOGIN_PROMPT":
            case 4:
                m.calloutType = 4;
                break;
            }
            switch (d.triggerReason) {
            default:
                if (typeof d.triggerReason === "number") {
                    m.triggerReason = d.triggerReason;
                    break;
                }
                break;
            case "UNKNOWN_CALLOUT_TRIGGER_REASON":
            case 0:
                m.triggerReason = 0;
                break;
            case "REASON_RECOMMENDATION":
            case 1:
                m.triggerReason = 1;
                break;
            case "REASON_CONTENT_DWELL":
            case 2:
                m.triggerReason = 2;
                break;
            case "REASON_CONTENT_SCROLL":
            case 3:
                m.triggerReason = 3;
                break;
            case "REASON_CONTENT_INTERACTION":
            case 4:
                m.triggerReason = 4;
                break;
            case "REASON_CONTENT_VIEW":
            case 5:
                m.triggerReason = 5;
                break;
            case "REASON_CONTENT_SHARE":
            case 6:
                m.triggerReason = 6;
                break;
            case "REASON_CONTENT_COMMENT":
            case 7:
                m.triggerReason = 7;
                break;
            case "REASON_CONTENT_LIKE":
            case 8:
                m.triggerReason = 8;
                break;
            case "REASON_CONTENT_DISLIKE":
            case 9:
                m.triggerReason = 9;
                break;
            }
            if (d.timeSpentMsec != null) {
                if ($util.Long)
                    (m.timeSpentMsec = $util.Long.fromValue(d.timeSpentMsec)).unsigned = true;
                else if (typeof d.timeSpentMsec === "string")
                    m.timeSpentMsec = parseInt(d.timeSpentMsec, 10);
                else if (typeof d.timeSpentMsec === "number")
                    m.timeSpentMsec = d.timeSpentMsec;
                else if (typeof d.timeSpentMsec === "object")
                    m.timeSpentMsec = new $util.LongBits(d.timeSpentMsec.low >>> 0, d.timeSpentMsec.high >>> 0).toNumber(true);
            }
            if (d.calloutTimeTotalMsec != null) {
                if ($util.Long)
                    (m.calloutTimeTotalMsec = $util.Long.fromValue(d.calloutTimeTotalMsec)).unsigned = true;
                else if (typeof d.calloutTimeTotalMsec === "string")
                    m.calloutTimeTotalMsec = parseInt(d.calloutTimeTotalMsec, 10);
                else if (typeof d.calloutTimeTotalMsec === "number")
                    m.calloutTimeTotalMsec = d.calloutTimeTotalMsec;
                else if (typeof d.calloutTimeTotalMsec === "object")
                    m.calloutTimeTotalMsec = new $util.LongBits(d.calloutTimeTotalMsec.low >>> 0, d.calloutTimeTotalMsec.high >>> 0).toNumber(true);
            }
            if (d.sectionsViewed != null) {
                m.sectionsViewed = d.sectionsViewed >>> 0;
            }
            if (d.sectionsTotal != null) {
                m.sectionsTotal = d.sectionsTotal >>> 0;
            }
            if (d.maxProgressPercentage != null) {
                m.maxProgressPercentage = Number(d.maxProgressPercentage);
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.TrackCalloutDwell.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            return m;
        };

        TrackCalloutDwell.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                d.calloutId = "";
                d.calloutType = o.enums === String ? "UNKNOWN_CALLOUT_TYPE" : 0;
                d.triggerReason = o.enums === String ? "UNKNOWN_CALLOUT_TRIGGER_REASON" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timeSpentMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timeSpentMsec = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.calloutTimeTotalMsec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.calloutTimeTotalMsec = o.longs === String ? "0" : 0;
                d.sectionsViewed = 0;
                d.sectionsTotal = 0;
                d.maxProgressPercentage = 0;
                d.context = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.calloutId != null && m.hasOwnProperty("calloutId")) {
                d.calloutId = m.calloutId;
            }
            if (m.calloutType != null && m.hasOwnProperty("calloutType")) {
                d.calloutType = o.enums === String ? $root.globals.CalloutType[m.calloutType] === undefined ? m.calloutType : $root.globals.CalloutType[m.calloutType] : m.calloutType;
            }
            if (m.triggerReason != null && m.hasOwnProperty("triggerReason")) {
                d.triggerReason = o.enums === String ? $root.globals.CalloutTriggerReason[m.triggerReason] === undefined ? m.triggerReason : $root.globals.CalloutTriggerReason[m.triggerReason] : m.triggerReason;
            }
            if (m.timeSpentMsec != null && m.hasOwnProperty("timeSpentMsec")) {
                if (typeof m.timeSpentMsec === "number")
                    d.timeSpentMsec = o.longs === String ? String(m.timeSpentMsec) : m.timeSpentMsec;
                else
                    d.timeSpentMsec = o.longs === String ? $util.Long.prototype.toString.call(m.timeSpentMsec) : o.longs === Number ? new $util.LongBits(m.timeSpentMsec.low >>> 0, m.timeSpentMsec.high >>> 0).toNumber(true) : m.timeSpentMsec;
            }
            if (m.calloutTimeTotalMsec != null && m.hasOwnProperty("calloutTimeTotalMsec")) {
                if (typeof m.calloutTimeTotalMsec === "number")
                    d.calloutTimeTotalMsec = o.longs === String ? String(m.calloutTimeTotalMsec) : m.calloutTimeTotalMsec;
                else
                    d.calloutTimeTotalMsec = o.longs === String ? $util.Long.prototype.toString.call(m.calloutTimeTotalMsec) : o.longs === Number ? new $util.LongBits(m.calloutTimeTotalMsec.low >>> 0, m.calloutTimeTotalMsec.high >>> 0).toNumber(true) : m.calloutTimeTotalMsec;
            }
            if (m.sectionsViewed != null && m.hasOwnProperty("sectionsViewed")) {
                d.sectionsViewed = m.sectionsViewed;
            }
            if (m.sectionsTotal != null && m.hasOwnProperty("sectionsTotal")) {
                d.sectionsTotal = m.sectionsTotal;
            }
            if (m.maxProgressPercentage != null && m.hasOwnProperty("maxProgressPercentage")) {
                d.maxProgressPercentage = o.json && !isFinite(m.maxProgressPercentage) ? String(m.maxProgressPercentage) : m.maxProgressPercentage;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            return d;
        };

        TrackCalloutDwell.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackCalloutDwell.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.TrackCalloutDwell";
        };

        return TrackCalloutDwell;
    })();

    commands.RateUrl = (function() {

        function RateUrl(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        RateUrl.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        RateUrl.prototype.urlId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        RateUrl.prototype.ratingType = 0;
        RateUrl.prototype.context = null;
        RateUrl.prototype.shareId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        RateUrl.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(8).uint64(m.userId);
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                w.uint32(16).uint64(m.urlId);
            if (m.ratingType != null && Object.hasOwnProperty.call(m, "ratingType"))
                w.uint32(24).int32(m.ratingType);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                $root.commands.CommandContext.encode(m.context, w.uint32(34).fork()).ldelim();
            if (m.shareId != null && Object.hasOwnProperty.call(m, "shareId"))
                w.uint32(40).uint64(m.shareId);
            return w;
        };

        RateUrl.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RateUrl.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.commands.RateUrl();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = r.uint64();
                        break;
                    }
                case 2: {
                        m.urlId = r.uint64();
                        break;
                    }
                case 3: {
                        m.ratingType = r.int32();
                        break;
                    }
                case 4: {
                        m.context = $root.commands.CommandContext.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        m.shareId = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        RateUrl.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RateUrl.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (!$util.isInteger(m.userId) && !(m.userId && $util.isInteger(m.userId.low) && $util.isInteger(m.userId.high)))
                    return "userId: integer|Long expected";
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (!$util.isInteger(m.urlId) && !(m.urlId && $util.isInteger(m.urlId.low) && $util.isInteger(m.urlId.high)))
                    return "urlId: integer|Long expected";
            }
            if (m.ratingType != null && m.hasOwnProperty("ratingType")) {
                switch (m.ratingType) {
                default:
                    return "ratingType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                {
                    var e = $root.commands.CommandContext.verify(m.context);
                    if (e)
                        return "context." + e;
                }
            }
            if (m.shareId != null && m.hasOwnProperty("shareId")) {
                if (!$util.isInteger(m.shareId) && !(m.shareId && $util.isInteger(m.shareId.low) && $util.isInteger(m.shareId.high)))
                    return "shareId: integer|Long expected";
            }
            return null;
        };

        RateUrl.fromObject = function fromObject(d) {
            if (d instanceof $root.commands.RateUrl)
                return d;
            var m = new $root.commands.RateUrl();
            if (d.userId != null) {
                if ($util.Long)
                    (m.userId = $util.Long.fromValue(d.userId)).unsigned = true;
                else if (typeof d.userId === "string")
                    m.userId = parseInt(d.userId, 10);
                else if (typeof d.userId === "number")
                    m.userId = d.userId;
                else if (typeof d.userId === "object")
                    m.userId = new $util.LongBits(d.userId.low >>> 0, d.userId.high >>> 0).toNumber(true);
            }
            if (d.urlId != null) {
                if ($util.Long)
                    (m.urlId = $util.Long.fromValue(d.urlId)).unsigned = true;
                else if (typeof d.urlId === "string")
                    m.urlId = parseInt(d.urlId, 10);
                else if (typeof d.urlId === "number")
                    m.urlId = d.urlId;
                else if (typeof d.urlId === "object")
                    m.urlId = new $util.LongBits(d.urlId.low >>> 0, d.urlId.high >>> 0).toNumber(true);
            }
            switch (d.ratingType) {
            default:
                if (typeof d.ratingType === "number") {
                    m.ratingType = d.ratingType;
                    break;
                }
                break;
            case "UNKNOWN_RATE_URL_TYPE":
            case 0:
                m.ratingType = 0;
                break;
            case "RATE_URL_NONE":
            case 1:
                m.ratingType = 1;
                break;
            case "RATE_URL_UP":
            case 2:
                m.ratingType = 2;
                break;
            case "RATE_URL_DOWN":
            case 3:
                m.ratingType = 3;
                break;
            }
            if (d.context != null) {
                if (typeof d.context !== "object")
                    throw TypeError(".commands.RateUrl.context: object expected");
                m.context = $root.commands.CommandContext.fromObject(d.context);
            }
            if (d.shareId != null) {
                if ($util.Long)
                    (m.shareId = $util.Long.fromValue(d.shareId)).unsigned = true;
                else if (typeof d.shareId === "string")
                    m.shareId = parseInt(d.shareId, 10);
                else if (typeof d.shareId === "number")
                    m.shareId = d.shareId;
                else if (typeof d.shareId === "object")
                    m.shareId = new $util.LongBits(d.shareId.low >>> 0, d.shareId.high >>> 0).toNumber(true);
            }
            return m;
        };

        RateUrl.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.userId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.userId = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.urlId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.urlId = o.longs === String ? "0" : 0;
                d.ratingType = o.enums === String ? "UNKNOWN_RATE_URL_TYPE" : 0;
                d.context = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.shareId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.shareId = o.longs === String ? "0" : 0;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                if (typeof m.userId === "number")
                    d.userId = o.longs === String ? String(m.userId) : m.userId;
                else
                    d.userId = o.longs === String ? $util.Long.prototype.toString.call(m.userId) : o.longs === Number ? new $util.LongBits(m.userId.low >>> 0, m.userId.high >>> 0).toNumber(true) : m.userId;
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                if (typeof m.urlId === "number")
                    d.urlId = o.longs === String ? String(m.urlId) : m.urlId;
                else
                    d.urlId = o.longs === String ? $util.Long.prototype.toString.call(m.urlId) : o.longs === Number ? new $util.LongBits(m.urlId.low >>> 0, m.urlId.high >>> 0).toNumber(true) : m.urlId;
            }
            if (m.ratingType != null && m.hasOwnProperty("ratingType")) {
                d.ratingType = o.enums === String ? $root.globals.RateUrlType[m.ratingType] === undefined ? m.ratingType : $root.globals.RateUrlType[m.ratingType] : m.ratingType;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = $root.commands.CommandContext.toObject(m.context, o);
            }
            if (m.shareId != null && m.hasOwnProperty("shareId")) {
                if (typeof m.shareId === "number")
                    d.shareId = o.longs === String ? String(m.shareId) : m.shareId;
                else
                    d.shareId = o.longs === String ? $util.Long.prototype.toString.call(m.shareId) : o.longs === Number ? new $util.LongBits(m.shareId.low >>> 0, m.shareId.high >>> 0).toNumber(true) : m.shareId;
            }
            return d;
        };

        RateUrl.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        RateUrl.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/commands.RateUrl";
        };

        return RateUrl;
    })();

    return commands;
})();

export const globals = $root.globals = (() => {

    const globals = {};

    globals.Gender = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_GENDER"] = 0;
        values[valuesById[1] = "FEMALE"] = 1;
        values[valuesById[2] = "MALE"] = 2;
        values[valuesById[3] = "NOT_SPECIFIED"] = 3;
        return values;
    })();

    globals.Language = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_LANGUAGE"] = 0;
        values[valuesById[1] = "ENGLISH"] = 1;
        return values;
    })();

    globals.DeviceType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_DEVICE"] = 0;
        values[valuesById[1] = "IPHONE"] = 1;
        values[valuesById[2] = "DEPRECATED_CHROME_EXTENSION"] = 2;
        values[valuesById[3] = "DEPRECATED_WEBAPP"] = 3;
        values[valuesById[4] = "DEPRECATED_FIREFOX_EXTENSION"] = 4;
        values[valuesById[5] = "ANDROID"] = 5;
        values[valuesById[6] = "IPAD"] = 6;
        values[valuesById[7] = "ANDROID_TABLET"] = 7;
        values[valuesById[8] = "DESKTOP"] = 8;
        values[valuesById[9] = "INTERNAL"] = 9;
        values[valuesById[10] = "WINDOWS_PHONE"] = 10;
        values[valuesById[11] = "SYMBIAN"] = 11;
        return values;
    })();

    globals.ApplicationType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_APP"] = 0;
        values[valuesById[1] = "IOS_APP"] = 1;
        values[valuesById[2] = "WEBAPP"] = 2;
        values[valuesById[3] = "ANDROID_APP"] = 3;
        values[valuesById[4] = "CHROME_EXTENSION"] = 4;
        values[valuesById[5] = "FIREFOX_EXTENSION"] = 5;
        values[valuesById[6] = "MIX_BADGE"] = 6;
        values[valuesById[7] = "THIRD_PARTY_BADGE"] = 7;
        values[valuesById[8] = "INGESTER"] = 8;
        values[valuesById[9] = "INTERNAL_APP"] = 9;
        values[valuesById[10] = "SAFARI_EXTENSION"] = 10;
        values[valuesById[11] = "EDGE_EXTENSION"] = 11;
        values[valuesById[12] = "INTO_IOS_APP"] = 12;
        values[valuesById[13] = "DESKTOP_APP"] = 13;
        values[valuesById[14] = "INTO_ANDROID_APP"] = 14;
        values[valuesById[15] = "LAYER_IOS_APP"] = 15;
        values[valuesById[16] = "STUMBLEUPON_WEB"] = 16;
        values[valuesById[17] = "EMAIL_CLIENT"] = 17;
        values[valuesById[18] = "APPLE_TV_APP"] = 18;
        return values;
    })();

    globals.AppProvider = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_PROVIDER"] = 0;
        values[valuesById[1] = "APPLE_STORE"] = 1;
        values[valuesById[2] = "CHROME_WEB_STORE"] = 2;
        values[valuesById[3] = "MIX"] = 3;
        values[valuesById[4] = "GOOGLE_PLAY_STORE"] = 4;
        values[valuesById[5] = "OTHER_APP_PROVIDER"] = 5;
        values[valuesById[6] = "FIREFOX_ADD_ONS"] = 6;
        values[valuesById[7] = "MICROSOFT_STORE"] = 7;
        return values;
    })();

    globals.StreamType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_STREAM_TYPE"] = 0;
        values[valuesById[1] = "TOP_FOR_YOU_STREAM"] = 1;
        values[valuesById[2] = "CATEGORY_STREAM"] = 2;
        values[valuesById[3] = "TOPIC_STREAM"] = 3;
        values[valuesById[4] = "MIX_STREAM"] = 4;
        values[valuesById[5] = "SHARE_STREAM"] = 5;
        values[valuesById[6] = "USER_MIXED_STREAM"] = 6;
        values[valuesById[7] = "MIX_DIGEST_STREAM"] = 7;
        values[valuesById[8] = "ALL_OF_MIX_STREAM"] = 8;
        values[valuesById[9] = "FEATURED_COLLECTION_CAROUSEL"] = 9;
        values[valuesById[10] = "FEATURED_ARTICLE_CAROUSEL"] = 10;
        values[valuesById[11] = "RELATED_ARTICLE_STREAM"] = 11;
        values[valuesById[12] = "SEARCH_POSTS_STREAM"] = 12;
        values[valuesById[13] = "SEARCH_COLLECTIONS_STREAM"] = 13;
        values[valuesById[14] = "SEARCH_INTERESTS_STREAM"] = 14;
        values[valuesById[15] = "SEARCH_PEOPLE_STREAM"] = 15;
        values[valuesById[16] = "SEARCH_ALL_STREAM"] = 16;
        values[valuesById[17] = "SLUG_STREAM"] = 17;
        values[valuesById[18] = "NODE_STREAM"] = 18;
        return values;
    })();

    globals.ShareType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_SHARE_TYPE"] = 0;
        values[valuesById[1] = "INTERNAL_SHARE"] = 1;
        values[valuesById[2] = "FB_SHARE"] = 2;
        values[valuesById[3] = "TWITTER_SHARE"] = 3;
        values[valuesById[4] = "WHATSAPP_SHARE"] = 4;
        values[valuesById[5] = "SMS_SHARE"] = 5;
        values[valuesById[6] = "EMAIL_SHARE"] = 6;
        values[valuesById[7] = "EXTERNAL_SHARE"] = 7;
        values[valuesById[8] = "PINTEREST_SHARE"] = 8;
        values[valuesById[9] = "SIGNAL_SHARE"] = 9;
        values[valuesById[10] = "COPY_LINK"] = 10;
        values[valuesById[11] = "INSTAGRAM_SHARE"] = 11;
        values[valuesById[12] = "FB_MESSENGER_SHARE"] = 12;
        return values;
    })();

    globals.OptType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_OPT_TYPE"] = 0;
        values[valuesById[1] = "OPT_IN"] = 1;
        values[valuesById[2] = "OPT_OUT"] = 2;
        return values;
    })();

    globals.NotificationChannel = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_NOTIFICATION_CHANNEL"] = 0;
        values[valuesById[1] = "SMS"] = 1;
        values[valuesById[2] = "EMAIL"] = 2;
        values[valuesById[3] = "MOBILE"] = 3;
        return values;
    })();

    globals.UserExternalAccountType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_ACCOUNT"] = 0;
        values[valuesById[1] = "FACEBOOK_ACCOUNT"] = 1;
        values[valuesById[2] = "TWITTER_ACCOUNT"] = 2;
        values[valuesById[3] = "GOOGLE_ACCOUNT"] = 3;
        values[valuesById[4] = "STUMBLEUPON_ACCOUNT"] = 4;
        values[valuesById[5] = "APPLE_ACCOUNT"] = 5;
        values[valuesById[6] = "EMAIL_ACCOUNT"] = 6;
        values[valuesById[7] = "ANONYMOUS_ACCOUNT"] = 7;
        values[valuesById[8] = "LAYER_ACCOUNT"] = 8;
        return values;
    })();

    globals.ContentViewType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_CONTENT_VIEW_TYPE"] = 0;
        values[valuesById[1] = "CARD_VIEW"] = 1;
        values[valuesById[2] = "CONTENT_VIEW"] = 2;
        values[valuesById[3] = "GRID_VIEW"] = 3;
        values[valuesById[4] = "IN_APP_BROWSER_VIEW"] = 4;
        return values;
    })();

    globals.ContentSelectionType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_CONTENT_SELECTION_TYPE"] = 0;
        values[valuesById[1] = "SINGLE_PRESS"] = 1;
        values[valuesById[2] = "LONG_PRESS"] = 2;
        return values;
    })();

    globals.FollowEventType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_FOLLOW_EVENT_TYPE"] = 0;
        values[valuesById[1] = "FOLLOWED"] = 1;
        values[valuesById[2] = "UNFOLLOWED"] = 2;
        return values;
    })();

    globals.UrlFlaggedReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_FLAG_REASON"] = 0;
        values[valuesById[1] = "NOT_INTERESTED"] = 1;
        values[valuesById[2] = "SENSITIVE_IMG"] = 2;
        values[valuesById[3] = "ABUSIVE"] = 3;
        values[valuesById[4] = "SPAM"] = 4;
        values[valuesById[5] = "NSFW"] = 5;
        values[valuesById[6] = "DEAD"] = 6;
        values[valuesById[7] = "EXTENSION_BREAKS"] = 7;
        values[valuesById[8] = "EXTENSION_REMOVED"] = 8;
        return values;
    })();

    globals.BooleanValue = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NOT_SET"] = 0;
        values[valuesById[1] = "TRUE"] = 1;
        values[valuesById[2] = "FALSE"] = 2;
        return values;
    })();

    globals.SessionEventType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_SESSION_EVENT_TYPE"] = 0;
        values[valuesById[1] = "SESSION_STARTED"] = 1;
        values[valuesById[2] = "SESSION_ENDED"] = 2;
        values[valuesById[3] = "SESSION_UPDATED"] = 3;
        return values;
    })();

    globals.UserProfileEventType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_USER_PROFILE_EVENT_TYPE"] = 0;
        values[valuesById[1] = "USER_PROFILE_CREATED"] = 1;
        values[valuesById[2] = "USER_PROFILE_DELETED"] = 2;
        values[valuesById[3] = "USER_PROFILE_UPDATED"] = 3;
        values[valuesById[4] = "USER_PROFILE_COMPLETED"] = 4;
        values[valuesById[5] = "USER_PROFILE_ANONYMOUS_PROMOTED"] = 5;
        return values;
    })();

    globals.RateUrlType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_RATE_URL_TYPE"] = 0;
        values[valuesById[1] = "RATE_URL_NONE"] = 1;
        values[valuesById[2] = "RATE_URL_UP"] = 2;
        values[valuesById[3] = "RATE_URL_DOWN"] = 3;
        return values;
    })();

    globals.MediaType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_MEDIA_TYPE"] = 0;
        values[valuesById[1] = "WEBSITE"] = 1;
        values[valuesById[2] = "ARTICLE"] = 2;
        values[valuesById[3] = "IMAGE"] = 3;
        values[valuesById[4] = "VIDEO"] = 4;
        values[valuesById[5] = "AUDIO"] = 5;
        values[valuesById[6] = "PDF"] = 6;
        values[valuesById[7] = "NEWS"] = 7;
        values[valuesById[8] = "GAME"] = 8;
        values[valuesById[9] = "GIF"] = 9;
        values[valuesById[10] = "APP"] = 10;
        values[valuesById[11] = "GALLERY"] = 11;
        return values;
    })();

    globals.ContentTheme = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_CONTENT_THEME"] = 0;
        values[valuesById[1] = "THEME_GAME"] = 1;
        values[valuesById[2] = "THEME_NEWS"] = 2;
        values[valuesById[3] = "THEME_POLITICS"] = 3;
        values[valuesById[4] = "THEME_ARTICLE"] = 4;
        values[valuesById[5] = "THEME_BLOG"] = 5;
        values[valuesById[6] = "THEME_EVERGREEN"] = 6;
        values[valuesById[7] = "THEME_NSFW"] = 7;
        return values;
    })();

    globals.EntityType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_ENTITY_TYPE"] = 0;
        values[valuesById[1] = "TYPE_URL"] = 1;
        values[valuesById[2] = "TYPE_COLLECTION"] = 2;
        values[valuesById[3] = "TYPE_USER"] = 3;
        values[valuesById[4] = "TYPE_INTEREST"] = 4;
        values[valuesById[5] = "TYPE_DOMAIN"] = 5;
        values[valuesById[6] = "TYPE_KEYWORD"] = 6;
        values[valuesById[7] = "TYPE_NODE"] = 7;
        values[valuesById[8] = "TYPE_CHANNEL"] = 8;
        values[valuesById[9] = "TYPE_PROVIDER"] = 9;
        return values;
    })();

    globals.DisplayOrientation = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_DISPLAY_ORIENTATION"] = 0;
        values[valuesById[1] = "DISPLAY_ORIENTATION_LANDSCAPE"] = 1;
        values[valuesById[2] = "DISPLAY_ORIENTATION_PORTRAIT"] = 2;
        return values;
    })();

    globals.RecMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_REC_MODE"] = 0;
        values[valuesById[1] = "REC_MODE_RECOMMENDED"] = 1;
        values[valuesById[2] = "REC_MODE_POPULAR"] = 2;
        values[valuesById[3] = "REC_MODE_TOPICS"] = 3;
        values[valuesById[4] = "REC_MODE_COLLECTIONS"] = 4;
        values[valuesById[5] = "REC_MODE_FRIENDS"] = 5;
        values[valuesById[6] = "REC_MODE_TRENDING"] = 6;
        values[valuesById[7] = "REC_MODE_SAMPLING"] = 7;
        values[valuesById[8] = "REC_MODE_EDITOR_PICKS"] = 8;
        values[valuesById[9] = "REC_MODE_SEARCH"] = 9;
        values[valuesById[10] = "REC_MODE_SUBSCRIPTIONS"] = 10;
        values[valuesById[11] = "REC_MODE_SESSION"] = 11;
        values[valuesById[12] = "REC_MODE_RELATED"] = 12;
        values[valuesById[13] = "REC_MODE_BECAUSE"] = 13;
        values[valuesById[14] = "REC_MODE_REPRESENTATIVE"] = 14;
        values[valuesById[15] = "REC_MODE_FOLLOWING"] = 15;
        values[valuesById[16] = "REC_MODE_NEW_USER"] = 16;
        values[valuesById[17] = "REC_MODE_CONTEXTUAL"] = 17;
        values[valuesById[18] = "REC_MODE_EMAIL"] = 18;
        values[valuesById[19] = "REC_MODE_PIVOT"] = 19;
        values[valuesById[20] = "REC_MODE_REORDER"] = 20;
        values[valuesById[21] = "REC_MODE_FOLLOWING_EMAIL"] = 21;
        values[valuesById[22] = "REC_MODE_COLOR_SPACE"] = 22;
        values[valuesById[23] = "REC_MODE_ABTEST"] = 23;
        values[valuesById[24] = "REC_MODE_CORPUS"] = 24;
        values[valuesById[25] = "REC_MODE_URL_POOL"] = 25;
        values[valuesById[26] = "REC_MODE_ECHO"] = 26;
        return values;
    })();

    globals.Sort = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_SORT"] = 0;
        values[valuesById[1] = "SORT_RECOMMENDED"] = 1;
        values[valuesById[2] = "SORT_ACCURATE"] = 2;
        values[valuesById[3] = "SORT_POPULAR"] = 3;
        values[valuesById[4] = "SORT_TRENDING"] = 4;
        values[valuesById[5] = "SORT_TIMEDESC"] = 5;
        values[valuesById[6] = "SORT_TIMEASC"] = 6;
        values[valuesById[7] = "SORT_FEED"] = 7;
        values[valuesById[8] = "SORT_RANDOM"] = 8;
        values[valuesById[9] = "SORT_DIVERSE"] = 9;
        return values;
    })();

    globals.ResultComparator = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "COMP_AUTO"] = 0;
        values[valuesById[1] = "COMP_COSINE_SIMILARITY"] = 1;
        values[valuesById[2] = "COMP_EUCLIDEAN"] = 2;
        values[valuesById[3] = "COMP_TRIANGLE"] = 3;
        values[valuesById[4] = "COMP_SECTOR"] = 4;
        values[valuesById[5] = "COMP_TRIANGLE_SECTOR"] = 5;
        values[valuesById[6] = "COMP_DOT_PRODUCT"] = 6;
        return values;
    })();

    globals.ScoreType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_SCORE_TYPE"] = 0;
        values[valuesById[1] = "SCORE_RATE_UP"] = 1;
        values[valuesById[2] = "SCORE_RATE_DOWN"] = 2;
        values[valuesById[3] = "SCORE_BOOKMARK"] = 3;
        values[valuesById[4] = "SCORE_CURATE"] = 4;
        values[valuesById[5] = "SCORE_DWELL"] = 5;
        values[valuesById[6] = "SCORE_PLAY"] = 6;
        values[valuesById[7] = "SCORE_SCROLL"] = 7;
        values[valuesById[8] = "SCORE_FLAG"] = 8;
        values[valuesById[9] = "SCORE_PIVOT"] = 9;
        values[valuesById[10] = "SCORE_CLICK"] = 10;
        return values;
    })();

    globals.RecResultType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_REC_RESULT_TYPE"] = 0;
        values[valuesById[1] = "URL_RESULT"] = 1;
        values[valuesById[2] = "USER_RESULT"] = 2;
        values[valuesById[3] = "MIX_RESULT"] = 3;
        values[valuesById[4] = "INTEREST_RESULT"] = 4;
        values[valuesById[5] = "CATEGORY_RESULT"] = 5;
        values[valuesById[6] = "NODE_RESULT"] = 6;
        values[valuesById[7] = "CHANNEL_RESULT"] = 7;
        values[valuesById[8] = "PROVIDER_RESULT"] = 8;
        return values;
    })();

    globals.ProviderType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_PROVIDER_TYPE"] = 0;
        values[valuesById[1] = "REDDIT"] = 1;
        values[valuesById[2] = "YOUTUBE"] = 2;
        values[valuesById[3] = "IMGUR"] = 3;
        values[valuesById[4] = "VIMEO"] = 4;
        values[valuesById[5] = "INSTAGRAM"] = 5;
        values[valuesById[6] = "TWITTER"] = 6;
        values[valuesById[7] = "PINTEREST"] = 7;
        values[valuesById[8] = "USER"] = 8;
        values[valuesById[9] = "SCRAPER"] = 9;
        return values;
    })();

    globals.ProviderItemInfoType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_PROVIDER_ITEM_STAT_TYPE"] = 0;
        values[valuesById[1] = "UP_VOTES"] = 1;
        values[valuesById[2] = "DOWN_VOTES"] = 2;
        values[valuesById[3] = "SCORE"] = 3;
        values[valuesById[4] = "FEATURED"] = 4;
        values[valuesById[5] = "POPULARITY"] = 5;
        values[valuesById[6] = "VIEWS"] = 6;
        values[valuesById[7] = "GILDED"] = 7;
        values[valuesById[8] = "PLAYS"] = 8;
        values[valuesById[9] = "LIKES"] = 9;
        values[valuesById[10] = "DISLIKES"] = 10;
        values[valuesById[11] = "REBLOGS"] = 11;
        return values;
    })();

    globals.RecReasonType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_REC_REASON_TYPE"] = 0;
        values[valuesById[1] = "TOPIC_FEEDS"] = 1;
        values[valuesById[2] = "CURATED_TOPICS"] = 2;
        values[valuesById[3] = "POPULAR_URLS"] = 3;
        values[valuesById[4] = "MIX_FEEDS_FOLLOWING"] = 4;
        values[valuesById[5] = "MIX_FEEDS_NOT_FOLLOWING"] = 5;
        values[valuesById[6] = "TAG_AFFINITY_GENERIC"] = 6;
        values[valuesById[7] = "FRIENDS_FEED"] = 7;
        values[valuesById[8] = "MIXED_MIX"] = 8;
        values[valuesById[9] = "TRENDING_URLS"] = 9;
        values[valuesById[10] = "COLLABORATIVE_FILTER"] = 10;
        values[valuesById[11] = "COLLABORATIVE_FILTER_IMPLICIT"] = 11;
        values[valuesById[12] = "COLLABORATIVE_FILTER_RATINGS"] = 12;
        values[valuesById[13] = "COLLABORATIVE_FILTER_TEST"] = 13;
        values[valuesById[14] = "COLLABORATIVE_FILTER_APPIMPLICIT"] = 14;
        values[valuesById[15] = "COLLABORATIVE_FILTER_CHANNEL"] = 15;
        return values;
    })();

    globals.ContentLoadErrorReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_LOAD_ERROR_REASON"] = 0;
        values[valuesById[1] = "FAILED_TO_GET_STREAM"] = 1;
        values[valuesById[2] = "FAILED_TO_PLAY_CONTENT"] = 2;
        values[valuesById[3] = "FAILED_TO_GET_CONTENT"] = 3;
        values[valuesById[4] = "FAILED_TO_DISPLAY_CONTENT"] = 4;
        values[valuesById[5] = "FAILED_TO_CONNECT"] = 5;
        values[valuesById[6] = "HTTP_CLIENT_ERROR"] = 6;
        values[valuesById[7] = "HTTP_SERVER_ERROR"] = 7;
        return values;
    })();

    globals.ContentFit = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_CONTENT_FIT"] = 0;
        values[valuesById[1] = "FIT_FILL"] = 1;
        values[valuesById[2] = "FIT_CONTAIN"] = 2;
        values[valuesById[3] = "FIT_COVER"] = 3;
        values[valuesById[4] = "FIT_SCALE_DOWN"] = 4;
        values[valuesById[5] = "FIT_NONE"] = 5;
        return values;
    })();

    globals.RecRescorerType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_RESCORER"] = 0;
        values[valuesById[1] = "RESCORER_CHANNELS"] = 1;
        values[valuesById[2] = "RESCORER_NUDE_FEATURES"] = 2;
        values[valuesById[3] = "RESCORER_INTERESTS"] = 3;
        return values;
    })();

    globals.UserActivityCohort = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_ACTIVITY_COHORT"] = 0;
        values[valuesById[1] = "ACTIVITY_COHORT_NEW"] = 1;
        values[valuesById[2] = "ACTIVITY_COHORT_ACTIVE"] = 2;
        values[valuesById[3] = "ACTIVITY_COHORT_PASSIVE"] = 3;
        values[valuesById[4] = "ACTIVITY_COHORT_DISLIKER"] = 4;
        values[valuesById[5] = "ACTIVITY_COHORT_UNTRACKED"] = 5;
        return values;
    })();

    globals.UserCommentRestrictionReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_REASON"] = 0;
        values[valuesById[1] = "ROLE_SPAMMER"] = 1;
        values[valuesById[2] = "ROLE_ANONYMOUS"] = 2;
        values[valuesById[3] = "ROLE_USER_TESTER"] = 3;
        values[valuesById[4] = "STATE_INACTIVE"] = 4;
        values[valuesById[5] = "STATE_UNVERIFIED"] = 5;
        values[valuesById[6] = "STATE_DEFAULT_PHOTO"] = 6;
        values[valuesById[7] = "STATE_NO_ACCESS"] = 7;
        values[valuesById[8] = "STATE_NEW_ACCOUNT"] = 8;
        values[valuesById[9] = "ROLE_SELF_PROMOTER"] = 9;
        return values;
    })();

    globals.ServerRole = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_SERVER_ROLE"] = 0;
        values[valuesById[1] = "SERVER_ROLE_LOCAL"] = 1;
        values[valuesById[2] = "SERVER_ROLE_STAGING"] = 2;
        values[valuesById[3] = "SERVER_ROLE_PRODUCTION"] = 3;
        values[valuesById[4] = "SERVER_ROLE_CANARY"] = 4;
        return values;
    })();

    globals.CalloutType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_CALLOUT_TYPE"] = 0;
        values[valuesById[1] = "CALLOUT_TYPE_NODES"] = 1;
        values[valuesById[2] = "CALLOUT_TYPE_REC_THEME"] = 2;
        values[valuesById[3] = "CALLOUT_TYPE_PASSIVE_USER_PROMPT"] = 3;
        values[valuesById[4] = "CALLOUT_TYPE_LOGIN_PROMPT"] = 4;
        return values;
    })();

    globals.InteractionType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_INTERACTION_TYPE"] = 0;
        values[valuesById[1] = "INTERACTION_TYPE_CLICK"] = 1;
        values[valuesById[2] = "INTERACTION_TYPE_LONG_PRESS"] = 2;
        values[valuesById[3] = "INTERACTION_TYPE_SWIPE"] = 3;
        return values;
    })();

    globals.CountryCodeType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_COUNTRY"] = 0;
        values[valuesById[1] = "AF"] = 1;
        values[valuesById[2] = "AL"] = 2;
        values[valuesById[3] = "AQ"] = 3;
        values[valuesById[4] = "DZ"] = 4;
        values[valuesById[5] = "AS"] = 5;
        values[valuesById[6] = "AD"] = 6;
        values[valuesById[7] = "AO"] = 7;
        values[valuesById[8] = "AG"] = 8;
        values[valuesById[9] = "AZ"] = 9;
        values[valuesById[10] = "AR"] = 10;
        values[valuesById[11] = "AU"] = 11;
        values[valuesById[12] = "AT"] = 12;
        values[valuesById[13] = "BS"] = 13;
        values[valuesById[14] = "BH"] = 14;
        values[valuesById[15] = "BD"] = 15;
        values[valuesById[16] = "AM"] = 16;
        values[valuesById[17] = "BB"] = 17;
        values[valuesById[18] = "BE"] = 18;
        values[valuesById[19] = "BM"] = 19;
        values[valuesById[20] = "BT"] = 20;
        values[valuesById[21] = "BO"] = 21;
        values[valuesById[22] = "BA"] = 22;
        values[valuesById[23] = "BW"] = 23;
        values[valuesById[24] = "BV"] = 24;
        values[valuesById[25] = "BR"] = 25;
        values[valuesById[26] = "BZ"] = 26;
        values[valuesById[27] = "IO"] = 27;
        values[valuesById[28] = "SB"] = 28;
        values[valuesById[29] = "VG"] = 29;
        values[valuesById[30] = "BN"] = 30;
        values[valuesById[31] = "BG"] = 31;
        values[valuesById[32] = "MM"] = 32;
        values[valuesById[33] = "BI"] = 33;
        values[valuesById[34] = "BY"] = 34;
        values[valuesById[35] = "KH"] = 35;
        values[valuesById[36] = "CM"] = 36;
        values[valuesById[37] = "CA"] = 37;
        values[valuesById[38] = "CV"] = 38;
        values[valuesById[39] = "KY"] = 39;
        values[valuesById[40] = "CF"] = 40;
        values[valuesById[41] = "LK"] = 41;
        values[valuesById[42] = "TD"] = 42;
        values[valuesById[43] = "CL"] = 43;
        values[valuesById[44] = "CN"] = 44;
        values[valuesById[45] = "TW"] = 45;
        values[valuesById[46] = "CX"] = 46;
        values[valuesById[47] = "CC"] = 47;
        values[valuesById[48] = "CO"] = 48;
        values[valuesById[49] = "KM"] = 49;
        values[valuesById[50] = "YT"] = 50;
        values[valuesById[51] = "CG"] = 51;
        values[valuesById[52] = "CD"] = 52;
        values[valuesById[53] = "CK"] = 53;
        values[valuesById[54] = "CR"] = 54;
        values[valuesById[55] = "HR"] = 55;
        values[valuesById[56] = "CU"] = 56;
        values[valuesById[57] = "CY"] = 57;
        values[valuesById[58] = "CZ"] = 58;
        values[valuesById[59] = "BJ"] = 59;
        values[valuesById[60] = "DK"] = 60;
        values[valuesById[61] = "DM"] = 61;
        values[valuesById[62] = "DO"] = 62;
        values[valuesById[63] = "EC"] = 63;
        values[valuesById[64] = "SV"] = 64;
        values[valuesById[65] = "GQ"] = 65;
        values[valuesById[66] = "ET"] = 66;
        values[valuesById[67] = "ER"] = 67;
        values[valuesById[68] = "EE"] = 68;
        values[valuesById[69] = "FO"] = 69;
        values[valuesById[70] = "FK"] = 70;
        values[valuesById[71] = "GS"] = 71;
        values[valuesById[72] = "FJ"] = 72;
        values[valuesById[73] = "FI"] = 73;
        values[valuesById[74] = "AX"] = 74;
        values[valuesById[75] = "FR"] = 75;
        values[valuesById[76] = "GF"] = 76;
        values[valuesById[77] = "PF"] = 77;
        values[valuesById[78] = "TF"] = 78;
        values[valuesById[79] = "DJ"] = 79;
        values[valuesById[80] = "GA"] = 80;
        values[valuesById[81] = "GE"] = 81;
        values[valuesById[82] = "GM"] = 82;
        values[valuesById[83] = "PS"] = 83;
        values[valuesById[84] = "DE"] = 84;
        values[valuesById[85] = "GH"] = 85;
        values[valuesById[86] = "GI"] = 86;
        values[valuesById[87] = "KI"] = 87;
        values[valuesById[88] = "GR"] = 88;
        values[valuesById[89] = "GL"] = 89;
        values[valuesById[90] = "GD"] = 90;
        values[valuesById[91] = "GP"] = 91;
        values[valuesById[92] = "GU"] = 92;
        values[valuesById[93] = "GT"] = 93;
        values[valuesById[94] = "GN"] = 94;
        values[valuesById[95] = "GY"] = 95;
        values[valuesById[96] = "HT"] = 96;
        values[valuesById[97] = "HM"] = 97;
        values[valuesById[98] = "VA"] = 98;
        values[valuesById[99] = "HN"] = 99;
        values[valuesById[100] = "HK"] = 100;
        values[valuesById[101] = "HU"] = 101;
        values[valuesById[102] = "IS"] = 102;
        values[valuesById[103] = "IN"] = 103;
        values[valuesById[104] = "ID"] = 104;
        values[valuesById[105] = "IR"] = 105;
        values[valuesById[106] = "IQ"] = 106;
        values[valuesById[107] = "IE"] = 107;
        values[valuesById[108] = "IL"] = 108;
        values[valuesById[109] = "IT"] = 109;
        values[valuesById[110] = "CI"] = 110;
        values[valuesById[111] = "JM"] = 111;
        values[valuesById[112] = "JP"] = 112;
        values[valuesById[113] = "KZ"] = 113;
        values[valuesById[114] = "JO"] = 114;
        values[valuesById[115] = "KE"] = 115;
        values[valuesById[116] = "KP"] = 116;
        values[valuesById[117] = "KR"] = 117;
        values[valuesById[118] = "KW"] = 118;
        values[valuesById[119] = "KG"] = 119;
        values[valuesById[120] = "LA"] = 120;
        values[valuesById[121] = "LB"] = 121;
        values[valuesById[122] = "LS"] = 122;
        values[valuesById[123] = "LV"] = 123;
        values[valuesById[124] = "LR"] = 124;
        values[valuesById[125] = "LY"] = 125;
        values[valuesById[126] = "LI"] = 126;
        values[valuesById[127] = "LT"] = 127;
        values[valuesById[128] = "LU"] = 128;
        values[valuesById[129] = "MO"] = 129;
        values[valuesById[130] = "MG"] = 130;
        values[valuesById[131] = "MW"] = 131;
        values[valuesById[132] = "MY"] = 132;
        values[valuesById[133] = "MV"] = 133;
        values[valuesById[134] = "ML"] = 134;
        values[valuesById[135] = "MT"] = 135;
        values[valuesById[136] = "MQ"] = 136;
        values[valuesById[137] = "MR"] = 137;
        values[valuesById[138] = "MU"] = 138;
        values[valuesById[139] = "MX"] = 139;
        values[valuesById[140] = "MC"] = 140;
        values[valuesById[141] = "MN"] = 141;
        values[valuesById[142] = "MD"] = 142;
        values[valuesById[143] = "ME"] = 143;
        values[valuesById[144] = "MS"] = 144;
        values[valuesById[145] = "MA"] = 145;
        values[valuesById[146] = "MZ"] = 146;
        values[valuesById[147] = "OM"] = 147;
        values[valuesById[148] = "NA"] = 148;
        values[valuesById[149] = "NR"] = 149;
        values[valuesById[150] = "NP"] = 150;
        values[valuesById[151] = "NL"] = 151;
        values[valuesById[152] = "CW"] = 152;
        values[valuesById[153] = "AW"] = 153;
        values[valuesById[154] = "SX"] = 154;
        values[valuesById[155] = "BQ"] = 155;
        values[valuesById[156] = "NC"] = 156;
        values[valuesById[157] = "VU"] = 157;
        values[valuesById[158] = "NZ"] = 158;
        values[valuesById[159] = "NI"] = 159;
        values[valuesById[160] = "NE"] = 160;
        values[valuesById[161] = "NG"] = 161;
        values[valuesById[162] = "NU"] = 162;
        values[valuesById[163] = "NF"] = 163;
        values[valuesById[164] = "NO"] = 164;
        values[valuesById[165] = "MP"] = 165;
        values[valuesById[166] = "UM"] = 166;
        values[valuesById[167] = "FM"] = 167;
        values[valuesById[168] = "MH"] = 168;
        values[valuesById[169] = "PW"] = 169;
        values[valuesById[170] = "PK"] = 170;
        values[valuesById[171] = "PA"] = 171;
        values[valuesById[172] = "PG"] = 172;
        values[valuesById[173] = "PY"] = 173;
        values[valuesById[174] = "PE"] = 174;
        values[valuesById[175] = "PH"] = 175;
        values[valuesById[176] = "PN"] = 176;
        values[valuesById[177] = "PL"] = 177;
        values[valuesById[178] = "PT"] = 178;
        values[valuesById[179] = "GW"] = 179;
        values[valuesById[180] = "TL"] = 180;
        values[valuesById[181] = "PR"] = 181;
        values[valuesById[182] = "QA"] = 182;
        values[valuesById[183] = "RE"] = 183;
        values[valuesById[184] = "RO"] = 184;
        values[valuesById[185] = "RU"] = 185;
        values[valuesById[186] = "RW"] = 186;
        values[valuesById[187] = "BL"] = 187;
        values[valuesById[188] = "SH"] = 188;
        values[valuesById[189] = "KN"] = 189;
        values[valuesById[190] = "AI"] = 190;
        values[valuesById[191] = "LC"] = 191;
        values[valuesById[192] = "MF"] = 192;
        values[valuesById[193] = "PM"] = 193;
        values[valuesById[194] = "VC"] = 194;
        values[valuesById[195] = "SM"] = 195;
        values[valuesById[196] = "ST"] = 196;
        values[valuesById[197] = "SA"] = 197;
        values[valuesById[198] = "SN"] = 198;
        values[valuesById[199] = "RS"] = 199;
        values[valuesById[200] = "SC"] = 200;
        values[valuesById[201] = "SL"] = 201;
        values[valuesById[202] = "SG"] = 202;
        values[valuesById[203] = "SK"] = 203;
        values[valuesById[204] = "VN"] = 204;
        values[valuesById[205] = "SI"] = 205;
        values[valuesById[206] = "SO"] = 206;
        values[valuesById[207] = "ZA"] = 207;
        values[valuesById[208] = "ZW"] = 208;
        values[valuesById[209] = "ES"] = 209;
        values[valuesById[210] = "SS"] = 210;
        values[valuesById[211] = "SD"] = 211;
        values[valuesById[212] = "EH"] = 212;
        values[valuesById[213] = "SR"] = 213;
        values[valuesById[214] = "SJ"] = 214;
        values[valuesById[215] = "SZ"] = 215;
        values[valuesById[216] = "SE"] = 216;
        values[valuesById[217] = "CH"] = 217;
        values[valuesById[218] = "SY"] = 218;
        values[valuesById[219] = "TJ"] = 219;
        values[valuesById[220] = "TH"] = 220;
        values[valuesById[221] = "TG"] = 221;
        values[valuesById[222] = "TK"] = 222;
        values[valuesById[223] = "TO"] = 223;
        values[valuesById[224] = "TT"] = 224;
        values[valuesById[225] = "AE"] = 225;
        values[valuesById[226] = "TN"] = 226;
        values[valuesById[227] = "TR"] = 227;
        values[valuesById[228] = "TM"] = 228;
        values[valuesById[229] = "TC"] = 229;
        values[valuesById[230] = "TV"] = 230;
        values[valuesById[231] = "UG"] = 231;
        values[valuesById[232] = "UA"] = 232;
        values[valuesById[233] = "MK"] = 233;
        values[valuesById[234] = "EG"] = 234;
        values[valuesById[235] = "GB"] = 235;
        values[valuesById[236] = "GG"] = 236;
        values[valuesById[237] = "JE"] = 237;
        values[valuesById[238] = "IM"] = 238;
        values[valuesById[239] = "TZ"] = 239;
        values[valuesById[240] = "US"] = 240;
        values[valuesById[241] = "VI"] = 241;
        values[valuesById[242] = "BF"] = 242;
        values[valuesById[243] = "UY"] = 243;
        values[valuesById[244] = "UZ"] = 244;
        values[valuesById[245] = "VE"] = 245;
        values[valuesById[246] = "WF"] = 246;
        values[valuesById[247] = "WS"] = 247;
        values[valuesById[248] = "YE"] = 248;
        values[valuesById[249] = "ZM"] = 249;
        values[valuesById[250] = "A1"] = 250;
        values[valuesById[251] = "A2"] = 251;
        values[valuesById[252] = "O1"] = 252;
        values[valuesById[253] = "AP"] = 253;
        values[valuesById[254] = "EU"] = 254;
        values[valuesById[255] = "PRIVATE"] = 255;
        return values;
    })();

    globals.RecItem = (function() {

        function RecItem(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        RecItem.prototype.type = 0;
        RecItem.prototype.id = null;
        RecItem.prototype.name = null;

        let $oneOfFields;

        Object.defineProperty(RecItem.prototype, "item", {
            get: $util.oneOfGetter($oneOfFields = ["id", "name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        RecItem.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                $root.google.protobuf.UInt64Value.encode(m.id, w.uint32(18).fork()).ldelim();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                $root.google.protobuf.StringValue.encode(m.name, w.uint32(26).fork()).ldelim();
            return w;
        };

        RecItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RecItem.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.RecItem();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.type = r.int32();
                        break;
                    }
                case 2: {
                        m.id = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.name = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        RecItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RecItem.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            var p = {};
            if (m.type != null && m.hasOwnProperty("type")) {
                switch (m.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    break;
                }
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                p.item = 1;
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.id);
                    if (e)
                        return "id." + e;
                }
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                if (p.item === 1)
                    return "item: multiple values";
                p.item = 1;
                {
                    var e = $root.google.protobuf.StringValue.verify(m.name);
                    if (e)
                        return "name." + e;
                }
            }
            return null;
        };

        RecItem.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.RecItem)
                return d;
            var m = new $root.globals.RecItem();
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "UNKNOWN_ENTITY_TYPE":
            case 0:
                m.type = 0;
                break;
            case "TYPE_URL":
            case 1:
                m.type = 1;
                break;
            case "TYPE_COLLECTION":
            case 2:
                m.type = 2;
                break;
            case "TYPE_USER":
            case 3:
                m.type = 3;
                break;
            case "TYPE_INTEREST":
            case 4:
                m.type = 4;
                break;
            case "TYPE_DOMAIN":
            case 5:
                m.type = 5;
                break;
            case "TYPE_KEYWORD":
            case 6:
                m.type = 6;
                break;
            case "TYPE_NODE":
            case 7:
                m.type = 7;
                break;
            case "TYPE_CHANNEL":
            case 8:
                m.type = 8;
                break;
            case "TYPE_PROVIDER":
            case 9:
                m.type = 9;
                break;
            }
            if (d.id != null) {
                if (typeof d.id === "object" && d.id !== null && d.id.value !== undefined)
                    m.id = $root.google.protobuf.UInt64Value.fromObject(d.id);
                else
                    m.id = $root.google.protobuf.UInt64Value.fromObject({ value: d.id });
            }
            if (d.name != null) {
                if (typeof d.name === "object" && d.name !== null && d.name.value !== undefined)
                    m.name = $root.google.protobuf.StringValue.fromObject(d.name);
                else
                    m.name = $root.google.protobuf.StringValue.fromObject({ value: d.name });
            }
            return m;
        };

        RecItem.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = o.enums === String ? "UNKNOWN_ENTITY_TYPE" : 0;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.globals.EntityType[m.type] === undefined ? m.type : $root.globals.EntityType[m.type] : m.type;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = $root.google.protobuf.UInt64Value.toObject(m.id, o).value;
                if (o.oneofs)
                    d.item = "id";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = $root.google.protobuf.StringValue.toObject(m.name, o).value;
                if (o.oneofs)
                    d.item = "name";
            }
            return d;
        };

        RecItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        RecItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.RecItem";
        };

        return RecItem;
    })();

    globals.RecFilters = (function() {

        function RecFilters(p) {
            this.media = [];
            this.types = [];
            this.keywords = [];
            this.urlFacetFilter = [];
            this.models = [];
            this.urlFacetRescorer = [];
            this.rescorers = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        RecFilters.prototype.media = $util.emptyArray;
        RecFilters.prototype.types = $util.emptyArray;
        RecFilters.prototype.keywords = $util.emptyArray;
        RecFilters.prototype.gridDiscount = null;
        RecFilters.prototype.allowSeen = null;
        RecFilters.prototype.newerThan = null;
        RecFilters.prototype.olderThan = null;
        RecFilters.prototype.urlFacetFilter = $util.emptyArray;
        RecFilters.prototype.models = $util.emptyArray;
        RecFilters.prototype.urlFacetRescorer = $util.emptyArray;
        RecFilters.prototype.hsv = null;
        RecFilters.prototype.rescorers = $util.emptyArray;

        RecFilters.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.media != null && m.media.length) {
                w.uint32(10).fork();
                for (var i = 0; i < m.media.length; ++i)
                    w.int32(m.media[i]);
                w.ldelim();
            }
            if (m.types != null && m.types.length) {
                w.uint32(18).fork();
                for (var i = 0; i < m.types.length; ++i)
                    w.int32(m.types[i]);
                w.ldelim();
            }
            if (m.keywords != null && m.keywords.length) {
                for (var i = 0; i < m.keywords.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.keywords[i], w.uint32(26).fork()).ldelim();
            }
            if (m.gridDiscount != null && Object.hasOwnProperty.call(m, "gridDiscount"))
                $root.google.protobuf.UInt64Value.encode(m.gridDiscount, w.uint32(34).fork()).ldelim();
            if (m.allowSeen != null && Object.hasOwnProperty.call(m, "allowSeen"))
                $root.google.protobuf.BoolValue.encode(m.allowSeen, w.uint32(42).fork()).ldelim();
            if (m.newerThan != null && Object.hasOwnProperty.call(m, "newerThan"))
                $root.google.protobuf.StringValue.encode(m.newerThan, w.uint32(50).fork()).ldelim();
            if (m.olderThan != null && Object.hasOwnProperty.call(m, "olderThan"))
                $root.google.protobuf.StringValue.encode(m.olderThan, w.uint32(58).fork()).ldelim();
            if (m.urlFacetFilter != null && m.urlFacetFilter.length) {
                for (var i = 0; i < m.urlFacetFilter.length; ++i)
                    $root.globals.UrlFacetFilter.encode(m.urlFacetFilter[i], w.uint32(66).fork()).ldelim();
            }
            if (m.models != null && m.models.length) {
                for (var i = 0; i < m.models.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.models[i], w.uint32(74).fork()).ldelim();
            }
            if (m.urlFacetRescorer != null && m.urlFacetRescorer.length) {
                for (var i = 0; i < m.urlFacetRescorer.length; ++i)
                    $root.globals.UrlFacetRescorer.encode(m.urlFacetRescorer[i], w.uint32(82).fork()).ldelim();
            }
            if (m.hsv != null && Object.hasOwnProperty.call(m, "hsv"))
                $root.globals.ColorSpaceHSV.encode(m.hsv, w.uint32(90).fork()).ldelim();
            if (m.rescorers != null && m.rescorers.length) {
                for (var i = 0; i < m.rescorers.length; ++i)
                    $root.globals.RecRescorer.encode(m.rescorers[i], w.uint32(98).fork()).ldelim();
            }
            return w;
        };

        RecFilters.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RecFilters.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.RecFilters();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (!(m.media && m.media.length))
                            m.media = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.media.push(r.int32());
                        } else
                            m.media.push(r.int32());
                        break;
                    }
                case 2: {
                        if (!(m.types && m.types.length))
                            m.types = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.types.push(r.int32());
                        } else
                            m.types.push(r.int32());
                        break;
                    }
                case 3: {
                        if (!(m.keywords && m.keywords.length))
                            m.keywords = [];
                        m.keywords.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 4: {
                        m.gridDiscount = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        m.allowSeen = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        m.newerThan = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.olderThan = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        if (!(m.urlFacetFilter && m.urlFacetFilter.length))
                            m.urlFacetFilter = [];
                        m.urlFacetFilter.push($root.globals.UrlFacetFilter.decode(r, r.uint32()));
                        break;
                    }
                case 9: {
                        if (!(m.models && m.models.length))
                            m.models = [];
                        m.models.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 10: {
                        if (!(m.urlFacetRescorer && m.urlFacetRescorer.length))
                            m.urlFacetRescorer = [];
                        m.urlFacetRescorer.push($root.globals.UrlFacetRescorer.decode(r, r.uint32()));
                        break;
                    }
                case 11: {
                        m.hsv = $root.globals.ColorSpaceHSV.decode(r, r.uint32());
                        break;
                    }
                case 12: {
                        if (!(m.rescorers && m.rescorers.length))
                            m.rescorers = [];
                        m.rescorers.push($root.globals.RecRescorer.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        RecFilters.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RecFilters.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.media != null && m.hasOwnProperty("media")) {
                if (!Array.isArray(m.media))
                    return "media: array expected";
                for (var i = 0; i < m.media.length; ++i) {
                    switch (m.media[i]) {
                    default:
                        return "media: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                }
            }
            if (m.types != null && m.hasOwnProperty("types")) {
                if (!Array.isArray(m.types))
                    return "types: array expected";
                for (var i = 0; i < m.types.length; ++i) {
                    switch (m.types[i]) {
                    default:
                        return "types: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        break;
                    }
                }
            }
            if (m.keywords != null && m.hasOwnProperty("keywords")) {
                if (!Array.isArray(m.keywords))
                    return "keywords: array expected";
                for (var i = 0; i < m.keywords.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.keywords[i]);
                        if (e)
                            return "keywords." + e;
                    }
                }
            }
            if (m.gridDiscount != null && m.hasOwnProperty("gridDiscount")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.gridDiscount);
                    if (e)
                        return "gridDiscount." + e;
                }
            }
            if (m.allowSeen != null && m.hasOwnProperty("allowSeen")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.allowSeen);
                    if (e)
                        return "allowSeen." + e;
                }
            }
            if (m.newerThan != null && m.hasOwnProperty("newerThan")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.newerThan);
                    if (e)
                        return "newerThan." + e;
                }
            }
            if (m.olderThan != null && m.hasOwnProperty("olderThan")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.olderThan);
                    if (e)
                        return "olderThan." + e;
                }
            }
            if (m.urlFacetFilter != null && m.hasOwnProperty("urlFacetFilter")) {
                if (!Array.isArray(m.urlFacetFilter))
                    return "urlFacetFilter: array expected";
                for (var i = 0; i < m.urlFacetFilter.length; ++i) {
                    {
                        var e = $root.globals.UrlFacetFilter.verify(m.urlFacetFilter[i]);
                        if (e)
                            return "urlFacetFilter." + e;
                    }
                }
            }
            if (m.models != null && m.hasOwnProperty("models")) {
                if (!Array.isArray(m.models))
                    return "models: array expected";
                for (var i = 0; i < m.models.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.models[i]);
                        if (e)
                            return "models." + e;
                    }
                }
            }
            if (m.urlFacetRescorer != null && m.hasOwnProperty("urlFacetRescorer")) {
                if (!Array.isArray(m.urlFacetRescorer))
                    return "urlFacetRescorer: array expected";
                for (var i = 0; i < m.urlFacetRescorer.length; ++i) {
                    {
                        var e = $root.globals.UrlFacetRescorer.verify(m.urlFacetRescorer[i]);
                        if (e)
                            return "urlFacetRescorer." + e;
                    }
                }
            }
            if (m.hsv != null && m.hasOwnProperty("hsv")) {
                {
                    var e = $root.globals.ColorSpaceHSV.verify(m.hsv);
                    if (e)
                        return "hsv." + e;
                }
            }
            if (m.rescorers != null && m.hasOwnProperty("rescorers")) {
                if (!Array.isArray(m.rescorers))
                    return "rescorers: array expected";
                for (var i = 0; i < m.rescorers.length; ++i) {
                    {
                        var e = $root.globals.RecRescorer.verify(m.rescorers[i]);
                        if (e)
                            return "rescorers." + e;
                    }
                }
            }
            return null;
        };

        RecFilters.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.RecFilters)
                return d;
            var m = new $root.globals.RecFilters();
            if (d.media) {
                if (!Array.isArray(d.media))
                    throw TypeError(".globals.RecFilters.media: array expected");
                m.media = [];
                for (var i = 0; i < d.media.length; ++i) {
                    switch (d.media[i]) {
                    default:
                        if (typeof d.media[i] === "number") {
                            m.media[i] = d.media[i];
                            break;
                        }
                    case "UNKNOWN_MEDIA_TYPE":
                    case 0:
                        m.media[i] = 0;
                        break;
                    case "WEBSITE":
                    case 1:
                        m.media[i] = 1;
                        break;
                    case "ARTICLE":
                    case 2:
                        m.media[i] = 2;
                        break;
                    case "IMAGE":
                    case 3:
                        m.media[i] = 3;
                        break;
                    case "VIDEO":
                    case 4:
                        m.media[i] = 4;
                        break;
                    case "AUDIO":
                    case 5:
                        m.media[i] = 5;
                        break;
                    case "PDF":
                    case 6:
                        m.media[i] = 6;
                        break;
                    case "NEWS":
                    case 7:
                        m.media[i] = 7;
                        break;
                    case "GAME":
                    case 8:
                        m.media[i] = 8;
                        break;
                    case "GIF":
                    case 9:
                        m.media[i] = 9;
                        break;
                    case "APP":
                    case 10:
                        m.media[i] = 10;
                        break;
                    case "GALLERY":
                    case 11:
                        m.media[i] = 11;
                        break;
                    }
                }
            }
            if (d.types) {
                if (!Array.isArray(d.types))
                    throw TypeError(".globals.RecFilters.types: array expected");
                m.types = [];
                for (var i = 0; i < d.types.length; ++i) {
                    switch (d.types[i]) {
                    default:
                        if (typeof d.types[i] === "number") {
                            m.types[i] = d.types[i];
                            break;
                        }
                    case "UNKNOWN_ENTITY_TYPE":
                    case 0:
                        m.types[i] = 0;
                        break;
                    case "TYPE_URL":
                    case 1:
                        m.types[i] = 1;
                        break;
                    case "TYPE_COLLECTION":
                    case 2:
                        m.types[i] = 2;
                        break;
                    case "TYPE_USER":
                    case 3:
                        m.types[i] = 3;
                        break;
                    case "TYPE_INTEREST":
                    case 4:
                        m.types[i] = 4;
                        break;
                    case "TYPE_DOMAIN":
                    case 5:
                        m.types[i] = 5;
                        break;
                    case "TYPE_KEYWORD":
                    case 6:
                        m.types[i] = 6;
                        break;
                    case "TYPE_NODE":
                    case 7:
                        m.types[i] = 7;
                        break;
                    case "TYPE_CHANNEL":
                    case 8:
                        m.types[i] = 8;
                        break;
                    case "TYPE_PROVIDER":
                    case 9:
                        m.types[i] = 9;
                        break;
                    }
                }
            }
            if (d.keywords) {
                if (!Array.isArray(d.keywords))
                    throw TypeError(".globals.RecFilters.keywords: array expected");
                m.keywords = [];
                for (var i = 0; i < d.keywords.length; ++i) {
                    if (typeof d.keywords[i] === "object" && d.keywords[i] !== null && d.keywords[i].value !== undefined)
                        m.keywords[i] = $root.google.protobuf.StringValue.fromObject(d.keywords[i]);
                    else
                        m.keywords[i] = $root.google.protobuf.StringValue.fromObject({ value: d.keywords[i] });
                }
            }
            if (d.gridDiscount != null) {
                if (typeof d.gridDiscount === "object" && d.gridDiscount !== null && d.gridDiscount.value !== undefined)
                    m.gridDiscount = $root.google.protobuf.UInt64Value.fromObject(d.gridDiscount);
                else
                    m.gridDiscount = $root.google.protobuf.UInt64Value.fromObject({ value: d.gridDiscount });
            }
            if (d.allowSeen != null) {
                if (typeof d.allowSeen === "object" && d.allowSeen !== null && d.allowSeen.value !== undefined)
                    m.allowSeen = $root.google.protobuf.BoolValue.fromObject(d.allowSeen);
                else
                    m.allowSeen = $root.google.protobuf.BoolValue.fromObject({ value: d.allowSeen });
            }
            if (d.newerThan != null) {
                if (typeof d.newerThan === "object" && d.newerThan !== null && d.newerThan.value !== undefined)
                    m.newerThan = $root.google.protobuf.StringValue.fromObject(d.newerThan);
                else
                    m.newerThan = $root.google.protobuf.StringValue.fromObject({ value: d.newerThan });
            }
            if (d.olderThan != null) {
                if (typeof d.olderThan === "object" && d.olderThan !== null && d.olderThan.value !== undefined)
                    m.olderThan = $root.google.protobuf.StringValue.fromObject(d.olderThan);
                else
                    m.olderThan = $root.google.protobuf.StringValue.fromObject({ value: d.olderThan });
            }
            if (d.urlFacetFilter) {
                if (!Array.isArray(d.urlFacetFilter))
                    throw TypeError(".globals.RecFilters.urlFacetFilter: array expected");
                m.urlFacetFilter = [];
                for (var i = 0; i < d.urlFacetFilter.length; ++i) {
                    if (typeof d.urlFacetFilter[i] !== "object")
                        throw TypeError(".globals.RecFilters.urlFacetFilter: object expected");
                    m.urlFacetFilter[i] = $root.globals.UrlFacetFilter.fromObject(d.urlFacetFilter[i]);
                }
            }
            if (d.models) {
                if (!Array.isArray(d.models))
                    throw TypeError(".globals.RecFilters.models: array expected");
                m.models = [];
                for (var i = 0; i < d.models.length; ++i) {
                    if (typeof d.models[i] === "object" && d.models[i] !== null && d.models[i].value !== undefined)
                        m.models[i] = $root.google.protobuf.StringValue.fromObject(d.models[i]);
                    else
                        m.models[i] = $root.google.protobuf.StringValue.fromObject({ value: d.models[i] });
                }
            }
            if (d.urlFacetRescorer) {
                if (!Array.isArray(d.urlFacetRescorer))
                    throw TypeError(".globals.RecFilters.urlFacetRescorer: array expected");
                m.urlFacetRescorer = [];
                for (var i = 0; i < d.urlFacetRescorer.length; ++i) {
                    if (typeof d.urlFacetRescorer[i] !== "object")
                        throw TypeError(".globals.RecFilters.urlFacetRescorer: object expected");
                    m.urlFacetRescorer[i] = $root.globals.UrlFacetRescorer.fromObject(d.urlFacetRescorer[i]);
                }
            }
            if (d.hsv != null) {
                if (typeof d.hsv !== "object")
                    throw TypeError(".globals.RecFilters.hsv: object expected");
                m.hsv = $root.globals.ColorSpaceHSV.fromObject(d.hsv);
            }
            if (d.rescorers) {
                if (!Array.isArray(d.rescorers))
                    throw TypeError(".globals.RecFilters.rescorers: array expected");
                m.rescorers = [];
                for (var i = 0; i < d.rescorers.length; ++i) {
                    if (typeof d.rescorers[i] !== "object")
                        throw TypeError(".globals.RecFilters.rescorers: object expected");
                    m.rescorers[i] = $root.globals.RecRescorer.fromObject(d.rescorers[i]);
                }
            }
            return m;
        };

        RecFilters.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.media = [];
                d.types = [];
                d.keywords = [];
                d.urlFacetFilter = [];
                d.models = [];
                d.urlFacetRescorer = [];
                d.rescorers = [];
            }
            if (o.defaults) {
                d.gridDiscount = null;
                d.allowSeen = null;
                d.newerThan = null;
                d.olderThan = null;
                d.hsv = null;
            }
            if (m.media && m.media.length) {
                d.media = [];
                for (var j = 0; j < m.media.length; ++j) {
                    d.media[j] = o.enums === String ? $root.globals.MediaType[m.media[j]] === undefined ? m.media[j] : $root.globals.MediaType[m.media[j]] : m.media[j];
                }
            }
            if (m.types && m.types.length) {
                d.types = [];
                for (var j = 0; j < m.types.length; ++j) {
                    d.types[j] = o.enums === String ? $root.globals.EntityType[m.types[j]] === undefined ? m.types[j] : $root.globals.EntityType[m.types[j]] : m.types[j];
                }
            }
            if (m.keywords && m.keywords.length) {
                d.keywords = [];
                for (var j = 0; j < m.keywords.length; ++j) {
                    d.keywords[j] = $root.google.protobuf.StringValue.toObject(m.keywords[j], o).value;
                }
            }
            if (m.gridDiscount != null && m.hasOwnProperty("gridDiscount")) {
                d.gridDiscount = $root.google.protobuf.UInt64Value.toObject(m.gridDiscount, o).value;
            }
            if (m.allowSeen != null && m.hasOwnProperty("allowSeen")) {
                d.allowSeen = $root.google.protobuf.BoolValue.toObject(m.allowSeen, o).value;
            }
            if (m.newerThan != null && m.hasOwnProperty("newerThan")) {
                d.newerThan = $root.google.protobuf.StringValue.toObject(m.newerThan, o).value;
            }
            if (m.olderThan != null && m.hasOwnProperty("olderThan")) {
                d.olderThan = $root.google.protobuf.StringValue.toObject(m.olderThan, o).value;
            }
            if (m.urlFacetFilter && m.urlFacetFilter.length) {
                d.urlFacetFilter = [];
                for (var j = 0; j < m.urlFacetFilter.length; ++j) {
                    d.urlFacetFilter[j] = $root.globals.UrlFacetFilter.toObject(m.urlFacetFilter[j], o);
                }
            }
            if (m.models && m.models.length) {
                d.models = [];
                for (var j = 0; j < m.models.length; ++j) {
                    d.models[j] = $root.google.protobuf.StringValue.toObject(m.models[j], o).value;
                }
            }
            if (m.urlFacetRescorer && m.urlFacetRescorer.length) {
                d.urlFacetRescorer = [];
                for (var j = 0; j < m.urlFacetRescorer.length; ++j) {
                    d.urlFacetRescorer[j] = $root.globals.UrlFacetRescorer.toObject(m.urlFacetRescorer[j], o);
                }
            }
            if (m.hsv != null && m.hasOwnProperty("hsv")) {
                d.hsv = $root.globals.ColorSpaceHSV.toObject(m.hsv, o);
            }
            if (m.rescorers && m.rescorers.length) {
                d.rescorers = [];
                for (var j = 0; j < m.rescorers.length; ++j) {
                    d.rescorers[j] = $root.globals.RecRescorer.toObject(m.rescorers[j], o);
                }
            }
            return d;
        };

        RecFilters.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        RecFilters.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.RecFilters";
        };

        return RecFilters;
    })();

    globals.RecRescorer = (function() {

        function RecRescorer(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        RecRescorer.prototype.type = 0;
        RecRescorer.prototype.state = null;

        RecRescorer.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                $root.google.protobuf.BoolValue.encode(m.state, w.uint32(18).fork()).ldelim();
            return w;
        };

        RecRescorer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RecRescorer.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.RecRescorer();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.type = r.int32();
                        break;
                    }
                case 2: {
                        m.state = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        RecRescorer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RecRescorer.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.type != null && m.hasOwnProperty("type")) {
                switch (m.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.state);
                    if (e)
                        return "state." + e;
                }
            }
            return null;
        };

        RecRescorer.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.RecRescorer)
                return d;
            var m = new $root.globals.RecRescorer();
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "UNKNOWN_RESCORER":
            case 0:
                m.type = 0;
                break;
            case "RESCORER_CHANNELS":
            case 1:
                m.type = 1;
                break;
            case "RESCORER_NUDE_FEATURES":
            case 2:
                m.type = 2;
                break;
            case "RESCORER_INTERESTS":
            case 3:
                m.type = 3;
                break;
            }
            if (d.state != null) {
                if (typeof d.state === "object" && d.state !== null && d.state.value !== undefined)
                    m.state = $root.google.protobuf.BoolValue.fromObject(d.state);
                else
                    m.state = $root.google.protobuf.BoolValue.fromObject({ value: d.state });
            }
            return m;
        };

        RecRescorer.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = o.enums === String ? "UNKNOWN_RESCORER" : 0;
                d.state = null;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.globals.RecRescorerType[m.type] === undefined ? m.type : $root.globals.RecRescorerType[m.type] : m.type;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = $root.google.protobuf.BoolValue.toObject(m.state, o).value;
            }
            return d;
        };

        RecRescorer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        RecRescorer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.RecRescorer";
        };

        return RecRescorer;
    })();

    globals.ColorSpaceHSV = (function() {

        function ColorSpaceHSV(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        ColorSpaceHSV.prototype.hue = null;
        ColorSpaceHSV.prototype.saturation = null;
        ColorSpaceHSV.prototype.value = null;

        ColorSpaceHSV.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.hue != null && Object.hasOwnProperty.call(m, "hue"))
                $root.google.protobuf.FloatValue.encode(m.hue, w.uint32(10).fork()).ldelim();
            if (m.saturation != null && Object.hasOwnProperty.call(m, "saturation"))
                $root.google.protobuf.FloatValue.encode(m.saturation, w.uint32(18).fork()).ldelim();
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                $root.google.protobuf.FloatValue.encode(m.value, w.uint32(26).fork()).ldelim();
            return w;
        };

        ColorSpaceHSV.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ColorSpaceHSV.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.ColorSpaceHSV();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.hue = $root.google.protobuf.FloatValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.saturation = $root.google.protobuf.FloatValue.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.value = $root.google.protobuf.FloatValue.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        ColorSpaceHSV.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ColorSpaceHSV.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.hue != null && m.hasOwnProperty("hue")) {
                {
                    var e = $root.google.protobuf.FloatValue.verify(m.hue);
                    if (e)
                        return "hue." + e;
                }
            }
            if (m.saturation != null && m.hasOwnProperty("saturation")) {
                {
                    var e = $root.google.protobuf.FloatValue.verify(m.saturation);
                    if (e)
                        return "saturation." + e;
                }
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                {
                    var e = $root.google.protobuf.FloatValue.verify(m.value);
                    if (e)
                        return "value." + e;
                }
            }
            return null;
        };

        ColorSpaceHSV.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.ColorSpaceHSV)
                return d;
            var m = new $root.globals.ColorSpaceHSV();
            if (d.hue != null) {
                if (typeof d.hue === "object" && d.hue !== null && d.hue.value !== undefined)
                    m.hue = $root.google.protobuf.FloatValue.fromObject(d.hue);
                else
                    m.hue = $root.google.protobuf.FloatValue.fromObject({ value: d.hue });
            }
            if (d.saturation != null) {
                if (typeof d.saturation === "object" && d.saturation !== null && d.saturation.value !== undefined)
                    m.saturation = $root.google.protobuf.FloatValue.fromObject(d.saturation);
                else
                    m.saturation = $root.google.protobuf.FloatValue.fromObject({ value: d.saturation });
            }
            if (d.value != null) {
                if (typeof d.value === "object" && d.value !== null && d.value.value !== undefined)
                    m.value = $root.google.protobuf.FloatValue.fromObject(d.value);
                else
                    m.value = $root.google.protobuf.FloatValue.fromObject({ value: d.value });
            }
            return m;
        };

        ColorSpaceHSV.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.hue = null;
                d.saturation = null;
                d.value = null;
            }
            if (m.hue != null && m.hasOwnProperty("hue")) {
                d.hue = $root.google.protobuf.FloatValue.toObject(m.hue, o).value;
            }
            if (m.saturation != null && m.hasOwnProperty("saturation")) {
                d.saturation = $root.google.protobuf.FloatValue.toObject(m.saturation, o).value;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = $root.google.protobuf.FloatValue.toObject(m.value, o).value;
            }
            return d;
        };

        ColorSpaceHSV.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ColorSpaceHSV.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.ColorSpaceHSV";
        };

        return ColorSpaceHSV;
    })();

    globals.DisplayInfo = (function() {

        function DisplayInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        DisplayInfo.prototype.orientation = 0;
        DisplayInfo.prototype.width = null;
        DisplayInfo.prototype.height = null;
        DisplayInfo.prototype.dpi = null;
        DisplayInfo.prototype.foldItems = null;
        DisplayInfo.prototype.thumbWidth = null;
        DisplayInfo.prototype.thumbHeight = null;

        DisplayInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.orientation != null && Object.hasOwnProperty.call(m, "orientation"))
                w.uint32(8).int32(m.orientation);
            if (m.width != null && Object.hasOwnProperty.call(m, "width"))
                $root.google.protobuf.UInt64Value.encode(m.width, w.uint32(18).fork()).ldelim();
            if (m.height != null && Object.hasOwnProperty.call(m, "height"))
                $root.google.protobuf.UInt64Value.encode(m.height, w.uint32(26).fork()).ldelim();
            if (m.dpi != null && Object.hasOwnProperty.call(m, "dpi"))
                $root.google.protobuf.DoubleValue.encode(m.dpi, w.uint32(34).fork()).ldelim();
            if (m.foldItems != null && Object.hasOwnProperty.call(m, "foldItems"))
                $root.google.protobuf.UInt64Value.encode(m.foldItems, w.uint32(42).fork()).ldelim();
            if (m.thumbWidth != null && Object.hasOwnProperty.call(m, "thumbWidth"))
                $root.google.protobuf.UInt64Value.encode(m.thumbWidth, w.uint32(50).fork()).ldelim();
            if (m.thumbHeight != null && Object.hasOwnProperty.call(m, "thumbHeight"))
                $root.google.protobuf.UInt64Value.encode(m.thumbHeight, w.uint32(58).fork()).ldelim();
            return w;
        };

        DisplayInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        DisplayInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.DisplayInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.orientation = r.int32();
                        break;
                    }
                case 2: {
                        m.width = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.height = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.dpi = $root.google.protobuf.DoubleValue.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        m.foldItems = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        m.thumbWidth = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.thumbHeight = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        DisplayInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        DisplayInfo.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.orientation != null && m.hasOwnProperty("orientation")) {
                switch (m.orientation) {
                default:
                    return "orientation: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            }
            if (m.width != null && m.hasOwnProperty("width")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.width);
                    if (e)
                        return "width." + e;
                }
            }
            if (m.height != null && m.hasOwnProperty("height")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.height);
                    if (e)
                        return "height." + e;
                }
            }
            if (m.dpi != null && m.hasOwnProperty("dpi")) {
                {
                    var e = $root.google.protobuf.DoubleValue.verify(m.dpi);
                    if (e)
                        return "dpi." + e;
                }
            }
            if (m.foldItems != null && m.hasOwnProperty("foldItems")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.foldItems);
                    if (e)
                        return "foldItems." + e;
                }
            }
            if (m.thumbWidth != null && m.hasOwnProperty("thumbWidth")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.thumbWidth);
                    if (e)
                        return "thumbWidth." + e;
                }
            }
            if (m.thumbHeight != null && m.hasOwnProperty("thumbHeight")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.thumbHeight);
                    if (e)
                        return "thumbHeight." + e;
                }
            }
            return null;
        };

        DisplayInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.DisplayInfo)
                return d;
            var m = new $root.globals.DisplayInfo();
            switch (d.orientation) {
            default:
                if (typeof d.orientation === "number") {
                    m.orientation = d.orientation;
                    break;
                }
                break;
            case "UNKNOWN_DISPLAY_ORIENTATION":
            case 0:
                m.orientation = 0;
                break;
            case "DISPLAY_ORIENTATION_LANDSCAPE":
            case 1:
                m.orientation = 1;
                break;
            case "DISPLAY_ORIENTATION_PORTRAIT":
            case 2:
                m.orientation = 2;
                break;
            }
            if (d.width != null) {
                if (typeof d.width === "object" && d.width !== null && d.width.value !== undefined)
                    m.width = $root.google.protobuf.UInt64Value.fromObject(d.width);
                else
                    m.width = $root.google.protobuf.UInt64Value.fromObject({ value: d.width });
            }
            if (d.height != null) {
                if (typeof d.height === "object" && d.height !== null && d.height.value !== undefined)
                    m.height = $root.google.protobuf.UInt64Value.fromObject(d.height);
                else
                    m.height = $root.google.protobuf.UInt64Value.fromObject({ value: d.height });
            }
            if (d.dpi != null) {
                if (typeof d.dpi === "object" && d.dpi !== null && d.dpi.value !== undefined)
                    m.dpi = $root.google.protobuf.DoubleValue.fromObject(d.dpi);
                else
                    m.dpi = $root.google.protobuf.DoubleValue.fromObject({ value: d.dpi });
            }
            if (d.foldItems != null) {
                if (typeof d.foldItems === "object" && d.foldItems !== null && d.foldItems.value !== undefined)
                    m.foldItems = $root.google.protobuf.UInt64Value.fromObject(d.foldItems);
                else
                    m.foldItems = $root.google.protobuf.UInt64Value.fromObject({ value: d.foldItems });
            }
            if (d.thumbWidth != null) {
                if (typeof d.thumbWidth === "object" && d.thumbWidth !== null && d.thumbWidth.value !== undefined)
                    m.thumbWidth = $root.google.protobuf.UInt64Value.fromObject(d.thumbWidth);
                else
                    m.thumbWidth = $root.google.protobuf.UInt64Value.fromObject({ value: d.thumbWidth });
            }
            if (d.thumbHeight != null) {
                if (typeof d.thumbHeight === "object" && d.thumbHeight !== null && d.thumbHeight.value !== undefined)
                    m.thumbHeight = $root.google.protobuf.UInt64Value.fromObject(d.thumbHeight);
                else
                    m.thumbHeight = $root.google.protobuf.UInt64Value.fromObject({ value: d.thumbHeight });
            }
            return m;
        };

        DisplayInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.orientation = o.enums === String ? "UNKNOWN_DISPLAY_ORIENTATION" : 0;
                d.width = null;
                d.height = null;
                d.dpi = null;
                d.foldItems = null;
                d.thumbWidth = null;
                d.thumbHeight = null;
            }
            if (m.orientation != null && m.hasOwnProperty("orientation")) {
                d.orientation = o.enums === String ? $root.globals.DisplayOrientation[m.orientation] === undefined ? m.orientation : $root.globals.DisplayOrientation[m.orientation] : m.orientation;
            }
            if (m.width != null && m.hasOwnProperty("width")) {
                d.width = $root.google.protobuf.UInt64Value.toObject(m.width, o).value;
            }
            if (m.height != null && m.hasOwnProperty("height")) {
                d.height = $root.google.protobuf.UInt64Value.toObject(m.height, o).value;
            }
            if (m.dpi != null && m.hasOwnProperty("dpi")) {
                d.dpi = $root.google.protobuf.DoubleValue.toObject(m.dpi, o).value;
            }
            if (m.foldItems != null && m.hasOwnProperty("foldItems")) {
                d.foldItems = $root.google.protobuf.UInt64Value.toObject(m.foldItems, o).value;
            }
            if (m.thumbWidth != null && m.hasOwnProperty("thumbWidth")) {
                d.thumbWidth = $root.google.protobuf.UInt64Value.toObject(m.thumbWidth, o).value;
            }
            if (m.thumbHeight != null && m.hasOwnProperty("thumbHeight")) {
                d.thumbHeight = $root.google.protobuf.UInt64Value.toObject(m.thumbHeight, o).value;
            }
            return d;
        };

        DisplayInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        DisplayInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.DisplayInfo";
        };

        return DisplayInfo;
    })();

    globals.RecScore = (function() {

        function RecScore(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        RecScore.prototype.item = null;
        RecScore.prototype.type = 0;
        RecScore.prototype.seconds = null;
        RecScore.prototype.percent = null;
        RecScore.prototype.offset = null;
        RecScore.prototype.count = null;
        RecScore.prototype.value = null;

        let $oneOfFields;

        Object.defineProperty(RecScore.prototype, "score", {
            get: $util.oneOfGetter($oneOfFields = ["seconds", "percent", "offset", "count", "value"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        RecScore.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.item != null && Object.hasOwnProperty.call(m, "item"))
                $root.globals.RecItem.encode(m.item, w.uint32(10).fork()).ldelim();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.seconds != null && Object.hasOwnProperty.call(m, "seconds"))
                $root.google.protobuf.DoubleValue.encode(m.seconds, w.uint32(26).fork()).ldelim();
            if (m.percent != null && Object.hasOwnProperty.call(m, "percent"))
                $root.google.protobuf.DoubleValue.encode(m.percent, w.uint32(34).fork()).ldelim();
            if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
                $root.google.protobuf.UInt32Value.encode(m.offset, w.uint32(42).fork()).ldelim();
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                $root.google.protobuf.UInt32Value.encode(m.count, w.uint32(50).fork()).ldelim();
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                $root.google.protobuf.DoubleValue.encode(m.value, w.uint32(58).fork()).ldelim();
            return w;
        };

        RecScore.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RecScore.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.RecScore();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.item = $root.globals.RecItem.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.type = r.int32();
                        break;
                    }
                case 3: {
                        m.seconds = $root.google.protobuf.DoubleValue.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.percent = $root.google.protobuf.DoubleValue.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        m.offset = $root.google.protobuf.UInt32Value.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        m.count = $root.google.protobuf.UInt32Value.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.value = $root.google.protobuf.DoubleValue.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        RecScore.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RecScore.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            var p = {};
            if (m.item != null && m.hasOwnProperty("item")) {
                {
                    var e = $root.globals.RecItem.verify(m.item);
                    if (e)
                        return "item." + e;
                }
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                switch (m.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                    break;
                }
            }
            if (m.seconds != null && m.hasOwnProperty("seconds")) {
                p.score = 1;
                {
                    var e = $root.google.protobuf.DoubleValue.verify(m.seconds);
                    if (e)
                        return "seconds." + e;
                }
            }
            if (m.percent != null && m.hasOwnProperty("percent")) {
                if (p.score === 1)
                    return "score: multiple values";
                p.score = 1;
                {
                    var e = $root.google.protobuf.DoubleValue.verify(m.percent);
                    if (e)
                        return "percent." + e;
                }
            }
            if (m.offset != null && m.hasOwnProperty("offset")) {
                if (p.score === 1)
                    return "score: multiple values";
                p.score = 1;
                {
                    var e = $root.google.protobuf.UInt32Value.verify(m.offset);
                    if (e)
                        return "offset." + e;
                }
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                if (p.score === 1)
                    return "score: multiple values";
                p.score = 1;
                {
                    var e = $root.google.protobuf.UInt32Value.verify(m.count);
                    if (e)
                        return "count." + e;
                }
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                if (p.score === 1)
                    return "score: multiple values";
                p.score = 1;
                {
                    var e = $root.google.protobuf.DoubleValue.verify(m.value);
                    if (e)
                        return "value." + e;
                }
            }
            return null;
        };

        RecScore.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.RecScore)
                return d;
            var m = new $root.globals.RecScore();
            if (d.item != null) {
                if (typeof d.item !== "object")
                    throw TypeError(".globals.RecScore.item: object expected");
                m.item = $root.globals.RecItem.fromObject(d.item);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "UNKNOWN_SCORE_TYPE":
            case 0:
                m.type = 0;
                break;
            case "SCORE_RATE_UP":
            case 1:
                m.type = 1;
                break;
            case "SCORE_RATE_DOWN":
            case 2:
                m.type = 2;
                break;
            case "SCORE_BOOKMARK":
            case 3:
                m.type = 3;
                break;
            case "SCORE_CURATE":
            case 4:
                m.type = 4;
                break;
            case "SCORE_DWELL":
            case 5:
                m.type = 5;
                break;
            case "SCORE_PLAY":
            case 6:
                m.type = 6;
                break;
            case "SCORE_SCROLL":
            case 7:
                m.type = 7;
                break;
            case "SCORE_FLAG":
            case 8:
                m.type = 8;
                break;
            case "SCORE_PIVOT":
            case 9:
                m.type = 9;
                break;
            case "SCORE_CLICK":
            case 10:
                m.type = 10;
                break;
            }
            if (d.seconds != null) {
                if (typeof d.seconds === "object" && d.seconds !== null && d.seconds.value !== undefined)
                    m.seconds = $root.google.protobuf.DoubleValue.fromObject(d.seconds);
                else
                    m.seconds = $root.google.protobuf.DoubleValue.fromObject({ value: d.seconds });
            }
            if (d.percent != null) {
                if (typeof d.percent === "object" && d.percent !== null && d.percent.value !== undefined)
                    m.percent = $root.google.protobuf.DoubleValue.fromObject(d.percent);
                else
                    m.percent = $root.google.protobuf.DoubleValue.fromObject({ value: d.percent });
            }
            if (d.offset != null) {
                if (typeof d.offset === "object" && d.offset !== null && d.offset.value !== undefined)
                    m.offset = $root.google.protobuf.UInt32Value.fromObject(d.offset);
                else
                    m.offset = $root.google.protobuf.UInt32Value.fromObject({ value: d.offset });
            }
            if (d.count != null) {
                if (typeof d.count === "object" && d.count !== null && d.count.value !== undefined)
                    m.count = $root.google.protobuf.UInt32Value.fromObject(d.count);
                else
                    m.count = $root.google.protobuf.UInt32Value.fromObject({ value: d.count });
            }
            if (d.value != null) {
                if (typeof d.value === "object" && d.value !== null && d.value.value !== undefined)
                    m.value = $root.google.protobuf.DoubleValue.fromObject(d.value);
                else
                    m.value = $root.google.protobuf.DoubleValue.fromObject({ value: d.value });
            }
            return m;
        };

        RecScore.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.item = null;
                d.type = o.enums === String ? "UNKNOWN_SCORE_TYPE" : 0;
            }
            if (m.item != null && m.hasOwnProperty("item")) {
                d.item = $root.globals.RecItem.toObject(m.item, o);
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.globals.ScoreType[m.type] === undefined ? m.type : $root.globals.ScoreType[m.type] : m.type;
            }
            if (m.seconds != null && m.hasOwnProperty("seconds")) {
                d.seconds = $root.google.protobuf.DoubleValue.toObject(m.seconds, o).value;
                if (o.oneofs)
                    d.score = "seconds";
            }
            if (m.percent != null && m.hasOwnProperty("percent")) {
                d.percent = $root.google.protobuf.DoubleValue.toObject(m.percent, o).value;
                if (o.oneofs)
                    d.score = "percent";
            }
            if (m.offset != null && m.hasOwnProperty("offset")) {
                d.offset = $root.google.protobuf.UInt32Value.toObject(m.offset, o).value;
                if (o.oneofs)
                    d.score = "offset";
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                d.count = $root.google.protobuf.UInt32Value.toObject(m.count, o).value;
                if (o.oneofs)
                    d.score = "count";
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = $root.google.protobuf.DoubleValue.toObject(m.value, o).value;
                if (o.oneofs)
                    d.score = "value";
            }
            return d;
        };

        RecScore.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        RecScore.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.RecScore";
        };

        return RecScore;
    })();

    globals.UrlFacets = (function() {

        function UrlFacets(p) {
            this.interests = [];
            this.owners = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        UrlFacets.prototype.contentClass = null;
        UrlFacets.prototype.domain = null;
        UrlFacets.prototype.interests = $util.emptyArray;
        UrlFacets.prototype.mediaType = null;
        UrlFacets.prototype.qualityScore = null;
        UrlFacets.prototype.providerStream = null;
        UrlFacets.prototype.provider = null;
        UrlFacets.prototype.owners = $util.emptyArray;
        UrlFacets.prototype.creator = null;
        UrlFacets.prototype.isDupe = null;
        UrlFacets.prototype.isNSFW = null;
        UrlFacets.prototype.isAdult = null;
        UrlFacets.prototype.likes = null;
        UrlFacets.prototype.durationSeconds = null;

        UrlFacets.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contentClass != null && Object.hasOwnProperty.call(m, "contentClass"))
                $root.google.protobuf.StringValue.encode(m.contentClass, w.uint32(10).fork()).ldelim();
            if (m.domain != null && Object.hasOwnProperty.call(m, "domain"))
                $root.google.protobuf.StringValue.encode(m.domain, w.uint32(18).fork()).ldelim();
            if (m.interests != null && m.interests.length) {
                for (var i = 0; i < m.interests.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.interests[i], w.uint32(26).fork()).ldelim();
            }
            if (m.mediaType != null && Object.hasOwnProperty.call(m, "mediaType"))
                $root.google.protobuf.StringValue.encode(m.mediaType, w.uint32(34).fork()).ldelim();
            if (m.qualityScore != null && Object.hasOwnProperty.call(m, "qualityScore"))
                $root.google.protobuf.FloatValue.encode(m.qualityScore, w.uint32(42).fork()).ldelim();
            if (m.providerStream != null && Object.hasOwnProperty.call(m, "providerStream"))
                $root.google.protobuf.StringValue.encode(m.providerStream, w.uint32(50).fork()).ldelim();
            if (m.provider != null && Object.hasOwnProperty.call(m, "provider"))
                $root.google.protobuf.StringValue.encode(m.provider, w.uint32(58).fork()).ldelim();
            if (m.owners != null && m.owners.length) {
                for (var i = 0; i < m.owners.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.owners[i], w.uint32(66).fork()).ldelim();
            }
            if (m.creator != null && Object.hasOwnProperty.call(m, "creator"))
                $root.google.protobuf.StringValue.encode(m.creator, w.uint32(74).fork()).ldelim();
            if (m.isDupe != null && Object.hasOwnProperty.call(m, "isDupe"))
                $root.google.protobuf.BoolValue.encode(m.isDupe, w.uint32(82).fork()).ldelim();
            if (m.isNSFW != null && Object.hasOwnProperty.call(m, "isNSFW"))
                $root.google.protobuf.BoolValue.encode(m.isNSFW, w.uint32(90).fork()).ldelim();
            if (m.isAdult != null && Object.hasOwnProperty.call(m, "isAdult"))
                $root.google.protobuf.BoolValue.encode(m.isAdult, w.uint32(98).fork()).ldelim();
            if (m.likes != null && Object.hasOwnProperty.call(m, "likes"))
                $root.google.protobuf.Int32Value.encode(m.likes, w.uint32(106).fork()).ldelim();
            if (m.durationSeconds != null && Object.hasOwnProperty.call(m, "durationSeconds"))
                $root.google.protobuf.Int32Value.encode(m.durationSeconds, w.uint32(114).fork()).ldelim();
            return w;
        };

        UrlFacets.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        UrlFacets.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.UrlFacets();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.contentClass = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.domain = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        if (!(m.interests && m.interests.length))
                            m.interests = [];
                        m.interests.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 4: {
                        m.mediaType = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        m.qualityScore = $root.google.protobuf.FloatValue.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        m.providerStream = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.provider = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        if (!(m.owners && m.owners.length))
                            m.owners = [];
                        m.owners.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 9: {
                        m.creator = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 10: {
                        m.isDupe = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 11: {
                        m.isNSFW = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 12: {
                        m.isAdult = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 13: {
                        m.likes = $root.google.protobuf.Int32Value.decode(r, r.uint32());
                        break;
                    }
                case 14: {
                        m.durationSeconds = $root.google.protobuf.Int32Value.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        UrlFacets.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        UrlFacets.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.contentClass != null && m.hasOwnProperty("contentClass")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.contentClass);
                    if (e)
                        return "contentClass." + e;
                }
            }
            if (m.domain != null && m.hasOwnProperty("domain")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.domain);
                    if (e)
                        return "domain." + e;
                }
            }
            if (m.interests != null && m.hasOwnProperty("interests")) {
                if (!Array.isArray(m.interests))
                    return "interests: array expected";
                for (var i = 0; i < m.interests.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.interests[i]);
                        if (e)
                            return "interests." + e;
                    }
                }
            }
            if (m.mediaType != null && m.hasOwnProperty("mediaType")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.mediaType);
                    if (e)
                        return "mediaType." + e;
                }
            }
            if (m.qualityScore != null && m.hasOwnProperty("qualityScore")) {
                {
                    var e = $root.google.protobuf.FloatValue.verify(m.qualityScore);
                    if (e)
                        return "qualityScore." + e;
                }
            }
            if (m.providerStream != null && m.hasOwnProperty("providerStream")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.providerStream);
                    if (e)
                        return "providerStream." + e;
                }
            }
            if (m.provider != null && m.hasOwnProperty("provider")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.provider);
                    if (e)
                        return "provider." + e;
                }
            }
            if (m.owners != null && m.hasOwnProperty("owners")) {
                if (!Array.isArray(m.owners))
                    return "owners: array expected";
                for (var i = 0; i < m.owners.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.owners[i]);
                        if (e)
                            return "owners." + e;
                    }
                }
            }
            if (m.creator != null && m.hasOwnProperty("creator")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.creator);
                    if (e)
                        return "creator." + e;
                }
            }
            if (m.isDupe != null && m.hasOwnProperty("isDupe")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.isDupe);
                    if (e)
                        return "isDupe." + e;
                }
            }
            if (m.isNSFW != null && m.hasOwnProperty("isNSFW")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.isNSFW);
                    if (e)
                        return "isNSFW." + e;
                }
            }
            if (m.isAdult != null && m.hasOwnProperty("isAdult")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.isAdult);
                    if (e)
                        return "isAdult." + e;
                }
            }
            if (m.likes != null && m.hasOwnProperty("likes")) {
                {
                    var e = $root.google.protobuf.Int32Value.verify(m.likes);
                    if (e)
                        return "likes." + e;
                }
            }
            if (m.durationSeconds != null && m.hasOwnProperty("durationSeconds")) {
                {
                    var e = $root.google.protobuf.Int32Value.verify(m.durationSeconds);
                    if (e)
                        return "durationSeconds." + e;
                }
            }
            return null;
        };

        UrlFacets.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.UrlFacets)
                return d;
            var m = new $root.globals.UrlFacets();
            if (d.contentClass != null) {
                if (typeof d.contentClass === "object" && d.contentClass !== null && d.contentClass.value !== undefined)
                    m.contentClass = $root.google.protobuf.StringValue.fromObject(d.contentClass);
                else
                    m.contentClass = $root.google.protobuf.StringValue.fromObject({ value: d.contentClass });
            }
            if (d.domain != null) {
                if (typeof d.domain === "object" && d.domain !== null && d.domain.value !== undefined)
                    m.domain = $root.google.protobuf.StringValue.fromObject(d.domain);
                else
                    m.domain = $root.google.protobuf.StringValue.fromObject({ value: d.domain });
            }
            if (d.interests) {
                if (!Array.isArray(d.interests))
                    throw TypeError(".globals.UrlFacets.interests: array expected");
                m.interests = [];
                for (var i = 0; i < d.interests.length; ++i) {
                    if (typeof d.interests[i] === "object" && d.interests[i] !== null && d.interests[i].value !== undefined)
                        m.interests[i] = $root.google.protobuf.StringValue.fromObject(d.interests[i]);
                    else
                        m.interests[i] = $root.google.protobuf.StringValue.fromObject({ value: d.interests[i] });
                }
            }
            if (d.mediaType != null) {
                if (typeof d.mediaType === "object" && d.mediaType !== null && d.mediaType.value !== undefined)
                    m.mediaType = $root.google.protobuf.StringValue.fromObject(d.mediaType);
                else
                    m.mediaType = $root.google.protobuf.StringValue.fromObject({ value: d.mediaType });
            }
            if (d.qualityScore != null) {
                if (typeof d.qualityScore === "object" && d.qualityScore !== null && d.qualityScore.value !== undefined)
                    m.qualityScore = $root.google.protobuf.FloatValue.fromObject(d.qualityScore);
                else
                    m.qualityScore = $root.google.protobuf.FloatValue.fromObject({ value: d.qualityScore });
            }
            if (d.providerStream != null) {
                if (typeof d.providerStream === "object" && d.providerStream !== null && d.providerStream.value !== undefined)
                    m.providerStream = $root.google.protobuf.StringValue.fromObject(d.providerStream);
                else
                    m.providerStream = $root.google.protobuf.StringValue.fromObject({ value: d.providerStream });
            }
            if (d.provider != null) {
                if (typeof d.provider === "object" && d.provider !== null && d.provider.value !== undefined)
                    m.provider = $root.google.protobuf.StringValue.fromObject(d.provider);
                else
                    m.provider = $root.google.protobuf.StringValue.fromObject({ value: d.provider });
            }
            if (d.owners) {
                if (!Array.isArray(d.owners))
                    throw TypeError(".globals.UrlFacets.owners: array expected");
                m.owners = [];
                for (var i = 0; i < d.owners.length; ++i) {
                    if (typeof d.owners[i] === "object" && d.owners[i] !== null && d.owners[i].value !== undefined)
                        m.owners[i] = $root.google.protobuf.StringValue.fromObject(d.owners[i]);
                    else
                        m.owners[i] = $root.google.protobuf.StringValue.fromObject({ value: d.owners[i] });
                }
            }
            if (d.creator != null) {
                if (typeof d.creator === "object" && d.creator !== null && d.creator.value !== undefined)
                    m.creator = $root.google.protobuf.StringValue.fromObject(d.creator);
                else
                    m.creator = $root.google.protobuf.StringValue.fromObject({ value: d.creator });
            }
            if (d.isDupe != null) {
                if (typeof d.isDupe === "object" && d.isDupe !== null && d.isDupe.value !== undefined)
                    m.isDupe = $root.google.protobuf.BoolValue.fromObject(d.isDupe);
                else
                    m.isDupe = $root.google.protobuf.BoolValue.fromObject({ value: d.isDupe });
            }
            if (d.isNSFW != null) {
                if (typeof d.isNSFW === "object" && d.isNSFW !== null && d.isNSFW.value !== undefined)
                    m.isNSFW = $root.google.protobuf.BoolValue.fromObject(d.isNSFW);
                else
                    m.isNSFW = $root.google.protobuf.BoolValue.fromObject({ value: d.isNSFW });
            }
            if (d.isAdult != null) {
                if (typeof d.isAdult === "object" && d.isAdult !== null && d.isAdult.value !== undefined)
                    m.isAdult = $root.google.protobuf.BoolValue.fromObject(d.isAdult);
                else
                    m.isAdult = $root.google.protobuf.BoolValue.fromObject({ value: d.isAdult });
            }
            if (d.likes != null) {
                if (typeof d.likes === "object" && d.likes !== null && d.likes.value !== undefined)
                    m.likes = $root.google.protobuf.Int32Value.fromObject(d.likes);
                else
                    m.likes = $root.google.protobuf.Int32Value.fromObject({ value: d.likes });
            }
            if (d.durationSeconds != null) {
                if (typeof d.durationSeconds === "object" && d.durationSeconds !== null && d.durationSeconds.value !== undefined)
                    m.durationSeconds = $root.google.protobuf.Int32Value.fromObject(d.durationSeconds);
                else
                    m.durationSeconds = $root.google.protobuf.Int32Value.fromObject({ value: d.durationSeconds });
            }
            return m;
        };

        UrlFacets.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.interests = [];
                d.owners = [];
            }
            if (o.defaults) {
                d.contentClass = null;
                d.domain = null;
                d.mediaType = null;
                d.qualityScore = null;
                d.providerStream = null;
                d.provider = null;
                d.creator = null;
                d.isDupe = null;
                d.isNSFW = null;
                d.isAdult = null;
                d.likes = null;
                d.durationSeconds = null;
            }
            if (m.contentClass != null && m.hasOwnProperty("contentClass")) {
                d.contentClass = $root.google.protobuf.StringValue.toObject(m.contentClass, o).value;
            }
            if (m.domain != null && m.hasOwnProperty("domain")) {
                d.domain = $root.google.protobuf.StringValue.toObject(m.domain, o).value;
            }
            if (m.interests && m.interests.length) {
                d.interests = [];
                for (var j = 0; j < m.interests.length; ++j) {
                    d.interests[j] = $root.google.protobuf.StringValue.toObject(m.interests[j], o).value;
                }
            }
            if (m.mediaType != null && m.hasOwnProperty("mediaType")) {
                d.mediaType = $root.google.protobuf.StringValue.toObject(m.mediaType, o).value;
            }
            if (m.qualityScore != null && m.hasOwnProperty("qualityScore")) {
                d.qualityScore = $root.google.protobuf.FloatValue.toObject(m.qualityScore, o).value;
            }
            if (m.providerStream != null && m.hasOwnProperty("providerStream")) {
                d.providerStream = $root.google.protobuf.StringValue.toObject(m.providerStream, o).value;
            }
            if (m.provider != null && m.hasOwnProperty("provider")) {
                d.provider = $root.google.protobuf.StringValue.toObject(m.provider, o).value;
            }
            if (m.owners && m.owners.length) {
                d.owners = [];
                for (var j = 0; j < m.owners.length; ++j) {
                    d.owners[j] = $root.google.protobuf.StringValue.toObject(m.owners[j], o).value;
                }
            }
            if (m.creator != null && m.hasOwnProperty("creator")) {
                d.creator = $root.google.protobuf.StringValue.toObject(m.creator, o).value;
            }
            if (m.isDupe != null && m.hasOwnProperty("isDupe")) {
                d.isDupe = $root.google.protobuf.BoolValue.toObject(m.isDupe, o).value;
            }
            if (m.isNSFW != null && m.hasOwnProperty("isNSFW")) {
                d.isNSFW = $root.google.protobuf.BoolValue.toObject(m.isNSFW, o).value;
            }
            if (m.isAdult != null && m.hasOwnProperty("isAdult")) {
                d.isAdult = $root.google.protobuf.BoolValue.toObject(m.isAdult, o).value;
            }
            if (m.likes != null && m.hasOwnProperty("likes")) {
                d.likes = $root.google.protobuf.Int32Value.toObject(m.likes, o).value;
            }
            if (m.durationSeconds != null && m.hasOwnProperty("durationSeconds")) {
                d.durationSeconds = $root.google.protobuf.Int32Value.toObject(m.durationSeconds, o).value;
            }
            return d;
        };

        UrlFacets.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UrlFacets.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.UrlFacets";
        };

        return UrlFacets;
    })();

    globals.DurationRange = (function() {

        function DurationRange(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        DurationRange.prototype.minSeconds = null;
        DurationRange.prototype.maxSeconds = null;

        DurationRange.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.minSeconds != null && Object.hasOwnProperty.call(m, "minSeconds"))
                $root.google.protobuf.Int32Value.encode(m.minSeconds, w.uint32(10).fork()).ldelim();
            if (m.maxSeconds != null && Object.hasOwnProperty.call(m, "maxSeconds"))
                $root.google.protobuf.Int32Value.encode(m.maxSeconds, w.uint32(18).fork()).ldelim();
            return w;
        };

        DurationRange.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        DurationRange.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.DurationRange();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.minSeconds = $root.google.protobuf.Int32Value.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.maxSeconds = $root.google.protobuf.Int32Value.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        DurationRange.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        DurationRange.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.minSeconds != null && m.hasOwnProperty("minSeconds")) {
                {
                    var e = $root.google.protobuf.Int32Value.verify(m.minSeconds);
                    if (e)
                        return "minSeconds." + e;
                }
            }
            if (m.maxSeconds != null && m.hasOwnProperty("maxSeconds")) {
                {
                    var e = $root.google.protobuf.Int32Value.verify(m.maxSeconds);
                    if (e)
                        return "maxSeconds." + e;
                }
            }
            return null;
        };

        DurationRange.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.DurationRange)
                return d;
            var m = new $root.globals.DurationRange();
            if (d.minSeconds != null) {
                if (typeof d.minSeconds === "object" && d.minSeconds !== null && d.minSeconds.value !== undefined)
                    m.minSeconds = $root.google.protobuf.Int32Value.fromObject(d.minSeconds);
                else
                    m.minSeconds = $root.google.protobuf.Int32Value.fromObject({ value: d.minSeconds });
            }
            if (d.maxSeconds != null) {
                if (typeof d.maxSeconds === "object" && d.maxSeconds !== null && d.maxSeconds.value !== undefined)
                    m.maxSeconds = $root.google.protobuf.Int32Value.fromObject(d.maxSeconds);
                else
                    m.maxSeconds = $root.google.protobuf.Int32Value.fromObject({ value: d.maxSeconds });
            }
            return m;
        };

        DurationRange.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.minSeconds = null;
                d.maxSeconds = null;
            }
            if (m.minSeconds != null && m.hasOwnProperty("minSeconds")) {
                d.minSeconds = $root.google.protobuf.Int32Value.toObject(m.minSeconds, o).value;
            }
            if (m.maxSeconds != null && m.hasOwnProperty("maxSeconds")) {
                d.maxSeconds = $root.google.protobuf.Int32Value.toObject(m.maxSeconds, o).value;
            }
            return d;
        };

        DurationRange.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        DurationRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.DurationRange";
        };

        return DurationRange;
    })();

    globals.UrlFacetFilter = (function() {

        function UrlFacetFilter(p) {
            this.contentClasses = [];
            this.domains = [];
            this.interests = [];
            this.mediaTypes = [];
            this.providerStreams = [];
            this.providers = [];
            this.owners = [];
            this.creators = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        UrlFacetFilter.prototype.contentClasses = $util.emptyArray;
        UrlFacetFilter.prototype.domains = $util.emptyArray;
        UrlFacetFilter.prototype.interests = $util.emptyArray;
        UrlFacetFilter.prototype.mediaTypes = $util.emptyArray;
        UrlFacetFilter.prototype.minQualityScore = null;
        UrlFacetFilter.prototype.providerStreams = $util.emptyArray;
        UrlFacetFilter.prototype.providers = $util.emptyArray;
        UrlFacetFilter.prototype.block = null;
        UrlFacetFilter.prototype.caseSensitive = null;
        UrlFacetFilter.prototype.owners = $util.emptyArray;
        UrlFacetFilter.prototype.creators = $util.emptyArray;
        UrlFacetFilter.prototype.isDupe = null;
        UrlFacetFilter.prototype.isNSFW = null;
        UrlFacetFilter.prototype.isAdult = null;
        UrlFacetFilter.prototype.minLikes = null;
        UrlFacetFilter.prototype.durationRange = null;

        UrlFacetFilter.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contentClasses != null && m.contentClasses.length) {
                for (var i = 0; i < m.contentClasses.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.contentClasses[i], w.uint32(10).fork()).ldelim();
            }
            if (m.domains != null && m.domains.length) {
                for (var i = 0; i < m.domains.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.domains[i], w.uint32(18).fork()).ldelim();
            }
            if (m.interests != null && m.interests.length) {
                for (var i = 0; i < m.interests.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.interests[i], w.uint32(26).fork()).ldelim();
            }
            if (m.mediaTypes != null && m.mediaTypes.length) {
                for (var i = 0; i < m.mediaTypes.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.mediaTypes[i], w.uint32(34).fork()).ldelim();
            }
            if (m.minQualityScore != null && Object.hasOwnProperty.call(m, "minQualityScore"))
                $root.google.protobuf.FloatValue.encode(m.minQualityScore, w.uint32(42).fork()).ldelim();
            if (m.providerStreams != null && m.providerStreams.length) {
                for (var i = 0; i < m.providerStreams.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.providerStreams[i], w.uint32(50).fork()).ldelim();
            }
            if (m.providers != null && m.providers.length) {
                for (var i = 0; i < m.providers.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.providers[i], w.uint32(58).fork()).ldelim();
            }
            if (m.block != null && Object.hasOwnProperty.call(m, "block"))
                $root.google.protobuf.BoolValue.encode(m.block, w.uint32(66).fork()).ldelim();
            if (m.caseSensitive != null && Object.hasOwnProperty.call(m, "caseSensitive"))
                $root.google.protobuf.BoolValue.encode(m.caseSensitive, w.uint32(74).fork()).ldelim();
            if (m.owners != null && m.owners.length) {
                for (var i = 0; i < m.owners.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.owners[i], w.uint32(82).fork()).ldelim();
            }
            if (m.creators != null && m.creators.length) {
                for (var i = 0; i < m.creators.length; ++i)
                    $root.google.protobuf.StringValue.encode(m.creators[i], w.uint32(90).fork()).ldelim();
            }
            if (m.isDupe != null && Object.hasOwnProperty.call(m, "isDupe"))
                $root.google.protobuf.BoolValue.encode(m.isDupe, w.uint32(98).fork()).ldelim();
            if (m.isNSFW != null && Object.hasOwnProperty.call(m, "isNSFW"))
                $root.google.protobuf.BoolValue.encode(m.isNSFW, w.uint32(106).fork()).ldelim();
            if (m.isAdult != null && Object.hasOwnProperty.call(m, "isAdult"))
                $root.google.protobuf.BoolValue.encode(m.isAdult, w.uint32(114).fork()).ldelim();
            if (m.minLikes != null && Object.hasOwnProperty.call(m, "minLikes"))
                $root.google.protobuf.Int32Value.encode(m.minLikes, w.uint32(122).fork()).ldelim();
            if (m.durationRange != null && Object.hasOwnProperty.call(m, "durationRange"))
                $root.globals.DurationRange.encode(m.durationRange, w.uint32(130).fork()).ldelim();
            return w;
        };

        UrlFacetFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        UrlFacetFilter.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.UrlFacetFilter();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (!(m.contentClasses && m.contentClasses.length))
                            m.contentClasses = [];
                        m.contentClasses.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 2: {
                        if (!(m.domains && m.domains.length))
                            m.domains = [];
                        m.domains.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 3: {
                        if (!(m.interests && m.interests.length))
                            m.interests = [];
                        m.interests.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 4: {
                        if (!(m.mediaTypes && m.mediaTypes.length))
                            m.mediaTypes = [];
                        m.mediaTypes.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 5: {
                        m.minQualityScore = $root.google.protobuf.FloatValue.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        if (!(m.providerStreams && m.providerStreams.length))
                            m.providerStreams = [];
                        m.providerStreams.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 7: {
                        if (!(m.providers && m.providers.length))
                            m.providers = [];
                        m.providers.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 8: {
                        m.block = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 9: {
                        m.caseSensitive = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 10: {
                        if (!(m.owners && m.owners.length))
                            m.owners = [];
                        m.owners.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 11: {
                        if (!(m.creators && m.creators.length))
                            m.creators = [];
                        m.creators.push($root.google.protobuf.StringValue.decode(r, r.uint32()));
                        break;
                    }
                case 12: {
                        m.isDupe = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 13: {
                        m.isNSFW = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 14: {
                        m.isAdult = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 15: {
                        m.minLikes = $root.google.protobuf.Int32Value.decode(r, r.uint32());
                        break;
                    }
                case 16: {
                        m.durationRange = $root.globals.DurationRange.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        UrlFacetFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        UrlFacetFilter.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.contentClasses != null && m.hasOwnProperty("contentClasses")) {
                if (!Array.isArray(m.contentClasses))
                    return "contentClasses: array expected";
                for (var i = 0; i < m.contentClasses.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.contentClasses[i]);
                        if (e)
                            return "contentClasses." + e;
                    }
                }
            }
            if (m.domains != null && m.hasOwnProperty("domains")) {
                if (!Array.isArray(m.domains))
                    return "domains: array expected";
                for (var i = 0; i < m.domains.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.domains[i]);
                        if (e)
                            return "domains." + e;
                    }
                }
            }
            if (m.interests != null && m.hasOwnProperty("interests")) {
                if (!Array.isArray(m.interests))
                    return "interests: array expected";
                for (var i = 0; i < m.interests.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.interests[i]);
                        if (e)
                            return "interests." + e;
                    }
                }
            }
            if (m.mediaTypes != null && m.hasOwnProperty("mediaTypes")) {
                if (!Array.isArray(m.mediaTypes))
                    return "mediaTypes: array expected";
                for (var i = 0; i < m.mediaTypes.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.mediaTypes[i]);
                        if (e)
                            return "mediaTypes." + e;
                    }
                }
            }
            if (m.minQualityScore != null && m.hasOwnProperty("minQualityScore")) {
                {
                    var e = $root.google.protobuf.FloatValue.verify(m.minQualityScore);
                    if (e)
                        return "minQualityScore." + e;
                }
            }
            if (m.providerStreams != null && m.hasOwnProperty("providerStreams")) {
                if (!Array.isArray(m.providerStreams))
                    return "providerStreams: array expected";
                for (var i = 0; i < m.providerStreams.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.providerStreams[i]);
                        if (e)
                            return "providerStreams." + e;
                    }
                }
            }
            if (m.providers != null && m.hasOwnProperty("providers")) {
                if (!Array.isArray(m.providers))
                    return "providers: array expected";
                for (var i = 0; i < m.providers.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.providers[i]);
                        if (e)
                            return "providers." + e;
                    }
                }
            }
            if (m.block != null && m.hasOwnProperty("block")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.block);
                    if (e)
                        return "block." + e;
                }
            }
            if (m.caseSensitive != null && m.hasOwnProperty("caseSensitive")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.caseSensitive);
                    if (e)
                        return "caseSensitive." + e;
                }
            }
            if (m.owners != null && m.hasOwnProperty("owners")) {
                if (!Array.isArray(m.owners))
                    return "owners: array expected";
                for (var i = 0; i < m.owners.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.owners[i]);
                        if (e)
                            return "owners." + e;
                    }
                }
            }
            if (m.creators != null && m.hasOwnProperty("creators")) {
                if (!Array.isArray(m.creators))
                    return "creators: array expected";
                for (var i = 0; i < m.creators.length; ++i) {
                    {
                        var e = $root.google.protobuf.StringValue.verify(m.creators[i]);
                        if (e)
                            return "creators." + e;
                    }
                }
            }
            if (m.isDupe != null && m.hasOwnProperty("isDupe")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.isDupe);
                    if (e)
                        return "isDupe." + e;
                }
            }
            if (m.isNSFW != null && m.hasOwnProperty("isNSFW")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.isNSFW);
                    if (e)
                        return "isNSFW." + e;
                }
            }
            if (m.isAdult != null && m.hasOwnProperty("isAdult")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.isAdult);
                    if (e)
                        return "isAdult." + e;
                }
            }
            if (m.minLikes != null && m.hasOwnProperty("minLikes")) {
                {
                    var e = $root.google.protobuf.Int32Value.verify(m.minLikes);
                    if (e)
                        return "minLikes." + e;
                }
            }
            if (m.durationRange != null && m.hasOwnProperty("durationRange")) {
                {
                    var e = $root.globals.DurationRange.verify(m.durationRange);
                    if (e)
                        return "durationRange." + e;
                }
            }
            return null;
        };

        UrlFacetFilter.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.UrlFacetFilter)
                return d;
            var m = new $root.globals.UrlFacetFilter();
            if (d.contentClasses) {
                if (!Array.isArray(d.contentClasses))
                    throw TypeError(".globals.UrlFacetFilter.contentClasses: array expected");
                m.contentClasses = [];
                for (var i = 0; i < d.contentClasses.length; ++i) {
                    if (typeof d.contentClasses[i] === "object" && d.contentClasses[i] !== null && d.contentClasses[i].value !== undefined)
                        m.contentClasses[i] = $root.google.protobuf.StringValue.fromObject(d.contentClasses[i]);
                    else
                        m.contentClasses[i] = $root.google.protobuf.StringValue.fromObject({ value: d.contentClasses[i] });
                }
            }
            if (d.domains) {
                if (!Array.isArray(d.domains))
                    throw TypeError(".globals.UrlFacetFilter.domains: array expected");
                m.domains = [];
                for (var i = 0; i < d.domains.length; ++i) {
                    if (typeof d.domains[i] === "object" && d.domains[i] !== null && d.domains[i].value !== undefined)
                        m.domains[i] = $root.google.protobuf.StringValue.fromObject(d.domains[i]);
                    else
                        m.domains[i] = $root.google.protobuf.StringValue.fromObject({ value: d.domains[i] });
                }
            }
            if (d.interests) {
                if (!Array.isArray(d.interests))
                    throw TypeError(".globals.UrlFacetFilter.interests: array expected");
                m.interests = [];
                for (var i = 0; i < d.interests.length; ++i) {
                    if (typeof d.interests[i] === "object" && d.interests[i] !== null && d.interests[i].value !== undefined)
                        m.interests[i] = $root.google.protobuf.StringValue.fromObject(d.interests[i]);
                    else
                        m.interests[i] = $root.google.protobuf.StringValue.fromObject({ value: d.interests[i] });
                }
            }
            if (d.mediaTypes) {
                if (!Array.isArray(d.mediaTypes))
                    throw TypeError(".globals.UrlFacetFilter.mediaTypes: array expected");
                m.mediaTypes = [];
                for (var i = 0; i < d.mediaTypes.length; ++i) {
                    if (typeof d.mediaTypes[i] === "object" && d.mediaTypes[i] !== null && d.mediaTypes[i].value !== undefined)
                        m.mediaTypes[i] = $root.google.protobuf.StringValue.fromObject(d.mediaTypes[i]);
                    else
                        m.mediaTypes[i] = $root.google.protobuf.StringValue.fromObject({ value: d.mediaTypes[i] });
                }
            }
            if (d.minQualityScore != null) {
                if (typeof d.minQualityScore === "object" && d.minQualityScore !== null && d.minQualityScore.value !== undefined)
                    m.minQualityScore = $root.google.protobuf.FloatValue.fromObject(d.minQualityScore);
                else
                    m.minQualityScore = $root.google.protobuf.FloatValue.fromObject({ value: d.minQualityScore });
            }
            if (d.providerStreams) {
                if (!Array.isArray(d.providerStreams))
                    throw TypeError(".globals.UrlFacetFilter.providerStreams: array expected");
                m.providerStreams = [];
                for (var i = 0; i < d.providerStreams.length; ++i) {
                    if (typeof d.providerStreams[i] === "object" && d.providerStreams[i] !== null && d.providerStreams[i].value !== undefined)
                        m.providerStreams[i] = $root.google.protobuf.StringValue.fromObject(d.providerStreams[i]);
                    else
                        m.providerStreams[i] = $root.google.protobuf.StringValue.fromObject({ value: d.providerStreams[i] });
                }
            }
            if (d.providers) {
                if (!Array.isArray(d.providers))
                    throw TypeError(".globals.UrlFacetFilter.providers: array expected");
                m.providers = [];
                for (var i = 0; i < d.providers.length; ++i) {
                    if (typeof d.providers[i] === "object" && d.providers[i] !== null && d.providers[i].value !== undefined)
                        m.providers[i] = $root.google.protobuf.StringValue.fromObject(d.providers[i]);
                    else
                        m.providers[i] = $root.google.protobuf.StringValue.fromObject({ value: d.providers[i] });
                }
            }
            if (d.block != null) {
                if (typeof d.block === "object" && d.block !== null && d.block.value !== undefined)
                    m.block = $root.google.protobuf.BoolValue.fromObject(d.block);
                else
                    m.block = $root.google.protobuf.BoolValue.fromObject({ value: d.block });
            }
            if (d.caseSensitive != null) {
                if (typeof d.caseSensitive === "object" && d.caseSensitive !== null && d.caseSensitive.value !== undefined)
                    m.caseSensitive = $root.google.protobuf.BoolValue.fromObject(d.caseSensitive);
                else
                    m.caseSensitive = $root.google.protobuf.BoolValue.fromObject({ value: d.caseSensitive });
            }
            if (d.owners) {
                if (!Array.isArray(d.owners))
                    throw TypeError(".globals.UrlFacetFilter.owners: array expected");
                m.owners = [];
                for (var i = 0; i < d.owners.length; ++i) {
                    if (typeof d.owners[i] === "object" && d.owners[i] !== null && d.owners[i].value !== undefined)
                        m.owners[i] = $root.google.protobuf.StringValue.fromObject(d.owners[i]);
                    else
                        m.owners[i] = $root.google.protobuf.StringValue.fromObject({ value: d.owners[i] });
                }
            }
            if (d.creators) {
                if (!Array.isArray(d.creators))
                    throw TypeError(".globals.UrlFacetFilter.creators: array expected");
                m.creators = [];
                for (var i = 0; i < d.creators.length; ++i) {
                    if (typeof d.creators[i] === "object" && d.creators[i] !== null && d.creators[i].value !== undefined)
                        m.creators[i] = $root.google.protobuf.StringValue.fromObject(d.creators[i]);
                    else
                        m.creators[i] = $root.google.protobuf.StringValue.fromObject({ value: d.creators[i] });
                }
            }
            if (d.isDupe != null) {
                if (typeof d.isDupe === "object" && d.isDupe !== null && d.isDupe.value !== undefined)
                    m.isDupe = $root.google.protobuf.BoolValue.fromObject(d.isDupe);
                else
                    m.isDupe = $root.google.protobuf.BoolValue.fromObject({ value: d.isDupe });
            }
            if (d.isNSFW != null) {
                if (typeof d.isNSFW === "object" && d.isNSFW !== null && d.isNSFW.value !== undefined)
                    m.isNSFW = $root.google.protobuf.BoolValue.fromObject(d.isNSFW);
                else
                    m.isNSFW = $root.google.protobuf.BoolValue.fromObject({ value: d.isNSFW });
            }
            if (d.isAdult != null) {
                if (typeof d.isAdult === "object" && d.isAdult !== null && d.isAdult.value !== undefined)
                    m.isAdult = $root.google.protobuf.BoolValue.fromObject(d.isAdult);
                else
                    m.isAdult = $root.google.protobuf.BoolValue.fromObject({ value: d.isAdult });
            }
            if (d.minLikes != null) {
                if (typeof d.minLikes === "object" && d.minLikes !== null && d.minLikes.value !== undefined)
                    m.minLikes = $root.google.protobuf.Int32Value.fromObject(d.minLikes);
                else
                    m.minLikes = $root.google.protobuf.Int32Value.fromObject({ value: d.minLikes });
            }
            if (d.durationRange != null) {
                if (typeof d.durationRange !== "object")
                    throw TypeError(".globals.UrlFacetFilter.durationRange: object expected");
                m.durationRange = $root.globals.DurationRange.fromObject(d.durationRange);
            }
            return m;
        };

        UrlFacetFilter.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.contentClasses = [];
                d.domains = [];
                d.interests = [];
                d.mediaTypes = [];
                d.providerStreams = [];
                d.providers = [];
                d.owners = [];
                d.creators = [];
            }
            if (o.defaults) {
                d.minQualityScore = null;
                d.block = null;
                d.caseSensitive = null;
                d.isDupe = null;
                d.isNSFW = null;
                d.isAdult = null;
                d.minLikes = null;
                d.durationRange = null;
            }
            if (m.contentClasses && m.contentClasses.length) {
                d.contentClasses = [];
                for (var j = 0; j < m.contentClasses.length; ++j) {
                    d.contentClasses[j] = $root.google.protobuf.StringValue.toObject(m.contentClasses[j], o).value;
                }
            }
            if (m.domains && m.domains.length) {
                d.domains = [];
                for (var j = 0; j < m.domains.length; ++j) {
                    d.domains[j] = $root.google.protobuf.StringValue.toObject(m.domains[j], o).value;
                }
            }
            if (m.interests && m.interests.length) {
                d.interests = [];
                for (var j = 0; j < m.interests.length; ++j) {
                    d.interests[j] = $root.google.protobuf.StringValue.toObject(m.interests[j], o).value;
                }
            }
            if (m.mediaTypes && m.mediaTypes.length) {
                d.mediaTypes = [];
                for (var j = 0; j < m.mediaTypes.length; ++j) {
                    d.mediaTypes[j] = $root.google.protobuf.StringValue.toObject(m.mediaTypes[j], o).value;
                }
            }
            if (m.minQualityScore != null && m.hasOwnProperty("minQualityScore")) {
                d.minQualityScore = $root.google.protobuf.FloatValue.toObject(m.minQualityScore, o).value;
            }
            if (m.providerStreams && m.providerStreams.length) {
                d.providerStreams = [];
                for (var j = 0; j < m.providerStreams.length; ++j) {
                    d.providerStreams[j] = $root.google.protobuf.StringValue.toObject(m.providerStreams[j], o).value;
                }
            }
            if (m.providers && m.providers.length) {
                d.providers = [];
                for (var j = 0; j < m.providers.length; ++j) {
                    d.providers[j] = $root.google.protobuf.StringValue.toObject(m.providers[j], o).value;
                }
            }
            if (m.block != null && m.hasOwnProperty("block")) {
                d.block = $root.google.protobuf.BoolValue.toObject(m.block, o).value;
            }
            if (m.caseSensitive != null && m.hasOwnProperty("caseSensitive")) {
                d.caseSensitive = $root.google.protobuf.BoolValue.toObject(m.caseSensitive, o).value;
            }
            if (m.owners && m.owners.length) {
                d.owners = [];
                for (var j = 0; j < m.owners.length; ++j) {
                    d.owners[j] = $root.google.protobuf.StringValue.toObject(m.owners[j], o).value;
                }
            }
            if (m.creators && m.creators.length) {
                d.creators = [];
                for (var j = 0; j < m.creators.length; ++j) {
                    d.creators[j] = $root.google.protobuf.StringValue.toObject(m.creators[j], o).value;
                }
            }
            if (m.isDupe != null && m.hasOwnProperty("isDupe")) {
                d.isDupe = $root.google.protobuf.BoolValue.toObject(m.isDupe, o).value;
            }
            if (m.isNSFW != null && m.hasOwnProperty("isNSFW")) {
                d.isNSFW = $root.google.protobuf.BoolValue.toObject(m.isNSFW, o).value;
            }
            if (m.isAdult != null && m.hasOwnProperty("isAdult")) {
                d.isAdult = $root.google.protobuf.BoolValue.toObject(m.isAdult, o).value;
            }
            if (m.minLikes != null && m.hasOwnProperty("minLikes")) {
                d.minLikes = $root.google.protobuf.Int32Value.toObject(m.minLikes, o).value;
            }
            if (m.durationRange != null && m.hasOwnProperty("durationRange")) {
                d.durationRange = $root.globals.DurationRange.toObject(m.durationRange, o);
            }
            return d;
        };

        UrlFacetFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UrlFacetFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.UrlFacetFilter";
        };

        return UrlFacetFilter;
    })();

    globals.UrlFacetRescorer = (function() {

        function UrlFacetRescorer(p) {
            this.domains = {};
            this.interests = {};
            this.providers = {};
            this.providerStreams = {};
            this.owners = {};
            this.creators = {};
            this.contentClasses = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        UrlFacetRescorer.prototype.filter = null;
        UrlFacetRescorer.prototype.domains = $util.emptyObject;
        UrlFacetRescorer.prototype.interests = $util.emptyObject;
        UrlFacetRescorer.prototype.providers = $util.emptyObject;
        UrlFacetRescorer.prototype.providerStreams = $util.emptyObject;
        UrlFacetRescorer.prototype.owners = $util.emptyObject;
        UrlFacetRescorer.prototype.creators = $util.emptyObject;
        UrlFacetRescorer.prototype.contentClasses = $util.emptyObject;

        UrlFacetRescorer.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.filter != null && Object.hasOwnProperty.call(m, "filter"))
                $root.globals.UrlFacetFilter.encode(m.filter, w.uint32(10).fork()).ldelim();
            if (m.domains != null && Object.hasOwnProperty.call(m, "domains")) {
                for (var ks = Object.keys(m.domains), i = 0; i < ks.length; ++i) {
                    w.uint32(18).fork().uint32(10).string(ks[i]).uint32(17).double(m.domains[ks[i]]).ldelim();
                }
            }
            if (m.interests != null && Object.hasOwnProperty.call(m, "interests")) {
                for (var ks = Object.keys(m.interests), i = 0; i < ks.length; ++i) {
                    w.uint32(26).fork().uint32(10).string(ks[i]).uint32(17).double(m.interests[ks[i]]).ldelim();
                }
            }
            if (m.providers != null && Object.hasOwnProperty.call(m, "providers")) {
                for (var ks = Object.keys(m.providers), i = 0; i < ks.length; ++i) {
                    w.uint32(34).fork().uint32(10).string(ks[i]).uint32(17).double(m.providers[ks[i]]).ldelim();
                }
            }
            if (m.providerStreams != null && Object.hasOwnProperty.call(m, "providerStreams")) {
                for (var ks = Object.keys(m.providerStreams), i = 0; i < ks.length; ++i) {
                    w.uint32(42).fork().uint32(10).string(ks[i]).uint32(17).double(m.providerStreams[ks[i]]).ldelim();
                }
            }
            if (m.owners != null && Object.hasOwnProperty.call(m, "owners")) {
                for (var ks = Object.keys(m.owners), i = 0; i < ks.length; ++i) {
                    w.uint32(50).fork().uint32(10).string(ks[i]).uint32(17).double(m.owners[ks[i]]).ldelim();
                }
            }
            if (m.creators != null && Object.hasOwnProperty.call(m, "creators")) {
                for (var ks = Object.keys(m.creators), i = 0; i < ks.length; ++i) {
                    w.uint32(58).fork().uint32(10).string(ks[i]).uint32(17).double(m.creators[ks[i]]).ldelim();
                }
            }
            if (m.contentClasses != null && Object.hasOwnProperty.call(m, "contentClasses")) {
                for (var ks = Object.keys(m.contentClasses), i = 0; i < ks.length; ++i) {
                    w.uint32(66).fork().uint32(10).string(ks[i]).uint32(17).double(m.contentClasses[ks[i]]).ldelim();
                }
            }
            return w;
        };

        UrlFacetRescorer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        UrlFacetRescorer.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.UrlFacetRescorer(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.filter = $root.globals.UrlFacetFilter.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        if (m.domains === $util.emptyObject)
                            m.domains = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.double();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.domains[k] = value;
                        break;
                    }
                case 3: {
                        if (m.interests === $util.emptyObject)
                            m.interests = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.double();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.interests[k] = value;
                        break;
                    }
                case 4: {
                        if (m.providers === $util.emptyObject)
                            m.providers = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.double();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.providers[k] = value;
                        break;
                    }
                case 5: {
                        if (m.providerStreams === $util.emptyObject)
                            m.providerStreams = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.double();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.providerStreams[k] = value;
                        break;
                    }
                case 6: {
                        if (m.owners === $util.emptyObject)
                            m.owners = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.double();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.owners[k] = value;
                        break;
                    }
                case 7: {
                        if (m.creators === $util.emptyObject)
                            m.creators = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.double();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.creators[k] = value;
                        break;
                    }
                case 8: {
                        if (m.contentClasses === $util.emptyObject)
                            m.contentClasses = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.double();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.contentClasses[k] = value;
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        UrlFacetRescorer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        UrlFacetRescorer.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.filter != null && m.hasOwnProperty("filter")) {
                {
                    var e = $root.globals.UrlFacetFilter.verify(m.filter);
                    if (e)
                        return "filter." + e;
                }
            }
            if (m.domains != null && m.hasOwnProperty("domains")) {
                if (!$util.isObject(m.domains))
                    return "domains: object expected";
                var k = Object.keys(m.domains);
                for (var i = 0; i < k.length; ++i) {
                    if (typeof m.domains[k[i]] !== "number")
                        return "domains: number{k:string} expected";
                }
            }
            if (m.interests != null && m.hasOwnProperty("interests")) {
                if (!$util.isObject(m.interests))
                    return "interests: object expected";
                var k = Object.keys(m.interests);
                for (var i = 0; i < k.length; ++i) {
                    if (typeof m.interests[k[i]] !== "number")
                        return "interests: number{k:string} expected";
                }
            }
            if (m.providers != null && m.hasOwnProperty("providers")) {
                if (!$util.isObject(m.providers))
                    return "providers: object expected";
                var k = Object.keys(m.providers);
                for (var i = 0; i < k.length; ++i) {
                    if (typeof m.providers[k[i]] !== "number")
                        return "providers: number{k:string} expected";
                }
            }
            if (m.providerStreams != null && m.hasOwnProperty("providerStreams")) {
                if (!$util.isObject(m.providerStreams))
                    return "providerStreams: object expected";
                var k = Object.keys(m.providerStreams);
                for (var i = 0; i < k.length; ++i) {
                    if (typeof m.providerStreams[k[i]] !== "number")
                        return "providerStreams: number{k:string} expected";
                }
            }
            if (m.owners != null && m.hasOwnProperty("owners")) {
                if (!$util.isObject(m.owners))
                    return "owners: object expected";
                var k = Object.keys(m.owners);
                for (var i = 0; i < k.length; ++i) {
                    if (typeof m.owners[k[i]] !== "number")
                        return "owners: number{k:string} expected";
                }
            }
            if (m.creators != null && m.hasOwnProperty("creators")) {
                if (!$util.isObject(m.creators))
                    return "creators: object expected";
                var k = Object.keys(m.creators);
                for (var i = 0; i < k.length; ++i) {
                    if (typeof m.creators[k[i]] !== "number")
                        return "creators: number{k:string} expected";
                }
            }
            if (m.contentClasses != null && m.hasOwnProperty("contentClasses")) {
                if (!$util.isObject(m.contentClasses))
                    return "contentClasses: object expected";
                var k = Object.keys(m.contentClasses);
                for (var i = 0; i < k.length; ++i) {
                    if (typeof m.contentClasses[k[i]] !== "number")
                        return "contentClasses: number{k:string} expected";
                }
            }
            return null;
        };

        UrlFacetRescorer.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.UrlFacetRescorer)
                return d;
            var m = new $root.globals.UrlFacetRescorer();
            if (d.filter != null) {
                if (typeof d.filter !== "object")
                    throw TypeError(".globals.UrlFacetRescorer.filter: object expected");
                m.filter = $root.globals.UrlFacetFilter.fromObject(d.filter);
            }
            if (d.domains) {
                if (typeof d.domains !== "object")
                    throw TypeError(".globals.UrlFacetRescorer.domains: object expected");
                m.domains = {};
                for (var ks = Object.keys(d.domains), i = 0; i < ks.length; ++i) {
                    m.domains[ks[i]] = Number(d.domains[ks[i]]);
                }
            }
            if (d.interests) {
                if (typeof d.interests !== "object")
                    throw TypeError(".globals.UrlFacetRescorer.interests: object expected");
                m.interests = {};
                for (var ks = Object.keys(d.interests), i = 0; i < ks.length; ++i) {
                    m.interests[ks[i]] = Number(d.interests[ks[i]]);
                }
            }
            if (d.providers) {
                if (typeof d.providers !== "object")
                    throw TypeError(".globals.UrlFacetRescorer.providers: object expected");
                m.providers = {};
                for (var ks = Object.keys(d.providers), i = 0; i < ks.length; ++i) {
                    m.providers[ks[i]] = Number(d.providers[ks[i]]);
                }
            }
            if (d.providerStreams) {
                if (typeof d.providerStreams !== "object")
                    throw TypeError(".globals.UrlFacetRescorer.providerStreams: object expected");
                m.providerStreams = {};
                for (var ks = Object.keys(d.providerStreams), i = 0; i < ks.length; ++i) {
                    m.providerStreams[ks[i]] = Number(d.providerStreams[ks[i]]);
                }
            }
            if (d.owners) {
                if (typeof d.owners !== "object")
                    throw TypeError(".globals.UrlFacetRescorer.owners: object expected");
                m.owners = {};
                for (var ks = Object.keys(d.owners), i = 0; i < ks.length; ++i) {
                    m.owners[ks[i]] = Number(d.owners[ks[i]]);
                }
            }
            if (d.creators) {
                if (typeof d.creators !== "object")
                    throw TypeError(".globals.UrlFacetRescorer.creators: object expected");
                m.creators = {};
                for (var ks = Object.keys(d.creators), i = 0; i < ks.length; ++i) {
                    m.creators[ks[i]] = Number(d.creators[ks[i]]);
                }
            }
            if (d.contentClasses) {
                if (typeof d.contentClasses !== "object")
                    throw TypeError(".globals.UrlFacetRescorer.contentClasses: object expected");
                m.contentClasses = {};
                for (var ks = Object.keys(d.contentClasses), i = 0; i < ks.length; ++i) {
                    m.contentClasses[ks[i]] = Number(d.contentClasses[ks[i]]);
                }
            }
            return m;
        };

        UrlFacetRescorer.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.objects || o.defaults) {
                d.domains = {};
                d.interests = {};
                d.providers = {};
                d.providerStreams = {};
                d.owners = {};
                d.creators = {};
                d.contentClasses = {};
            }
            if (o.defaults) {
                d.filter = null;
            }
            if (m.filter != null && m.hasOwnProperty("filter")) {
                d.filter = $root.globals.UrlFacetFilter.toObject(m.filter, o);
            }
            var ks2;
            if (m.domains && (ks2 = Object.keys(m.domains)).length) {
                d.domains = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.domains[ks2[j]] = o.json && !isFinite(m.domains[ks2[j]]) ? String(m.domains[ks2[j]]) : m.domains[ks2[j]];
                }
            }
            if (m.interests && (ks2 = Object.keys(m.interests)).length) {
                d.interests = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.interests[ks2[j]] = o.json && !isFinite(m.interests[ks2[j]]) ? String(m.interests[ks2[j]]) : m.interests[ks2[j]];
                }
            }
            if (m.providers && (ks2 = Object.keys(m.providers)).length) {
                d.providers = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.providers[ks2[j]] = o.json && !isFinite(m.providers[ks2[j]]) ? String(m.providers[ks2[j]]) : m.providers[ks2[j]];
                }
            }
            if (m.providerStreams && (ks2 = Object.keys(m.providerStreams)).length) {
                d.providerStreams = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.providerStreams[ks2[j]] = o.json && !isFinite(m.providerStreams[ks2[j]]) ? String(m.providerStreams[ks2[j]]) : m.providerStreams[ks2[j]];
                }
            }
            if (m.owners && (ks2 = Object.keys(m.owners)).length) {
                d.owners = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.owners[ks2[j]] = o.json && !isFinite(m.owners[ks2[j]]) ? String(m.owners[ks2[j]]) : m.owners[ks2[j]];
                }
            }
            if (m.creators && (ks2 = Object.keys(m.creators)).length) {
                d.creators = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.creators[ks2[j]] = o.json && !isFinite(m.creators[ks2[j]]) ? String(m.creators[ks2[j]]) : m.creators[ks2[j]];
                }
            }
            if (m.contentClasses && (ks2 = Object.keys(m.contentClasses)).length) {
                d.contentClasses = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.contentClasses[ks2[j]] = o.json && !isFinite(m.contentClasses[ks2[j]]) ? String(m.contentClasses[ks2[j]]) : m.contentClasses[ks2[j]];
                }
            }
            return d;
        };

        UrlFacetRescorer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UrlFacetRescorer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.UrlFacetRescorer";
        };

        return UrlFacetRescorer;
    })();

    globals.LLMRequest = (function() {

        function LLMRequest(p) {
            this.prompt = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        LLMRequest.prototype.service = null;
        LLMRequest.prototype.model = null;
        LLMRequest.prototype.prompt = $util.emptyArray;
        LLMRequest.prototype.maxTokens = null;
        LLMRequest.prototype.requestTtlMsecs = null;

        LLMRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.service != null && Object.hasOwnProperty.call(m, "service"))
                $root.google.protobuf.StringValue.encode(m.service, w.uint32(10).fork()).ldelim();
            if (m.model != null && Object.hasOwnProperty.call(m, "model"))
                $root.google.protobuf.StringValue.encode(m.model, w.uint32(18).fork()).ldelim();
            if (m.prompt != null && m.prompt.length) {
                for (var i = 0; i < m.prompt.length; ++i)
                    $root.globals.LLMPrompt.encode(m.prompt[i], w.uint32(26).fork()).ldelim();
            }
            if (m.maxTokens != null && Object.hasOwnProperty.call(m, "maxTokens"))
                $root.google.protobuf.UInt32Value.encode(m.maxTokens, w.uint32(34).fork()).ldelim();
            if (m.requestTtlMsecs != null && Object.hasOwnProperty.call(m, "requestTtlMsecs"))
                $root.google.protobuf.UInt32Value.encode(m.requestTtlMsecs, w.uint32(42).fork()).ldelim();
            return w;
        };

        LLMRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        LLMRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.LLMRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.service = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.model = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        if (!(m.prompt && m.prompt.length))
                            m.prompt = [];
                        m.prompt.push($root.globals.LLMPrompt.decode(r, r.uint32()));
                        break;
                    }
                case 4: {
                        m.maxTokens = $root.google.protobuf.UInt32Value.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        m.requestTtlMsecs = $root.google.protobuf.UInt32Value.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        LLMRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        LLMRequest.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.service != null && m.hasOwnProperty("service")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.service);
                    if (e)
                        return "service." + e;
                }
            }
            if (m.model != null && m.hasOwnProperty("model")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.model);
                    if (e)
                        return "model." + e;
                }
            }
            if (m.prompt != null && m.hasOwnProperty("prompt")) {
                if (!Array.isArray(m.prompt))
                    return "prompt: array expected";
                for (var i = 0; i < m.prompt.length; ++i) {
                    {
                        var e = $root.globals.LLMPrompt.verify(m.prompt[i]);
                        if (e)
                            return "prompt." + e;
                    }
                }
            }
            if (m.maxTokens != null && m.hasOwnProperty("maxTokens")) {
                {
                    var e = $root.google.protobuf.UInt32Value.verify(m.maxTokens);
                    if (e)
                        return "maxTokens." + e;
                }
            }
            if (m.requestTtlMsecs != null && m.hasOwnProperty("requestTtlMsecs")) {
                {
                    var e = $root.google.protobuf.UInt32Value.verify(m.requestTtlMsecs);
                    if (e)
                        return "requestTtlMsecs." + e;
                }
            }
            return null;
        };

        LLMRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.LLMRequest)
                return d;
            var m = new $root.globals.LLMRequest();
            if (d.service != null) {
                if (typeof d.service === "object" && d.service !== null && d.service.value !== undefined)
                    m.service = $root.google.protobuf.StringValue.fromObject(d.service);
                else
                    m.service = $root.google.protobuf.StringValue.fromObject({ value: d.service });
            }
            if (d.model != null) {
                if (typeof d.model === "object" && d.model !== null && d.model.value !== undefined)
                    m.model = $root.google.protobuf.StringValue.fromObject(d.model);
                else
                    m.model = $root.google.protobuf.StringValue.fromObject({ value: d.model });
            }
            if (d.prompt) {
                if (!Array.isArray(d.prompt))
                    throw TypeError(".globals.LLMRequest.prompt: array expected");
                m.prompt = [];
                for (var i = 0; i < d.prompt.length; ++i) {
                    if (typeof d.prompt[i] !== "object")
                        throw TypeError(".globals.LLMRequest.prompt: object expected");
                    m.prompt[i] = $root.globals.LLMPrompt.fromObject(d.prompt[i]);
                }
            }
            if (d.maxTokens != null) {
                if (typeof d.maxTokens === "object" && d.maxTokens !== null && d.maxTokens.value !== undefined)
                    m.maxTokens = $root.google.protobuf.UInt32Value.fromObject(d.maxTokens);
                else
                    m.maxTokens = $root.google.protobuf.UInt32Value.fromObject({ value: d.maxTokens });
            }
            if (d.requestTtlMsecs != null) {
                if (typeof d.requestTtlMsecs === "object" && d.requestTtlMsecs !== null && d.requestTtlMsecs.value !== undefined)
                    m.requestTtlMsecs = $root.google.protobuf.UInt32Value.fromObject(d.requestTtlMsecs);
                else
                    m.requestTtlMsecs = $root.google.protobuf.UInt32Value.fromObject({ value: d.requestTtlMsecs });
            }
            return m;
        };

        LLMRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.prompt = [];
            }
            if (o.defaults) {
                d.service = null;
                d.model = null;
                d.maxTokens = null;
                d.requestTtlMsecs = null;
            }
            if (m.service != null && m.hasOwnProperty("service")) {
                d.service = $root.google.protobuf.StringValue.toObject(m.service, o).value;
            }
            if (m.model != null && m.hasOwnProperty("model")) {
                d.model = $root.google.protobuf.StringValue.toObject(m.model, o).value;
            }
            if (m.prompt && m.prompt.length) {
                d.prompt = [];
                for (var j = 0; j < m.prompt.length; ++j) {
                    d.prompt[j] = $root.globals.LLMPrompt.toObject(m.prompt[j], o);
                }
            }
            if (m.maxTokens != null && m.hasOwnProperty("maxTokens")) {
                d.maxTokens = $root.google.protobuf.UInt32Value.toObject(m.maxTokens, o).value;
            }
            if (m.requestTtlMsecs != null && m.hasOwnProperty("requestTtlMsecs")) {
                d.requestTtlMsecs = $root.google.protobuf.UInt32Value.toObject(m.requestTtlMsecs, o).value;
            }
            return d;
        };

        LLMRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LLMRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.LLMRequest";
        };

        return LLMRequest;
    })();

    globals.LLMPrompt = (function() {

        function LLMPrompt(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        LLMPrompt.prototype.type = 0;
        LLMPrompt.prototype.body = null;
        LLMPrompt.prototype.mediaType = null;
        LLMPrompt.prototype.intent = null;

        LLMPrompt.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.body != null && Object.hasOwnProperty.call(m, "body"))
                $root.google.protobuf.StringValue.encode(m.body, w.uint32(18).fork()).ldelim();
            if (m.mediaType != null && Object.hasOwnProperty.call(m, "mediaType"))
                $root.google.protobuf.StringValue.encode(m.mediaType, w.uint32(26).fork()).ldelim();
            if (m.intent != null && Object.hasOwnProperty.call(m, "intent"))
                $root.google.protobuf.StringValue.encode(m.intent, w.uint32(34).fork()).ldelim();
            return w;
        };

        LLMPrompt.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        LLMPrompt.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.LLMPrompt();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.type = r.int32();
                        break;
                    }
                case 2: {
                        m.body = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.mediaType = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.intent = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        LLMPrompt.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        LLMPrompt.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.type != null && m.hasOwnProperty("type")) {
                switch (m.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (m.body != null && m.hasOwnProperty("body")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.body);
                    if (e)
                        return "body." + e;
                }
            }
            if (m.mediaType != null && m.hasOwnProperty("mediaType")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.mediaType);
                    if (e)
                        return "mediaType." + e;
                }
            }
            if (m.intent != null && m.hasOwnProperty("intent")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.intent);
                    if (e)
                        return "intent." + e;
                }
            }
            return null;
        };

        LLMPrompt.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.LLMPrompt)
                return d;
            var m = new $root.globals.LLMPrompt();
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "UNKNOWN_LLM_PROMPT_TYPE":
            case 0:
                m.type = 0;
                break;
            case "LLM_PROMPT_TYPE_TEXT":
            case 1:
                m.type = 1;
                break;
            case "LLM_PROMPT_TYPE_IMAGE":
            case 2:
                m.type = 2;
                break;
            case "LLM_PROMPT_TYPE_IMAGE_URL":
            case 3:
                m.type = 3;
                break;
            }
            if (d.body != null) {
                if (typeof d.body === "object" && d.body !== null && d.body.value !== undefined)
                    m.body = $root.google.protobuf.StringValue.fromObject(d.body);
                else
                    m.body = $root.google.protobuf.StringValue.fromObject({ value: d.body });
            }
            if (d.mediaType != null) {
                if (typeof d.mediaType === "object" && d.mediaType !== null && d.mediaType.value !== undefined)
                    m.mediaType = $root.google.protobuf.StringValue.fromObject(d.mediaType);
                else
                    m.mediaType = $root.google.protobuf.StringValue.fromObject({ value: d.mediaType });
            }
            if (d.intent != null) {
                if (typeof d.intent === "object" && d.intent !== null && d.intent.value !== undefined)
                    m.intent = $root.google.protobuf.StringValue.fromObject(d.intent);
                else
                    m.intent = $root.google.protobuf.StringValue.fromObject({ value: d.intent });
            }
            return m;
        };

        LLMPrompt.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = o.enums === String ? "UNKNOWN_LLM_PROMPT_TYPE" : 0;
                d.body = null;
                d.mediaType = null;
                d.intent = null;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.globals.LLMPrompt.LLMPromptType[m.type] === undefined ? m.type : $root.globals.LLMPrompt.LLMPromptType[m.type] : m.type;
            }
            if (m.body != null && m.hasOwnProperty("body")) {
                d.body = $root.google.protobuf.StringValue.toObject(m.body, o).value;
            }
            if (m.mediaType != null && m.hasOwnProperty("mediaType")) {
                d.mediaType = $root.google.protobuf.StringValue.toObject(m.mediaType, o).value;
            }
            if (m.intent != null && m.hasOwnProperty("intent")) {
                d.intent = $root.google.protobuf.StringValue.toObject(m.intent, o).value;
            }
            return d;
        };

        LLMPrompt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LLMPrompt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.LLMPrompt";
        };

        LLMPrompt.LLMPromptType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_LLM_PROMPT_TYPE"] = 0;
            values[valuesById[1] = "LLM_PROMPT_TYPE_TEXT"] = 1;
            values[valuesById[2] = "LLM_PROMPT_TYPE_IMAGE"] = 2;
            values[valuesById[3] = "LLM_PROMPT_TYPE_IMAGE_URL"] = 3;
            return values;
        })();

        return LLMPrompt;
    })();

    globals.LLMQueryCost = (function() {

        function LLMQueryCost(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        LLMQueryCost.prototype.input = null;
        LLMQueryCost.prototype.output = null;
        LLMQueryCost.prototype.total = null;

        LLMQueryCost.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.input != null && Object.hasOwnProperty.call(m, "input"))
                $root.google.protobuf.UInt32Value.encode(m.input, w.uint32(10).fork()).ldelim();
            if (m.output != null && Object.hasOwnProperty.call(m, "output"))
                $root.google.protobuf.UInt32Value.encode(m.output, w.uint32(18).fork()).ldelim();
            if (m.total != null && Object.hasOwnProperty.call(m, "total"))
                $root.google.protobuf.UInt32Value.encode(m.total, w.uint32(26).fork()).ldelim();
            return w;
        };

        LLMQueryCost.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        LLMQueryCost.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.LLMQueryCost();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.input = $root.google.protobuf.UInt32Value.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.output = $root.google.protobuf.UInt32Value.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.total = $root.google.protobuf.UInt32Value.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        LLMQueryCost.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        LLMQueryCost.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.input != null && m.hasOwnProperty("input")) {
                {
                    var e = $root.google.protobuf.UInt32Value.verify(m.input);
                    if (e)
                        return "input." + e;
                }
            }
            if (m.output != null && m.hasOwnProperty("output")) {
                {
                    var e = $root.google.protobuf.UInt32Value.verify(m.output);
                    if (e)
                        return "output." + e;
                }
            }
            if (m.total != null && m.hasOwnProperty("total")) {
                {
                    var e = $root.google.protobuf.UInt32Value.verify(m.total);
                    if (e)
                        return "total." + e;
                }
            }
            return null;
        };

        LLMQueryCost.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.LLMQueryCost)
                return d;
            var m = new $root.globals.LLMQueryCost();
            if (d.input != null) {
                if (typeof d.input === "object" && d.input !== null && d.input.value !== undefined)
                    m.input = $root.google.protobuf.UInt32Value.fromObject(d.input);
                else
                    m.input = $root.google.protobuf.UInt32Value.fromObject({ value: d.input });
            }
            if (d.output != null) {
                if (typeof d.output === "object" && d.output !== null && d.output.value !== undefined)
                    m.output = $root.google.protobuf.UInt32Value.fromObject(d.output);
                else
                    m.output = $root.google.protobuf.UInt32Value.fromObject({ value: d.output });
            }
            if (d.total != null) {
                if (typeof d.total === "object" && d.total !== null && d.total.value !== undefined)
                    m.total = $root.google.protobuf.UInt32Value.fromObject(d.total);
                else
                    m.total = $root.google.protobuf.UInt32Value.fromObject({ value: d.total });
            }
            return m;
        };

        LLMQueryCost.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.input = null;
                d.output = null;
                d.total = null;
            }
            if (m.input != null && m.hasOwnProperty("input")) {
                d.input = $root.google.protobuf.UInt32Value.toObject(m.input, o).value;
            }
            if (m.output != null && m.hasOwnProperty("output")) {
                d.output = $root.google.protobuf.UInt32Value.toObject(m.output, o).value;
            }
            if (m.total != null && m.hasOwnProperty("total")) {
                d.total = $root.google.protobuf.UInt32Value.toObject(m.total, o).value;
            }
            return d;
        };

        LLMQueryCost.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LLMQueryCost.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.LLMQueryCost";
        };

        return LLMQueryCost;
    })();

    globals.LLMMetadata = (function() {

        function LLMMetadata(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        LLMMetadata.prototype.service = null;
        LLMMetadata.prototype.id = null;
        LLMMetadata.prototype.model = null;
        LLMMetadata.prototype.fingerprint = null;

        LLMMetadata.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.service != null && Object.hasOwnProperty.call(m, "service"))
                $root.google.protobuf.StringValue.encode(m.service, w.uint32(10).fork()).ldelim();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                $root.google.protobuf.StringValue.encode(m.id, w.uint32(18).fork()).ldelim();
            if (m.model != null && Object.hasOwnProperty.call(m, "model"))
                $root.google.protobuf.StringValue.encode(m.model, w.uint32(26).fork()).ldelim();
            if (m.fingerprint != null && Object.hasOwnProperty.call(m, "fingerprint"))
                $root.google.protobuf.StringValue.encode(m.fingerprint, w.uint32(34).fork()).ldelim();
            return w;
        };

        LLMMetadata.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        LLMMetadata.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.LLMMetadata();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.service = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.id = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.model = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.fingerprint = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        LLMMetadata.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        LLMMetadata.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.service != null && m.hasOwnProperty("service")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.service);
                    if (e)
                        return "service." + e;
                }
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.id);
                    if (e)
                        return "id." + e;
                }
            }
            if (m.model != null && m.hasOwnProperty("model")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.model);
                    if (e)
                        return "model." + e;
                }
            }
            if (m.fingerprint != null && m.hasOwnProperty("fingerprint")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.fingerprint);
                    if (e)
                        return "fingerprint." + e;
                }
            }
            return null;
        };

        LLMMetadata.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.LLMMetadata)
                return d;
            var m = new $root.globals.LLMMetadata();
            if (d.service != null) {
                if (typeof d.service === "object" && d.service !== null && d.service.value !== undefined)
                    m.service = $root.google.protobuf.StringValue.fromObject(d.service);
                else
                    m.service = $root.google.protobuf.StringValue.fromObject({ value: d.service });
            }
            if (d.id != null) {
                if (typeof d.id === "object" && d.id !== null && d.id.value !== undefined)
                    m.id = $root.google.protobuf.StringValue.fromObject(d.id);
                else
                    m.id = $root.google.protobuf.StringValue.fromObject({ value: d.id });
            }
            if (d.model != null) {
                if (typeof d.model === "object" && d.model !== null && d.model.value !== undefined)
                    m.model = $root.google.protobuf.StringValue.fromObject(d.model);
                else
                    m.model = $root.google.protobuf.StringValue.fromObject({ value: d.model });
            }
            if (d.fingerprint != null) {
                if (typeof d.fingerprint === "object" && d.fingerprint !== null && d.fingerprint.value !== undefined)
                    m.fingerprint = $root.google.protobuf.StringValue.fromObject(d.fingerprint);
                else
                    m.fingerprint = $root.google.protobuf.StringValue.fromObject({ value: d.fingerprint });
            }
            return m;
        };

        LLMMetadata.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.service = null;
                d.id = null;
                d.model = null;
                d.fingerprint = null;
            }
            if (m.service != null && m.hasOwnProperty("service")) {
                d.service = $root.google.protobuf.StringValue.toObject(m.service, o).value;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = $root.google.protobuf.StringValue.toObject(m.id, o).value;
            }
            if (m.model != null && m.hasOwnProperty("model")) {
                d.model = $root.google.protobuf.StringValue.toObject(m.model, o).value;
            }
            if (m.fingerprint != null && m.hasOwnProperty("fingerprint")) {
                d.fingerprint = $root.google.protobuf.StringValue.toObject(m.fingerprint, o).value;
            }
            return d;
        };

        LLMMetadata.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LLMMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.LLMMetadata";
        };

        return LLMMetadata;
    })();

    globals.LLMResponse = (function() {

        function LLMResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        LLMResponse.prototype.answer = null;
        LLMResponse.prototype.cost = null;
        LLMResponse.prototype.meta = null;

        LLMResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.answer != null && Object.hasOwnProperty.call(m, "answer"))
                $root.google.protobuf.StringValue.encode(m.answer, w.uint32(10).fork()).ldelim();
            if (m.cost != null && Object.hasOwnProperty.call(m, "cost"))
                $root.globals.LLMQueryCost.encode(m.cost, w.uint32(18).fork()).ldelim();
            if (m.meta != null && Object.hasOwnProperty.call(m, "meta"))
                $root.globals.LLMMetadata.encode(m.meta, w.uint32(26).fork()).ldelim();
            return w;
        };

        LLMResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        LLMResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.LLMResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.answer = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.cost = $root.globals.LLMQueryCost.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.meta = $root.globals.LLMMetadata.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        LLMResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        LLMResponse.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.answer != null && m.hasOwnProperty("answer")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.answer);
                    if (e)
                        return "answer." + e;
                }
            }
            if (m.cost != null && m.hasOwnProperty("cost")) {
                {
                    var e = $root.globals.LLMQueryCost.verify(m.cost);
                    if (e)
                        return "cost." + e;
                }
            }
            if (m.meta != null && m.hasOwnProperty("meta")) {
                {
                    var e = $root.globals.LLMMetadata.verify(m.meta);
                    if (e)
                        return "meta." + e;
                }
            }
            return null;
        };

        LLMResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.LLMResponse)
                return d;
            var m = new $root.globals.LLMResponse();
            if (d.answer != null) {
                if (typeof d.answer === "object" && d.answer !== null && d.answer.value !== undefined)
                    m.answer = $root.google.protobuf.StringValue.fromObject(d.answer);
                else
                    m.answer = $root.google.protobuf.StringValue.fromObject({ value: d.answer });
            }
            if (d.cost != null) {
                if (typeof d.cost !== "object")
                    throw TypeError(".globals.LLMResponse.cost: object expected");
                m.cost = $root.globals.LLMQueryCost.fromObject(d.cost);
            }
            if (d.meta != null) {
                if (typeof d.meta !== "object")
                    throw TypeError(".globals.LLMResponse.meta: object expected");
                m.meta = $root.globals.LLMMetadata.fromObject(d.meta);
            }
            return m;
        };

        LLMResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.answer = null;
                d.cost = null;
                d.meta = null;
            }
            if (m.answer != null && m.hasOwnProperty("answer")) {
                d.answer = $root.google.protobuf.StringValue.toObject(m.answer, o).value;
            }
            if (m.cost != null && m.hasOwnProperty("cost")) {
                d.cost = $root.globals.LLMQueryCost.toObject(m.cost, o);
            }
            if (m.meta != null && m.hasOwnProperty("meta")) {
                d.meta = $root.globals.LLMMetadata.toObject(m.meta, o);
            }
            return d;
        };

        LLMResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LLMResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.LLMResponse";
        };

        return LLMResponse;
    })();

    globals.PageContext = (function() {

        function PageContext(p) {
            this.attributes = {};
            this.testGroups = [];
            this.experiments = [];
            this.rollouts = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        PageContext.prototype.page = 0;
        PageContext.prototype.attributes = $util.emptyObject;
        PageContext.prototype.streamType = 0;
        PageContext.prototype.userId = null;
        PageContext.prototype.mixId = null;
        PageContext.prototype.categoryId = null;
        PageContext.prototype.categoryTopicId = null;
        PageContext.prototype.topicId = null;
        PageContext.prototype.shareId = null;
        PageContext.prototype.testGroups = $util.emptyArray;
        PageContext.prototype.pageSection = null;
        PageContext.prototype.categorySlugKey = null;
        PageContext.prototype.topicKey = null;
        PageContext.prototype.recId = null;
        PageContext.prototype.recBatchId = null;
        PageContext.prototype.searchQuery = null;
        PageContext.prototype.searchResultRank = null;
        PageContext.prototype.externalReferrer = null;
        PageContext.prototype.experiments = $util.emptyArray;
        PageContext.prototype.rollouts = $util.emptyArray;
        PageContext.prototype.urlId = null;
        PageContext.prototype.slug = null;
        PageContext.prototype.node = null;
        PageContext.prototype.streamUrlId = null;

        PageContext.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.page != null && Object.hasOwnProperty.call(m, "page"))
                w.uint32(32).int32(m.page);
            if (m.attributes != null && Object.hasOwnProperty.call(m, "attributes")) {
                for (var ks = Object.keys(m.attributes), i = 0; i < ks.length; ++i) {
                    w.uint32(42).fork().uint32(10).string(ks[i]).uint32(18).string(m.attributes[ks[i]]).ldelim();
                }
            }
            if (m.streamType != null && Object.hasOwnProperty.call(m, "streamType"))
                w.uint32(48).int32(m.streamType);
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                $root.google.protobuf.UInt64Value.encode(m.userId, w.uint32(58).fork()).ldelim();
            if (m.mixId != null && Object.hasOwnProperty.call(m, "mixId"))
                $root.google.protobuf.UInt64Value.encode(m.mixId, w.uint32(66).fork()).ldelim();
            if (m.categoryId != null && Object.hasOwnProperty.call(m, "categoryId"))
                $root.google.protobuf.UInt64Value.encode(m.categoryId, w.uint32(74).fork()).ldelim();
            if (m.categoryTopicId != null && Object.hasOwnProperty.call(m, "categoryTopicId"))
                $root.google.protobuf.UInt64Value.encode(m.categoryTopicId, w.uint32(82).fork()).ldelim();
            if (m.topicId != null && Object.hasOwnProperty.call(m, "topicId"))
                $root.google.protobuf.UInt64Value.encode(m.topicId, w.uint32(90).fork()).ldelim();
            if (m.shareId != null && Object.hasOwnProperty.call(m, "shareId"))
                $root.google.protobuf.UInt64Value.encode(m.shareId, w.uint32(98).fork()).ldelim();
            if (m.testGroups != null && m.testGroups.length) {
                for (var i = 0; i < m.testGroups.length; ++i)
                    w.uint32(106).string(m.testGroups[i]);
            }
            if (m.pageSection != null && Object.hasOwnProperty.call(m, "pageSection"))
                $root.google.protobuf.StringValue.encode(m.pageSection, w.uint32(114).fork()).ldelim();
            if (m.categorySlugKey != null && Object.hasOwnProperty.call(m, "categorySlugKey"))
                $root.google.protobuf.StringValue.encode(m.categorySlugKey, w.uint32(122).fork()).ldelim();
            if (m.topicKey != null && Object.hasOwnProperty.call(m, "topicKey"))
                $root.google.protobuf.StringValue.encode(m.topicKey, w.uint32(130).fork()).ldelim();
            if (m.recId != null && Object.hasOwnProperty.call(m, "recId"))
                $root.google.protobuf.StringValue.encode(m.recId, w.uint32(138).fork()).ldelim();
            if (m.recBatchId != null && Object.hasOwnProperty.call(m, "recBatchId"))
                $root.google.protobuf.StringValue.encode(m.recBatchId, w.uint32(146).fork()).ldelim();
            if (m.searchQuery != null && Object.hasOwnProperty.call(m, "searchQuery"))
                $root.google.protobuf.StringValue.encode(m.searchQuery, w.uint32(154).fork()).ldelim();
            if (m.searchResultRank != null && Object.hasOwnProperty.call(m, "searchResultRank"))
                $root.google.protobuf.UInt64Value.encode(m.searchResultRank, w.uint32(162).fork()).ldelim();
            if (m.externalReferrer != null && Object.hasOwnProperty.call(m, "externalReferrer"))
                $root.google.protobuf.StringValue.encode(m.externalReferrer, w.uint32(170).fork()).ldelim();
            if (m.experiments != null && m.experiments.length) {
                for (var i = 0; i < m.experiments.length; ++i)
                    $root.globals.Experiment.encode(m.experiments[i], w.uint32(178).fork()).ldelim();
            }
            if (m.rollouts != null && m.rollouts.length) {
                for (var i = 0; i < m.rollouts.length; ++i)
                    w.uint32(186).string(m.rollouts[i]);
            }
            if (m.urlId != null && Object.hasOwnProperty.call(m, "urlId"))
                $root.google.protobuf.UInt64Value.encode(m.urlId, w.uint32(194).fork()).ldelim();
            if (m.slug != null && Object.hasOwnProperty.call(m, "slug"))
                $root.google.protobuf.StringValue.encode(m.slug, w.uint32(202).fork()).ldelim();
            if (m.node != null && Object.hasOwnProperty.call(m, "node"))
                $root.google.protobuf.StringValue.encode(m.node, w.uint32(210).fork()).ldelim();
            if (m.streamUrlId != null && Object.hasOwnProperty.call(m, "streamUrlId"))
                $root.google.protobuf.UInt64Value.encode(m.streamUrlId, w.uint32(218).fork()).ldelim();
            return w;
        };

        PageContext.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        PageContext.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.PageContext(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 4: {
                        m.page = r.int32();
                        break;
                    }
                case 5: {
                        if (m.attributes === $util.emptyObject)
                            m.attributes = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.attributes[k] = value;
                        break;
                    }
                case 6: {
                        m.streamType = r.int32();
                        break;
                    }
                case 7: {
                        m.userId = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        m.mixId = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 9: {
                        m.categoryId = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 10: {
                        m.categoryTopicId = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 11: {
                        m.topicId = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 12: {
                        m.shareId = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 13: {
                        if (!(m.testGroups && m.testGroups.length))
                            m.testGroups = [];
                        m.testGroups.push(r.string());
                        break;
                    }
                case 14: {
                        m.pageSection = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 15: {
                        m.categorySlugKey = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 16: {
                        m.topicKey = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 17: {
                        m.recId = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 18: {
                        m.recBatchId = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 19: {
                        m.searchQuery = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 20: {
                        m.searchResultRank = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 21: {
                        m.externalReferrer = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 22: {
                        if (!(m.experiments && m.experiments.length))
                            m.experiments = [];
                        m.experiments.push($root.globals.Experiment.decode(r, r.uint32()));
                        break;
                    }
                case 23: {
                        if (!(m.rollouts && m.rollouts.length))
                            m.rollouts = [];
                        m.rollouts.push(r.string());
                        break;
                    }
                case 24: {
                        m.urlId = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 25: {
                        m.slug = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 26: {
                        m.node = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 27: {
                        m.streamUrlId = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        PageContext.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        PageContext.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.page != null && m.hasOwnProperty("page")) {
                switch (m.page) {
                default:
                    return "page: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                    break;
                }
            }
            if (m.attributes != null && m.hasOwnProperty("attributes")) {
                if (!$util.isObject(m.attributes))
                    return "attributes: object expected";
                var k = Object.keys(m.attributes);
                for (var i = 0; i < k.length; ++i) {
                    if (!$util.isString(m.attributes[k[i]]))
                        return "attributes: string{k:string} expected";
                }
            }
            if (m.streamType != null && m.hasOwnProperty("streamType")) {
                switch (m.streamType) {
                default:
                    return "streamType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                    break;
                }
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.userId);
                    if (e)
                        return "userId." + e;
                }
            }
            if (m.mixId != null && m.hasOwnProperty("mixId")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.mixId);
                    if (e)
                        return "mixId." + e;
                }
            }
            if (m.categoryId != null && m.hasOwnProperty("categoryId")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.categoryId);
                    if (e)
                        return "categoryId." + e;
                }
            }
            if (m.categoryTopicId != null && m.hasOwnProperty("categoryTopicId")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.categoryTopicId);
                    if (e)
                        return "categoryTopicId." + e;
                }
            }
            if (m.topicId != null && m.hasOwnProperty("topicId")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.topicId);
                    if (e)
                        return "topicId." + e;
                }
            }
            if (m.shareId != null && m.hasOwnProperty("shareId")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.shareId);
                    if (e)
                        return "shareId." + e;
                }
            }
            if (m.testGroups != null && m.hasOwnProperty("testGroups")) {
                if (!Array.isArray(m.testGroups))
                    return "testGroups: array expected";
                for (var i = 0; i < m.testGroups.length; ++i) {
                    if (!$util.isString(m.testGroups[i]))
                        return "testGroups: string[] expected";
                }
            }
            if (m.pageSection != null && m.hasOwnProperty("pageSection")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.pageSection);
                    if (e)
                        return "pageSection." + e;
                }
            }
            if (m.categorySlugKey != null && m.hasOwnProperty("categorySlugKey")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.categorySlugKey);
                    if (e)
                        return "categorySlugKey." + e;
                }
            }
            if (m.topicKey != null && m.hasOwnProperty("topicKey")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.topicKey);
                    if (e)
                        return "topicKey." + e;
                }
            }
            if (m.recId != null && m.hasOwnProperty("recId")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.recId);
                    if (e)
                        return "recId." + e;
                }
            }
            if (m.recBatchId != null && m.hasOwnProperty("recBatchId")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.recBatchId);
                    if (e)
                        return "recBatchId." + e;
                }
            }
            if (m.searchQuery != null && m.hasOwnProperty("searchQuery")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.searchQuery);
                    if (e)
                        return "searchQuery." + e;
                }
            }
            if (m.searchResultRank != null && m.hasOwnProperty("searchResultRank")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.searchResultRank);
                    if (e)
                        return "searchResultRank." + e;
                }
            }
            if (m.externalReferrer != null && m.hasOwnProperty("externalReferrer")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.externalReferrer);
                    if (e)
                        return "externalReferrer." + e;
                }
            }
            if (m.experiments != null && m.hasOwnProperty("experiments")) {
                if (!Array.isArray(m.experiments))
                    return "experiments: array expected";
                for (var i = 0; i < m.experiments.length; ++i) {
                    {
                        var e = $root.globals.Experiment.verify(m.experiments[i]);
                        if (e)
                            return "experiments." + e;
                    }
                }
            }
            if (m.rollouts != null && m.hasOwnProperty("rollouts")) {
                if (!Array.isArray(m.rollouts))
                    return "rollouts: array expected";
                for (var i = 0; i < m.rollouts.length; ++i) {
                    if (!$util.isString(m.rollouts[i]))
                        return "rollouts: string[] expected";
                }
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.urlId);
                    if (e)
                        return "urlId." + e;
                }
            }
            if (m.slug != null && m.hasOwnProperty("slug")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.slug);
                    if (e)
                        return "slug." + e;
                }
            }
            if (m.node != null && m.hasOwnProperty("node")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.node);
                    if (e)
                        return "node." + e;
                }
            }
            if (m.streamUrlId != null && m.hasOwnProperty("streamUrlId")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.streamUrlId);
                    if (e)
                        return "streamUrlId." + e;
                }
            }
            return null;
        };

        PageContext.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.PageContext)
                return d;
            var m = new $root.globals.PageContext();
            switch (d.page) {
            default:
                if (typeof d.page === "number") {
                    m.page = d.page;
                    break;
                }
                break;
            case "UNKNOWN_PAGE":
            case 0:
                m.page = 0;
                break;
            case "STREAMS_PAGE":
            case 1:
                m.page = 1;
                break;
            case "INBOX_SENT":
            case 2:
                m.page = 2;
                break;
            case "INBOX_RECEIVED":
            case 3:
                m.page = 3;
                break;
            case "CARD_VIEW":
            case 4:
                m.page = 4;
                break;
            case "CONTENT_VIEW":
            case 5:
                m.page = 5;
                break;
            case "IN_APP_SHARE":
            case 6:
                m.page = 6;
                break;
            case "SHARE_EXTENSION":
            case 7:
                m.page = 7;
                break;
            case "USER_PROFILE_PAGES":
            case 8:
                m.page = 8;
                break;
            case "USER_PROFILE_MIXES":
            case 9:
                m.page = 9;
                break;
            case "USER_PROFILE_FOLLOWERS":
            case 10:
                m.page = 10;
                break;
            case "USER_PROFILE_FOLLOWING":
            case 11:
                m.page = 11;
                break;
            case "USER_PROFILE_SEEN":
            case 12:
                m.page = 12;
                break;
            case "MIX_PROFILE_PAGES":
            case 13:
                m.page = 13;
                break;
            case "MIX_PROFILE_FOLLOWERS":
            case 14:
                m.page = 14;
                break;
            case "TOPIC_PROFILE_PAGES":
            case 15:
                m.page = 15;
                break;
            case "TOPIC_PROFILE_FOLLOWERS":
            case 16:
                m.page = 16;
                break;
            case "CATEGORY_PROFILE_PAGES":
            case 17:
                m.page = 17;
                break;
            case "CATEGORY_PROFILE_FOLLOWERS":
            case 18:
                m.page = 18;
                break;
            case "ONBOARDING_CATEGORY_FOLLOW":
            case 19:
                m.page = 19;
                break;
            case "ONBOARDING_USER_FOLLOW":
            case 20:
                m.page = 20;
                break;
            case "CARD_MIXED_BY":
            case 21:
                m.page = 21;
                break;
            case "SHARE_INBOX":
            case 22:
                m.page = 22;
                break;
            case "SHARE_CONVERSATION":
            case 23:
                m.page = 23;
                break;
            case "END_OF_STREAM":
            case 24:
                m.page = 24;
                break;
            case "DEEP_LINK":
            case 25:
                m.page = 25;
                break;
            case "PUSH_NOTIFICATION":
            case 26:
                m.page = 26;
                break;
            case "SEARCH_PAGE":
            case 27:
                m.page = 27;
                break;
            case "ONBOARDING_MIX_FOLLOW":
            case 28:
                m.page = 28;
                break;
            case "FIND_FRIENDS":
            case 29:
                m.page = 29;
                break;
            case "ONBOARDING_PROFILE":
            case 30:
                m.page = 30;
                break;
            case "STREAM_PROFILE_PAGE":
            case 31:
                m.page = 31;
                break;
            case "USER_PROFILE_CREATED_COLLECTIONS":
            case 32:
                m.page = 32;
                break;
            case "USER_PROFILE_SUBSCRIBED_COLLECTIONS":
            case 33:
                m.page = 33;
                break;
            case "USER_PROFILE_NOTIFICATIONS":
            case 34:
                m.page = 34;
                break;
            case "USER_PROFILE_SETTINGS":
            case 35:
                m.page = 35;
                break;
            case "LOGGED_OUT_HOME":
            case 36:
                m.page = 36;
                break;
            case "MIXIT_LINK":
            case 37:
                m.page = 37;
                break;
            case "MIX_PROFILE_EDIT":
            case 39:
                m.page = 39;
                break;
            case "LOGIN_PAGE":
            case 40:
                m.page = 40;
                break;
            case "ONBOARDING_INTEREST_FOLLOW":
            case 41:
                m.page = 41;
                break;
            case "SHARE_EXTENSION_MIXED_BY":
            case 42:
                m.page = 42;
                break;
            case "ADMIN_PAGE":
            case 43:
                m.page = 43;
                break;
            case "USER_PROFILE_LINKED_ACCOUNTS":
            case 44:
                m.page = 44;
                break;
            case "EMAIL_AUTH_LOGIN_PAGE":
            case 45:
                m.page = 45;
                break;
            case "EMAIL_AUTH_SENT_PAGE":
            case 46:
                m.page = 46;
                break;
            case "EMAIL_AUTH_ERROR_PAGE":
            case 47:
                m.page = 47;
                break;
            case "CONTENT_INFO_PAGE":
            case 48:
                m.page = 48;
                break;
            case "IN_APP_WEB_BROWSER":
            case 49:
                m.page = 49;
                break;
            case "LOADING_PAGE":
            case 50:
                m.page = 50;
                break;
            case "RECOMMENDED_USERS_PAGE":
            case 51:
                m.page = 51;
                break;
            case "ADD_URL_PAGE":
            case 52:
                m.page = 52;
                break;
            case "CALLOUT_PAGE":
            case 53:
                m.page = 53;
                break;
            }
            if (d.attributes) {
                if (typeof d.attributes !== "object")
                    throw TypeError(".globals.PageContext.attributes: object expected");
                m.attributes = {};
                for (var ks = Object.keys(d.attributes), i = 0; i < ks.length; ++i) {
                    m.attributes[ks[i]] = String(d.attributes[ks[i]]);
                }
            }
            switch (d.streamType) {
            default:
                if (typeof d.streamType === "number") {
                    m.streamType = d.streamType;
                    break;
                }
                break;
            case "UNKNOWN_STREAM_TYPE":
            case 0:
                m.streamType = 0;
                break;
            case "TOP_FOR_YOU_STREAM":
            case 1:
                m.streamType = 1;
                break;
            case "CATEGORY_STREAM":
            case 2:
                m.streamType = 2;
                break;
            case "TOPIC_STREAM":
            case 3:
                m.streamType = 3;
                break;
            case "MIX_STREAM":
            case 4:
                m.streamType = 4;
                break;
            case "SHARE_STREAM":
            case 5:
                m.streamType = 5;
                break;
            case "USER_MIXED_STREAM":
            case 6:
                m.streamType = 6;
                break;
            case "MIX_DIGEST_STREAM":
            case 7:
                m.streamType = 7;
                break;
            case "ALL_OF_MIX_STREAM":
            case 8:
                m.streamType = 8;
                break;
            case "FEATURED_COLLECTION_CAROUSEL":
            case 9:
                m.streamType = 9;
                break;
            case "FEATURED_ARTICLE_CAROUSEL":
            case 10:
                m.streamType = 10;
                break;
            case "RELATED_ARTICLE_STREAM":
            case 11:
                m.streamType = 11;
                break;
            case "SEARCH_POSTS_STREAM":
            case 12:
                m.streamType = 12;
                break;
            case "SEARCH_COLLECTIONS_STREAM":
            case 13:
                m.streamType = 13;
                break;
            case "SEARCH_INTERESTS_STREAM":
            case 14:
                m.streamType = 14;
                break;
            case "SEARCH_PEOPLE_STREAM":
            case 15:
                m.streamType = 15;
                break;
            case "SEARCH_ALL_STREAM":
            case 16:
                m.streamType = 16;
                break;
            case "SLUG_STREAM":
            case 17:
                m.streamType = 17;
                break;
            case "NODE_STREAM":
            case 18:
                m.streamType = 18;
                break;
            }
            if (d.userId != null) {
                if (typeof d.userId === "object" && d.userId !== null && d.userId.value !== undefined)
                    m.userId = $root.google.protobuf.UInt64Value.fromObject(d.userId);
                else
                    m.userId = $root.google.protobuf.UInt64Value.fromObject({ value: d.userId });
            }
            if (d.mixId != null) {
                if (typeof d.mixId === "object" && d.mixId !== null && d.mixId.value !== undefined)
                    m.mixId = $root.google.protobuf.UInt64Value.fromObject(d.mixId);
                else
                    m.mixId = $root.google.protobuf.UInt64Value.fromObject({ value: d.mixId });
            }
            if (d.categoryId != null) {
                if (typeof d.categoryId === "object" && d.categoryId !== null && d.categoryId.value !== undefined)
                    m.categoryId = $root.google.protobuf.UInt64Value.fromObject(d.categoryId);
                else
                    m.categoryId = $root.google.protobuf.UInt64Value.fromObject({ value: d.categoryId });
            }
            if (d.categoryTopicId != null) {
                if (typeof d.categoryTopicId === "object" && d.categoryTopicId !== null && d.categoryTopicId.value !== undefined)
                    m.categoryTopicId = $root.google.protobuf.UInt64Value.fromObject(d.categoryTopicId);
                else
                    m.categoryTopicId = $root.google.protobuf.UInt64Value.fromObject({ value: d.categoryTopicId });
            }
            if (d.topicId != null) {
                if (typeof d.topicId === "object" && d.topicId !== null && d.topicId.value !== undefined)
                    m.topicId = $root.google.protobuf.UInt64Value.fromObject(d.topicId);
                else
                    m.topicId = $root.google.protobuf.UInt64Value.fromObject({ value: d.topicId });
            }
            if (d.shareId != null) {
                if (typeof d.shareId === "object" && d.shareId !== null && d.shareId.value !== undefined)
                    m.shareId = $root.google.protobuf.UInt64Value.fromObject(d.shareId);
                else
                    m.shareId = $root.google.protobuf.UInt64Value.fromObject({ value: d.shareId });
            }
            if (d.testGroups) {
                if (!Array.isArray(d.testGroups))
                    throw TypeError(".globals.PageContext.testGroups: array expected");
                m.testGroups = [];
                for (var i = 0; i < d.testGroups.length; ++i) {
                    m.testGroups[i] = String(d.testGroups[i]);
                }
            }
            if (d.pageSection != null) {
                if (typeof d.pageSection === "object" && d.pageSection !== null && d.pageSection.value !== undefined)
                    m.pageSection = $root.google.protobuf.StringValue.fromObject(d.pageSection);
                else
                    m.pageSection = $root.google.protobuf.StringValue.fromObject({ value: d.pageSection });
            }
            if (d.categorySlugKey != null) {
                if (typeof d.categorySlugKey === "object" && d.categorySlugKey !== null && d.categorySlugKey.value !== undefined)
                    m.categorySlugKey = $root.google.protobuf.StringValue.fromObject(d.categorySlugKey);
                else
                    m.categorySlugKey = $root.google.protobuf.StringValue.fromObject({ value: d.categorySlugKey });
            }
            if (d.topicKey != null) {
                if (typeof d.topicKey === "object" && d.topicKey !== null && d.topicKey.value !== undefined)
                    m.topicKey = $root.google.protobuf.StringValue.fromObject(d.topicKey);
                else
                    m.topicKey = $root.google.protobuf.StringValue.fromObject({ value: d.topicKey });
            }
            if (d.recId != null) {
                if (typeof d.recId === "object" && d.recId !== null && d.recId.value !== undefined)
                    m.recId = $root.google.protobuf.StringValue.fromObject(d.recId);
                else
                    m.recId = $root.google.protobuf.StringValue.fromObject({ value: d.recId });
            }
            if (d.recBatchId != null) {
                if (typeof d.recBatchId === "object" && d.recBatchId !== null && d.recBatchId.value !== undefined)
                    m.recBatchId = $root.google.protobuf.StringValue.fromObject(d.recBatchId);
                else
                    m.recBatchId = $root.google.protobuf.StringValue.fromObject({ value: d.recBatchId });
            }
            if (d.searchQuery != null) {
                if (typeof d.searchQuery === "object" && d.searchQuery !== null && d.searchQuery.value !== undefined)
                    m.searchQuery = $root.google.protobuf.StringValue.fromObject(d.searchQuery);
                else
                    m.searchQuery = $root.google.protobuf.StringValue.fromObject({ value: d.searchQuery });
            }
            if (d.searchResultRank != null) {
                if (typeof d.searchResultRank === "object" && d.searchResultRank !== null && d.searchResultRank.value !== undefined)
                    m.searchResultRank = $root.google.protobuf.UInt64Value.fromObject(d.searchResultRank);
                else
                    m.searchResultRank = $root.google.protobuf.UInt64Value.fromObject({ value: d.searchResultRank });
            }
            if (d.externalReferrer != null) {
                if (typeof d.externalReferrer === "object" && d.externalReferrer !== null && d.externalReferrer.value !== undefined)
                    m.externalReferrer = $root.google.protobuf.StringValue.fromObject(d.externalReferrer);
                else
                    m.externalReferrer = $root.google.protobuf.StringValue.fromObject({ value: d.externalReferrer });
            }
            if (d.experiments) {
                if (!Array.isArray(d.experiments))
                    throw TypeError(".globals.PageContext.experiments: array expected");
                m.experiments = [];
                for (var i = 0; i < d.experiments.length; ++i) {
                    if (typeof d.experiments[i] !== "object")
                        throw TypeError(".globals.PageContext.experiments: object expected");
                    m.experiments[i] = $root.globals.Experiment.fromObject(d.experiments[i]);
                }
            }
            if (d.rollouts) {
                if (!Array.isArray(d.rollouts))
                    throw TypeError(".globals.PageContext.rollouts: array expected");
                m.rollouts = [];
                for (var i = 0; i < d.rollouts.length; ++i) {
                    m.rollouts[i] = String(d.rollouts[i]);
                }
            }
            if (d.urlId != null) {
                if (typeof d.urlId === "object" && d.urlId !== null && d.urlId.value !== undefined)
                    m.urlId = $root.google.protobuf.UInt64Value.fromObject(d.urlId);
                else
                    m.urlId = $root.google.protobuf.UInt64Value.fromObject({ value: d.urlId });
            }
            if (d.slug != null) {
                if (typeof d.slug === "object" && d.slug !== null && d.slug.value !== undefined)
                    m.slug = $root.google.protobuf.StringValue.fromObject(d.slug);
                else
                    m.slug = $root.google.protobuf.StringValue.fromObject({ value: d.slug });
            }
            if (d.node != null) {
                if (typeof d.node === "object" && d.node !== null && d.node.value !== undefined)
                    m.node = $root.google.protobuf.StringValue.fromObject(d.node);
                else
                    m.node = $root.google.protobuf.StringValue.fromObject({ value: d.node });
            }
            if (d.streamUrlId != null) {
                if (typeof d.streamUrlId === "object" && d.streamUrlId !== null && d.streamUrlId.value !== undefined)
                    m.streamUrlId = $root.google.protobuf.UInt64Value.fromObject(d.streamUrlId);
                else
                    m.streamUrlId = $root.google.protobuf.UInt64Value.fromObject({ value: d.streamUrlId });
            }
            return m;
        };

        PageContext.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.testGroups = [];
                d.experiments = [];
                d.rollouts = [];
            }
            if (o.objects || o.defaults) {
                d.attributes = {};
            }
            if (o.defaults) {
                d.page = o.enums === String ? "UNKNOWN_PAGE" : 0;
                d.streamType = o.enums === String ? "UNKNOWN_STREAM_TYPE" : 0;
                d.userId = null;
                d.mixId = null;
                d.categoryId = null;
                d.categoryTopicId = null;
                d.topicId = null;
                d.shareId = null;
                d.pageSection = null;
                d.categorySlugKey = null;
                d.topicKey = null;
                d.recId = null;
                d.recBatchId = null;
                d.searchQuery = null;
                d.searchResultRank = null;
                d.externalReferrer = null;
                d.urlId = null;
                d.slug = null;
                d.node = null;
                d.streamUrlId = null;
            }
            if (m.page != null && m.hasOwnProperty("page")) {
                d.page = o.enums === String ? $root.globals.PageContext.PageType[m.page] === undefined ? m.page : $root.globals.PageContext.PageType[m.page] : m.page;
            }
            var ks2;
            if (m.attributes && (ks2 = Object.keys(m.attributes)).length) {
                d.attributes = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.attributes[ks2[j]] = m.attributes[ks2[j]];
                }
            }
            if (m.streamType != null && m.hasOwnProperty("streamType")) {
                d.streamType = o.enums === String ? $root.globals.StreamType[m.streamType] === undefined ? m.streamType : $root.globals.StreamType[m.streamType] : m.streamType;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                d.userId = $root.google.protobuf.UInt64Value.toObject(m.userId, o).value;
            }
            if (m.mixId != null && m.hasOwnProperty("mixId")) {
                d.mixId = $root.google.protobuf.UInt64Value.toObject(m.mixId, o).value;
            }
            if (m.categoryId != null && m.hasOwnProperty("categoryId")) {
                d.categoryId = $root.google.protobuf.UInt64Value.toObject(m.categoryId, o).value;
            }
            if (m.categoryTopicId != null && m.hasOwnProperty("categoryTopicId")) {
                d.categoryTopicId = $root.google.protobuf.UInt64Value.toObject(m.categoryTopicId, o).value;
            }
            if (m.topicId != null && m.hasOwnProperty("topicId")) {
                d.topicId = $root.google.protobuf.UInt64Value.toObject(m.topicId, o).value;
            }
            if (m.shareId != null && m.hasOwnProperty("shareId")) {
                d.shareId = $root.google.protobuf.UInt64Value.toObject(m.shareId, o).value;
            }
            if (m.testGroups && m.testGroups.length) {
                d.testGroups = [];
                for (var j = 0; j < m.testGroups.length; ++j) {
                    d.testGroups[j] = m.testGroups[j];
                }
            }
            if (m.pageSection != null && m.hasOwnProperty("pageSection")) {
                d.pageSection = $root.google.protobuf.StringValue.toObject(m.pageSection, o).value;
            }
            if (m.categorySlugKey != null && m.hasOwnProperty("categorySlugKey")) {
                d.categorySlugKey = $root.google.protobuf.StringValue.toObject(m.categorySlugKey, o).value;
            }
            if (m.topicKey != null && m.hasOwnProperty("topicKey")) {
                d.topicKey = $root.google.protobuf.StringValue.toObject(m.topicKey, o).value;
            }
            if (m.recId != null && m.hasOwnProperty("recId")) {
                d.recId = $root.google.protobuf.StringValue.toObject(m.recId, o).value;
            }
            if (m.recBatchId != null && m.hasOwnProperty("recBatchId")) {
                d.recBatchId = $root.google.protobuf.StringValue.toObject(m.recBatchId, o).value;
            }
            if (m.searchQuery != null && m.hasOwnProperty("searchQuery")) {
                d.searchQuery = $root.google.protobuf.StringValue.toObject(m.searchQuery, o).value;
            }
            if (m.searchResultRank != null && m.hasOwnProperty("searchResultRank")) {
                d.searchResultRank = $root.google.protobuf.UInt64Value.toObject(m.searchResultRank, o).value;
            }
            if (m.externalReferrer != null && m.hasOwnProperty("externalReferrer")) {
                d.externalReferrer = $root.google.protobuf.StringValue.toObject(m.externalReferrer, o).value;
            }
            if (m.experiments && m.experiments.length) {
                d.experiments = [];
                for (var j = 0; j < m.experiments.length; ++j) {
                    d.experiments[j] = $root.globals.Experiment.toObject(m.experiments[j], o);
                }
            }
            if (m.rollouts && m.rollouts.length) {
                d.rollouts = [];
                for (var j = 0; j < m.rollouts.length; ++j) {
                    d.rollouts[j] = m.rollouts[j];
                }
            }
            if (m.urlId != null && m.hasOwnProperty("urlId")) {
                d.urlId = $root.google.protobuf.UInt64Value.toObject(m.urlId, o).value;
            }
            if (m.slug != null && m.hasOwnProperty("slug")) {
                d.slug = $root.google.protobuf.StringValue.toObject(m.slug, o).value;
            }
            if (m.node != null && m.hasOwnProperty("node")) {
                d.node = $root.google.protobuf.StringValue.toObject(m.node, o).value;
            }
            if (m.streamUrlId != null && m.hasOwnProperty("streamUrlId")) {
                d.streamUrlId = $root.google.protobuf.UInt64Value.toObject(m.streamUrlId, o).value;
            }
            return d;
        };

        PageContext.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PageContext.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.PageContext";
        };

        PageContext.PageType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_PAGE"] = 0;
            values[valuesById[1] = "STREAMS_PAGE"] = 1;
            values[valuesById[2] = "INBOX_SENT"] = 2;
            values[valuesById[3] = "INBOX_RECEIVED"] = 3;
            values[valuesById[4] = "CARD_VIEW"] = 4;
            values[valuesById[5] = "CONTENT_VIEW"] = 5;
            values[valuesById[6] = "IN_APP_SHARE"] = 6;
            values[valuesById[7] = "SHARE_EXTENSION"] = 7;
            values[valuesById[8] = "USER_PROFILE_PAGES"] = 8;
            values[valuesById[9] = "USER_PROFILE_MIXES"] = 9;
            values[valuesById[10] = "USER_PROFILE_FOLLOWERS"] = 10;
            values[valuesById[11] = "USER_PROFILE_FOLLOWING"] = 11;
            values[valuesById[12] = "USER_PROFILE_SEEN"] = 12;
            values[valuesById[13] = "MIX_PROFILE_PAGES"] = 13;
            values[valuesById[14] = "MIX_PROFILE_FOLLOWERS"] = 14;
            values[valuesById[15] = "TOPIC_PROFILE_PAGES"] = 15;
            values[valuesById[16] = "TOPIC_PROFILE_FOLLOWERS"] = 16;
            values[valuesById[17] = "CATEGORY_PROFILE_PAGES"] = 17;
            values[valuesById[18] = "CATEGORY_PROFILE_FOLLOWERS"] = 18;
            values[valuesById[19] = "ONBOARDING_CATEGORY_FOLLOW"] = 19;
            values[valuesById[20] = "ONBOARDING_USER_FOLLOW"] = 20;
            values[valuesById[21] = "CARD_MIXED_BY"] = 21;
            values[valuesById[22] = "SHARE_INBOX"] = 22;
            values[valuesById[23] = "SHARE_CONVERSATION"] = 23;
            values[valuesById[24] = "END_OF_STREAM"] = 24;
            values[valuesById[25] = "DEEP_LINK"] = 25;
            values[valuesById[26] = "PUSH_NOTIFICATION"] = 26;
            values[valuesById[27] = "SEARCH_PAGE"] = 27;
            values[valuesById[28] = "ONBOARDING_MIX_FOLLOW"] = 28;
            values[valuesById[29] = "FIND_FRIENDS"] = 29;
            values[valuesById[30] = "ONBOARDING_PROFILE"] = 30;
            values[valuesById[31] = "STREAM_PROFILE_PAGE"] = 31;
            values[valuesById[32] = "USER_PROFILE_CREATED_COLLECTIONS"] = 32;
            values[valuesById[33] = "USER_PROFILE_SUBSCRIBED_COLLECTIONS"] = 33;
            values[valuesById[34] = "USER_PROFILE_NOTIFICATIONS"] = 34;
            values[valuesById[35] = "USER_PROFILE_SETTINGS"] = 35;
            values[valuesById[36] = "LOGGED_OUT_HOME"] = 36;
            values[valuesById[37] = "MIXIT_LINK"] = 37;
            values[valuesById[39] = "MIX_PROFILE_EDIT"] = 39;
            values[valuesById[40] = "LOGIN_PAGE"] = 40;
            values[valuesById[41] = "ONBOARDING_INTEREST_FOLLOW"] = 41;
            values[valuesById[42] = "SHARE_EXTENSION_MIXED_BY"] = 42;
            values[valuesById[43] = "ADMIN_PAGE"] = 43;
            values[valuesById[44] = "USER_PROFILE_LINKED_ACCOUNTS"] = 44;
            values[valuesById[45] = "EMAIL_AUTH_LOGIN_PAGE"] = 45;
            values[valuesById[46] = "EMAIL_AUTH_SENT_PAGE"] = 46;
            values[valuesById[47] = "EMAIL_AUTH_ERROR_PAGE"] = 47;
            values[valuesById[48] = "CONTENT_INFO_PAGE"] = 48;
            values[valuesById[49] = "IN_APP_WEB_BROWSER"] = 49;
            values[valuesById[50] = "LOADING_PAGE"] = 50;
            values[valuesById[51] = "RECOMMENDED_USERS_PAGE"] = 51;
            values[valuesById[52] = "ADD_URL_PAGE"] = 52;
            values[valuesById[53] = "CALLOUT_PAGE"] = 53;
            return values;
        })();

        return PageContext;
    })();

    globals.AppVersion = (function() {

        function AppVersion(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AppVersion.prototype.appName = null;
        AppVersion.prototype.versionNumber = null;
        AppVersion.prototype.provider = 0;
        AppVersion.prototype.buildNumber = null;
        AppVersion.prototype.appType = 0;
        AppVersion.prototype.customProvider = null;
        AppVersion.prototype.isExternal = null;

        AppVersion.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.appName != null && Object.hasOwnProperty.call(m, "appName"))
                $root.google.protobuf.StringValue.encode(m.appName, w.uint32(10).fork()).ldelim();
            if (m.versionNumber != null && Object.hasOwnProperty.call(m, "versionNumber"))
                $root.google.protobuf.StringValue.encode(m.versionNumber, w.uint32(18).fork()).ldelim();
            if (m.provider != null && Object.hasOwnProperty.call(m, "provider"))
                w.uint32(24).int32(m.provider);
            if (m.buildNumber != null && Object.hasOwnProperty.call(m, "buildNumber"))
                $root.google.protobuf.StringValue.encode(m.buildNumber, w.uint32(34).fork()).ldelim();
            if (m.appType != null && Object.hasOwnProperty.call(m, "appType"))
                w.uint32(40).int32(m.appType);
            if (m.customProvider != null && Object.hasOwnProperty.call(m, "customProvider"))
                $root.google.protobuf.StringValue.encode(m.customProvider, w.uint32(50).fork()).ldelim();
            if (m.isExternal != null && Object.hasOwnProperty.call(m, "isExternal"))
                $root.google.protobuf.BoolValue.encode(m.isExternal, w.uint32(58).fork()).ldelim();
            return w;
        };

        AppVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        AppVersion.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.AppVersion();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.appName = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.versionNumber = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.provider = r.int32();
                        break;
                    }
                case 4: {
                        m.buildNumber = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        m.appType = r.int32();
                        break;
                    }
                case 6: {
                        m.customProvider = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.isExternal = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        AppVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        AppVersion.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.appName != null && m.hasOwnProperty("appName")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.appName);
                    if (e)
                        return "appName." + e;
                }
            }
            if (m.versionNumber != null && m.hasOwnProperty("versionNumber")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.versionNumber);
                    if (e)
                        return "versionNumber." + e;
                }
            }
            if (m.provider != null && m.hasOwnProperty("provider")) {
                switch (m.provider) {
                default:
                    return "provider: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            }
            if (m.buildNumber != null && m.hasOwnProperty("buildNumber")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.buildNumber);
                    if (e)
                        return "buildNumber." + e;
                }
            }
            if (m.appType != null && m.hasOwnProperty("appType")) {
                switch (m.appType) {
                default:
                    return "appType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                    break;
                }
            }
            if (m.customProvider != null && m.hasOwnProperty("customProvider")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.customProvider);
                    if (e)
                        return "customProvider." + e;
                }
            }
            if (m.isExternal != null && m.hasOwnProperty("isExternal")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.isExternal);
                    if (e)
                        return "isExternal." + e;
                }
            }
            return null;
        };

        AppVersion.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.AppVersion)
                return d;
            var m = new $root.globals.AppVersion();
            if (d.appName != null) {
                if (typeof d.appName === "object" && d.appName !== null && d.appName.value !== undefined)
                    m.appName = $root.google.protobuf.StringValue.fromObject(d.appName);
                else
                    m.appName = $root.google.protobuf.StringValue.fromObject({ value: d.appName });
            }
            if (d.versionNumber != null) {
                if (typeof d.versionNumber === "object" && d.versionNumber !== null && d.versionNumber.value !== undefined)
                    m.versionNumber = $root.google.protobuf.StringValue.fromObject(d.versionNumber);
                else
                    m.versionNumber = $root.google.protobuf.StringValue.fromObject({ value: d.versionNumber });
            }
            switch (d.provider) {
            default:
                if (typeof d.provider === "number") {
                    m.provider = d.provider;
                    break;
                }
                break;
            case "UNKNOWN_PROVIDER":
            case 0:
                m.provider = 0;
                break;
            case "APPLE_STORE":
            case 1:
                m.provider = 1;
                break;
            case "CHROME_WEB_STORE":
            case 2:
                m.provider = 2;
                break;
            case "MIX":
            case 3:
                m.provider = 3;
                break;
            case "GOOGLE_PLAY_STORE":
            case 4:
                m.provider = 4;
                break;
            case "OTHER_APP_PROVIDER":
            case 5:
                m.provider = 5;
                break;
            case "FIREFOX_ADD_ONS":
            case 6:
                m.provider = 6;
                break;
            case "MICROSOFT_STORE":
            case 7:
                m.provider = 7;
                break;
            }
            if (d.buildNumber != null) {
                if (typeof d.buildNumber === "object" && d.buildNumber !== null && d.buildNumber.value !== undefined)
                    m.buildNumber = $root.google.protobuf.StringValue.fromObject(d.buildNumber);
                else
                    m.buildNumber = $root.google.protobuf.StringValue.fromObject({ value: d.buildNumber });
            }
            switch (d.appType) {
            default:
                if (typeof d.appType === "number") {
                    m.appType = d.appType;
                    break;
                }
                break;
            case "UNKNOWN_APP":
            case 0:
                m.appType = 0;
                break;
            case "IOS_APP":
            case 1:
                m.appType = 1;
                break;
            case "WEBAPP":
            case 2:
                m.appType = 2;
                break;
            case "ANDROID_APP":
            case 3:
                m.appType = 3;
                break;
            case "CHROME_EXTENSION":
            case 4:
                m.appType = 4;
                break;
            case "FIREFOX_EXTENSION":
            case 5:
                m.appType = 5;
                break;
            case "MIX_BADGE":
            case 6:
                m.appType = 6;
                break;
            case "THIRD_PARTY_BADGE":
            case 7:
                m.appType = 7;
                break;
            case "INGESTER":
            case 8:
                m.appType = 8;
                break;
            case "INTERNAL_APP":
            case 9:
                m.appType = 9;
                break;
            case "SAFARI_EXTENSION":
            case 10:
                m.appType = 10;
                break;
            case "EDGE_EXTENSION":
            case 11:
                m.appType = 11;
                break;
            case "INTO_IOS_APP":
            case 12:
                m.appType = 12;
                break;
            case "DESKTOP_APP":
            case 13:
                m.appType = 13;
                break;
            case "INTO_ANDROID_APP":
            case 14:
                m.appType = 14;
                break;
            case "LAYER_IOS_APP":
            case 15:
                m.appType = 15;
                break;
            case "STUMBLEUPON_WEB":
            case 16:
                m.appType = 16;
                break;
            case "EMAIL_CLIENT":
            case 17:
                m.appType = 17;
                break;
            case "APPLE_TV_APP":
            case 18:
                m.appType = 18;
                break;
            }
            if (d.customProvider != null) {
                if (typeof d.customProvider === "object" && d.customProvider !== null && d.customProvider.value !== undefined)
                    m.customProvider = $root.google.protobuf.StringValue.fromObject(d.customProvider);
                else
                    m.customProvider = $root.google.protobuf.StringValue.fromObject({ value: d.customProvider });
            }
            if (d.isExternal != null) {
                if (typeof d.isExternal === "object" && d.isExternal !== null && d.isExternal.value !== undefined)
                    m.isExternal = $root.google.protobuf.BoolValue.fromObject(d.isExternal);
                else
                    m.isExternal = $root.google.protobuf.BoolValue.fromObject({ value: d.isExternal });
            }
            return m;
        };

        AppVersion.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.appName = null;
                d.versionNumber = null;
                d.provider = o.enums === String ? "UNKNOWN_PROVIDER" : 0;
                d.buildNumber = null;
                d.appType = o.enums === String ? "UNKNOWN_APP" : 0;
                d.customProvider = null;
                d.isExternal = null;
            }
            if (m.appName != null && m.hasOwnProperty("appName")) {
                d.appName = $root.google.protobuf.StringValue.toObject(m.appName, o).value;
            }
            if (m.versionNumber != null && m.hasOwnProperty("versionNumber")) {
                d.versionNumber = $root.google.protobuf.StringValue.toObject(m.versionNumber, o).value;
            }
            if (m.provider != null && m.hasOwnProperty("provider")) {
                d.provider = o.enums === String ? $root.globals.AppProvider[m.provider] === undefined ? m.provider : $root.globals.AppProvider[m.provider] : m.provider;
            }
            if (m.buildNumber != null && m.hasOwnProperty("buildNumber")) {
                d.buildNumber = $root.google.protobuf.StringValue.toObject(m.buildNumber, o).value;
            }
            if (m.appType != null && m.hasOwnProperty("appType")) {
                d.appType = o.enums === String ? $root.globals.ApplicationType[m.appType] === undefined ? m.appType : $root.globals.ApplicationType[m.appType] : m.appType;
            }
            if (m.customProvider != null && m.hasOwnProperty("customProvider")) {
                d.customProvider = $root.google.protobuf.StringValue.toObject(m.customProvider, o).value;
            }
            if (m.isExternal != null && m.hasOwnProperty("isExternal")) {
                d.isExternal = $root.google.protobuf.BoolValue.toObject(m.isExternal, o).value;
            }
            return d;
        };

        AppVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        AppVersion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.AppVersion";
        };

        return AppVersion;
    })();

    globals.Device = (function() {

        function Device(p) {
            this.data = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Device.prototype.deviceType = 0;
        Device.prototype.userAgent = null;
        Device.prototype.osVersion = null;
        Device.prototype.osLanguage = 0;
        Device.prototype.data = $util.emptyObject;
        Device.prototype.deviceModel = null;
        Device.prototype.deviceUuid = null;
        Device.prototype.displayInfo = null;

        Device.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.deviceType != null && Object.hasOwnProperty.call(m, "deviceType"))
                w.uint32(8).int32(m.deviceType);
            if (m.userAgent != null && Object.hasOwnProperty.call(m, "userAgent"))
                $root.google.protobuf.StringValue.encode(m.userAgent, w.uint32(18).fork()).ldelim();
            if (m.osVersion != null && Object.hasOwnProperty.call(m, "osVersion"))
                $root.google.protobuf.StringValue.encode(m.osVersion, w.uint32(26).fork()).ldelim();
            if (m.osLanguage != null && Object.hasOwnProperty.call(m, "osLanguage"))
                w.uint32(32).int32(m.osLanguage);
            if (m.data != null && Object.hasOwnProperty.call(m, "data")) {
                for (var ks = Object.keys(m.data), i = 0; i < ks.length; ++i) {
                    w.uint32(42).fork().uint32(10).string(ks[i]).uint32(18).string(m.data[ks[i]]).ldelim();
                }
            }
            if (m.deviceModel != null && Object.hasOwnProperty.call(m, "deviceModel"))
                $root.google.protobuf.StringValue.encode(m.deviceModel, w.uint32(50).fork()).ldelim();
            if (m.deviceUuid != null && Object.hasOwnProperty.call(m, "deviceUuid"))
                $root.google.protobuf.StringValue.encode(m.deviceUuid, w.uint32(58).fork()).ldelim();
            if (m.displayInfo != null && Object.hasOwnProperty.call(m, "displayInfo"))
                $root.globals.DisplayInfo.encode(m.displayInfo, w.uint32(66).fork()).ldelim();
            return w;
        };

        Device.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Device.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.Device(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.deviceType = r.int32();
                        break;
                    }
                case 2: {
                        m.userAgent = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.osVersion = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.osLanguage = r.int32();
                        break;
                    }
                case 5: {
                        if (m.data === $util.emptyObject)
                            m.data = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.data[k] = value;
                        break;
                    }
                case 6: {
                        m.deviceModel = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.deviceUuid = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        m.displayInfo = $root.globals.DisplayInfo.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        Device.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Device.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.deviceType != null && m.hasOwnProperty("deviceType")) {
                switch (m.deviceType) {
                default:
                    return "deviceType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    break;
                }
            }
            if (m.userAgent != null && m.hasOwnProperty("userAgent")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.userAgent);
                    if (e)
                        return "userAgent." + e;
                }
            }
            if (m.osVersion != null && m.hasOwnProperty("osVersion")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.osVersion);
                    if (e)
                        return "osVersion." + e;
                }
            }
            if (m.osLanguage != null && m.hasOwnProperty("osLanguage")) {
                switch (m.osLanguage) {
                default:
                    return "osLanguage: enum value expected";
                case 0:
                case 1:
                    break;
                }
            }
            if (m.data != null && m.hasOwnProperty("data")) {
                if (!$util.isObject(m.data))
                    return "data: object expected";
                var k = Object.keys(m.data);
                for (var i = 0; i < k.length; ++i) {
                    if (!$util.isString(m.data[k[i]]))
                        return "data: string{k:string} expected";
                }
            }
            if (m.deviceModel != null && m.hasOwnProperty("deviceModel")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.deviceModel);
                    if (e)
                        return "deviceModel." + e;
                }
            }
            if (m.deviceUuid != null && m.hasOwnProperty("deviceUuid")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.deviceUuid);
                    if (e)
                        return "deviceUuid." + e;
                }
            }
            if (m.displayInfo != null && m.hasOwnProperty("displayInfo")) {
                {
                    var e = $root.globals.DisplayInfo.verify(m.displayInfo);
                    if (e)
                        return "displayInfo." + e;
                }
            }
            return null;
        };

        Device.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.Device)
                return d;
            var m = new $root.globals.Device();
            switch (d.deviceType) {
            default:
                if (typeof d.deviceType === "number") {
                    m.deviceType = d.deviceType;
                    break;
                }
                break;
            case "UNKNOWN_DEVICE":
            case 0:
                m.deviceType = 0;
                break;
            case "IPHONE":
            case 1:
                m.deviceType = 1;
                break;
            case "DEPRECATED_CHROME_EXTENSION":
            case 2:
                m.deviceType = 2;
                break;
            case "DEPRECATED_WEBAPP":
            case 3:
                m.deviceType = 3;
                break;
            case "DEPRECATED_FIREFOX_EXTENSION":
            case 4:
                m.deviceType = 4;
                break;
            case "ANDROID":
            case 5:
                m.deviceType = 5;
                break;
            case "IPAD":
            case 6:
                m.deviceType = 6;
                break;
            case "ANDROID_TABLET":
            case 7:
                m.deviceType = 7;
                break;
            case "DESKTOP":
            case 8:
                m.deviceType = 8;
                break;
            case "INTERNAL":
            case 9:
                m.deviceType = 9;
                break;
            case "WINDOWS_PHONE":
            case 10:
                m.deviceType = 10;
                break;
            case "SYMBIAN":
            case 11:
                m.deviceType = 11;
                break;
            }
            if (d.userAgent != null) {
                if (typeof d.userAgent === "object" && d.userAgent !== null && d.userAgent.value !== undefined)
                    m.userAgent = $root.google.protobuf.StringValue.fromObject(d.userAgent);
                else
                    m.userAgent = $root.google.protobuf.StringValue.fromObject({ value: d.userAgent });
            }
            if (d.osVersion != null) {
                if (typeof d.osVersion === "object" && d.osVersion !== null && d.osVersion.value !== undefined)
                    m.osVersion = $root.google.protobuf.StringValue.fromObject(d.osVersion);
                else
                    m.osVersion = $root.google.protobuf.StringValue.fromObject({ value: d.osVersion });
            }
            switch (d.osLanguage) {
            default:
                if (typeof d.osLanguage === "number") {
                    m.osLanguage = d.osLanguage;
                    break;
                }
                break;
            case "UNKNOWN_LANGUAGE":
            case 0:
                m.osLanguage = 0;
                break;
            case "ENGLISH":
            case 1:
                m.osLanguage = 1;
                break;
            }
            if (d.data) {
                if (typeof d.data !== "object")
                    throw TypeError(".globals.Device.data: object expected");
                m.data = {};
                for (var ks = Object.keys(d.data), i = 0; i < ks.length; ++i) {
                    m.data[ks[i]] = String(d.data[ks[i]]);
                }
            }
            if (d.deviceModel != null) {
                if (typeof d.deviceModel === "object" && d.deviceModel !== null && d.deviceModel.value !== undefined)
                    m.deviceModel = $root.google.protobuf.StringValue.fromObject(d.deviceModel);
                else
                    m.deviceModel = $root.google.protobuf.StringValue.fromObject({ value: d.deviceModel });
            }
            if (d.deviceUuid != null) {
                if (typeof d.deviceUuid === "object" && d.deviceUuid !== null && d.deviceUuid.value !== undefined)
                    m.deviceUuid = $root.google.protobuf.StringValue.fromObject(d.deviceUuid);
                else
                    m.deviceUuid = $root.google.protobuf.StringValue.fromObject({ value: d.deviceUuid });
            }
            if (d.displayInfo != null) {
                if (typeof d.displayInfo !== "object")
                    throw TypeError(".globals.Device.displayInfo: object expected");
                m.displayInfo = $root.globals.DisplayInfo.fromObject(d.displayInfo);
            }
            return m;
        };

        Device.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.objects || o.defaults) {
                d.data = {};
            }
            if (o.defaults) {
                d.deviceType = o.enums === String ? "UNKNOWN_DEVICE" : 0;
                d.userAgent = null;
                d.osVersion = null;
                d.osLanguage = o.enums === String ? "UNKNOWN_LANGUAGE" : 0;
                d.deviceModel = null;
                d.deviceUuid = null;
                d.displayInfo = null;
            }
            if (m.deviceType != null && m.hasOwnProperty("deviceType")) {
                d.deviceType = o.enums === String ? $root.globals.DeviceType[m.deviceType] === undefined ? m.deviceType : $root.globals.DeviceType[m.deviceType] : m.deviceType;
            }
            if (m.userAgent != null && m.hasOwnProperty("userAgent")) {
                d.userAgent = $root.google.protobuf.StringValue.toObject(m.userAgent, o).value;
            }
            if (m.osVersion != null && m.hasOwnProperty("osVersion")) {
                d.osVersion = $root.google.protobuf.StringValue.toObject(m.osVersion, o).value;
            }
            if (m.osLanguage != null && m.hasOwnProperty("osLanguage")) {
                d.osLanguage = o.enums === String ? $root.globals.Language[m.osLanguage] === undefined ? m.osLanguage : $root.globals.Language[m.osLanguage] : m.osLanguage;
            }
            var ks2;
            if (m.data && (ks2 = Object.keys(m.data)).length) {
                d.data = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.data[ks2[j]] = m.data[ks2[j]];
                }
            }
            if (m.deviceModel != null && m.hasOwnProperty("deviceModel")) {
                d.deviceModel = $root.google.protobuf.StringValue.toObject(m.deviceModel, o).value;
            }
            if (m.deviceUuid != null && m.hasOwnProperty("deviceUuid")) {
                d.deviceUuid = $root.google.protobuf.StringValue.toObject(m.deviceUuid, o).value;
            }
            if (m.displayInfo != null && m.hasOwnProperty("displayInfo")) {
                d.displayInfo = $root.globals.DisplayInfo.toObject(m.displayInfo, o);
            }
            return d;
        };

        Device.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Device.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.Device";
        };

        return Device;
    })();

    globals.Session = (function() {

        function Session(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Session.prototype.sessionId = null;
        Session.prototype.device = null;
        Session.prototype.appVersion = null;
        Session.prototype.location = null;
        Session.prototype.clientIp = null;
        Session.prototype.isAdmin = null;
        Session.prototype.clientSessionId = null;
        Session.prototype.isSystem = null;
        Session.prototype.serverRole = 0;

        Session.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.sessionId != null && Object.hasOwnProperty.call(m, "sessionId"))
                $root.google.protobuf.StringValue.encode(m.sessionId, w.uint32(10).fork()).ldelim();
            if (m.device != null && Object.hasOwnProperty.call(m, "device"))
                $root.globals.Device.encode(m.device, w.uint32(18).fork()).ldelim();
            if (m.appVersion != null && Object.hasOwnProperty.call(m, "appVersion"))
                $root.globals.AppVersion.encode(m.appVersion, w.uint32(26).fork()).ldelim();
            if (m.location != null && Object.hasOwnProperty.call(m, "location"))
                $root.globals.Geolocation.encode(m.location, w.uint32(34).fork()).ldelim();
            if (m.clientIp != null && Object.hasOwnProperty.call(m, "clientIp"))
                $root.google.protobuf.StringValue.encode(m.clientIp, w.uint32(42).fork()).ldelim();
            if (m.isAdmin != null && Object.hasOwnProperty.call(m, "isAdmin"))
                $root.google.protobuf.BoolValue.encode(m.isAdmin, w.uint32(50).fork()).ldelim();
            if (m.clientSessionId != null && Object.hasOwnProperty.call(m, "clientSessionId"))
                $root.google.protobuf.StringValue.encode(m.clientSessionId, w.uint32(58).fork()).ldelim();
            if (m.isSystem != null && Object.hasOwnProperty.call(m, "isSystem"))
                $root.google.protobuf.BoolValue.encode(m.isSystem, w.uint32(66).fork()).ldelim();
            if (m.serverRole != null && Object.hasOwnProperty.call(m, "serverRole"))
                w.uint32(72).int32(m.serverRole);
            return w;
        };

        Session.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Session.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.Session();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.sessionId = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.device = $root.globals.Device.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.appVersion = $root.globals.AppVersion.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.location = $root.globals.Geolocation.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        m.clientIp = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        m.isAdmin = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.clientSessionId = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        m.isSystem = $root.google.protobuf.BoolValue.decode(r, r.uint32());
                        break;
                    }
                case 9: {
                        m.serverRole = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        Session.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Session.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.sessionId != null && m.hasOwnProperty("sessionId")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.sessionId);
                    if (e)
                        return "sessionId." + e;
                }
            }
            if (m.device != null && m.hasOwnProperty("device")) {
                {
                    var e = $root.globals.Device.verify(m.device);
                    if (e)
                        return "device." + e;
                }
            }
            if (m.appVersion != null && m.hasOwnProperty("appVersion")) {
                {
                    var e = $root.globals.AppVersion.verify(m.appVersion);
                    if (e)
                        return "appVersion." + e;
                }
            }
            if (m.location != null && m.hasOwnProperty("location")) {
                {
                    var e = $root.globals.Geolocation.verify(m.location);
                    if (e)
                        return "location." + e;
                }
            }
            if (m.clientIp != null && m.hasOwnProperty("clientIp")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.clientIp);
                    if (e)
                        return "clientIp." + e;
                }
            }
            if (m.isAdmin != null && m.hasOwnProperty("isAdmin")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.isAdmin);
                    if (e)
                        return "isAdmin." + e;
                }
            }
            if (m.clientSessionId != null && m.hasOwnProperty("clientSessionId")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.clientSessionId);
                    if (e)
                        return "clientSessionId." + e;
                }
            }
            if (m.isSystem != null && m.hasOwnProperty("isSystem")) {
                {
                    var e = $root.google.protobuf.BoolValue.verify(m.isSystem);
                    if (e)
                        return "isSystem." + e;
                }
            }
            if (m.serverRole != null && m.hasOwnProperty("serverRole")) {
                switch (m.serverRole) {
                default:
                    return "serverRole: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            }
            return null;
        };

        Session.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.Session)
                return d;
            var m = new $root.globals.Session();
            if (d.sessionId != null) {
                if (typeof d.sessionId === "object" && d.sessionId !== null && d.sessionId.value !== undefined)
                    m.sessionId = $root.google.protobuf.StringValue.fromObject(d.sessionId);
                else
                    m.sessionId = $root.google.protobuf.StringValue.fromObject({ value: d.sessionId });
            }
            if (d.device != null) {
                if (typeof d.device !== "object")
                    throw TypeError(".globals.Session.device: object expected");
                m.device = $root.globals.Device.fromObject(d.device);
            }
            if (d.appVersion != null) {
                if (typeof d.appVersion !== "object")
                    throw TypeError(".globals.Session.appVersion: object expected");
                m.appVersion = $root.globals.AppVersion.fromObject(d.appVersion);
            }
            if (d.location != null) {
                if (typeof d.location !== "object")
                    throw TypeError(".globals.Session.location: object expected");
                m.location = $root.globals.Geolocation.fromObject(d.location);
            }
            if (d.clientIp != null) {
                if (typeof d.clientIp === "object" && d.clientIp !== null && d.clientIp.value !== undefined)
                    m.clientIp = $root.google.protobuf.StringValue.fromObject(d.clientIp);
                else
                    m.clientIp = $root.google.protobuf.StringValue.fromObject({ value: d.clientIp });
            }
            if (d.isAdmin != null) {
                if (typeof d.isAdmin === "object" && d.isAdmin !== null && d.isAdmin.value !== undefined)
                    m.isAdmin = $root.google.protobuf.BoolValue.fromObject(d.isAdmin);
                else
                    m.isAdmin = $root.google.protobuf.BoolValue.fromObject({ value: d.isAdmin });
            }
            if (d.clientSessionId != null) {
                if (typeof d.clientSessionId === "object" && d.clientSessionId !== null && d.clientSessionId.value !== undefined)
                    m.clientSessionId = $root.google.protobuf.StringValue.fromObject(d.clientSessionId);
                else
                    m.clientSessionId = $root.google.protobuf.StringValue.fromObject({ value: d.clientSessionId });
            }
            if (d.isSystem != null) {
                if (typeof d.isSystem === "object" && d.isSystem !== null && d.isSystem.value !== undefined)
                    m.isSystem = $root.google.protobuf.BoolValue.fromObject(d.isSystem);
                else
                    m.isSystem = $root.google.protobuf.BoolValue.fromObject({ value: d.isSystem });
            }
            switch (d.serverRole) {
            default:
                if (typeof d.serverRole === "number") {
                    m.serverRole = d.serverRole;
                    break;
                }
                break;
            case "UNKNOWN_SERVER_ROLE":
            case 0:
                m.serverRole = 0;
                break;
            case "SERVER_ROLE_LOCAL":
            case 1:
                m.serverRole = 1;
                break;
            case "SERVER_ROLE_STAGING":
            case 2:
                m.serverRole = 2;
                break;
            case "SERVER_ROLE_PRODUCTION":
            case 3:
                m.serverRole = 3;
                break;
            case "SERVER_ROLE_CANARY":
            case 4:
                m.serverRole = 4;
                break;
            }
            return m;
        };

        Session.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.sessionId = null;
                d.device = null;
                d.appVersion = null;
                d.location = null;
                d.clientIp = null;
                d.isAdmin = null;
                d.clientSessionId = null;
                d.isSystem = null;
                d.serverRole = o.enums === String ? "UNKNOWN_SERVER_ROLE" : 0;
            }
            if (m.sessionId != null && m.hasOwnProperty("sessionId")) {
                d.sessionId = $root.google.protobuf.StringValue.toObject(m.sessionId, o).value;
            }
            if (m.device != null && m.hasOwnProperty("device")) {
                d.device = $root.globals.Device.toObject(m.device, o);
            }
            if (m.appVersion != null && m.hasOwnProperty("appVersion")) {
                d.appVersion = $root.globals.AppVersion.toObject(m.appVersion, o);
            }
            if (m.location != null && m.hasOwnProperty("location")) {
                d.location = $root.globals.Geolocation.toObject(m.location, o);
            }
            if (m.clientIp != null && m.hasOwnProperty("clientIp")) {
                d.clientIp = $root.google.protobuf.StringValue.toObject(m.clientIp, o).value;
            }
            if (m.isAdmin != null && m.hasOwnProperty("isAdmin")) {
                d.isAdmin = $root.google.protobuf.BoolValue.toObject(m.isAdmin, o).value;
            }
            if (m.clientSessionId != null && m.hasOwnProperty("clientSessionId")) {
                d.clientSessionId = $root.google.protobuf.StringValue.toObject(m.clientSessionId, o).value;
            }
            if (m.isSystem != null && m.hasOwnProperty("isSystem")) {
                d.isSystem = $root.google.protobuf.BoolValue.toObject(m.isSystem, o).value;
            }
            if (m.serverRole != null && m.hasOwnProperty("serverRole")) {
                d.serverRole = o.enums === String ? $root.globals.ServerRole[m.serverRole] === undefined ? m.serverRole : $root.globals.ServerRole[m.serverRole] : m.serverRole;
            }
            return d;
        };

        Session.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Session.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.Session";
        };

        return Session;
    })();

    globals.Geolocation = (function() {

        function Geolocation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Geolocation.prototype.latitude = null;
        Geolocation.prototype.longitude = null;
        Geolocation.prototype.timestamp = null;
        Geolocation.prototype.country = 0;
        Geolocation.prototype.subdivision1Name = null;
        Geolocation.prototype.subdivision1Iso = null;
        Geolocation.prototype.subdivision2Name = null;
        Geolocation.prototype.subdivision2Iso = null;
        Geolocation.prototype.cityName = null;
        Geolocation.prototype.timeZone = null;
        Geolocation.prototype.continentCode = null;
        Geolocation.prototype.accuracyRadius = null;

        Geolocation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.latitude != null && Object.hasOwnProperty.call(m, "latitude"))
                $root.google.protobuf.DoubleValue.encode(m.latitude, w.uint32(10).fork()).ldelim();
            if (m.longitude != null && Object.hasOwnProperty.call(m, "longitude"))
                $root.google.protobuf.DoubleValue.encode(m.longitude, w.uint32(18).fork()).ldelim();
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                $root.google.protobuf.UInt64Value.encode(m.timestamp, w.uint32(26).fork()).ldelim();
            if (m.country != null && Object.hasOwnProperty.call(m, "country"))
                w.uint32(32).int32(m.country);
            if (m.subdivision1Name != null && Object.hasOwnProperty.call(m, "subdivision1Name"))
                $root.google.protobuf.StringValue.encode(m.subdivision1Name, w.uint32(42).fork()).ldelim();
            if (m.subdivision1Iso != null && Object.hasOwnProperty.call(m, "subdivision1Iso"))
                $root.google.protobuf.StringValue.encode(m.subdivision1Iso, w.uint32(50).fork()).ldelim();
            if (m.subdivision2Name != null && Object.hasOwnProperty.call(m, "subdivision2Name"))
                $root.google.protobuf.StringValue.encode(m.subdivision2Name, w.uint32(58).fork()).ldelim();
            if (m.subdivision2Iso != null && Object.hasOwnProperty.call(m, "subdivision2Iso"))
                $root.google.protobuf.StringValue.encode(m.subdivision2Iso, w.uint32(66).fork()).ldelim();
            if (m.cityName != null && Object.hasOwnProperty.call(m, "cityName"))
                $root.google.protobuf.StringValue.encode(m.cityName, w.uint32(74).fork()).ldelim();
            if (m.timeZone != null && Object.hasOwnProperty.call(m, "timeZone"))
                $root.google.protobuf.StringValue.encode(m.timeZone, w.uint32(82).fork()).ldelim();
            if (m.continentCode != null && Object.hasOwnProperty.call(m, "continentCode"))
                $root.google.protobuf.StringValue.encode(m.continentCode, w.uint32(90).fork()).ldelim();
            if (m.accuracyRadius != null && Object.hasOwnProperty.call(m, "accuracyRadius"))
                $root.google.protobuf.UInt32Value.encode(m.accuracyRadius, w.uint32(98).fork()).ldelim();
            return w;
        };

        Geolocation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Geolocation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.Geolocation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.latitude = $root.google.protobuf.DoubleValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.longitude = $root.google.protobuf.DoubleValue.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.timestamp = $root.google.protobuf.UInt64Value.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.country = r.int32();
                        break;
                    }
                case 5: {
                        m.subdivision1Name = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        m.subdivision1Iso = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.subdivision2Name = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        m.subdivision2Iso = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 9: {
                        m.cityName = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 10: {
                        m.timeZone = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 11: {
                        m.continentCode = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 12: {
                        m.accuracyRadius = $root.google.protobuf.UInt32Value.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        Geolocation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Geolocation.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.latitude != null && m.hasOwnProperty("latitude")) {
                {
                    var e = $root.google.protobuf.DoubleValue.verify(m.latitude);
                    if (e)
                        return "latitude." + e;
                }
            }
            if (m.longitude != null && m.hasOwnProperty("longitude")) {
                {
                    var e = $root.google.protobuf.DoubleValue.verify(m.longitude);
                    if (e)
                        return "longitude." + e;
                }
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                {
                    var e = $root.google.protobuf.UInt64Value.verify(m.timestamp);
                    if (e)
                        return "timestamp." + e;
                }
            }
            if (m.country != null && m.hasOwnProperty("country")) {
                switch (m.country) {
                default:
                    return "country: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 58:
                case 59:
                case 60:
                case 61:
                case 62:
                case 63:
                case 64:
                case 65:
                case 66:
                case 67:
                case 68:
                case 69:
                case 70:
                case 71:
                case 72:
                case 73:
                case 74:
                case 75:
                case 76:
                case 77:
                case 78:
                case 79:
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 89:
                case 90:
                case 91:
                case 92:
                case 93:
                case 94:
                case 95:
                case 96:
                case 97:
                case 98:
                case 99:
                case 100:
                case 101:
                case 102:
                case 103:
                case 104:
                case 105:
                case 106:
                case 107:
                case 108:
                case 109:
                case 110:
                case 111:
                case 112:
                case 113:
                case 114:
                case 115:
                case 116:
                case 117:
                case 118:
                case 119:
                case 120:
                case 121:
                case 122:
                case 123:
                case 124:
                case 125:
                case 126:
                case 127:
                case 128:
                case 129:
                case 130:
                case 131:
                case 132:
                case 133:
                case 134:
                case 135:
                case 136:
                case 137:
                case 138:
                case 139:
                case 140:
                case 141:
                case 142:
                case 143:
                case 144:
                case 145:
                case 146:
                case 147:
                case 148:
                case 149:
                case 150:
                case 151:
                case 152:
                case 153:
                case 154:
                case 155:
                case 156:
                case 157:
                case 158:
                case 159:
                case 160:
                case 161:
                case 162:
                case 163:
                case 164:
                case 165:
                case 166:
                case 167:
                case 168:
                case 169:
                case 170:
                case 171:
                case 172:
                case 173:
                case 174:
                case 175:
                case 176:
                case 177:
                case 178:
                case 179:
                case 180:
                case 181:
                case 182:
                case 183:
                case 184:
                case 185:
                case 186:
                case 187:
                case 188:
                case 189:
                case 190:
                case 191:
                case 192:
                case 193:
                case 194:
                case 195:
                case 196:
                case 197:
                case 198:
                case 199:
                case 200:
                case 201:
                case 202:
                case 203:
                case 204:
                case 205:
                case 206:
                case 207:
                case 208:
                case 209:
                case 210:
                case 211:
                case 212:
                case 213:
                case 214:
                case 215:
                case 216:
                case 217:
                case 218:
                case 219:
                case 220:
                case 221:
                case 222:
                case 223:
                case 224:
                case 225:
                case 226:
                case 227:
                case 228:
                case 229:
                case 230:
                case 231:
                case 232:
                case 233:
                case 234:
                case 235:
                case 236:
                case 237:
                case 238:
                case 239:
                case 240:
                case 241:
                case 242:
                case 243:
                case 244:
                case 245:
                case 246:
                case 247:
                case 248:
                case 249:
                case 250:
                case 251:
                case 252:
                case 253:
                case 254:
                case 255:
                    break;
                }
            }
            if (m.subdivision1Name != null && m.hasOwnProperty("subdivision1Name")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.subdivision1Name);
                    if (e)
                        return "subdivision1Name." + e;
                }
            }
            if (m.subdivision1Iso != null && m.hasOwnProperty("subdivision1Iso")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.subdivision1Iso);
                    if (e)
                        return "subdivision1Iso." + e;
                }
            }
            if (m.subdivision2Name != null && m.hasOwnProperty("subdivision2Name")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.subdivision2Name);
                    if (e)
                        return "subdivision2Name." + e;
                }
            }
            if (m.subdivision2Iso != null && m.hasOwnProperty("subdivision2Iso")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.subdivision2Iso);
                    if (e)
                        return "subdivision2Iso." + e;
                }
            }
            if (m.cityName != null && m.hasOwnProperty("cityName")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.cityName);
                    if (e)
                        return "cityName." + e;
                }
            }
            if (m.timeZone != null && m.hasOwnProperty("timeZone")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.timeZone);
                    if (e)
                        return "timeZone." + e;
                }
            }
            if (m.continentCode != null && m.hasOwnProperty("continentCode")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.continentCode);
                    if (e)
                        return "continentCode." + e;
                }
            }
            if (m.accuracyRadius != null && m.hasOwnProperty("accuracyRadius")) {
                {
                    var e = $root.google.protobuf.UInt32Value.verify(m.accuracyRadius);
                    if (e)
                        return "accuracyRadius." + e;
                }
            }
            return null;
        };

        Geolocation.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.Geolocation)
                return d;
            var m = new $root.globals.Geolocation();
            if (d.latitude != null) {
                if (typeof d.latitude === "object" && d.latitude !== null && d.latitude.value !== undefined)
                    m.latitude = $root.google.protobuf.DoubleValue.fromObject(d.latitude);
                else
                    m.latitude = $root.google.protobuf.DoubleValue.fromObject({ value: d.latitude });
            }
            if (d.longitude != null) {
                if (typeof d.longitude === "object" && d.longitude !== null && d.longitude.value !== undefined)
                    m.longitude = $root.google.protobuf.DoubleValue.fromObject(d.longitude);
                else
                    m.longitude = $root.google.protobuf.DoubleValue.fromObject({ value: d.longitude });
            }
            if (d.timestamp != null) {
                if (typeof d.timestamp === "object" && d.timestamp !== null && d.timestamp.value !== undefined)
                    m.timestamp = $root.google.protobuf.UInt64Value.fromObject(d.timestamp);
                else
                    m.timestamp = $root.google.protobuf.UInt64Value.fromObject({ value: d.timestamp });
            }
            switch (d.country) {
            default:
                if (typeof d.country === "number") {
                    m.country = d.country;
                    break;
                }
                break;
            case "UNKNOWN_COUNTRY":
            case 0:
                m.country = 0;
                break;
            case "AF":
            case 1:
                m.country = 1;
                break;
            case "AL":
            case 2:
                m.country = 2;
                break;
            case "AQ":
            case 3:
                m.country = 3;
                break;
            case "DZ":
            case 4:
                m.country = 4;
                break;
            case "AS":
            case 5:
                m.country = 5;
                break;
            case "AD":
            case 6:
                m.country = 6;
                break;
            case "AO":
            case 7:
                m.country = 7;
                break;
            case "AG":
            case 8:
                m.country = 8;
                break;
            case "AZ":
            case 9:
                m.country = 9;
                break;
            case "AR":
            case 10:
                m.country = 10;
                break;
            case "AU":
            case 11:
                m.country = 11;
                break;
            case "AT":
            case 12:
                m.country = 12;
                break;
            case "BS":
            case 13:
                m.country = 13;
                break;
            case "BH":
            case 14:
                m.country = 14;
                break;
            case "BD":
            case 15:
                m.country = 15;
                break;
            case "AM":
            case 16:
                m.country = 16;
                break;
            case "BB":
            case 17:
                m.country = 17;
                break;
            case "BE":
            case 18:
                m.country = 18;
                break;
            case "BM":
            case 19:
                m.country = 19;
                break;
            case "BT":
            case 20:
                m.country = 20;
                break;
            case "BO":
            case 21:
                m.country = 21;
                break;
            case "BA":
            case 22:
                m.country = 22;
                break;
            case "BW":
            case 23:
                m.country = 23;
                break;
            case "BV":
            case 24:
                m.country = 24;
                break;
            case "BR":
            case 25:
                m.country = 25;
                break;
            case "BZ":
            case 26:
                m.country = 26;
                break;
            case "IO":
            case 27:
                m.country = 27;
                break;
            case "SB":
            case 28:
                m.country = 28;
                break;
            case "VG":
            case 29:
                m.country = 29;
                break;
            case "BN":
            case 30:
                m.country = 30;
                break;
            case "BG":
            case 31:
                m.country = 31;
                break;
            case "MM":
            case 32:
                m.country = 32;
                break;
            case "BI":
            case 33:
                m.country = 33;
                break;
            case "BY":
            case 34:
                m.country = 34;
                break;
            case "KH":
            case 35:
                m.country = 35;
                break;
            case "CM":
            case 36:
                m.country = 36;
                break;
            case "CA":
            case 37:
                m.country = 37;
                break;
            case "CV":
            case 38:
                m.country = 38;
                break;
            case "KY":
            case 39:
                m.country = 39;
                break;
            case "CF":
            case 40:
                m.country = 40;
                break;
            case "LK":
            case 41:
                m.country = 41;
                break;
            case "TD":
            case 42:
                m.country = 42;
                break;
            case "CL":
            case 43:
                m.country = 43;
                break;
            case "CN":
            case 44:
                m.country = 44;
                break;
            case "TW":
            case 45:
                m.country = 45;
                break;
            case "CX":
            case 46:
                m.country = 46;
                break;
            case "CC":
            case 47:
                m.country = 47;
                break;
            case "CO":
            case 48:
                m.country = 48;
                break;
            case "KM":
            case 49:
                m.country = 49;
                break;
            case "YT":
            case 50:
                m.country = 50;
                break;
            case "CG":
            case 51:
                m.country = 51;
                break;
            case "CD":
            case 52:
                m.country = 52;
                break;
            case "CK":
            case 53:
                m.country = 53;
                break;
            case "CR":
            case 54:
                m.country = 54;
                break;
            case "HR":
            case 55:
                m.country = 55;
                break;
            case "CU":
            case 56:
                m.country = 56;
                break;
            case "CY":
            case 57:
                m.country = 57;
                break;
            case "CZ":
            case 58:
                m.country = 58;
                break;
            case "BJ":
            case 59:
                m.country = 59;
                break;
            case "DK":
            case 60:
                m.country = 60;
                break;
            case "DM":
            case 61:
                m.country = 61;
                break;
            case "DO":
            case 62:
                m.country = 62;
                break;
            case "EC":
            case 63:
                m.country = 63;
                break;
            case "SV":
            case 64:
                m.country = 64;
                break;
            case "GQ":
            case 65:
                m.country = 65;
                break;
            case "ET":
            case 66:
                m.country = 66;
                break;
            case "ER":
            case 67:
                m.country = 67;
                break;
            case "EE":
            case 68:
                m.country = 68;
                break;
            case "FO":
            case 69:
                m.country = 69;
                break;
            case "FK":
            case 70:
                m.country = 70;
                break;
            case "GS":
            case 71:
                m.country = 71;
                break;
            case "FJ":
            case 72:
                m.country = 72;
                break;
            case "FI":
            case 73:
                m.country = 73;
                break;
            case "AX":
            case 74:
                m.country = 74;
                break;
            case "FR":
            case 75:
                m.country = 75;
                break;
            case "GF":
            case 76:
                m.country = 76;
                break;
            case "PF":
            case 77:
                m.country = 77;
                break;
            case "TF":
            case 78:
                m.country = 78;
                break;
            case "DJ":
            case 79:
                m.country = 79;
                break;
            case "GA":
            case 80:
                m.country = 80;
                break;
            case "GE":
            case 81:
                m.country = 81;
                break;
            case "GM":
            case 82:
                m.country = 82;
                break;
            case "PS":
            case 83:
                m.country = 83;
                break;
            case "DE":
            case 84:
                m.country = 84;
                break;
            case "GH":
            case 85:
                m.country = 85;
                break;
            case "GI":
            case 86:
                m.country = 86;
                break;
            case "KI":
            case 87:
                m.country = 87;
                break;
            case "GR":
            case 88:
                m.country = 88;
                break;
            case "GL":
            case 89:
                m.country = 89;
                break;
            case "GD":
            case 90:
                m.country = 90;
                break;
            case "GP":
            case 91:
                m.country = 91;
                break;
            case "GU":
            case 92:
                m.country = 92;
                break;
            case "GT":
            case 93:
                m.country = 93;
                break;
            case "GN":
            case 94:
                m.country = 94;
                break;
            case "GY":
            case 95:
                m.country = 95;
                break;
            case "HT":
            case 96:
                m.country = 96;
                break;
            case "HM":
            case 97:
                m.country = 97;
                break;
            case "VA":
            case 98:
                m.country = 98;
                break;
            case "HN":
            case 99:
                m.country = 99;
                break;
            case "HK":
            case 100:
                m.country = 100;
                break;
            case "HU":
            case 101:
                m.country = 101;
                break;
            case "IS":
            case 102:
                m.country = 102;
                break;
            case "IN":
            case 103:
                m.country = 103;
                break;
            case "ID":
            case 104:
                m.country = 104;
                break;
            case "IR":
            case 105:
                m.country = 105;
                break;
            case "IQ":
            case 106:
                m.country = 106;
                break;
            case "IE":
            case 107:
                m.country = 107;
                break;
            case "IL":
            case 108:
                m.country = 108;
                break;
            case "IT":
            case 109:
                m.country = 109;
                break;
            case "CI":
            case 110:
                m.country = 110;
                break;
            case "JM":
            case 111:
                m.country = 111;
                break;
            case "JP":
            case 112:
                m.country = 112;
                break;
            case "KZ":
            case 113:
                m.country = 113;
                break;
            case "JO":
            case 114:
                m.country = 114;
                break;
            case "KE":
            case 115:
                m.country = 115;
                break;
            case "KP":
            case 116:
                m.country = 116;
                break;
            case "KR":
            case 117:
                m.country = 117;
                break;
            case "KW":
            case 118:
                m.country = 118;
                break;
            case "KG":
            case 119:
                m.country = 119;
                break;
            case "LA":
            case 120:
                m.country = 120;
                break;
            case "LB":
            case 121:
                m.country = 121;
                break;
            case "LS":
            case 122:
                m.country = 122;
                break;
            case "LV":
            case 123:
                m.country = 123;
                break;
            case "LR":
            case 124:
                m.country = 124;
                break;
            case "LY":
            case 125:
                m.country = 125;
                break;
            case "LI":
            case 126:
                m.country = 126;
                break;
            case "LT":
            case 127:
                m.country = 127;
                break;
            case "LU":
            case 128:
                m.country = 128;
                break;
            case "MO":
            case 129:
                m.country = 129;
                break;
            case "MG":
            case 130:
                m.country = 130;
                break;
            case "MW":
            case 131:
                m.country = 131;
                break;
            case "MY":
            case 132:
                m.country = 132;
                break;
            case "MV":
            case 133:
                m.country = 133;
                break;
            case "ML":
            case 134:
                m.country = 134;
                break;
            case "MT":
            case 135:
                m.country = 135;
                break;
            case "MQ":
            case 136:
                m.country = 136;
                break;
            case "MR":
            case 137:
                m.country = 137;
                break;
            case "MU":
            case 138:
                m.country = 138;
                break;
            case "MX":
            case 139:
                m.country = 139;
                break;
            case "MC":
            case 140:
                m.country = 140;
                break;
            case "MN":
            case 141:
                m.country = 141;
                break;
            case "MD":
            case 142:
                m.country = 142;
                break;
            case "ME":
            case 143:
                m.country = 143;
                break;
            case "MS":
            case 144:
                m.country = 144;
                break;
            case "MA":
            case 145:
                m.country = 145;
                break;
            case "MZ":
            case 146:
                m.country = 146;
                break;
            case "OM":
            case 147:
                m.country = 147;
                break;
            case "NA":
            case 148:
                m.country = 148;
                break;
            case "NR":
            case 149:
                m.country = 149;
                break;
            case "NP":
            case 150:
                m.country = 150;
                break;
            case "NL":
            case 151:
                m.country = 151;
                break;
            case "CW":
            case 152:
                m.country = 152;
                break;
            case "AW":
            case 153:
                m.country = 153;
                break;
            case "SX":
            case 154:
                m.country = 154;
                break;
            case "BQ":
            case 155:
                m.country = 155;
                break;
            case "NC":
            case 156:
                m.country = 156;
                break;
            case "VU":
            case 157:
                m.country = 157;
                break;
            case "NZ":
            case 158:
                m.country = 158;
                break;
            case "NI":
            case 159:
                m.country = 159;
                break;
            case "NE":
            case 160:
                m.country = 160;
                break;
            case "NG":
            case 161:
                m.country = 161;
                break;
            case "NU":
            case 162:
                m.country = 162;
                break;
            case "NF":
            case 163:
                m.country = 163;
                break;
            case "NO":
            case 164:
                m.country = 164;
                break;
            case "MP":
            case 165:
                m.country = 165;
                break;
            case "UM":
            case 166:
                m.country = 166;
                break;
            case "FM":
            case 167:
                m.country = 167;
                break;
            case "MH":
            case 168:
                m.country = 168;
                break;
            case "PW":
            case 169:
                m.country = 169;
                break;
            case "PK":
            case 170:
                m.country = 170;
                break;
            case "PA":
            case 171:
                m.country = 171;
                break;
            case "PG":
            case 172:
                m.country = 172;
                break;
            case "PY":
            case 173:
                m.country = 173;
                break;
            case "PE":
            case 174:
                m.country = 174;
                break;
            case "PH":
            case 175:
                m.country = 175;
                break;
            case "PN":
            case 176:
                m.country = 176;
                break;
            case "PL":
            case 177:
                m.country = 177;
                break;
            case "PT":
            case 178:
                m.country = 178;
                break;
            case "GW":
            case 179:
                m.country = 179;
                break;
            case "TL":
            case 180:
                m.country = 180;
                break;
            case "PR":
            case 181:
                m.country = 181;
                break;
            case "QA":
            case 182:
                m.country = 182;
                break;
            case "RE":
            case 183:
                m.country = 183;
                break;
            case "RO":
            case 184:
                m.country = 184;
                break;
            case "RU":
            case 185:
                m.country = 185;
                break;
            case "RW":
            case 186:
                m.country = 186;
                break;
            case "BL":
            case 187:
                m.country = 187;
                break;
            case "SH":
            case 188:
                m.country = 188;
                break;
            case "KN":
            case 189:
                m.country = 189;
                break;
            case "AI":
            case 190:
                m.country = 190;
                break;
            case "LC":
            case 191:
                m.country = 191;
                break;
            case "MF":
            case 192:
                m.country = 192;
                break;
            case "PM":
            case 193:
                m.country = 193;
                break;
            case "VC":
            case 194:
                m.country = 194;
                break;
            case "SM":
            case 195:
                m.country = 195;
                break;
            case "ST":
            case 196:
                m.country = 196;
                break;
            case "SA":
            case 197:
                m.country = 197;
                break;
            case "SN":
            case 198:
                m.country = 198;
                break;
            case "RS":
            case 199:
                m.country = 199;
                break;
            case "SC":
            case 200:
                m.country = 200;
                break;
            case "SL":
            case 201:
                m.country = 201;
                break;
            case "SG":
            case 202:
                m.country = 202;
                break;
            case "SK":
            case 203:
                m.country = 203;
                break;
            case "VN":
            case 204:
                m.country = 204;
                break;
            case "SI":
            case 205:
                m.country = 205;
                break;
            case "SO":
            case 206:
                m.country = 206;
                break;
            case "ZA":
            case 207:
                m.country = 207;
                break;
            case "ZW":
            case 208:
                m.country = 208;
                break;
            case "ES":
            case 209:
                m.country = 209;
                break;
            case "SS":
            case 210:
                m.country = 210;
                break;
            case "SD":
            case 211:
                m.country = 211;
                break;
            case "EH":
            case 212:
                m.country = 212;
                break;
            case "SR":
            case 213:
                m.country = 213;
                break;
            case "SJ":
            case 214:
                m.country = 214;
                break;
            case "SZ":
            case 215:
                m.country = 215;
                break;
            case "SE":
            case 216:
                m.country = 216;
                break;
            case "CH":
            case 217:
                m.country = 217;
                break;
            case "SY":
            case 218:
                m.country = 218;
                break;
            case "TJ":
            case 219:
                m.country = 219;
                break;
            case "TH":
            case 220:
                m.country = 220;
                break;
            case "TG":
            case 221:
                m.country = 221;
                break;
            case "TK":
            case 222:
                m.country = 222;
                break;
            case "TO":
            case 223:
                m.country = 223;
                break;
            case "TT":
            case 224:
                m.country = 224;
                break;
            case "AE":
            case 225:
                m.country = 225;
                break;
            case "TN":
            case 226:
                m.country = 226;
                break;
            case "TR":
            case 227:
                m.country = 227;
                break;
            case "TM":
            case 228:
                m.country = 228;
                break;
            case "TC":
            case 229:
                m.country = 229;
                break;
            case "TV":
            case 230:
                m.country = 230;
                break;
            case "UG":
            case 231:
                m.country = 231;
                break;
            case "UA":
            case 232:
                m.country = 232;
                break;
            case "MK":
            case 233:
                m.country = 233;
                break;
            case "EG":
            case 234:
                m.country = 234;
                break;
            case "GB":
            case 235:
                m.country = 235;
                break;
            case "GG":
            case 236:
                m.country = 236;
                break;
            case "JE":
            case 237:
                m.country = 237;
                break;
            case "IM":
            case 238:
                m.country = 238;
                break;
            case "TZ":
            case 239:
                m.country = 239;
                break;
            case "US":
            case 240:
                m.country = 240;
                break;
            case "VI":
            case 241:
                m.country = 241;
                break;
            case "BF":
            case 242:
                m.country = 242;
                break;
            case "UY":
            case 243:
                m.country = 243;
                break;
            case "UZ":
            case 244:
                m.country = 244;
                break;
            case "VE":
            case 245:
                m.country = 245;
                break;
            case "WF":
            case 246:
                m.country = 246;
                break;
            case "WS":
            case 247:
                m.country = 247;
                break;
            case "YE":
            case 248:
                m.country = 248;
                break;
            case "ZM":
            case 249:
                m.country = 249;
                break;
            case "A1":
            case 250:
                m.country = 250;
                break;
            case "A2":
            case 251:
                m.country = 251;
                break;
            case "O1":
            case 252:
                m.country = 252;
                break;
            case "AP":
            case 253:
                m.country = 253;
                break;
            case "EU":
            case 254:
                m.country = 254;
                break;
            case "PRIVATE":
            case 255:
                m.country = 255;
                break;
            }
            if (d.subdivision1Name != null) {
                if (typeof d.subdivision1Name === "object" && d.subdivision1Name !== null && d.subdivision1Name.value !== undefined)
                    m.subdivision1Name = $root.google.protobuf.StringValue.fromObject(d.subdivision1Name);
                else
                    m.subdivision1Name = $root.google.protobuf.StringValue.fromObject({ value: d.subdivision1Name });
            }
            if (d.subdivision1Iso != null) {
                if (typeof d.subdivision1Iso === "object" && d.subdivision1Iso !== null && d.subdivision1Iso.value !== undefined)
                    m.subdivision1Iso = $root.google.protobuf.StringValue.fromObject(d.subdivision1Iso);
                else
                    m.subdivision1Iso = $root.google.protobuf.StringValue.fromObject({ value: d.subdivision1Iso });
            }
            if (d.subdivision2Name != null) {
                if (typeof d.subdivision2Name === "object" && d.subdivision2Name !== null && d.subdivision2Name.value !== undefined)
                    m.subdivision2Name = $root.google.protobuf.StringValue.fromObject(d.subdivision2Name);
                else
                    m.subdivision2Name = $root.google.protobuf.StringValue.fromObject({ value: d.subdivision2Name });
            }
            if (d.subdivision2Iso != null) {
                if (typeof d.subdivision2Iso === "object" && d.subdivision2Iso !== null && d.subdivision2Iso.value !== undefined)
                    m.subdivision2Iso = $root.google.protobuf.StringValue.fromObject(d.subdivision2Iso);
                else
                    m.subdivision2Iso = $root.google.protobuf.StringValue.fromObject({ value: d.subdivision2Iso });
            }
            if (d.cityName != null) {
                if (typeof d.cityName === "object" && d.cityName !== null && d.cityName.value !== undefined)
                    m.cityName = $root.google.protobuf.StringValue.fromObject(d.cityName);
                else
                    m.cityName = $root.google.protobuf.StringValue.fromObject({ value: d.cityName });
            }
            if (d.timeZone != null) {
                if (typeof d.timeZone === "object" && d.timeZone !== null && d.timeZone.value !== undefined)
                    m.timeZone = $root.google.protobuf.StringValue.fromObject(d.timeZone);
                else
                    m.timeZone = $root.google.protobuf.StringValue.fromObject({ value: d.timeZone });
            }
            if (d.continentCode != null) {
                if (typeof d.continentCode === "object" && d.continentCode !== null && d.continentCode.value !== undefined)
                    m.continentCode = $root.google.protobuf.StringValue.fromObject(d.continentCode);
                else
                    m.continentCode = $root.google.protobuf.StringValue.fromObject({ value: d.continentCode });
            }
            if (d.accuracyRadius != null) {
                if (typeof d.accuracyRadius === "object" && d.accuracyRadius !== null && d.accuracyRadius.value !== undefined)
                    m.accuracyRadius = $root.google.protobuf.UInt32Value.fromObject(d.accuracyRadius);
                else
                    m.accuracyRadius = $root.google.protobuf.UInt32Value.fromObject({ value: d.accuracyRadius });
            }
            return m;
        };

        Geolocation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.latitude = null;
                d.longitude = null;
                d.timestamp = null;
                d.country = o.enums === String ? "UNKNOWN_COUNTRY" : 0;
                d.subdivision1Name = null;
                d.subdivision1Iso = null;
                d.subdivision2Name = null;
                d.subdivision2Iso = null;
                d.cityName = null;
                d.timeZone = null;
                d.continentCode = null;
                d.accuracyRadius = null;
            }
            if (m.latitude != null && m.hasOwnProperty("latitude")) {
                d.latitude = $root.google.protobuf.DoubleValue.toObject(m.latitude, o).value;
            }
            if (m.longitude != null && m.hasOwnProperty("longitude")) {
                d.longitude = $root.google.protobuf.DoubleValue.toObject(m.longitude, o).value;
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                d.timestamp = $root.google.protobuf.UInt64Value.toObject(m.timestamp, o).value;
            }
            if (m.country != null && m.hasOwnProperty("country")) {
                d.country = o.enums === String ? $root.globals.CountryCodeType[m.country] === undefined ? m.country : $root.globals.CountryCodeType[m.country] : m.country;
            }
            if (m.subdivision1Name != null && m.hasOwnProperty("subdivision1Name")) {
                d.subdivision1Name = $root.google.protobuf.StringValue.toObject(m.subdivision1Name, o).value;
            }
            if (m.subdivision1Iso != null && m.hasOwnProperty("subdivision1Iso")) {
                d.subdivision1Iso = $root.google.protobuf.StringValue.toObject(m.subdivision1Iso, o).value;
            }
            if (m.subdivision2Name != null && m.hasOwnProperty("subdivision2Name")) {
                d.subdivision2Name = $root.google.protobuf.StringValue.toObject(m.subdivision2Name, o).value;
            }
            if (m.subdivision2Iso != null && m.hasOwnProperty("subdivision2Iso")) {
                d.subdivision2Iso = $root.google.protobuf.StringValue.toObject(m.subdivision2Iso, o).value;
            }
            if (m.cityName != null && m.hasOwnProperty("cityName")) {
                d.cityName = $root.google.protobuf.StringValue.toObject(m.cityName, o).value;
            }
            if (m.timeZone != null && m.hasOwnProperty("timeZone")) {
                d.timeZone = $root.google.protobuf.StringValue.toObject(m.timeZone, o).value;
            }
            if (m.continentCode != null && m.hasOwnProperty("continentCode")) {
                d.continentCode = $root.google.protobuf.StringValue.toObject(m.continentCode, o).value;
            }
            if (m.accuracyRadius != null && m.hasOwnProperty("accuracyRadius")) {
                d.accuracyRadius = $root.google.protobuf.UInt32Value.toObject(m.accuracyRadius, o).value;
            }
            return d;
        };

        Geolocation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Geolocation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.Geolocation";
        };

        return Geolocation;
    })();

    globals.ApptimizeClientSession = (function() {

        function ApptimizeClientSession(p) {
            this.groups = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        ApptimizeClientSession.prototype.userId = null;
        ApptimizeClientSession.prototype.groups = $util.emptyArray;

        ApptimizeClientSession.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                $root.google.protobuf.StringValue.encode(m.userId, w.uint32(10).fork()).ldelim();
            if (m.groups != null && m.groups.length) {
                for (var i = 0; i < m.groups.length; ++i)
                    w.uint32(18).string(m.groups[i]);
            }
            return w;
        };

        ApptimizeClientSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ApptimizeClientSession.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.ApptimizeClientSession();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.userId = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        if (!(m.groups && m.groups.length))
                            m.groups = [];
                        m.groups.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        ApptimizeClientSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ApptimizeClientSession.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.userId != null && m.hasOwnProperty("userId")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.userId);
                    if (e)
                        return "userId." + e;
                }
            }
            if (m.groups != null && m.hasOwnProperty("groups")) {
                if (!Array.isArray(m.groups))
                    return "groups: array expected";
                for (var i = 0; i < m.groups.length; ++i) {
                    if (!$util.isString(m.groups[i]))
                        return "groups: string[] expected";
                }
            }
            return null;
        };

        ApptimizeClientSession.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.ApptimizeClientSession)
                return d;
            var m = new $root.globals.ApptimizeClientSession();
            if (d.userId != null) {
                if (typeof d.userId === "object" && d.userId !== null && d.userId.value !== undefined)
                    m.userId = $root.google.protobuf.StringValue.fromObject(d.userId);
                else
                    m.userId = $root.google.protobuf.StringValue.fromObject({ value: d.userId });
            }
            if (d.groups) {
                if (!Array.isArray(d.groups))
                    throw TypeError(".globals.ApptimizeClientSession.groups: array expected");
                m.groups = [];
                for (var i = 0; i < d.groups.length; ++i) {
                    m.groups[i] = String(d.groups[i]);
                }
            }
            return m;
        };

        ApptimizeClientSession.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.groups = [];
            }
            if (o.defaults) {
                d.userId = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                d.userId = $root.google.protobuf.StringValue.toObject(m.userId, o).value;
            }
            if (m.groups && m.groups.length) {
                d.groups = [];
                for (var j = 0; j < m.groups.length; ++j) {
                    d.groups[j] = m.groups[j];
                }
            }
            return d;
        };

        ApptimizeClientSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ApptimizeClientSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.ApptimizeClientSession";
        };

        return ApptimizeClientSession;
    })();

    globals.BranchClientSession = (function() {

        function BranchClientSession(p) {
            this.params = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        BranchClientSession.prototype.identityId = null;
        BranchClientSession.prototype.params = $util.emptyObject;

        BranchClientSession.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.identityId != null && Object.hasOwnProperty.call(m, "identityId"))
                $root.google.protobuf.StringValue.encode(m.identityId, w.uint32(10).fork()).ldelim();
            if (m.params != null && Object.hasOwnProperty.call(m, "params")) {
                for (var ks = Object.keys(m.params), i = 0; i < ks.length; ++i) {
                    w.uint32(18).fork().uint32(10).string(ks[i]).uint32(18).string(m.params[ks[i]]).ldelim();
                }
            }
            return w;
        };

        BranchClientSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        BranchClientSession.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.BranchClientSession(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.identityId = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        if (m.params === $util.emptyObject)
                            m.params = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.params[k] = value;
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        BranchClientSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        BranchClientSession.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.identityId != null && m.hasOwnProperty("identityId")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.identityId);
                    if (e)
                        return "identityId." + e;
                }
            }
            if (m.params != null && m.hasOwnProperty("params")) {
                if (!$util.isObject(m.params))
                    return "params: object expected";
                var k = Object.keys(m.params);
                for (var i = 0; i < k.length; ++i) {
                    if (!$util.isString(m.params[k[i]]))
                        return "params: string{k:string} expected";
                }
            }
            return null;
        };

        BranchClientSession.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.BranchClientSession)
                return d;
            var m = new $root.globals.BranchClientSession();
            if (d.identityId != null) {
                if (typeof d.identityId === "object" && d.identityId !== null && d.identityId.value !== undefined)
                    m.identityId = $root.google.protobuf.StringValue.fromObject(d.identityId);
                else
                    m.identityId = $root.google.protobuf.StringValue.fromObject({ value: d.identityId });
            }
            if (d.params) {
                if (typeof d.params !== "object")
                    throw TypeError(".globals.BranchClientSession.params: object expected");
                m.params = {};
                for (var ks = Object.keys(d.params), i = 0; i < ks.length; ++i) {
                    m.params[ks[i]] = String(d.params[ks[i]]);
                }
            }
            return m;
        };

        BranchClientSession.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.objects || o.defaults) {
                d.params = {};
            }
            if (o.defaults) {
                d.identityId = null;
            }
            if (m.identityId != null && m.hasOwnProperty("identityId")) {
                d.identityId = $root.google.protobuf.StringValue.toObject(m.identityId, o).value;
            }
            var ks2;
            if (m.params && (ks2 = Object.keys(m.params)).length) {
                d.params = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.params[ks2[j]] = m.params[ks2[j]];
                }
            }
            return d;
        };

        BranchClientSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        BranchClientSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.BranchClientSession";
        };

        return BranchClientSession;
    })();

    globals.TrackingParams = (function() {

        function TrackingParams(p) {
            this.utmCustomParams = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrackingParams.prototype.utmSource = null;
        TrackingParams.prototype.utmMedium = null;
        TrackingParams.prototype.utmCampaign = null;
        TrackingParams.prototype.utmTerm = null;
        TrackingParams.prototype.utmContent = null;
        TrackingParams.prototype.utmCustomParams = $util.emptyObject;

        TrackingParams.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.utmSource != null && Object.hasOwnProperty.call(m, "utmSource"))
                $root.google.protobuf.StringValue.encode(m.utmSource, w.uint32(10).fork()).ldelim();
            if (m.utmMedium != null && Object.hasOwnProperty.call(m, "utmMedium"))
                $root.google.protobuf.StringValue.encode(m.utmMedium, w.uint32(18).fork()).ldelim();
            if (m.utmCampaign != null && Object.hasOwnProperty.call(m, "utmCampaign"))
                $root.google.protobuf.StringValue.encode(m.utmCampaign, w.uint32(26).fork()).ldelim();
            if (m.utmTerm != null && Object.hasOwnProperty.call(m, "utmTerm"))
                $root.google.protobuf.StringValue.encode(m.utmTerm, w.uint32(34).fork()).ldelim();
            if (m.utmContent != null && Object.hasOwnProperty.call(m, "utmContent"))
                $root.google.protobuf.StringValue.encode(m.utmContent, w.uint32(42).fork()).ldelim();
            if (m.utmCustomParams != null && Object.hasOwnProperty.call(m, "utmCustomParams")) {
                for (var ks = Object.keys(m.utmCustomParams), i = 0; i < ks.length; ++i) {
                    w.uint32(50).fork().uint32(10).string(ks[i]).uint32(18).string(m.utmCustomParams[ks[i]]).ldelim();
                }
            }
            return w;
        };

        TrackingParams.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        TrackingParams.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.TrackingParams(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.utmSource = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.utmMedium = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.utmCampaign = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.utmTerm = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        m.utmContent = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 6: {
                        if (m.utmCustomParams === $util.emptyObject)
                            m.utmCustomParams = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.utmCustomParams[k] = value;
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        TrackingParams.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        TrackingParams.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.utmSource != null && m.hasOwnProperty("utmSource")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.utmSource);
                    if (e)
                        return "utmSource." + e;
                }
            }
            if (m.utmMedium != null && m.hasOwnProperty("utmMedium")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.utmMedium);
                    if (e)
                        return "utmMedium." + e;
                }
            }
            if (m.utmCampaign != null && m.hasOwnProperty("utmCampaign")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.utmCampaign);
                    if (e)
                        return "utmCampaign." + e;
                }
            }
            if (m.utmTerm != null && m.hasOwnProperty("utmTerm")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.utmTerm);
                    if (e)
                        return "utmTerm." + e;
                }
            }
            if (m.utmContent != null && m.hasOwnProperty("utmContent")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.utmContent);
                    if (e)
                        return "utmContent." + e;
                }
            }
            if (m.utmCustomParams != null && m.hasOwnProperty("utmCustomParams")) {
                if (!$util.isObject(m.utmCustomParams))
                    return "utmCustomParams: object expected";
                var k = Object.keys(m.utmCustomParams);
                for (var i = 0; i < k.length; ++i) {
                    if (!$util.isString(m.utmCustomParams[k[i]]))
                        return "utmCustomParams: string{k:string} expected";
                }
            }
            return null;
        };

        TrackingParams.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.TrackingParams)
                return d;
            var m = new $root.globals.TrackingParams();
            if (d.utmSource != null) {
                if (typeof d.utmSource === "object" && d.utmSource !== null && d.utmSource.value !== undefined)
                    m.utmSource = $root.google.protobuf.StringValue.fromObject(d.utmSource);
                else
                    m.utmSource = $root.google.protobuf.StringValue.fromObject({ value: d.utmSource });
            }
            if (d.utmMedium != null) {
                if (typeof d.utmMedium === "object" && d.utmMedium !== null && d.utmMedium.value !== undefined)
                    m.utmMedium = $root.google.protobuf.StringValue.fromObject(d.utmMedium);
                else
                    m.utmMedium = $root.google.protobuf.StringValue.fromObject({ value: d.utmMedium });
            }
            if (d.utmCampaign != null) {
                if (typeof d.utmCampaign === "object" && d.utmCampaign !== null && d.utmCampaign.value !== undefined)
                    m.utmCampaign = $root.google.protobuf.StringValue.fromObject(d.utmCampaign);
                else
                    m.utmCampaign = $root.google.protobuf.StringValue.fromObject({ value: d.utmCampaign });
            }
            if (d.utmTerm != null) {
                if (typeof d.utmTerm === "object" && d.utmTerm !== null && d.utmTerm.value !== undefined)
                    m.utmTerm = $root.google.protobuf.StringValue.fromObject(d.utmTerm);
                else
                    m.utmTerm = $root.google.protobuf.StringValue.fromObject({ value: d.utmTerm });
            }
            if (d.utmContent != null) {
                if (typeof d.utmContent === "object" && d.utmContent !== null && d.utmContent.value !== undefined)
                    m.utmContent = $root.google.protobuf.StringValue.fromObject(d.utmContent);
                else
                    m.utmContent = $root.google.protobuf.StringValue.fromObject({ value: d.utmContent });
            }
            if (d.utmCustomParams) {
                if (typeof d.utmCustomParams !== "object")
                    throw TypeError(".globals.TrackingParams.utmCustomParams: object expected");
                m.utmCustomParams = {};
                for (var ks = Object.keys(d.utmCustomParams), i = 0; i < ks.length; ++i) {
                    m.utmCustomParams[ks[i]] = String(d.utmCustomParams[ks[i]]);
                }
            }
            return m;
        };

        TrackingParams.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.objects || o.defaults) {
                d.utmCustomParams = {};
            }
            if (o.defaults) {
                d.utmSource = null;
                d.utmMedium = null;
                d.utmCampaign = null;
                d.utmTerm = null;
                d.utmContent = null;
            }
            if (m.utmSource != null && m.hasOwnProperty("utmSource")) {
                d.utmSource = $root.google.protobuf.StringValue.toObject(m.utmSource, o).value;
            }
            if (m.utmMedium != null && m.hasOwnProperty("utmMedium")) {
                d.utmMedium = $root.google.protobuf.StringValue.toObject(m.utmMedium, o).value;
            }
            if (m.utmCampaign != null && m.hasOwnProperty("utmCampaign")) {
                d.utmCampaign = $root.google.protobuf.StringValue.toObject(m.utmCampaign, o).value;
            }
            if (m.utmTerm != null && m.hasOwnProperty("utmTerm")) {
                d.utmTerm = $root.google.protobuf.StringValue.toObject(m.utmTerm, o).value;
            }
            if (m.utmContent != null && m.hasOwnProperty("utmContent")) {
                d.utmContent = $root.google.protobuf.StringValue.toObject(m.utmContent, o).value;
            }
            var ks2;
            if (m.utmCustomParams && (ks2 = Object.keys(m.utmCustomParams)).length) {
                d.utmCustomParams = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.utmCustomParams[ks2[j]] = m.utmCustomParams[ks2[j]];
                }
            }
            return d;
        };

        TrackingParams.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TrackingParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.TrackingParams";
        };

        return TrackingParams;
    })();

    globals.ClientSession = (function() {

        function ClientSession(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        ClientSession.prototype.id = null;
        ClientSession.prototype.apptimize = null;
        ClientSession.prototype.branch = null;
        ClientSession.prototype.track = null;

        ClientSession.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                $root.google.protobuf.StringValue.encode(m.id, w.uint32(10).fork()).ldelim();
            if (m.apptimize != null && Object.hasOwnProperty.call(m, "apptimize"))
                $root.globals.ApptimizeClientSession.encode(m.apptimize, w.uint32(18).fork()).ldelim();
            if (m.branch != null && Object.hasOwnProperty.call(m, "branch"))
                $root.globals.BranchClientSession.encode(m.branch, w.uint32(26).fork()).ldelim();
            if (m.track != null && Object.hasOwnProperty.call(m, "track"))
                $root.globals.TrackingParams.encode(m.track, w.uint32(34).fork()).ldelim();
            return w;
        };

        ClientSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ClientSession.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.ClientSession();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.apptimize = $root.globals.ApptimizeClientSession.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.branch = $root.globals.BranchClientSession.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.track = $root.globals.TrackingParams.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        ClientSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ClientSession.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.id != null && m.hasOwnProperty("id")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.id);
                    if (e)
                        return "id." + e;
                }
            }
            if (m.apptimize != null && m.hasOwnProperty("apptimize")) {
                {
                    var e = $root.globals.ApptimizeClientSession.verify(m.apptimize);
                    if (e)
                        return "apptimize." + e;
                }
            }
            if (m.branch != null && m.hasOwnProperty("branch")) {
                {
                    var e = $root.globals.BranchClientSession.verify(m.branch);
                    if (e)
                        return "branch." + e;
                }
            }
            if (m.track != null && m.hasOwnProperty("track")) {
                {
                    var e = $root.globals.TrackingParams.verify(m.track);
                    if (e)
                        return "track." + e;
                }
            }
            return null;
        };

        ClientSession.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.ClientSession)
                return d;
            var m = new $root.globals.ClientSession();
            if (d.id != null) {
                if (typeof d.id === "object" && d.id !== null && d.id.value !== undefined)
                    m.id = $root.google.protobuf.StringValue.fromObject(d.id);
                else
                    m.id = $root.google.protobuf.StringValue.fromObject({ value: d.id });
            }
            if (d.apptimize != null) {
                if (typeof d.apptimize !== "object")
                    throw TypeError(".globals.ClientSession.apptimize: object expected");
                m.apptimize = $root.globals.ApptimizeClientSession.fromObject(d.apptimize);
            }
            if (d.branch != null) {
                if (typeof d.branch !== "object")
                    throw TypeError(".globals.ClientSession.branch: object expected");
                m.branch = $root.globals.BranchClientSession.fromObject(d.branch);
            }
            if (d.track != null) {
                if (typeof d.track !== "object")
                    throw TypeError(".globals.ClientSession.track: object expected");
                m.track = $root.globals.TrackingParams.fromObject(d.track);
            }
            return m;
        };

        ClientSession.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = null;
                d.apptimize = null;
                d.branch = null;
                d.track = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = $root.google.protobuf.StringValue.toObject(m.id, o).value;
            }
            if (m.apptimize != null && m.hasOwnProperty("apptimize")) {
                d.apptimize = $root.globals.ApptimizeClientSession.toObject(m.apptimize, o);
            }
            if (m.branch != null && m.hasOwnProperty("branch")) {
                d.branch = $root.globals.BranchClientSession.toObject(m.branch, o);
            }
            if (m.track != null && m.hasOwnProperty("track")) {
                d.track = $root.globals.TrackingParams.toObject(m.track, o);
            }
            return d;
        };

        ClientSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ClientSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.ClientSession";
        };

        return ClientSession;
    })();

    globals.UserExternalAccount = (function() {

        function UserExternalAccount(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        UserExternalAccount.prototype.type = 0;
        UserExternalAccount.prototype.username = null;

        UserExternalAccount.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                $root.google.protobuf.StringValue.encode(m.username, w.uint32(18).fork()).ldelim();
            return w;
        };

        UserExternalAccount.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        UserExternalAccount.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.UserExternalAccount();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.type = r.int32();
                        break;
                    }
                case 2: {
                        m.username = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        UserExternalAccount.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        UserExternalAccount.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.type != null && m.hasOwnProperty("type")) {
                switch (m.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    break;
                }
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.username);
                    if (e)
                        return "username." + e;
                }
            }
            return null;
        };

        UserExternalAccount.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.UserExternalAccount)
                return d;
            var m = new $root.globals.UserExternalAccount();
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "UNKNOWN_ACCOUNT":
            case 0:
                m.type = 0;
                break;
            case "FACEBOOK_ACCOUNT":
            case 1:
                m.type = 1;
                break;
            case "TWITTER_ACCOUNT":
            case 2:
                m.type = 2;
                break;
            case "GOOGLE_ACCOUNT":
            case 3:
                m.type = 3;
                break;
            case "STUMBLEUPON_ACCOUNT":
            case 4:
                m.type = 4;
                break;
            case "APPLE_ACCOUNT":
            case 5:
                m.type = 5;
                break;
            case "EMAIL_ACCOUNT":
            case 6:
                m.type = 6;
                break;
            case "ANONYMOUS_ACCOUNT":
            case 7:
                m.type = 7;
                break;
            case "LAYER_ACCOUNT":
            case 8:
                m.type = 8;
                break;
            }
            if (d.username != null) {
                if (typeof d.username === "object" && d.username !== null && d.username.value !== undefined)
                    m.username = $root.google.protobuf.StringValue.fromObject(d.username);
                else
                    m.username = $root.google.protobuf.StringValue.fromObject({ value: d.username });
            }
            return m;
        };

        UserExternalAccount.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = o.enums === String ? "UNKNOWN_ACCOUNT" : 0;
                d.username = null;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.globals.UserExternalAccountType[m.type] === undefined ? m.type : $root.globals.UserExternalAccountType[m.type] : m.type;
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = $root.google.protobuf.StringValue.toObject(m.username, o).value;
            }
            return d;
        };

        UserExternalAccount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UserExternalAccount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.UserExternalAccount";
        };

        return UserExternalAccount;
    })();

    globals.NotificationPreference = (function() {

        function NotificationPreference(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        NotificationPreference.prototype.optType = 0;
        NotificationPreference.prototype.channel = 0;
        NotificationPreference.prototype.name = null;

        NotificationPreference.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.optType != null && Object.hasOwnProperty.call(m, "optType"))
                w.uint32(8).int32(m.optType);
            if (m.channel != null && Object.hasOwnProperty.call(m, "channel"))
                w.uint32(16).int32(m.channel);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                $root.google.protobuf.StringValue.encode(m.name, w.uint32(26).fork()).ldelim();
            return w;
        };

        NotificationPreference.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        NotificationPreference.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.NotificationPreference();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.optType = r.int32();
                        break;
                    }
                case 2: {
                        m.channel = r.int32();
                        break;
                    }
                case 3: {
                        m.name = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        NotificationPreference.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        NotificationPreference.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.optType != null && m.hasOwnProperty("optType")) {
                switch (m.optType) {
                default:
                    return "optType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            }
            if (m.channel != null && m.hasOwnProperty("channel")) {
                switch (m.channel) {
                default:
                    return "channel: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.name);
                    if (e)
                        return "name." + e;
                }
            }
            return null;
        };

        NotificationPreference.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.NotificationPreference)
                return d;
            var m = new $root.globals.NotificationPreference();
            switch (d.optType) {
            default:
                if (typeof d.optType === "number") {
                    m.optType = d.optType;
                    break;
                }
                break;
            case "UNKNOWN_OPT_TYPE":
            case 0:
                m.optType = 0;
                break;
            case "OPT_IN":
            case 1:
                m.optType = 1;
                break;
            case "OPT_OUT":
            case 2:
                m.optType = 2;
                break;
            }
            switch (d.channel) {
            default:
                if (typeof d.channel === "number") {
                    m.channel = d.channel;
                    break;
                }
                break;
            case "UNKNOWN_NOTIFICATION_CHANNEL":
            case 0:
                m.channel = 0;
                break;
            case "SMS":
            case 1:
                m.channel = 1;
                break;
            case "EMAIL":
            case 2:
                m.channel = 2;
                break;
            case "MOBILE":
            case 3:
                m.channel = 3;
                break;
            }
            if (d.name != null) {
                if (typeof d.name === "object" && d.name !== null && d.name.value !== undefined)
                    m.name = $root.google.protobuf.StringValue.fromObject(d.name);
                else
                    m.name = $root.google.protobuf.StringValue.fromObject({ value: d.name });
            }
            return m;
        };

        NotificationPreference.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.optType = o.enums === String ? "UNKNOWN_OPT_TYPE" : 0;
                d.channel = o.enums === String ? "UNKNOWN_NOTIFICATION_CHANNEL" : 0;
                d.name = null;
            }
            if (m.optType != null && m.hasOwnProperty("optType")) {
                d.optType = o.enums === String ? $root.globals.OptType[m.optType] === undefined ? m.optType : $root.globals.OptType[m.optType] : m.optType;
            }
            if (m.channel != null && m.hasOwnProperty("channel")) {
                d.channel = o.enums === String ? $root.globals.NotificationChannel[m.channel] === undefined ? m.channel : $root.globals.NotificationChannel[m.channel] : m.channel;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = $root.google.protobuf.StringValue.toObject(m.name, o).value;
            }
            return d;
        };

        NotificationPreference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        NotificationPreference.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.NotificationPreference";
        };

        return NotificationPreference;
    })();

    globals.ExperimentType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_EXPERIMENT_TYPE"] = 0;
        values[valuesById[1] = "SERVER_SIDE"] = 1;
        values[valuesById[2] = "CLIENT_SIDE"] = 2;
        values[valuesById[3] = "MAB_OPTIMIZATION"] = 3;
        return values;
    })();

    globals.Experiment = (function() {

        function Experiment(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Experiment.prototype.name = null;
        Experiment.prototype.group = null;
        Experiment.prototype.testId = null;
        Experiment.prototype.type = 0;
        Experiment.prototype.testEntityStateId = null;

        Experiment.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                $root.google.protobuf.StringValue.encode(m.name, w.uint32(10).fork()).ldelim();
            if (m.group != null && Object.hasOwnProperty.call(m, "group"))
                $root.google.protobuf.StringValue.encode(m.group, w.uint32(18).fork()).ldelim();
            if (m.testId != null && Object.hasOwnProperty.call(m, "testId"))
                $root.google.protobuf.UInt32Value.encode(m.testId, w.uint32(26).fork()).ldelim();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(32).int32(m.type);
            if (m.testEntityStateId != null && Object.hasOwnProperty.call(m, "testEntityStateId"))
                $root.google.protobuf.UInt32Value.encode(m.testEntityStateId, w.uint32(42).fork()).ldelim();
            return w;
        };

        Experiment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Experiment.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.globals.Experiment();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.group = $root.google.protobuf.StringValue.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.testId = $root.google.protobuf.UInt32Value.decode(r, r.uint32());
                        break;
                    }
                case 4: {
                        m.type = r.int32();
                        break;
                    }
                case 5: {
                        m.testEntityStateId = $root.google.protobuf.UInt32Value.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        Experiment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Experiment.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.name != null && m.hasOwnProperty("name")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.name);
                    if (e)
                        return "name." + e;
                }
            }
            if (m.group != null && m.hasOwnProperty("group")) {
                {
                    var e = $root.google.protobuf.StringValue.verify(m.group);
                    if (e)
                        return "group." + e;
                }
            }
            if (m.testId != null && m.hasOwnProperty("testId")) {
                {
                    var e = $root.google.protobuf.UInt32Value.verify(m.testId);
                    if (e)
                        return "testId." + e;
                }
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                switch (m.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (m.testEntityStateId != null && m.hasOwnProperty("testEntityStateId")) {
                {
                    var e = $root.google.protobuf.UInt32Value.verify(m.testEntityStateId);
                    if (e)
                        return "testEntityStateId." + e;
                }
            }
            return null;
        };

        Experiment.fromObject = function fromObject(d) {
            if (d instanceof $root.globals.Experiment)
                return d;
            var m = new $root.globals.Experiment();
            if (d.name != null) {
                if (typeof d.name === "object" && d.name !== null && d.name.value !== undefined)
                    m.name = $root.google.protobuf.StringValue.fromObject(d.name);
                else
                    m.name = $root.google.protobuf.StringValue.fromObject({ value: d.name });
            }
            if (d.group != null) {
                if (typeof d.group === "object" && d.group !== null && d.group.value !== undefined)
                    m.group = $root.google.protobuf.StringValue.fromObject(d.group);
                else
                    m.group = $root.google.protobuf.StringValue.fromObject({ value: d.group });
            }
            if (d.testId != null) {
                if (typeof d.testId === "object" && d.testId !== null && d.testId.value !== undefined)
                    m.testId = $root.google.protobuf.UInt32Value.fromObject(d.testId);
                else
                    m.testId = $root.google.protobuf.UInt32Value.fromObject({ value: d.testId });
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "UNKNOWN_EXPERIMENT_TYPE":
            case 0:
                m.type = 0;
                break;
            case "SERVER_SIDE":
            case 1:
                m.type = 1;
                break;
            case "CLIENT_SIDE":
            case 2:
                m.type = 2;
                break;
            case "MAB_OPTIMIZATION":
            case 3:
                m.type = 3;
                break;
            }
            if (d.testEntityStateId != null) {
                if (typeof d.testEntityStateId === "object" && d.testEntityStateId !== null && d.testEntityStateId.value !== undefined)
                    m.testEntityStateId = $root.google.protobuf.UInt32Value.fromObject(d.testEntityStateId);
                else
                    m.testEntityStateId = $root.google.protobuf.UInt32Value.fromObject({ value: d.testEntityStateId });
            }
            return m;
        };

        Experiment.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.name = null;
                d.group = null;
                d.testId = null;
                d.type = o.enums === String ? "UNKNOWN_EXPERIMENT_TYPE" : 0;
                d.testEntityStateId = null;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = $root.google.protobuf.StringValue.toObject(m.name, o).value;
            }
            if (m.group != null && m.hasOwnProperty("group")) {
                d.group = $root.google.protobuf.StringValue.toObject(m.group, o).value;
            }
            if (m.testId != null && m.hasOwnProperty("testId")) {
                d.testId = $root.google.protobuf.UInt32Value.toObject(m.testId, o).value;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.globals.ExperimentType[m.type] === undefined ? m.type : $root.globals.ExperimentType[m.type] : m.type;
            }
            if (m.testEntityStateId != null && m.hasOwnProperty("testEntityStateId")) {
                d.testEntityStateId = $root.google.protobuf.UInt32Value.toObject(m.testEntityStateId, o).value;
            }
            return d;
        };

        Experiment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Experiment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/globals.Experiment";
        };

        return Experiment;
    })();

    globals.CalloutTriggerReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_CALLOUT_TRIGGER_REASON"] = 0;
        values[valuesById[1] = "REASON_RECOMMENDATION"] = 1;
        values[valuesById[2] = "REASON_CONTENT_DWELL"] = 2;
        values[valuesById[3] = "REASON_CONTENT_SCROLL"] = 3;
        values[valuesById[4] = "REASON_CONTENT_INTERACTION"] = 4;
        values[valuesById[5] = "REASON_CONTENT_VIEW"] = 5;
        values[valuesById[6] = "REASON_CONTENT_SHARE"] = 6;
        values[valuesById[7] = "REASON_CONTENT_COMMENT"] = 7;
        values[valuesById[8] = "REASON_CONTENT_LIKE"] = 8;
        values[valuesById[9] = "REASON_CONTENT_DISLIKE"] = 9;
        return values;
    })();

    return globals;
})();

export const google = $root.google = (() => {

    const google = {};

    google.protobuf = (function() {

        const protobuf = {};

        protobuf.DoubleValue = (function() {

            function DoubleValue(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            DoubleValue.prototype.value = 0;

            DoubleValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(9).double(m.value);
                return w;
            };

            DoubleValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            DoubleValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.DoubleValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.double();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            DoubleValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            DoubleValue.verify = function verify(m) {
                if (typeof m !== "object" || m === null)
                    return "object expected";
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (typeof m.value !== "number")
                        return "value: number expected";
                }
                return null;
            };

            DoubleValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.DoubleValue)
                    return d;
                var m = new $root.google.protobuf.DoubleValue();
                if (d.value != null) {
                    m.value = Number(d.value);
                }
                return m;
            };

            DoubleValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = o.json && !isFinite(m.value) ? String(m.value) : m.value;
                }
                return d;
            };

            DoubleValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            DoubleValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.DoubleValue";
            };

            return DoubleValue;
        })();

        protobuf.FloatValue = (function() {

            function FloatValue(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            FloatValue.prototype.value = 0;

            FloatValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(13).float(m.value);
                return w;
            };

            FloatValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            FloatValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.FloatValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.float();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            FloatValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            FloatValue.verify = function verify(m) {
                if (typeof m !== "object" || m === null)
                    return "object expected";
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (typeof m.value !== "number")
                        return "value: number expected";
                }
                return null;
            };

            FloatValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FloatValue)
                    return d;
                var m = new $root.google.protobuf.FloatValue();
                if (d.value != null) {
                    m.value = Number(d.value);
                }
                return m;
            };

            FloatValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = o.json && !isFinite(m.value) ? String(m.value) : m.value;
                }
                return d;
            };

            FloatValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            FloatValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FloatValue";
            };

            return FloatValue;
        })();

        protobuf.Int64Value = (function() {

            function Int64Value(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Int64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            Int64Value.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(8).int64(m.value);
                return w;
            };

            Int64Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            Int64Value.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Int64Value();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.int64();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            Int64Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            Int64Value.verify = function verify(m) {
                if (typeof m !== "object" || m === null)
                    return "object expected";
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (!$util.isInteger(m.value) && !(m.value && $util.isInteger(m.value.low) && $util.isInteger(m.value.high)))
                        return "value: integer|Long expected";
                }
                return null;
            };

            Int64Value.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Int64Value)
                    return d;
                var m = new $root.google.protobuf.Int64Value();
                if (d.value != null) {
                    if ($util.Long)
                        (m.value = $util.Long.fromValue(d.value)).unsigned = false;
                    else if (typeof d.value === "string")
                        m.value = parseInt(d.value, 10);
                    else if (typeof d.value === "number")
                        m.value = d.value;
                    else if (typeof d.value === "object")
                        m.value = new $util.LongBits(d.value.low >>> 0, d.value.high >>> 0).toNumber();
                }
                return m;
            };

            Int64Value.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.value = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.value = o.longs === String ? "0" : 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (typeof m.value === "number")
                        d.value = o.longs === String ? String(m.value) : m.value;
                    else
                        d.value = o.longs === String ? $util.Long.prototype.toString.call(m.value) : o.longs === Number ? new $util.LongBits(m.value.low >>> 0, m.value.high >>> 0).toNumber() : m.value;
                }
                return d;
            };

            Int64Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Int64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int64Value";
            };

            return Int64Value;
        })();

        protobuf.UInt64Value = (function() {

            function UInt64Value(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            UInt64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            UInt64Value.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(8).uint64(m.value);
                return w;
            };

            UInt64Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            UInt64Value.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.UInt64Value();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.uint64();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            UInt64Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            UInt64Value.verify = function verify(m) {
                if (typeof m !== "object" || m === null)
                    return "object expected";
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (!$util.isInteger(m.value) && !(m.value && $util.isInteger(m.value.low) && $util.isInteger(m.value.high)))
                        return "value: integer|Long expected";
                }
                return null;
            };

            UInt64Value.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.UInt64Value)
                    return d;
                var m = new $root.google.protobuf.UInt64Value();
                if (d.value != null) {
                    if ($util.Long)
                        (m.value = $util.Long.fromValue(d.value)).unsigned = true;
                    else if (typeof d.value === "string")
                        m.value = parseInt(d.value, 10);
                    else if (typeof d.value === "number")
                        m.value = d.value;
                    else if (typeof d.value === "object")
                        m.value = new $util.LongBits(d.value.low >>> 0, d.value.high >>> 0).toNumber(true);
                }
                return m;
            };

            UInt64Value.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, true);
                        d.value = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.value = o.longs === String ? "0" : 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (typeof m.value === "number")
                        d.value = o.longs === String ? String(m.value) : m.value;
                    else
                        d.value = o.longs === String ? $util.Long.prototype.toString.call(m.value) : o.longs === Number ? new $util.LongBits(m.value.low >>> 0, m.value.high >>> 0).toNumber(true) : m.value;
                }
                return d;
            };

            UInt64Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UInt64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt64Value";
            };

            return UInt64Value;
        })();

        protobuf.Int32Value = (function() {

            function Int32Value(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Int32Value.prototype.value = 0;

            Int32Value.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(8).int32(m.value);
                return w;
            };

            Int32Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            Int32Value.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Int32Value();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.int32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            Int32Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            Int32Value.verify = function verify(m) {
                if (typeof m !== "object" || m === null)
                    return "object expected";
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (!$util.isInteger(m.value))
                        return "value: integer expected";
                }
                return null;
            };

            Int32Value.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Int32Value)
                    return d;
                var m = new $root.google.protobuf.Int32Value();
                if (d.value != null) {
                    m.value = d.value | 0;
                }
                return m;
            };

            Int32Value.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            Int32Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Int32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int32Value";
            };

            return Int32Value;
        })();

        protobuf.UInt32Value = (function() {

            function UInt32Value(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            UInt32Value.prototype.value = 0;

            UInt32Value.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(8).uint32(m.value);
                return w;
            };

            UInt32Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            UInt32Value.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.UInt32Value();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.uint32();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            UInt32Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            UInt32Value.verify = function verify(m) {
                if (typeof m !== "object" || m === null)
                    return "object expected";
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (!$util.isInteger(m.value))
                        return "value: integer expected";
                }
                return null;
            };

            UInt32Value.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.UInt32Value)
                    return d;
                var m = new $root.google.protobuf.UInt32Value();
                if (d.value != null) {
                    m.value = d.value >>> 0;
                }
                return m;
            };

            UInt32Value.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = 0;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            UInt32Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UInt32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt32Value";
            };

            return UInt32Value;
        })();

        protobuf.BoolValue = (function() {

            function BoolValue(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            BoolValue.prototype.value = false;

            BoolValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(8).bool(m.value);
                return w;
            };

            BoolValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            BoolValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.BoolValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.bool();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            BoolValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            BoolValue.verify = function verify(m) {
                if (typeof m !== "object" || m === null)
                    return "object expected";
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (typeof m.value !== "boolean")
                        return "value: boolean expected";
                }
                return null;
            };

            BoolValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.BoolValue)
                    return d;
                var m = new $root.google.protobuf.BoolValue();
                if (d.value != null) {
                    m.value = Boolean(d.value);
                }
                return m;
            };

            BoolValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = false;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            BoolValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            BoolValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BoolValue";
            };

            return BoolValue;
        })();

        protobuf.StringValue = (function() {

            function StringValue(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            StringValue.prototype.value = "";

            StringValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(10).string(m.value);
                return w;
            };

            StringValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            StringValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.StringValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.string();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            StringValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            StringValue.verify = function verify(m) {
                if (typeof m !== "object" || m === null)
                    return "object expected";
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (!$util.isString(m.value))
                        return "value: string expected";
                }
                return null;
            };

            StringValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.StringValue)
                    return d;
                var m = new $root.google.protobuf.StringValue();
                if (d.value != null) {
                    m.value = String(d.value);
                }
                return m;
            };

            StringValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.value = "";
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            StringValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            StringValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.StringValue";
            };

            return StringValue;
        })();

        protobuf.BytesValue = (function() {

            function BytesValue(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            BytesValue.prototype.value = $util.newBuffer([]);

            BytesValue.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(10).bytes(m.value);
                return w;
            };

            BytesValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            BytesValue.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.BytesValue();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.value = r.bytes();
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            BytesValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            BytesValue.verify = function verify(m) {
                if (typeof m !== "object" || m === null)
                    return "object expected";
                if (m.value != null && m.hasOwnProperty("value")) {
                    if (!(m.value && typeof m.value.length === "number" || $util.isString(m.value)))
                        return "value: buffer expected";
                }
                return null;
            };

            BytesValue.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.BytesValue)
                    return d;
                var m = new $root.google.protobuf.BytesValue();
                if (d.value != null) {
                    if (typeof d.value === "string")
                        $util.base64.decode(d.value, m.value = $util.newBuffer($util.base64.length(d.value)), 0);
                    else if (d.value.length >= 0)
                        m.value = d.value;
                }
                return m;
            };

            BytesValue.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if (o.bytes === String)
                        d.value = "";
                    else {
                        d.value = [];
                        if (o.bytes !== Array)
                            d.value = $util.newBuffer(d.value);
                    }
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = o.bytes === String ? $util.base64.encode(m.value, 0, m.value.length) : o.bytes === Array ? Array.prototype.slice.call(m.value) : m.value;
                }
                return d;
            };

            BytesValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            BytesValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BytesValue";
            };

            return BytesValue;
        })();

        return protobuf;
    })();

    return google;
})();

export const events = $root.events = (() => {

    const events = {};

    events.common = (function() {

        const common = {};

        common.ApplicationTenant = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_APPLICATION_TENANT"] = 0;
            values[valuesById[1] = "MIX"] = 1;
            values[valuesById[2] = "LAYER"] = 2;
            return values;
        })();

        common.UserType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_USER_TYPE"] = 0;
            values[valuesById[1] = "ORGANIC"] = 1;
            values[valuesById[2] = "AUTO_GENERATED"] = 2;
            values[valuesById[3] = "BOT"] = 3;
            values[valuesById[4] = "SU_SHADOW"] = 4;
            values[valuesById[5] = "SU_IMPORTED"] = 5;
            values[valuesById[6] = "INTERNAL_USER"] = 6;
            values[valuesById[7] = "LAYER_USER"] = 7;
            return values;
        })();

        common.UserRole = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_USER_ROLE"] = 0;
            values[valuesById[1] = "GENERAL"] = 1;
            values[valuesById[2] = "MIX_EMPLOYEE"] = 2;
            values[valuesById[3] = "EXPA_EMPLOYEE"] = 3;
            values[valuesById[4] = "PUBLISHER"] = 4;
            values[valuesById[5] = "BRAND"] = 5;
            values[valuesById[6] = "CELEBRITY"] = 6;
            values[valuesById[7] = "USER_TESTER"] = 7;
            values[valuesById[8] = "INFLUENCER"] = 8;
            values[valuesById[9] = "SELF_PROMOTER"] = 9;
            values[valuesById[10] = "SPAMMER"] = 10;
            values[valuesById[11] = "BLOGGER"] = 11;
            values[valuesById[12] = "AMBASSADOR"] = 12;
            values[valuesById[13] = "MIX_EDITOR"] = 13;
            values[valuesById[14] = "MIX_SYSTEM"] = 14;
            values[valuesById[15] = "ANONYMOUS"] = 15;
            values[valuesById[16] = "FRIENDS"] = 16;
            values[valuesById[17] = "SUBMITTER"] = 17;
            return values;
        })();

        common.ContentProviderType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_CONTENT_PROVIDER"] = 0;
            values[valuesById[1] = "TWITTER"] = 1;
            values[valuesById[2] = "MIX_FEEDER"] = 2;
            values[valuesById[3] = "STUMBLEUPON"] = 3;
            values[valuesById[4] = "RSS_FEED"] = 4;
            values[valuesById[5] = "REDDIT"] = 5;
            values[valuesById[6] = "YOUTUBE_TRENDING"] = 6;
            values[valuesById[7] = "INSTAGRAM"] = 7;
            values[valuesById[8] = "TWITTER_MEDIA"] = 8;
            values[valuesById[9] = "YOUTUBE"] = 9;
            values[valuesById[10] = "USER_CURATED"] = 10;
            values[valuesById[11] = "MIX_SCRAPER"] = 11;
            values[valuesById[12] = "_500PX"] = 12;
            values[valuesById[13] = "_1X"] = 13;
            values[valuesById[14] = "TUMBLR"] = 14;
            values[valuesById[15] = "DESIGNBOOM"] = 15;
            values[valuesById[16] = "MYMODERNMET"] = 16;
            values[valuesById[17] = "IMGUR"] = 17;
            values[valuesById[18] = "FLICKR"] = 18;
            values[valuesById[19] = "PINTEREST"] = 19;
            values[valuesById[20] = "OPENSEA"] = 20;
            values[valuesById[21] = "AUTO_INGEST"] = 21;
            values[valuesById[22] = "USER_SUBMISSION"] = 22;
            values[valuesById[23] = "TIKTOK"] = 23;
            values[valuesById[24] = "RSS_CURATED"] = 24;
            values[valuesById[25] = "USER_SUBMISSION_UPLOAD"] = 25;
            values[valuesById[26] = "VIMEO"] = 26;
            values[valuesById[27] = "ACTIVITYPUB"] = 27;
            values[valuesById[28] = "SOUNDCLOUD"] = 28;
            return values;
        })();

        common.UserRSSFeedType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_USER_RSS_FEED_TYPE"] = 0;
            values[valuesById[1] = "USER_RSS_FEED_TYPE_PUBLISHER"] = 1;
            return values;
        })();

        common.ReSubmissionReason = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_RESUBMISSION_REASON"] = 0;
            values[valuesById[1] = "URL_CHURN"] = 1;
            values[valuesById[2] = "ACTIVE_URL_CHURN"] = 2;
            values[valuesById[3] = "MIXED_URL_CHURN"] = 3;
            values[valuesById[4] = "ADMIN_OVERRIDE"] = 4;
            values[valuesById[5] = "ADHOC_REINGESTION"] = 5;
            values[valuesById[6] = "URL_FEATURES_CHURN"] = 6;
            return values;
        })();

        common.UrlSpamReason = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_SPAM_REASON"] = 0;
            values[valuesById[1] = "ADVERTISEMENT"] = 1;
            values[valuesById[2] = "POLITICAL_SPAM"] = 2;
            values[valuesById[3] = "REDIRECTS"] = 3;
            values[valuesById[4] = "KEYWORD_STUFFING"] = 4;
            values[valuesById[5] = "MALWARE"] = 5;
            values[valuesById[6] = "PARKED_DOMAINS"] = 6;
            values[valuesById[7] = "DECEPTIVE_LINKS"] = 7;
            values[valuesById[8] = "OTHER_SPAM_REASON"] = 8;
            values[valuesById[9] = "PAYWALLED_CONTENT"] = 9;
            values[valuesById[10] = "NAG_SCREEN"] = 10;
            return values;
        })();

        common.NsfwReason = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_NSFW_REASON"] = 0;
            values[valuesById[1] = "ILLEGAL_CONTENT"] = 1;
            values[valuesById[2] = "PORN"] = 2;
            values[valuesById[3] = "PROFANITY"] = 3;
            values[valuesById[4] = "VIOLENCE"] = 4;
            values[valuesById[5] = "HATE_SPEECH"] = 5;
            values[valuesById[6] = "NUDITY"] = 6;
            values[valuesById[7] = "OTHER_NSFW_REASON"] = 7;
            return values;
        })();

        common.UrlModerationAction = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_URL_MODERATION_ACTION"] = 0;
            values[valuesById[1] = "REJECT_CHANGE_PROVIDER_TYPE"] = 1;
            values[valuesById[2] = "CHANGE_PROVIDER_TYPE_USER_CURATED"] = 2;
            values[valuesById[3] = "SET_HIDDEN"] = 3;
            values[valuesById[4] = "SET_UNHIDDEN"] = 4;
            return values;
        })();

        common.CommentHiddenReason = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_COMMENT_HIDDEN_REASON"] = 0;
            values[valuesById[1] = "USER_SPAMMER"] = 1;
            values[valuesById[2] = "PARENT_COMMENT_HIDDEN"] = 2;
            values[valuesById[3] = "MODERATION_ACTION"] = 3;
            values[valuesById[4] = "SHADOWBAN"] = 4;
            return values;
        })();

        common.ShareReaction = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_SHARE_REACTION"] = 0;
            values[valuesById[1] = "LIKE"] = 1;
            return values;
        })();

        return common;
    })();

    return events;
})();

export { $root as default };
