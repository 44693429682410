import { PayloadAction, createSlice, prepareAutoBatched } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { AppState } from '../store/store'

export interface IUserPageData {
  followingPage: number
  followersPage: number
  likersPage: number
  savesPage: number
  postsPage: number
}

export interface UserSlice {
  userId?: number
  likedByUsername?: string | null
  usersPageData: Record<string, IUserPageData | undefined>
  _metadata: { updatedFields: (keyof UserSlice)[] }
}

const initialState: UserSlice = {
  usersPageData: {},
  _metadata: { updatedFields: [] },
}
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: {
      reducer: (state, action: PayloadAction<number | undefined>) => {
        state.userId = action.payload
        state._metadata.updatedFields.push('userId')
      },
      prepare: prepareAutoBatched<number | undefined>(),
    },
    setLikedByUsername: {
      reducer: (state, action: PayloadAction<string | null>) => {
        state.likedByUsername = action.payload
        state._metadata.updatedFields.push('likedByUsername')
      },
      prepare: prepareAutoBatched<string | null>(),
    },
    incrementUserPageData: {
      reducer: (
        state,
        action: PayloadAction<{
          username: string
          type: 'following' | 'followers' | 'likes' | 'saved' | 'posts'
          incrementBy: number
        }>
      ) => {
        const { username, type, incrementBy } = action.payload
        const userData = state.usersPageData[username] ?? {
          followingPage: 1,
          followersPage: 1,
          likersPage: 1,
          savesPage: 1,
          postsPage: 1,
        }

        switch (type) {
          case 'following':
            userData.followingPage += incrementBy
            break
          case 'followers':
            userData.followersPage += incrementBy
            break
          case 'likes':
            userData.likersPage += incrementBy
            break
          case 'saved':
            userData.savesPage += incrementBy
            break
          case 'posts':
            userData.postsPage += incrementBy
            break
        }

        state.usersPageData[username] = userData
        state._metadata.updatedFields.push('usersPageData')
      },
      prepare: prepareAutoBatched<{
        username: string
        type: 'following' | 'followers' | 'likes' | 'saved' | 'posts'
        incrementBy: number
      }>(),
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.user

        const fieldsToCheck: (keyof UserSlice)[] = ['userId', 'likedByUsername', 'usersPageData']

        function updateField<K extends keyof UserSlice>(state: UserSlice, field: K, value: UserSlice[K]): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (serverApp._metadata.updatedFields.includes(field) && serverApp[field] !== state[field]) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const selectUserId = (state: AppState) => state.user.userId
export const selectFollowersPage = (state: AppState, username: string) =>
  state.user.usersPageData[username]?.followersPage ?? 1
export const selectLikesPage = (state: AppState, username: string) =>
  state.user.usersPageData[username]?.likersPage ?? 1
export const selectFollowingPage = (state: AppState, username: string) =>
  state.user.usersPageData[username]?.followingPage ?? 1
export const selectSavesPage = (state: AppState, username: string) => state.user.usersPageData[username]?.savesPage ?? 1
export const { setUserId, setLikedByUsername, incrementUserPageData } = userSlice.actions
export default userSlice.reducer
