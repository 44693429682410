import axios from 'axios'
import Image from 'next/image'
import Link from 'next/link'
import React, { ReactElement, useCallback } from 'react'

import logger from '../../lib/logger'
import { CommonNotification } from '../../models/NotificationsResponse'
import notificationsApi from '../../redux/api/notificationsApi'
import IntoAPI from '../../services/IntoAPI'
import MixMix from '../../services/MixMix'
import { useTimeSince } from './useTimeSince'

interface NotificationImageProps {
  src: string
  alt: string
  shape: 'square' | 'round'
  secondaryImage?: {
    src: string
    alt: string
    hideBorder?: boolean
  }
}

export const NotificationImage = ({ src, alt, shape, secondaryImage }: NotificationImageProps) => (
  <div className="relative shrink-0">
    <Image
      className={`${shape === 'square' ? 'rounded-md' : 'rounded-full'} aspect-square shrink-0`}
      src={src}
      alt={alt}
      height={40}
      width={40}
    />
    {secondaryImage && (
      <Image
        // Secondary image shape is always opposite of main image
        className={`${shape === 'square' ? 'rounded-full' : 'rounded-md'} ${secondaryImage.hideBorder ? '' : 'border border-white'} absolute -bottom-2 -right-2 z-50 aspect-square`}
        src={secondaryImage.src}
        alt={secondaryImage.alt}
        height={20}
        width={20}
      />
    )}
  </div>
)
export const Notification = ({
  children,
  notification,
  href,
  bgColorUnread = 'bg-primary/80',
  hideTimestamp,
  badge,
}: {
  children: ReactElement[]
  notification: CommonNotification
  href: string
  bgColorUnread?: string
  hideTimestamp?: boolean
  badge?: ReactElement
}) => {
  const timeSince = useTimeSince(notification.notification_at)
  const [markRead] = notificationsApi.useMarkSingleReadMutation()

  const markShareAsRead = useCallback((content: CommonNotification['content']) => {
    if ('share_id' in content && content.share_id)
      axios.request(MixMix.share.markRead(content.share_id)).catch(err => {
        logger.warn('Failed to mark share as read', err)
      })
  }, [])

  const markShareReactionRead = useCallback((content: CommonNotification['content']) => {
    if ('share_id' in content && content.share_id)
      axios.request(IntoAPI.share.markShareReactionRead(content.share_id)).catch(err => {
        logger.warn('Failed to mark share reaction as read', err)
      })
  }, [])

  return (
    <Link
      className={`${notification.status === 'unread' ? 'border-l-4 border-accent ' + bgColorUnread : 'light:bg-menu dark:bg-contrast/[.16]'} flex items-center justify-between space-x-4 p-4 text-left light:hover:bg-contrast/[.03] dark:hover:bg-contrast/20`}
      href={href}
      onClick={() => {
        if (notification.type === 'share') markShareAsRead(notification.content)
        if (notification.type === 'share_reaction') markShareReactionRead(notification.content)
        void markRead(notification.id)
      }}
    >
      <div className="flex items-center space-x-4">{children}</div>
      {!hideTimestamp && <span className="whitespace-nowrap text-xs">{timeSince}</span>}
      {badge}
    </Link>
  )
}
