import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { REHYDRATE } from 'redux-persist'

import runtimeConfig from '../../lib/RuntimeConfig'
import { AppState } from '../store/store'

const isDev =
  runtimeConfig().publicRuntimeConfig.appEnv === 'dev' || runtimeConfig().publicRuntimeConfig.nodeEnv === 'development'

export interface TweaksSlice {
  showDebugInfo: boolean
  isCanary: boolean
  enableFullScreenMode: boolean
  hasTweakedEnv: boolean
  showBusinessEventsModal: boolean
  _metadata: { updatedFields: string[] }
}

const initialState: TweaksSlice = {
  showDebugInfo: false,
  isCanary: false,
  enableFullScreenMode: false,
  hasTweakedEnv: false,
  showBusinessEventsModal: false,
  _metadata: { updatedFields: [] },
}

const tweaksSlice = createSlice({
  name: 'tweaks',
  initialState,
  reducers: {
    setTweaksState: (state, action: PayloadAction<{ key: string; isChecked: boolean }>) => {
      state[action.payload.key as keyof Omit<TweaksSlice, '_metadata'>] = action.payload.isChecked
      state._metadata.updatedFields.push(action.payload.key)
      if (action.payload.key === 'isCanary') {
        state.hasTweakedEnv = true
        state._metadata.updatedFields.push('hasTweakedEnv')
      }
    },
    setShowDebugInfo: (state, action: PayloadAction<boolean>) => {
      state.showDebugInfo = action.payload
      state._metadata.updatedFields.push('showDebugInfo')
    },
    setEnableFullScreenMode: (state, action: PayloadAction<boolean>) => {
      state.enableFullScreenMode = action.payload
      state._metadata.updatedFields.push('enableFullScreenMode')
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        (action): action is PayloadAction<AppState> => action.type === HYDRATE,
        (state, action) => {
          const serverApp = action.payload.tweaks

          const fieldsToCheck: (keyof TweaksSlice)[] = [
            'showDebugInfo',
            'isCanary',
            'hasTweakedEnv',
            'enableFullScreenMode',
          ]

          function updateField<K extends keyof TweaksSlice>(state: TweaksSlice, field: K, value: TweaksSlice[K]): void {
            state[field] = value
          }

          fieldsToCheck.forEach(field => {
            if (
              serverApp._metadata.updatedFields.includes(field) &&
              serverApp[field] !== undefined &&
              serverApp[field] !== state[field]
            ) {
              updateField(state, field, serverApp[field])
            }
          })
          state._metadata.updatedFields = []
        }
      )
      .addMatcher(
        action => action.type === REHYDRATE,
        (state, action: PayloadAction<{ hasTweakedEnv: boolean }>) => {
          if (isDev && !action.payload?.hasTweakedEnv) state.isCanary = true
        }
      )
  },
})

export const { setTweaksState, setEnableFullScreenMode, setShowDebugInfo } = tweaksSlice.actions
export const selectTweaksState = (state: AppState) => state.tweaks
export const selectShowDebugInfo = (state: AppState) => state.tweaks.showDebugInfo
export const selectIsCanary = (state: AppState) => state.tweaks.isCanary
export const selectShowBusinessEventsModal = (state: AppState) => state.tweaks.showBusinessEventsModal

export default tweaksSlice.reducer
