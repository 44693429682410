import { useState } from 'react'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

import { useGlobalContext } from '../context/GlobalContexts'

export const useIsDesktop = () => {
  const {
    browserInfo: { isDesktop: isDesktopClientSide },
  } = useGlobalContext()
  const [isDesktop, setIsDesktop] = useState(false)

  useIsomorphicLayoutEffect(() => {
    setIsDesktop(isDesktopClientSide)
  }, [isDesktopClientSide])
  return isDesktop
}
