import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { NodeStreamPageContextValues } from '../../components/layouts/FullScreenFeedLayout'
import { globals } from '../../lib/messages/protobuf'
import { AppState } from '../store/store'

import PageType = globals.PageContext.PageType
import StreamType = globals.StreamType

interface RawPageContext {
  page: keyof typeof PageType
  pageSection: NodeStreamPageContextValues | null
  streamType: keyof typeof StreamType | null
}

export interface AnalyticsSlice {
  currentContext: RawPageContext
  referrerContext: RawPageContext
  _metadata: { updatedFields: string[] }
}
const initialState: AnalyticsSlice = {
  currentContext: {
    page: 'UNKNOWN_PAGE',
    pageSection: null,
    streamType: 'UNKNOWN_STREAM_TYPE',
  },
  referrerContext: {
    page: 'UNKNOWN_PAGE',
    pageSection: null,
    streamType: 'UNKNOWN_STREAM_TYPE',
  },
  _metadata: { updatedFields: [] },
}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    mergeCurrentContext: (
      state,
      action: PayloadAction<{
        page?: keyof typeof PageType
        pageSection?: NodeStreamPageContextValues | null
        streamType?: keyof typeof StreamType
      }>
    ) => {
      state.currentContext = {
        ...state.currentContext,
        ...action.payload,
      }
      state._metadata.updatedFields.push('currentContext')
    },
    setCurrentContext: (state, action: PayloadAction<RawPageContext>) => {
      state.currentContext = action.payload
      state._metadata.updatedFields.push('currentContext')
    },
    setReferrerContext: (state, action: PayloadAction<RawPageContext>) => {
      state.referrerContext = action.payload
      state._metadata.updatedFields.push('referrerContext')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.analytics

        const fieldsToCheck: (keyof AnalyticsSlice)[] = ['currentContext', 'referrerContext']
        function updateField<K extends keyof AnalyticsSlice>(
          state: AnalyticsSlice,
          field: K,
          value: AnalyticsSlice[K]
        ): void {
          state[field] = value
        }
        fieldsToCheck.forEach(field => {
          if (
            serverApp._metadata.updatedFields.includes(field) &&
            serverApp[field] !== undefined &&
            serverApp[field] !== state[field]
          ) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const { mergeCurrentContext, setCurrentContext, setReferrerContext } = analyticsSlice.actions

export default analyticsSlice.reducer
