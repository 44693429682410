import { ParsedUrlQuery } from 'querystring'

import Long from 'long'

import { commands, globals } from '../../lib/messages/protobuf'
import { WEEK_TO_SECONDS } from '../../lib/time-constants'
import { IntoUser } from '../../models/IntoUser'
import RecommendResponse from '../../models/RecommendResponse'
import IntoAPI, { IntoUrlResponse, IntoUrlSummaryResponse } from '../../services/IntoAPI'
import axios from '../../services/MixAxios'
import MixMix from '../../services/MixMix'
import { AppState } from '../store/store'
import { injectUrlsIntoFeed } from '../utils/injectsUrlsIntoFeed'
import nodeApi from './nodeApi'
import { mixApi } from './root'
import userApi from './userApi'

import RateUrl = commands.RateUrl
import RateUrlType = globals.RateUrlType

interface URLDetailsRequest {
  urlId: string
  query?: ParsedUrlQuery
  cookie?: string | null
}

interface LikersRequest {
  urlId: string
  cookie?: string | null
}

interface LikeUrlRequest {
  urlId: string
  user: IntoUser
}

const urlApi = mixApi.injectEndpoints({
  endpoints: builder => ({
    getUrlDetails: builder.query<IntoUrlResponse | undefined, URLDetailsRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: ({ urlId, query, cookie }) => {
        const config = { ...IntoAPI.url.getDetails({ url_id: urlId, query }) }
        if (cookie) {
          config.headers = { ...config.headers, cookie }
        }
        return {
          config,
        }
      },
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}({"url_id":"${queryArgs.urlId}"})}`
      },
      providesTags: (result, error, { urlId }) => {
        return [{ type: 'Url', id: urlId }]
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          const nodes = data?.url[0].nodes
          if (nodes)
            await Promise.all(
              nodes.map(node => dispatch(nodeApi.util.upsertQueryData('getNodeDetails', { slug: node.slug }, node)))
            )
        } catch (_e) {
          /* Do nothing */
        }
      },
    }),
    getLikers: builder.query<IntoUser[] | undefined, LikersRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: ({ urlId, cookie }) => {
        const config = { ...IntoAPI.url.likers({ url_id: urlId }) }
        if (cookie) {
          config.headers = { ...config.headers, cookie }
        }
        return {
          config,
        }
      },
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}({"url_id":"${queryArgs.urlId}"})}`
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data: likers } = await queryFulfilled
          if (likers)
            likers.forEach(liker =>
              dispatch(
                userApi.util.upsertQueryData(
                  'getUserDetails',
                  {
                    userId: liker.user_id,
                  },
                  liker
                )
              )
            )
        } catch (_e) {
          /* Do nothing */
        }
      },
    }),
    likeUrl: builder.mutation<{ context: RecommendResponse | undefined; ok: boolean }, LikeUrlRequest>({
      queryFn: async ({ urlId, user }) => {
        const likeCommand = new RateUrl({
          urlId: Long.fromString(urlId),
          userId: Long.fromNumber(user.user_id),
          ratingType: RateUrlType.RATE_URL_UP,
        })
        const rateUrlPromise = axios.request(MixMix.commands.rateUrl(likeCommand))
        const setIntoUrlPromise = axios.request(IntoAPI.url.setInto({ url_id: urlId }))

        const data = await Promise.all([rateUrlPromise, setIntoUrlPromise]).then(
          ([_rateUrlResponse, setIntoResponse]) => {
            return setIntoResponse.data
          }
        )
        return { data }
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled, getState }) => {
        const { urlId, user } = _args
        let wasDisliked = false
        dispatch(
          urlApi.util.updateQueryData('getUrlDetails', { urlId }, draft => {
            if (draft) {
              const url = draft.url[0]
              if (url.meta.isDisliked) {
                url.meta.isDisliked = false
                wasDisliked = true
              }
              url.meta.isInto = true
            }
          })
        )
        dispatch(
          urlApi.util.updateQueryData('getLikers', { urlId }, draft => {
            draft?.unshift(user)
          })
        )
        try {
          const response = await queryFulfilled
          const { data: setIntoData } = response
          if (setIntoData.ok) {
            const { context } = setIntoData
            if (context) {
              const state = getState() as AppState
              await injectUrlsIntoFeed(dispatch, state, context)
            }
          }
        } catch (_e) {
          dispatch(
            urlApi.util.updateQueryData('getUrlDetails', { urlId }, draft => {
              if (draft) {
                const url = draft.url[0]
                if (wasDisliked) {
                  url.meta.isDisliked = true
                }
                url.meta.isInto = false
              }
            })
          )
          dispatch(
            urlApi.util.updateQueryData('getLikers', { urlId }, draft => {
              if (draft) {
                const index = draft.findIndex(liker => liker.user_id === user.user_id)
                if (index !== -1) {
                  draft?.splice(index, 1)
                }
              }
            })
          )
        }
      },
    }),
    dislikeUrl: builder.mutation<Promise<IntoUrlResponse> | undefined, LikeUrlRequest>({
      queryFn: async ({ urlId, user }) => {
        const dislikeCommand = new RateUrl({
          urlId: Long.fromString(urlId),
          userId: Long.fromNumber(user.user_id),
          ratingType: RateUrlType.RATE_URL_DOWN,
        })
        const rateUrlPromise = axios.request(MixMix.commands.rateUrl(dislikeCommand))
        const deleteIntoUrlPromise = axios.request(IntoAPI.url.deleteInto({ url_id: urlId }))

        const data = Promise.all([rateUrlPromise, deleteIntoUrlPromise]).then(([rateUrlResponse]) => {
          return rateUrlResponse.data
        })
        return { data }
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        const { urlId, user } = _args
        let wasLiked = false
        dispatch(
          urlApi.util.updateQueryData('getUrlDetails', { urlId }, draft => {
            if (draft) {
              const url = draft.url[0]
              if (url.meta.isInto) {
                url.meta.isInto = false
                wasLiked = true
              }
              url.meta.isDisliked = true
            }
          })
        )
        dispatch(
          urlApi.util.updateQueryData('getLikers', { urlId }, draft => {
            if (draft && wasLiked) {
              const index = draft.findIndex(liker => liker.user_id === user.user_id)
              if (index !== -1) {
                draft?.splice(index, 1)
              }
            }
          })
        )
        try {
          await queryFulfilled
        } catch (_error) {
          dispatch(
            urlApi.util.updateQueryData('getUrlDetails', { urlId }, draft => {
              if (draft) {
                const url = draft.url[0]
                if (wasLiked) {
                  url.meta.isInto = true
                }
                url.meta.isDisliked = false
              }
            })
          )
          dispatch(
            urlApi.util.updateQueryData('getLikers', { urlId }, draft => {
              if (wasLiked) draft?.unshift(user)
            })
          )
        }
      },
    }),
    clearLikes: builder.mutation<Promise<IntoUrlResponse> | undefined, LikeUrlRequest>({
      queryFn: async ({ urlId, user }) => {
        const clearLikeCommand = new RateUrl({
          urlId: Long.fromString(urlId),
          userId: Long.fromNumber(user.user_id),
          ratingType: RateUrlType.RATE_URL_NONE,
        })
        const rateUrlPromise = axios.request(MixMix.commands.rateUrl(clearLikeCommand))
        const deleteIntoUrlPromise = axios.request(IntoAPI.url.deleteInto({ url_id: urlId }))

        const data = Promise.all([rateUrlPromise, deleteIntoUrlPromise]).then(([rateUrlResponse]) => {
          return rateUrlResponse.data
        })
        return { data }
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        const { urlId, user } = _args
        let wasDisliked = false
        let wasLiked = false
        dispatch(
          urlApi.util.updateQueryData('getUrlDetails', { urlId }, draft => {
            if (draft) {
              const url = draft.url[0]
              if (url.meta.isInto) {
                url.meta.isInto = false
                wasLiked = true
              }

              if (url.meta.isDisliked) {
                url.meta.isDisliked = false
                wasDisliked = true
              }
            }
          })
        )
        dispatch(
          urlApi.util.updateQueryData('getLikers', { urlId }, draft => {
            if (draft && wasLiked) {
              const index = draft.findIndex(liker => liker.user_id === user.user_id)
              if (index !== -1) {
                draft?.splice(index, 1)
              }
            }
          })
        )
        try {
          await queryFulfilled
        } catch (_error) {
          dispatch(
            urlApi.util.updateQueryData('getUrlDetails', { urlId }, draft => {
              if (draft) {
                const url = draft.url[0]
                if (wasLiked) {
                  url.meta.isInto = true
                }

                if (wasDisliked) {
                  url.meta.isDisliked = true
                }
              }
            })
          )
          dispatch(
            urlApi.util.updateQueryData('getLikers', { urlId }, draft => {
              if (wasLiked) draft?.unshift(user)
            })
          )
        }
      },
    }),
    getSummary: builder.query<IntoUrlSummaryResponse, { urlId: string }>({
      query: ({ urlId }) => ({
        config: IntoAPI.url.getSummary({ url_id: urlId }),
      }),
    }),
  }),
})

export default urlApi
