import { WEEK_TO_SECONDS } from '../../lib/time-constants'
import { keyForResource } from '../../models/APIResource'
import { FeedItem } from '../../models/APIResource'
import { IntoNode } from '../../models/IntoNode'
import { MediaType } from '../../models/MediaTypes'
import { RecommendFilter } from '../../models/RecommendRequest'
import RecommendResponse, { filterResourcesByType } from '../../models/RecommendResponse'
import IntoAPI from '../../services/IntoAPI'
import { setMediaTypes } from '../slices/appSlice'
import { setHasReachedEnd, setIsChangingFeed } from '../slices/feedSlice'
import type { AppDispatch } from '../store/store'
import nodeApi from './nodeApi'
import { mixApi } from './root'
import urlApi from './urlApi'
import userApi from './userApi'

type UniqueInstanceID = string | 'likes' | 'saved' | 'curated'

const RELATED_NODE_COUNT = 5

interface FeedApiRequest {
  page: number
  pageSize?: number
  slug: string
  contextId: string | number | null
  filterIds?: (string | number)[]
  mediaTypes?: MediaType[]
  shouldFetchNodes?: boolean
  _instanceId: UniqueInstanceID | null
  cookie?: string
}
const upsertResources = async (items: (FeedItem | undefined)[], dispatch: AppDispatch) => {
  const nodes = filterResourcesByType(items, 'node')
  if (nodes?.length) {
    await Promise.all(
      nodes.map(node => dispatch(nodeApi.util.upsertQueryData('getNodeDetails', { slug: node.slug }, node)))
    )
  }
  const urls = filterResourcesByType(items, 'url')
  if (urls?.length) {
    await Promise.all(
      urls.map(url => dispatch(urlApi.util.upsertQueryData('getUrlDetails', { urlId: url.url_id }, { url: [url] })))
    )
  }
  const users = filterResourcesByType(items, 'user')
  if (users?.length) {
    await Promise.all(
      users.map(user => dispatch(userApi.util.upsertQueryData('getUserDetails', { userId: user.user_id }, user)))
    )
  }
}
const feedApi = mixApi.injectEndpoints({
  endpoints: builder => ({
    getFeedData: builder.query<RecommendResponse, FeedApiRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: ({ page, pageSize, slug, contextId, filterIds, mediaTypes, shouldFetchNodes, cookie }) => {
        const requestFilter: RecommendFilter = {
          ...(slug === IntoNode.RELATED.slug && {
            url_ids: [contextId],
          }),
          page,
          slug,
          type: {
            url: pageSize,
            node: shouldFetchNodes && page === 1 ? RELATED_NODE_COUNT : undefined,
          },
          mediaTypes: mediaTypes?.length ? mediaTypes : undefined,
          ids: filterIds ?? [],
        }

        const config = { ...IntoAPI.recommend.slug({ filter: requestFilter }) }

        if (cookie) {
          config.headers = { ...config.headers, cookie }
        }

        return {
          config,
        }
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          const { data } = response
          const { items, meta } = data
          const incomingMediaTypes = meta?.mediaTypes

          if (incomingMediaTypes && incomingMediaTypes?.length > 0) {
            dispatch(setMediaTypes(incomingMediaTypes))
          }

          const urls = filterResourcesByType(items, 'url')
          const nodes = filterResourcesByType(items, 'node')

          if (nodes?.length)
            await Promise.all(
              nodes.map(node => dispatch(nodeApi.util.upsertQueryData('getNodeDetails', { slug: node.slug }, node)))
            )

          if (!urls?.length) {
            dispatch(setHasReachedEnd(true))
          }
          dispatch(setIsChangingFeed(false))
        } catch (_err) {
          /* Do nothing */
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs: { slug, _instanceId } }) => {
        return `${endpointName}:${slug}:${_instanceId}`
      },

      merge(currentCache, newState) {
        const existingFeed = currentCache
        if (existingFeed && newState) {
          existingFeed.items = [...existingFeed.items, ...newState.items]
        } else {
          currentCache = newState
        }

        return currentCache
      },
      forceRefetch({ currentArg, previousArg }) {
        const shouldRefetch = currentArg?.page !== previousArg?.page
        return shouldRefetch
      },
    }),
    getFilteredFeed: builder.query<
      RecommendResponse & { hasReachedEnd: boolean },
      { filter?: object; filterIds?: string[]; page: number }
    >({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      async queryFn(arg, { dispatch }, _extraOptions, baseQuery) {
        const { filter, filterIds, page } = arg
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ids = [...((filter as any)?.ids ?? []), ...(filterIds ?? [])]
        try {
          const { error, data: recommendResponse } = await baseQuery({
            config: IntoAPI.recommend.filter({ filter: { ...filter, ids }, page }),
          })

          if (error) return { error }

          const { items } = recommendResponse as RecommendResponse
          await upsertResources(items, dispatch)
          const hasReachedEnd = items?.length === 0
          return { data: { ...(recommendResponse as RecommendResponse), hasReachedEnd } }
        } catch (_err) {
          /* Do nothing */
          return { error: _err }
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}:${JSON.stringify(queryArgs.filter)}`
      },
      merge(currentCache, newItems) {
        if (currentCache && newItems) {
          const existingIds = new Set(currentCache.items.map(item => keyForResource(item)))
          const uniqueNewItems = newItems.items.filter(item => !existingIds.has(keyForResource(item)))

          return {
            ...newItems,
            items: [...(currentCache.items || []), ...uniqueNewItems],
            hasReachedEnd: uniqueNewItems?.length === 0,
          }
        }
        return newItems
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page
      },
    }),
  }),
})

export default feedApi
