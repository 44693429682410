import axios from 'axios'
import React, { useMemo } from 'react'
import toast from 'react-hot-toast'

import { useCommandContext } from '../../context/CommandContext'
import { useCurrentUserContext } from '../../context/CurrentUserContext'
import { useSession } from '../../context/SessionManagementContext'
import { IntoUrl } from '../../models/IntoUrl'
import MixMix from '../../services/MixMix'
import Modal from '../Modal'
import SupportEmailLink from '../SupportEmailLink'

interface ReportModalProps {
  url: IntoUrl
  isVisible: boolean
  onClose: () => void
}
export const UrlReportModal = ({ url, isVisible, onClose }: ReportModalProps) => {
  const { currentUserID, currentUser } = useCurrentUserContext()
  const { session } = useSession()
  const { commandContext } = useCommandContext()

  const markUrlSpam = () => {
    void axios
      .request(MixMix.admin.url.report(url.url_id, 'spam'))
      .then(() => {
        toast.success(`Marked as spam`)
      })
      .catch(() => toast.error(`Failed to mark as spam`))
  }
  const markUrlBroken = () => {
    void axios
      .request(MixMix.admin.url.report(url.url_id, 'dead'))
      .then(() => {
        toast.success(`Marked as broken`)
      })
      .catch(() => toast.error(`Failed to mark as broken`))
  }
  const markUrlNSFW = () => {
    void axios
      .request(MixMix.admin.url.report(url.url_id, 'nsfw'))
      .then(() => {
        toast.success(`Marked as sensitive`)
      })
      .catch(() => toast.error(`Failed to mark as sensitive`))
  }

  const supportEmailSubject = 'Support request from mix.com'
  const supportEmailBody = useMemo(() => {
    return `
_________________________________________________________

Mix user: ${currentUser?.username}(${currentUserID})
Context: ${url.url_id}${
      commandContext.currentContext?.node?.value ? ` on ${commandContext.currentContext.node.value}` : ''
    }
Rec info: ${url.meta.recBatchId}, ${url.meta.recId}
Session: ${session?.id}
`
  }, [
    commandContext.currentContext?.node?.value,
    currentUser?.username,
    currentUserID,
    session?.id,
    url.meta.recBatchId,
    url.meta.recId,
    url.url_id,
  ])

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <div className="max-w-screen z-10 flex flex-col space-y-6 rounded-lg bg-neutral-700 py-4 md:max-w-md">
        <div className="space-y-6 p-2">
          <div className="px-4 text-center font-semibold">Report</div>
          <div className="flex flex-col items-start">
            <button onClick={markUrlSpam} className="p-2 transition-all hover:scale-105">
              This is spam
            </button>
            <button onClick={markUrlBroken} className="p-2 transition-all hover:scale-105">
              This link is broken
            </button>
            <button onClick={markUrlNSFW} className="p-2 transition-all hover:scale-105">
              This shows sensitive content
            </button>
            <SupportEmailLink
              className="p-2 transition-all hover:scale-105"
              subject={supportEmailSubject}
              body={supportEmailBody}
            >
              This is my intellectual property
            </SupportEmailLink>
            <SupportEmailLink
              className="p-2 transition-all hover:scale-105"
              subject={supportEmailSubject}
              body={supportEmailBody}
            >
              I need help with something
            </SupportEmailLink>
          </div>
        </div>
      </div>
    </Modal>
  )
}
