import { FeedItem, keyForResource } from '../models/APIResource'

export const findItemIndexByKey = (currentFeed: FeedItem[] | undefined, currentFeedItemKey: string | number | null) => {
  if (!currentFeed || currentFeed?.length === 0 || !currentFeedItemKey) return -1
  return currentFeed.findIndex(item => {
    const key = keyForResource(item)
    return key === currentFeedItemKey
  })
}

export const findPreviousItem = (items: FeedItem[] | undefined, currentFeedItemKey: string | number | null) => {
  if (!items || !currentFeedItemKey || items?.length === 0) {
    return null
  }
  const currentIndex = findItemIndexByKey(items, currentFeedItemKey)
  if (currentIndex === -1 || currentIndex === 0) {
    return null
  }

  for (let i = currentIndex - 1; i >= 0; i--) {
    const item = items[i]
    if (item.type === 'url' || item.type === 'web_interstitial') return item
  }

  const item = items[currentIndex]
  if (item.type === 'url' || item.type === 'web_interstitial') return item
  return null
}

export const findNextUrlKey = (items: FeedItem[] | undefined, currentFeedItemKey: string | number | null) => {
  if (!items || items?.length === 0) {
    return null
  }
  if (!currentFeedItemKey && items[0].type === 'url') {
    return items[0].url_id
  }

  const currentIndex = findItemIndexByKey(items, currentFeedItemKey)
  for (let i = currentIndex + 1; i < items?.length; i++) {
    const item = items[i]
    if (['url', 'web_interstitial'].includes(item.type)) return keyForResource(item)?.toString() ?? null
  }

  const item = items[currentIndex]
  if (['url', 'web_interstitial'].includes(item.type)) return keyForResource(item)?.toString() ?? null
  return null
}

export function countRemainingUrls(items: FeedItem[], currentFeedItemKey: string | number | null): number {
  const currentIndex = items.findIndex(item => {
    const key = keyForResource(item)
    return key === currentFeedItemKey
  })

  if (currentIndex === -1) {
    return 0
  }

  let iterations = 0

  for (let i = currentIndex + 1; i < items?.length; i++) {
    if (items[i].type === 'url') {
      iterations++
    }
  }

  return iterations
}
