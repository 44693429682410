import * as querystring from 'querystring'

import qs from 'qs'

import runtimeConfig from './RuntimeConfig'

const baseUrl = () => runtimeConfig().publicRuntimeConfig.baseUrl

const UrlHelper = {
  auth: {
    googleIDToken: () => baseUrl() + '/auth/google/id',
  },

  nodePermalink: (slug: string): string => baseUrl() + UrlHelper.nodePath(slug),
  nodePath: (slug: string): string => `/topic/${slug}`,

  userPermalink: (username: string): string => baseUrl() + UrlHelper.userPath(username),
  userPath: (username: string): string => `/${username}`,
  userSubPath: (username: string, page: 'posts' | 'saved' | 'following' | 'followers'): string =>
    `/${username}/${page}`,

  urlPermalink: (urlID: string): string => baseUrl() + UrlHelper.urlIDPath({ urlID }),
  urlIDPath: ({
    urlID,
    likedBy,
    recId,
    recBatchId,
    skipSSR,
  }: {
    urlID: string
    likedBy?: string
    recId?: string
    recBatchId?: string
    skipSSR?: boolean
  }): string =>
    `/!${urlID}${qs.stringify({ liked_by: likedBy, recId, recBatchId, skipSSR }, { addQueryPrefix: true })}`,

  settingsPath: () => '/account',

  asRelativePath: (absoluteUrl?: string) => absoluteUrl?.replace(baseUrl(), ''),

  embedPath: (
    urlID: string,
    options: {
      autoplay: boolean
      controls: boolean
      loop: boolean
      muted: boolean
    }
  ) => `/embed/${urlID}?` + querystring.stringify(options),
}
export default UrlHelper
