import {
  DAY_TO_HOURS,
  HOUR_TO_MINUTES,
  MONTH_TO_WEEKS,
  WEEK_TO_DAYS,
  YEAR_TO_MONTHS,
  YEAR_TO_MS,
} from '../../lib/time-constants'

export const useTimeSince = (date: string) => {
  const now = new Date()
  const timestamp = new Date(date)

  const differenceInYears = (now.getTime() - timestamp.getTime()) / YEAR_TO_MS
  if (differenceInYears > 1) return `${Math.floor(differenceInYears)} yr`

  const differenceInMonths = differenceInYears * YEAR_TO_MONTHS
  if (differenceInMonths > 1) return `${Math.floor(differenceInMonths)} mo`

  const differenceInWeeks = differenceInMonths * MONTH_TO_WEEKS
  if (differenceInWeeks > 1) return `${Math.floor(differenceInWeeks)} wk`

  const differenceInDays = differenceInWeeks * WEEK_TO_DAYS
  if (differenceInDays > 1) {
    const days = Math.floor(differenceInDays)
    return `${days} day${days > 1 ? 's' : ''}`
  }

  const differenceInHours = differenceInDays * DAY_TO_HOURS
  if (differenceInHours > 1) return `${Math.floor(differenceInHours)} hr`

  const differenceInMins = differenceInHours * HOUR_TO_MINUTES
  if (differenceInMins > 1) return `${Math.floor(differenceInMins)} min`

  return 'now'
}
