import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIsomorphicLayoutEffect, useSessionStorage } from 'usehooks-ts'

import IconPlus from '../../icons/IconPlus.svg'
import runtimeConfig from '../../lib/RuntimeConfig'
import UrlHelper from '../../lib/UrlHelper'
import { selectShowGSignIn, setShowGSignIn } from '../../redux/slices/appSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store/store'
import DownloadOptions from './app_download.svg'

const classNameHidden = '-bottom-96 opacity-0'
const classNameVisible = 'bottom-auto'

const SHOW_DELAY = 1000

const PEEKABOO_EXEMPT_ROUTES = ['/signup']

const GSignInPanel = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const showGSignIn = useAppSelector(selectShowGSignIn)

  useEffect(() => {
    if (router.route !== '/home/Home') {
      setTimeout(() => {
        dispatch(setShowGSignIn(true))
      }, SHOW_DELAY)
    }
  }, [router.route, dispatch])

  return (
    <div
      id="g_id_onload"
      className={`-mr-2 transition-all duration-500 [&>div]:!-bottom-[8px] [&>div]:!-right-[8px] [&>div]:!top-auto ${
        showGSignIn ? 'opacity-100' : '!h-0 overflow-hidden opacity-0'
      }`}
      data-prompt_parent_id="g_id_onload"
      data-client_id={runtimeConfig().publicRuntimeConfig.auth.google.clientID}
      data-context="signin"
      data-login_uri={UrlHelper.auth.googleIDToken()}
      data-ux-mode="popup"
      data-auto_select="false"
      data-auto_prompt="true"
      data-itp_support="true"
      data-cancel_on_tap_outside="false"
    />
  )
}

export const DownloadAppPeekabooPanel = () => {
  const { asPath } = useRouter()
  const [isPeekabooHiddenFE, setIsPeekabooHiddenFE] = useState(true)
  const [isPeekabooHidden, setIsPeekabooHidden] = useSessionStorage('hide_peekaboo_panel', false)

  const containerRef = useRef<HTMLDivElement | null>(null)

  useIsomorphicLayoutEffect(() => {
    setIsPeekabooHiddenFE(isPeekabooHidden)
    if (isPeekabooHidden || PEEKABOO_EXEMPT_ROUTES.includes(asPath)) return

    // Show with a bit of an animation.
    setTimeout(() => {
      containerRef.current?.classList.remove(...classNameHidden.split(' '))
      containerRef.current?.classList.add(...classNameVisible.split(' '))
    }, SHOW_DELAY)
  }, [asPath, isPeekabooHidden, setIsPeekabooHiddenFE])

  const hideMe = useCallback(() => {
    containerRef.current?.classList.remove(...classNameVisible.split(' '))
    containerRef.current?.classList.add(...classNameHidden.split(' '))
    setIsPeekabooHidden(true)
  }, [setIsPeekabooHidden])

  return (
    <div className="fixed bottom-6 right-6 z-50 flex items-end gap-6">
      <GSignInPanel />
      {!isPeekabooHiddenFE && (
        <div
          className={[
            'group relative h-48 w-96 select-none transition-all duration-500 ease-in-out',
            classNameHidden,
          ].join(' ')}
          ref={containerRef}
        >
          <button
            className="btn btn-square absolute -right-4 -top-4 z-40 flex flex-wrap content-center justify-center bg-white text-black opacity-0 shadow-lg transition-opacity duration-500 group-hover:opacity-100"
            onClick={() => hideMe()}
          >
            <IconPlus className="size-5 rotate-45" />
          </button>
          <div className="flex size-full items-center space-y-2 rounded-3xl bg-white p-4 drop-shadow-md group-hover:drop-shadow-lg">
            <DownloadOptions className="size-full" />
          </div>
        </div>
      )}
    </div>
  )
}
