import { useTrackClick } from '../../../hooks/analytics/useTrackClick'
import { IntoNode } from '../../../models/IntoNode'
import nodeApi from '../../../redux/api/nodeApi'

export const useToggleNodeFollow = (node: IntoNode | undefined) => {
  const [followNode] = nodeApi.useFollowNodeMutation()
  const [unfollowNode] = nodeApi.useUnfollowNodeMutation()
  const trackClick = useTrackClick()

  return async () => {
    if (!node) return
    try {
      if (node.isFollowing) {
        await unfollowNode({ slug: node.slug })
        trackClick('nodeUnfollow')
      } else {
        await followNode({ slug: node.slug })
        trackClick('nodeFollow')
      }
    } catch (_e) {
      return
    }
  }
}
