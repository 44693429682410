import { CookieValueTypes } from 'cookies-next'

import { WEEK_TO_SECONDS } from '../../lib/time-constants'
import { IntoNode } from '../../models/IntoNode'
import RecommendResponse, { filterResourcesByType } from '../../models/RecommendResponse'
import IntoAPI from '../../services/IntoAPI'
import { mixApi } from '../api/root'
import userApi from './userApi'

interface NodeFollowingApiRequest {
  slug: string
  cookie?: CookieValueTypes
}

interface NodeApiRequest {
  slug: string
  cookie?: CookieValueTypes
}

const nodeApi = mixApi.injectEndpoints({
  endpoints: builder => ({
    getNodeDetails: builder.query<IntoNode | undefined, NodeApiRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: ({ slug, cookie }) => {
        const config = { ...IntoAPI.node.getDetails(slug) }
        if (cookie) {
          config.headers = { ...config.headers, cookie }
        }
        return {
          config,
        }
      },
      serializeQueryArgs: ({ queryArgs: { slug }, endpointName }) => {
        return `${endpointName}({"slug":"${slug}"})}`
      },
      providesTags: (_result, _error, { slug }) => {
        return [{ type: 'Node', id: slug }]
      },
    }),
    getNodeFollowers: builder.query<RecommendResponse | undefined, NodeFollowingApiRequest>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: args => {
        const { slug, cookie } = args
        let config = IntoAPI.node.getFollowers(slug)

        if (cookie) {
          config = { ...config, headers: { ...config.headers, cookie } }
        }
        return {
          config,
        }
      },
      providesTags: (_result, _error, { slug }) => {
        return [{ type: 'Node', id: `${slug}_followers` }]
      },
      serializeQueryArgs: ({ queryArgs: { slug }, endpointName }) => {
        return `${endpointName}({"slug":"${slug}"})}`
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          if (data) {
            const users = filterResourcesByType(data.items, 'user')
            users.forEach(user =>
              dispatch(userApi.util.upsertQueryData('getUserDetails', { userId: user.user_id }, user))
            )
          }
        } catch (_e) {
          /* Do nothing */
        }
      },
    }),
    followNode: builder.mutation<IntoNode, { slug: IntoNode['slug'] }>({
      query: ({ slug }) => {
        const config = { ...IntoAPI.node.follow({ slug }) }

        return {
          config,
        }
      },
      onQueryStarted: ({ slug }, { dispatch, queryFulfilled }) => {
        dispatch(
          nodeApi.util.updateQueryData('getNodeDetails', { slug }, draft => {
            if (draft) {
              draft.isFollowing = true
            }
          })
        )

        queryFulfilled.catch(() => {
          dispatch(
            nodeApi.util.updateQueryData('getNodeDetails', { slug }, draft => {
              if (draft) {
                draft.isFollowing = false
              }
            })
          )
        })
      },
    }),
    unfollowNode: builder.mutation<IntoNode, { slug: IntoNode['slug'] }>({
      query: ({ slug }) => {
        const config = { ...IntoAPI.node.unfollow({ slug }) }

        return {
          config,
        }
      },
      onQueryStarted: ({ slug }, { dispatch, queryFulfilled }) => {
        dispatch(
          nodeApi.util.updateQueryData('getNodeDetails', { slug }, draft => {
            if (draft) {
              draft.isFollowing = false
            }
          })
        )

        queryFulfilled.catch(() => {
          dispatch(
            nodeApi.util.updateQueryData('getNodeDetails', { slug }, draft => {
              if (draft) {
                draft.isFollowing = true
              }
            })
          )
        })
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getDiscoverNodes: builder.query<RecommendResponse, void>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: () => {
        const config = { ...IntoAPI.discover.getFeed() }
        return {
          config,
        }
      },
      providesTags: () => {
        return [{ type: 'Node', id: 'discovery' }]
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          const nodes = filterResourcesByType(data.items, 'node')
          const promises = nodes.map(node => {
            return dispatch(nodeApi.util.upsertQueryData('getNodeDetails', { slug: node.slug }, node))
          })
          await Promise.all(promises)
        } catch (_e) {
          /* Do nothing */
        }
      },
    }),
    getSearchedNodes: builder.query<IntoNode[], { query: string }>({
      keepUnusedDataFor: WEEK_TO_SECONDS,
      query: ({ query }) => {
        const config = { ...IntoAPI.node.search({ query }) }
        return {
          config,
        }
      },
      onQueryStarted: async (_, { dispatch, getState, queryFulfilled }) => {
        try {
          const { data: nodes } = await queryFulfilled
          if (nodes?.length) {
            const cache = getState().api.queries
            const isNodeInCache = (slug: string) => {
              const cacheKey = `getNodeDetails({"slug":"${slug}"})}`
              return cache[cacheKey] && cache[cacheKey]?.status === 'fulfilled'
            }

            await Promise.all(
              nodes.map(node => {
                if (!isNodeInCache(node.slug))
                  void dispatch(nodeApi.util.upsertQueryData('getNodeDetails', { slug: node.slug }, node))
              })
            )
          }
        } catch (_e) {
          /* Do nothing */
        }
      },
      transformResponse: (response: RecommendResponse) => {
        return filterResourcesByType(response.items, 'node')
      },
    }),
  }),
})

export default nodeApi
