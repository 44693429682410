import axios from 'axios'

export interface APIErrorResponse {
  message: string
}

export const errorToHelpfulMessage = (error: Error | unknown) =>
  axios.isAxiosError(error)
    ? (error.response?.data?.message ?? error.response?.data?.err?.friendlyMessage ?? error.message)
    : error instanceof Error
      ? error.message
      : 'Something went wrong'
