import { ConnectedRouter } from 'connected-next-router'
import { ReactNode } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { SWRConfig } from 'swr'

import { CommandContextProvider } from '../context/CommandContext'
import { CurrentUserContextProvider } from '../context/CurrentUserContext'
import { GlobalContextProvider } from '../context/GlobalContexts'
import { SessionManagementProvider } from '../context/SessionManagementContext'
import { ThemeProvider } from '../context/ThemeContext'
import { BrowserInfo } from '../lib/browser'
import { commandContextSWRMiddleware } from '../lib/commands/commandContextSWRMiddleware'
import InternalServerErrorPage from '../pages/500.page'
import { AppStore } from '../redux/store/store'
import { MessageServiceProvider } from '../tracking/context/MessageServiceContext'
import ErrorBoundary from './ErrorBoundary'

interface AppProvidersProps {
  children: ReactNode
  store: AppStore
  browserInfo: BrowserInfo
  isLoggedIn: boolean
  pageName?: string
}

export const AppProviders = ({ children, store, browserInfo, isLoggedIn, pageName }: AppProvidersProps) => {
  return (
    <ThemeProvider>
      <ReduxProvider store={store}>
        <ConnectedRouter>
          <GlobalContextProvider browserInfo={browserInfo} pageName={pageName}>
            <CommandContextProvider>
              <SessionManagementProvider>
                <CurrentUserContextProvider isLoggedInFromCookies={isLoggedIn}>
                  <SWRConfig value={{ use: [commandContextSWRMiddleware] }}>
                    <MessageServiceProvider>
                      <ErrorBoundary fallbackComponent={<InternalServerErrorPage disableTracking={false} />}>
                        {children}
                      </ErrorBoundary>
                    </MessageServiceProvider>
                  </SWRConfig>
                </CurrentUserContextProvider>
              </SessionManagementProvider>
            </CommandContextProvider>
          </GlobalContextProvider>
        </ConnectedRouter>
      </ReduxProvider>
    </ThemeProvider>
  )
}
