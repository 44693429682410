import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { AppState } from '../../store/store'

export interface AddUrlModalSlice {
  isAddUrlModalOpen: boolean
  url: string | undefined | null
  _metadata: { updatedFields: string[] }
}

const initialState: AddUrlModalSlice = {
  isAddUrlModalOpen: false,
  url: null,
  _metadata: { updatedFields: [] },
}

const addUrlModalSlice = createSlice({
  name: 'addUrlModal',
  initialState,
  reducers: {
    setIsAddUrlModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isAddUrlModalOpen = action.payload
      state._metadata.updatedFields.push('isAddUrlModalOpen')
    },
    setUrl: (state, action: PayloadAction<string | undefined | null>) => {
      state.url = action.payload
      state._metadata.updatedFields.push('url')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.addUrlModal

        const fieldsToCheck: (keyof AddUrlModalSlice)[] = ['isAddUrlModalOpen', 'url']

        function updateField<K extends keyof AddUrlModalSlice>(
          state: AddUrlModalSlice,
          field: K,
          value: AddUrlModalSlice[K]
        ): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (
            serverApp._metadata.updatedFields.includes(field) &&
            serverApp[field] !== undefined &&
            serverApp[field] !== state[field]
          ) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const { setIsAddUrlModalOpen, setUrl } = addUrlModalSlice.actions
export default addUrlModalSlice.reducer
