import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { useCurrentUserContext } from '../../context/CurrentUserContext'
import { useTrackClick } from '../../hooks/analytics/useTrackClick'
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen'
import IconBell from '../../icons/IconBell.svg'
import IconHome from '../../icons/IconHome.svg'
import IconPlus from '../../icons/IconPlus.svg'
import IconSearch from '../../icons/IconSearch.svg'
import UrlHelper from '../../lib/UrlHelper'
import { IntoUser } from '../../models/IntoUser'
import notificationsApi from '../../redux/api/notificationsApi'
import { setIsAddUrlModalOpen } from '../../redux/slices/modals/addUrlModalSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store/store'
import { ContextMenu } from '../ContextMenu'
import { NotificationsMenu } from '../notifications/NotificationsMenu'
import { ToolbarTitle } from '../toolbar/ToolbarFeedTitle'
import { ToolbarActions } from '../toolbar/ToolbarUrlActions'
import Header from './Header'

const NotificationsIcon = () => {
  const { data } = notificationsApi.useGetUnreadCountQuery()
  return (
    <div>
      {data?.notifications && data?.notifications > 0 ? (
        <div className="absolute -right-2 -top-2 z-50 flex size-5 items-center justify-center rounded-full bg-accent p-1 text-xs font-bold">
          {data?.notifications}
        </div>
      ) : (
        <></>
      )}
      <div className="flex size-9 cursor-pointer p-2 text-primary opacity-50 transition-all duration-150 hover:rounded-full hover:bg-contrast/5 hover:text-primary hover:opacity-100">
        <IconBell />
      </div>
    </div>
  )
}

const LoggedInHeaderUser = () => {
  const { currentUser: currentUserClientSide } = useCurrentUserContext()
  const [currentUser, setCurrentUser] = useState<IntoUser | undefined>(undefined)
  const trackClick = useTrackClick()

  useEffect(() => {
    setCurrentUser(currentUserClientSide)
  }, [currentUserClientSide])

  if (!currentUser) return <div className="aspect-square w-10 animate-pulse rounded-full bg-contrast/10" />
  return (
    <div className="relative inline-block">
      <div
        slot="trigger"
        className="group relative cursor-pointer hover:before:fixed hover:before:right-0 hover:before:top-0 hover:before:block hover:before:size-24"
      >
        <ContextMenu position="topRight" />
        <Link
          href={UrlHelper.userPath(currentUser.username)}
          key="user-option"
          as={UrlHelper.userPermalink(currentUser.username)}
          className="relative z-20"
          onClick={() => trackClick('userProfileMenu')}
        >
          <Image
            className="aspect-square rounded-full"
            src={currentUser.photo_url ?? '/avatar-dark.svg'}
            alt={currentUser.username}
            height={40}
            width={40}
          />
        </Link>
      </div>
    </div>
  )
}

const LoggedInHeader = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const isDiscoverActive = router.route === '/discover'
  const [currentUser, setCurrentUser] = useState<IntoUser | undefined>(undefined)

  const { currentUser: currentUserClientSide } = useCurrentUserContext()
  const isSmallScreen = useIsSmallScreen()
  const trackClick = useTrackClick()

  const { isTitleEnabled } = useAppSelector(state => state.header)
  const { identifier } = useAppSelector(state => state.feed)

  useEffect(() => {
    setCurrentUser(currentUserClientSide)
  }, [currentUserClientSide])

  const isOnForYou = 'slug' in identifier && identifier.slug === 'for-you'

  return (
    <Header className="relative flex px-4 sm:px-6">
      <div className="flex w-full min-w-0 items-center space-x-2">
        <a
          className={`size-9 shrink-0 p-2 ${
            isOnForYou
              ? ''
              : 'text-primary opacity-50 transition-all duration-150 hover:rounded-full hover:bg-contrast/5 hover:text-primary hover:opacity-100'
          }`}
          href="/"
          key="home-option"
          onClick={() => trackClick('home')}
        >
          <IconHome />
        </a>
        {isTitleEnabled ? <ToolbarTitle isSmallScreen={isSmallScreen} identifier={identifier} /> : <></>}
      </div>
      <ToolbarActions />

      <div className="flex w-full items-center justify-end space-x-6">
        <div
          className={`${
            currentUser?.isEmployee ? 'flex' : 'hidden'
          } size-9 cursor-pointer p-2 text-primary opacity-50 transition-all duration-150 hover:rounded-full hover:bg-contrast/5 hover:text-primary hover:opacity-100`}
          onClick={() => {
            dispatch(setIsAddUrlModalOpen(true))
            trackClick('add')
          }}
        >
          <IconPlus />
        </div>

        <Link
          className={`hidden size-9 p-2 ${
            isDiscoverActive ? '' : 'text-primary opacity-50 hover:rounded-full hover:bg-contrast/5 hover:text-primary'
          } hover:opacity-100 sm:block`}
          href="/discover"
          key="search-option"
          onClick={() => trackClick('search')}
        >
          <IconSearch />
        </Link>
        <div
          slot="trigger"
          className="group relative flex cursor-pointer hover:before:fixed hover:before:right-0 hover:before:top-0 hover:before:block hover:before:h-24 hover:before:w-60"
        >
          <NotificationsIcon />
          <NotificationsMenu />
        </div>
        <div className="size-10 shrink-0 grow-0">
          <LoggedInHeaderUser />
        </div>
      </div>
    </Header>
  )
}

export default LoggedInHeader
