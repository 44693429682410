import Script from 'next/script'
import React from 'react'

import { useCurrentUserContext } from '../context/CurrentUserContext'
import { useGlobalContext } from '../context/GlobalContexts'
import runtimeConfig from './RuntimeConfig'

export const GoogleRecaptcha = () => {
  const reCaptchaKey = runtimeConfig().publicRuntimeConfig.auth.reCaptchaV3Key
  const { isLoggedIn } = useCurrentUserContext()
  const { browserInfo } = useGlobalContext()
  if (isLoggedIn || browserInfo.browser.bot) return null
  return (
    <>
      <Script src={`https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`} />
    </>
  )
}
