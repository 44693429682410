import { Switch } from '@headlessui/react'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { TweaksSlice, selectTweaksState, setTweaksState } from '../redux/slices/tweaksSlice'
import { useAppDispatch, useAppSelector } from '../redux/store/store'
import Modal from './Modal'

interface Tweak {
  id: string
  title: string
  description?: string
}

const showDebugInfo: Tweak = {
  id: 'showDebugInfo',
  title: 'Show debug info',
}

const isCanary: Tweak = {
  id: 'isCanary',
  title: 'Use into canary',
  description: 'Use canary-into.mix.com instead of using into.mix.com.',
}

const enableFullScreenMode: Tweak = {
  id: 'enableFullScreenMode',
  title: 'Enable full screen mode',
}

const showBusinessEventsModal: Tweak = {
  id: 'showBusinessEventsModal',
  title: 'Show Business Events Modal',
  description: 'Display a modal with business events for development purposes.',
}

const TWEAK_SECTIONS = [
  {
    title: 'Dev',
    tweaks: [showDebugInfo, isCanary, enableFullScreenMode, showBusinessEventsModal],
  },
]

const TweakRow = (tweak: Tweak) => {
  const dispatch = useAppDispatch()
  const tweaksState = useAppSelector(selectTweaksState)
  const isOn = tweaksState[tweak.id as keyof Omit<TweaksSlice, '_metadata'>]

  const toggleValue = useCallback(
    (isChecked: boolean) => {
      dispatch(setTweaksState({ key: tweak.id, isChecked }))
    },
    [tweak, dispatch]
  )

  return (
    <div className="flex border-b border-menu/5 py-3">
      <div className="flex w-10/12 flex-col">
        <div>{tweak.title}</div>
        {tweak.description && <div className="w-10/12 text-sm text-menu/40">{tweak.description}</div>}
      </div>
      <div className="w-2/12">
        <Switch
          checked={isOn}
          onChange={toggleValue}
          className={`${
            isOn ? 'bg-accent' : 'bg-gray-200 light:bg-gray-300'
          } relative inline-flex h-6 w-11 cursor-pointer items-center rounded-full`}
        >
          <span
            className={`${
              isOn ? 'translate-x-6' : 'translate-x-1'
            } inline-block size-4 rounded-full bg-white transition`}
          />
        </Switch>
      </div>
    </div>
  )
}

const TweakSection = ({ title, tweaks }: { title: string; tweaks: Tweak[] }) => (
  <>
    {tweaks?.length ? (
      <div key={title} className="mt-3 font-medium">
        {title}:
      </div>
    ) : (
      <></>
    )}
    {tweaks.map(t => (
      <TweakRow key={t.id} {...t} />
    ))}
  </>
)

export const TweaksModal = ({ isVisible, onClose }: { isVisible: boolean; onClose: (value: boolean) => void }) => {
  const router = useRouter()

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <div className="xs:w-[30rem] relative z-10 flex max-h-screen w-full flex-col rounded bg-menu p-6 text-menu">
        <div className="my-6 self-center text-2xl font-semibold">Tweaks</div>
        <div className="scrollbar-primary flex max-h-96 flex-col overflow-y-scroll">
          {TWEAK_SECTIONS.map(section => (
            <TweakSection key={section.title} {...section} />
          ))}
        </div>
        <div className="mt-6 text-center">
          <button
            onClick={async () => {
              await router.replace(router.asPath).catch(() => {
                window.location.reload()
              })
              onClose(false)
            }}
            className="btn btn-secondary cursor-pointer"
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}
