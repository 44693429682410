// hooks/useServiceWorker.ts
import { useEffect } from 'react'

import logger from '../lib/logger'

const useRootServiceWorker = () => {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(registration => {
          logger.info('Service worker registration successful with scope:', registration.scope)
        })
        .catch(e => {
          logger.warn('Service worker registration failed', e)
        })
    }
  }, [])
}

export default useRootServiceWorker
