import axios, { InternalAxiosRequestConfig } from 'axios'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

import { useSegmentTrack } from '../hooks/analytics/useSegmentAnalytics'
import { overrideSessionIDInterceptor } from '../lib/commands/overrideCommandContextInterceptor'
import logger from '../lib/logger'
import { commands, globals } from '../lib/messages/protobuf'
import MixMix from '../services/MixMix'
import { useCurrentUserContext } from './CurrentUserContext'
import { useSession } from './SessionManagementContext'

import CommandContext = commands.CommandContext
import TrackUserSession = commands.TrackUserSession
import SessionEventType = globals.SessionEventType

const getBranchData = (): Promise<{
  identityId: string
  params: { [k: string]: string }
}> => {
  const parseBranchData = (data: object, prefix: string) =>
    Object.fromEntries(Object.entries(data).map(([key, value]) => [`${prefix}${key}`, value?.toString()]))

  return new Promise((resolve, reject) => {
    try {
      const branchSession = JSON.parse(window.sessionStorage.getItem('branch_session') ?? 'null')
      if (!window.branch || !branchSession) {
        return reject(new Error('Branch is not available'))
      }
      window.branch.first?.((err, firstReferringParams) => {
        if (err) return reject(err)
        window.branch?.data?.((err, latestReferringParams) => {
          if (err) return reject(err)
          resolve({
            identityId: branchSession.identity_id,
            params: {
              ...(firstReferringParams.data !== '' &&
                parseBranchData(JSON.parse(firstReferringParams.data), 'branch_first_referring_param_')),
              ...parseBranchData(latestReferringParams.data_parsed, 'branch_latest_referring_param_'),
            },
          })
        })
      })
    } catch (err) {
      return reject(err)
    }
  })
}

export const ObserveSessionID = () => {
  const router = useRouter()
  const { session, setSessionInfo, getCurrentSession } = useSession()

  const setSessionIDInterceptor = useCallback(
    (
      config: InternalAxiosRequestConfig<{ context?: CommandContext }>
    ): InternalAxiosRequestConfig<{ context?: CommandContext }> => {
      if (!config.baseURL?.includes('mix.com')) return config
      const sessionId = getCurrentSession().id
      config.headers['x-session-id'] = sessionId

      // Set session info if this is a request with session info.
      if (config.data?.context?.session) {
        config.data.context.session.sessionId = { value: sessionId }
        config.data.context.session.clientSessionId = { value: sessionId }
      }
      return config
    },
    [getCurrentSession]
  )
  //#endregion

  //#region React dependent stuff

  const { currentUserID } = useCurrentUserContext()
  const track = useSegmentTrack()
  const trackUserSession = useCallback(
    (
      eventType: SessionEventType,
      currentUserID: number,
      sessionId: string,
      branchData?: {
        identityId: string
        params: {
          [k: string]: string
        }
      }
    ) => {
      const {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_term: utmTerm,
        utm_content: utmContent,
        ...rest
      } = Object.fromEntries(Object.entries(router.query).filter(([key]) => key.startsWith('utm_')))
      const customParams = Object.fromEntries(
        Object.entries(rest).map(([key, value]) => {
          if (!key.startsWith('utm_mix_')) return []
          return [key.replace('utm_mix_', ''), value]
        })
      )
      axios
        .request(
          MixMix.commands.trackUserSession(
            new TrackUserSession({
              clientSession: {
                id: { value: sessionId },
                track: {
                  utmSource: { value: Array.isArray(utmSource) ? utmSource[0] : utmSource },
                  utmMedium: { value: Array.isArray(utmMedium) ? utmMedium[0] : utmMedium },
                  utmCampaign: { value: Array.isArray(utmCampaign) ? utmCampaign[0] : utmCampaign },
                  utmTerm: { value: Array.isArray(utmTerm) ? utmTerm[0] : utmTerm },
                  utmContent: { value: Array.isArray(utmContent) ? utmContent[0] : utmContent },
                  ...(customParams && {
                    utmCustomParams: customParams,
                  }),
                },
                branch: {
                  identityId: { value: branchData?.identityId },
                  params: branchData?.params,
                },
              },
              eventType,
              userId: currentUserID,
            })
          )
        )
        .then(undefined)
        .catch(logger.warn.bind(logger))
    },
    [router.query]
  )

  useEffect(() => {
    if (currentUserID && session?.id && !session.started) {
      setSessionInfo(previousSession => {
        if (!previousSession) return null
        previousSession.started = true
      })
      track('sessionStarted')
      getBranchData()
        .then(branchData => {
          trackUserSession(SessionEventType.SESSION_STARTED, currentUserID, session.id, branchData)
        })
        .catch(() => trackUserSession(SessionEventType.SESSION_STARTED, currentUserID, session.id))
    }
  }, [currentUserID, session?.id, session?.started, setSessionInfo, trackUserSession, track])

  useEffect(() => {
    overrideSessionIDInterceptor(setSessionIDInterceptor)
  }, [setSessionIDInterceptor])

  //#endregion
  return <></>
}
