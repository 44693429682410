import { useEffect } from 'react'

import RecommendResponse from '../../models/RecommendResponse'
import { useInjectUrlsIntoFeed } from '../../redux/utils/useInjectUrlsIntoFeed'
import { useMessageService } from '../context/MessageServiceContext'
import { ServiceWorkerMessageTag } from '../utils/tags'

export interface IExpliotResponse {
  tag: ServiceWorkerMessageTag
  ok: boolean
  context?: RecommendResponse
}

export const useRegisterPassiveExploit = () => {
  const { addHandler, removeHandler } = useMessageService()

  const injectUrls = useInjectUrlsIntoFeed()
  useEffect(() => {
    const tag = 'TrackContentDwell'
    const handlePassiveExpliot = (data: IExpliotResponse) => {
      injectUrls(data.context).catch(() => {})
    }
    addHandler(tag, handlePassiveExpliot)
    return () => removeHandler(tag, handlePassiveExpliot)
  }, [addHandler, removeHandler, injectUrls])
}
