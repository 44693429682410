import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useGlobalContext } from '../context/GlobalContexts'
import { AlphaNumericChar } from '../lib/AlphaNumericChar'
import { selectShowBusinessEventsModal } from '../redux/slices/tweaksSlice'

type KeyboardAction = (event?: KeyboardEvent) => void
type KeyActionMapping = Partial<Record<AlphaNumericChar, KeyboardAction>>
type CodeActionMapping = Partial<Record<KeyboardEvent['code'], KeyboardAction>>

const useKeyboardShortcut = (mappings: { keys?: KeyActionMapping; codes?: CodeActionMapping }) => {
  const { isModalOpen } = useGlobalContext()
  const showBusinessEventsModal = useSelector(selectShowBusinessEventsModal)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isModalOpen || showBusinessEventsModal) return
      // CMD + L shouldn't trigger keyboard event for "L"
      if (event.metaKey) return
      const keyAction = mappings.keys?.[event.key.toLowerCase() as AlphaNumericChar]
      if (keyAction) {
        event.preventDefault()
        return keyAction(event)
      }
      const codeAction = mappings.codes?.[event.code]
      if (codeAction) {
        event.preventDefault()
        return codeAction(event)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [mappings, isModalOpen, showBusinessEventsModal])
}

export default useKeyboardShortcut
