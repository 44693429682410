import { Action, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

import { RootState } from '../store/store'
import { axiosBaseQuery } from './baseQuery'

const isHydrateAction = (action: Action): action is PayloadAction<RootState> => action.type === HYDRATE

export const mixApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['User', 'Node', 'Url', 'Feed'],
  /* return type 'any' required to prevent circular dependency: https://redux-toolkit.js.org/rtk-query/usage/persistence-and-rehydration#redux-persist */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extractRehydrationInfo(action, { reducerPath }): any {
    if (isHydrateAction(action)) {
      return action.payload[reducerPath]
    }
  },
  endpoints: () => ({}),
})
