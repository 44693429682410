import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import { useIsDesktop } from '../hooks/useIsDesktop'
import runtimeConfig from '../lib/RuntimeConfig'
import Taglines from '../pages/home/components/tagline'

interface HomePageMetaTagsProps {
  title?: string
  description?: string
  image?: string
  permalink?: string
  preloadImages?: string[]
}

const fallbackImage = 'https://assets.mix.com/assets/img/mix-dot-com-cad689f34536e269215f9e95e3973593.jpg'

export const MetaTags = ({
  title = `Mix – ${Taglines.default.copy.heading}`,
  description = Taglines.default.copy.subheading,
  image = fallbackImage,
  permalink,
  preloadImages,
}: HomePageMetaTagsProps) => {
  const config = runtimeConfig().publicRuntimeConfig
  const { asPath, pathname } = useRouter()
  const currentUrl = (() => {
    // Reverse rewrite: mix.com/user/:username -> mix.com/:username
    const basePathRewritten = asPath.replace(/^\/user/, '')
    return permalink ?? config.baseUrl + basePathRewritten
  })()
  const currentUrlNative = currentUrl.replace(/^(https?)/, 'mix')
  const isDesktop = useIsDesktop()
  const utmMedium = isDesktop ? 'web' : 'web_mobile'
  // /url/[url_id] -> url/url_id
  // /user/[username] -> user/username
  // /node/[slug] -> node/slug
  // /home/Home -> home/home
  const utmCampaign = pathname
    .replace(/^\//, '')
    .replace(/[\\[\]]/g, '')
    .toLowerCase()
  return (
    <Head>
      <link rel="icon" href="/favicon.ico" key="favicon.ico" />
      <link rel="icon" href="/favicon.png" sizes="32x32" key="favicon.png" />
      <link rel="canonical" href={currentUrl} key="link.canonical" />
      <link rel="home" href={currentUrl} key="link.home" />
      <meta name="referrer" content="always" key="referrer" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=0"
        key="viewport"
      />
      <meta property="fb:app_id" content="1633239056950945" key="fb:app_id" />
      <meta
        name="google-site-verification"
        content="DRwMRXEBYGtZLT9oxw2HnjLDkh8y_MhJADnD3LmiCM4"
        key="google-site-verification"
      />
      <meta name="msvalidate.01" content="E4FA407B6FB31C729CB006EBABE76C31" key="msvalidate.01" />
      <meta property="al:web:should_fallback" content="false" key="al:web:should_fallback" />
      <title key="title">{title}</title>
      {/* OG*/}
      <meta property="og:type" content="website" key="og:type" />
      <meta property="og:title" content={title} key="og:title" />
      <meta property="og:description" content={description} key="og:description" />
      <meta
        property="og:url"
        content={`${currentUrl}?utm_source=facebook&amp;utm_medium=${utmMedium}&amp;utm_campaign=${utmCampaign}&amp;utm_term=meta`}
        key="og:url"
      />
      <meta property="og:site_name" content="Mix" key="og:site_name" />
      <meta property="og:image" content={image} key="og:image" />
      {/* TW*/}
      <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
      <meta name="twitter:site" content="@getmixapp" key="twitter:site" />
      <meta name="twitter:title" content={title} key="twitter:title" />
      <meta name="twitter:description" content={description} key="twitter:description" />
      <meta name="twitter:image" content={image} key="twitter:image" />
      <meta
        name="twitter:url"
        content={`${currentUrl}?utm_source=twitter&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_term=meta`}
        key="twitter:url"
      />
      <meta name="twitter:app:country" content="US" key="twitter:app:country" />
      <meta name="twitter:app:name:iphone" content="Mix" key="twitter:app:name:iphone" />
      <meta name="twitter:app:id:iphone" content="1092817691" key="twitter:app:id:iphone" />
      <meta name="twitter:app:url:iphone" content={currentUrlNative} key="twitter:app:url:iphone" />
      {/* APP*/}
      <meta property="al:ios:app_store_id" content="1092817691" key="al:ios:app_store_id" />
      <meta property="al:ios:app_name" content="Mix" key="al:ios:app_name" />
      <meta property="al:ios:url" content={currentUrlNative} key="al:ios:url" />
      <meta property="al:android:package" content="com.mix.android" key="al:android:package" />
      <meta property="al:android:app_name" content="Mix" key="al:android:app_name" />
      <meta property="al:android:url" content={currentUrlNative} key="al:android:url" />
      <meta
        name="apple-itunes-app"
        content={`app-id=1092817691, app-argument=${currentUrl}?utm_source=website&amp;utm_medium=${utmMedium}&amp;utm_campaign=${utmCampaign}&amp;utm_term=meta`}
        key="apple-itunes-app"
      />
      {preloadImages?.map(image => <link key={image} rel="preload" as="image" href={image} />)}
    </Head>
  )
}
