import { useModalActions } from '../../../components/Modal'
import { UrlShareModal } from '../../../components/share/UrlShareModal'
import { useTrackClick } from '../../../hooks/analytics/useTrackClick'
import IconShare from '../../../icons/IconShare.svg'
import { IntoUrl } from '../../../models/IntoUrl'

export const UrlShareActions = ({ url, buttonClassName }: { url: IntoUrl; buttonClassName?: string }) => {
  const { isVisible, toggleVisibility } = useModalActions()
  const trackClick = useTrackClick()

  return (
    <>
      <button
        className={`rounded-full text-primary opacity-50 transition-all duration-150 hover:bg-contrast/5 hover:text-primary hover:opacity-100 ${buttonClassName}`}
        onClick={() => {
          trackClick('urlShare')
          toggleVisibility()
        }}
      >
        <IconShare className="size-10 p-2" />
      </button>
      <UrlShareModal url={url} isVisible={isVisible} onClose={toggleVisibility} />
    </>
  )
}
