import React, { useMemo, useState } from 'react'

import { useTrackClick } from '../../hooks/analytics/useTrackClick'
import { useRequest } from '../../hooks/useRequest'
import IconArrowMin from '../../icons/IconArrowMin.svg'
import { IntoNode } from '../../models/IntoNode'
import { filterResourcesByType } from '../../models/RecommendResponse'
import {
  setCurrentFeedItemKey,
  setIdentifier,
  setInstanceId,
  setIsChangingFeed,
  setPageNumber,
} from '../../redux/slices/feedSlice'
import { setUserId } from '../../redux/slices/userSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store/store'
import IntoAPI from '../../services/IntoAPI'
import { HoverMenu } from '../HoverMenu'

export const TopicSwitcherSection = ({ nodes, section }: { nodes: IntoNode[]; section: string }) => {
  const dispatch = useAppDispatch()
  const { identifier } = useAppSelector(state => state.feed)
  const trackClick = useTrackClick()

  return (
    <div className="flex flex-col space-y-2">
      <span className="text-xs font-light leading-loose text-primary/90">{section}</span>
      {nodes.map((node, index) => (
        <button
          className={`${node.slug === identifier.slug ? 'font-medium' : 'font-light'} cursor-pointer pr-2 text-left hover:font-medium`}
          key={node.slug}
          value={node.slug}
          disabled={node.slug === identifier.slug}
          onClick={() => {
            const _instanceId = Date.now().toString()
            dispatch(setIsChangingFeed(true))
            dispatch(setCurrentFeedItemKey(null))
            dispatch(setInstanceId(_instanceId))
            dispatch(setPageNumber(1))
            dispatch(setIdentifier(node))
            dispatch(setUserId(undefined))
            trackClick('switcherSelection', {
              section,
              slug: node.slug,
              index,
            })
          }}
        >
          {node.name}
        </button>
      ))}
    </div>
  )
}

export const ToolbarTopicSwitcher = ({ identifier }: { identifier: IntoNode }) => {
  const { data } = useRequest(IntoAPI.node.getFollowedNodes({ page: 1 }))
  const [isOpen, setIsOpen] = useState(false)

  const followedNodes = useMemo(() => {
    return filterResourcesByType(data?.items, 'node')
  }, [data?.items])

  const curatedNodes = [IntoNode.FOR_YOU, IntoNode.POPULAR, IntoNode.MY_INTERESTS]

  return (
    <div
      slot="trigger"
      className="group relative flex cursor-pointer hover:before:fixed hover:before:top-0 hover:before:block hover:before:h-24 hover:before:w-60"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="group z-50 flex shrink-0 px-3 py-2 text-contrast outline-none transition-all duration-150 hover:rounded-xl hover:bg-contrast/5">
        <IconArrowMin
          className={`${isOpen ? 'rotate-90 pb-0 pr-2 pt-4' : ''} size-7 -scale-x-100 p-2 pr-0 text-primary opacity-50 transition-all group-hover:opacity-100`}
        />
        <div className="min-w-0 whitespace-nowrap text-lg font-semibold text-primary">{identifier.name}</div>
      </div>
      <HoverMenu position="topLeft">
        <div className="scrollbar-menu-rounded max-h-[80vh] w-60 overflow-x-hidden whitespace-break-spaces rounded-xl bg-primary">
          <div className="flex flex-col space-y-3 bg-contrast/[.08] p-4 text-lg outline-none light:bg-menu">
            <TopicSwitcherSection nodes={curatedNodes} section={'DISCOVER'} />
            {followedNodes?.length > 0 && <TopicSwitcherSection nodes={followedNodes} section={'FOLLOWING'} />}
          </div>
        </div>
      </HoverMenu>
    </div>
  )
}
