import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { AppDispatch, AppState } from '../store/store'

interface HeaderState {
  isUrlActionsEnabled: boolean
  isUrlLikesEnabled: boolean
  isTitleEnabled: boolean
  isGridActionsEnabled: boolean
  isSharingEnabled: boolean
  isDisabled: boolean
  isFiltersEnabled: boolean
  _metadata: {
    updatedFields: (keyof HeaderState)[]
  }
}

const initialState: HeaderState = {
  isUrlActionsEnabled: false,
  isUrlLikesEnabled: true,
  isTitleEnabled: false,
  isGridActionsEnabled: false,
  isSharingEnabled: false,
  isDisabled: false,
  isFiltersEnabled: false,
  _metadata: { updatedFields: [] },
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setUrlActionsEnabled: (state, action) => {
      state.isUrlActionsEnabled = action.payload
      state._metadata.updatedFields.push('isUrlActionsEnabled')
    },
    setUrlLikesEnabled: (state, action) => {
      state.isUrlLikesEnabled = action.payload
      state._metadata.updatedFields.push('isUrlLikesEnabled')
    },
    setTitleEnabled: (state, action) => {
      state.isTitleEnabled = action.payload
      state._metadata.updatedFields.push('isTitleEnabled')
    },
    setGridActionsEnabled: (state, action) => {
      state.isGridActionsEnabled = action.payload
      state._metadata.updatedFields.push('isGridActionsEnabled')
    },
    setSharingEnabled: (state, action) => {
      state.isSharingEnabled = action.payload
      state._metadata.updatedFields.push('isSharingEnabled')
    },
    setIsDisabled: (state, action) => {
      state.isDisabled = action.payload
      state._metadata.updatedFields.push('isDisabled')
    },
    setIsFiltersEnabled: (state, action) => {
      state.isFiltersEnabled = action.payload
      state._metadata.updatedFields.push('isFiltersEnabled')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverFeed = action.payload.header
        const fieldsToCheck: (keyof HeaderState)[] = [
          'isUrlActionsEnabled',
          'isUrlLikesEnabled',
          'isTitleEnabled',
          'isGridActionsEnabled',
          'isSharingEnabled',
          'isDisabled',
          'isFiltersEnabled',
        ]

        function updateField<K extends keyof HeaderState>(state: HeaderState, field: K, value: HeaderState[K]): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (serverFeed._metadata.updatedFields.includes(field) && serverFeed[field] !== state[field]) {
            updateField(state, field, serverFeed[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const {
  setUrlActionsEnabled,
  setUrlLikesEnabled,
  setTitleEnabled,
  setGridActionsEnabled,
  setSharingEnabled,
  setIsDisabled,
  setIsFiltersEnabled,
} = headerSlice.actions

interface HeaderStateHookProps {
  isUrlActionsEnabled: boolean
  isUrlLikesEnabled?: boolean
  isTitleEnabled: boolean
  isGridActionsEnabled: boolean
  isSharingEnabled: boolean
  isDisabled: boolean
  isFiltersEnabled: boolean
}

export const useSetHeaderState = ({
  isUrlActionsEnabled = false,
  isUrlLikesEnabled = true,
  isTitleEnabled = false,
  isGridActionsEnabled = false,
  isSharingEnabled = false,
  isDisabled = false,
  isFiltersEnabled = false,
}: HeaderStateHookProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setUrlActionsEnabled(isUrlActionsEnabled))
    dispatch(setUrlLikesEnabled(isUrlLikesEnabled))
    dispatch(setTitleEnabled(isTitleEnabled))
    dispatch(setGridActionsEnabled(isGridActionsEnabled))
    dispatch(setSharingEnabled(isSharingEnabled))
    dispatch(setIsDisabled(isDisabled))
    dispatch(setIsFiltersEnabled(isFiltersEnabled))
  }, [
    dispatch,
    isUrlActionsEnabled,
    isUrlLikesEnabled,
    isTitleEnabled,
    isGridActionsEnabled,
    isSharingEnabled,
    isDisabled,
    isFiltersEnabled,
  ])
}

interface SetHeaderStateProps {
  isUrlActionsEnabled: boolean
  isTitleEnabled: boolean
  isGridActionsEnabled: boolean
  isSharingEnabled: boolean
  isDisabled: boolean
  dispatch: AppDispatch
  isFiltersEnabled: boolean
}

export const setHeaderState = ({
  isUrlActionsEnabled,
  isTitleEnabled,
  isGridActionsEnabled,
  isSharingEnabled,
  isDisabled,
  isFiltersEnabled,
  dispatch,
}: SetHeaderStateProps) => {
  dispatch(setUrlActionsEnabled(isUrlActionsEnabled))
  dispatch(setTitleEnabled(isTitleEnabled))
  dispatch(setGridActionsEnabled(isGridActionsEnabled))
  dispatch(setSharingEnabled(isSharingEnabled))
  dispatch(setIsDisabled(isDisabled))
  dispatch(setIsFiltersEnabled(isFiltersEnabled))
}

export default headerSlice.reducer
