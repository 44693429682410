import { APIRequest } from '../../hooks/useRequest'
import logger from '../../lib/logger'
import { ServiceWorkerMessage } from '../../lib/service-worker'
import { ISerializedMessageWithContext } from '../broadcast/broadcastHelpers'
import { ServiceWorkerMessageTag } from '../utils/tags'

export const postMessageToServiceWorker = async (
  tag: ServiceWorkerMessageTag,
  config: APIRequest<unknown, ISerializedMessageWithContext>
): Promise<void> => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        const payload: ServiceWorkerMessage = { tag, ...config }
        registration.active?.postMessage(payload)
      })
      .catch(error => {
        logger.warn('Failed to post message to service worker:', error)
      })
  } else {
    try {
      const response = await fetch(`${config.baseURL}${config.url}`, {
        method: config.method,
        headers: new Headers(config.headers as Record<string, string>),
        credentials: 'include',
        cache: 'no-cache',
        keepalive: true,
        referrerPolicy: 'unsafe-url',
        mode: 'cors',
        body: JSON.stringify(config.data),
      })

      if (!response.ok) {
        logger.warn(`Fetch failed with status ${response.status}`, await response.text())
        return
      }
    } catch (error) {
      logger.warn('Failed to fetch:', error)
    }
  }
}
