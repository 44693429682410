import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { FollowButton } from '../../../components/FollowButton'
import { useCurrentUserContext } from '../../../context/CurrentUserContext'
import { useTrackClick } from '../../../hooks/analytics/useTrackClick'
import UrlHelper from '../../../lib/UrlHelper'
import urlHelper from '../../../lib/UrlHelper'
import userApi from '../../../redux/api/userApi'
import { useAppSelector } from '../../../redux/store/store'
import { useToggleUserFollow } from '../hooks/useToggleUserFollow'

const UserSidePanel = ({ userId }: { userId: number | undefined }) => {
  const { currentUserID } = useCurrentUserContext()
  const identifier = useAppSelector(state => state.feed.identifier)
  const router = useRouter()
  const { data: user } = userApi.useGetUserDetailsQuery({ userId: userId ?? 0 }, { skip: !userId })
  const [isOwnProfile, setIsOwnProfile] = useState(false)
  const trackClick = useTrackClick()
  const handleFollow = useToggleUserFollow(user)

  useEffect(() => {
    setIsOwnProfile(currentUserID === user?.user_id)
  }, [currentUserID, user?.user_id])

  if (!user) return <></>
  return (
    <>
      <div className="flex flex-1 flex-col items-start space-y-6 lg:h-full">
        <div className="flex space-x-10 text-primary sm:flex-col sm:space-x-0 sm:space-y-4">
          <Image
            className="block aspect-square size-32 shrink-0 rounded-full object-contain md:size-40"
            src={user.photo_url ?? ''}
            alt={user.display_name}
            width={400}
            height={400}
          />
          <div className="flex flex-col self-center sm:flex-col sm:items-start sm:space-y-4 sm:self-auto">
            <p className="text-3xl font-bold text-primary lg:text-4xl">{user.display_name}</p>
            <p className="text-primary opacity-50 lg:text-2xl">{`@${user.username}`}</p>
            {user?.bio?.length ? <p className="mt-4 text-primary opacity-75 sm:mt-0">{user.bio}</p> : <></>}
          </div>
        </div>
        {isOwnProfile ? (
          <Link
            href={'/account'}
            onClick={() => trackClick('userSettings')}
            className="btn btn-primary btn-dot my-8 font-semibold text-primary"
          >
            Settings
          </Link>
        ) : identifier.slug === 'user' ? (
          <FollowButton className="btn-dot my-8" isFollowing={user.meta.isLiked ?? false} onFollow={handleFollow} />
        ) : (
          <></>
        )}
        <div className="flex flex-1 self-stretch sm:flex-col">
          <Link
            as={UrlHelper.userPath(user.username)}
            href={`${UrlHelper.userPath(user.username)}/?skipUserSSR=true`}
            className={`${router.asPath === urlHelper.userPath(user.username) ? 'tab-active' : 'tab-inactive'} tab`}
          >
            <span className="flex-1 font-semibold text-primary">Likes</span>
            <span className="font-mono text-sm text-primary">{user?.meta.likesCount?.toLocaleString()}</span>
          </Link>
          <Link
            as={urlHelper.userSubPath(user.username, 'posts')}
            href={`${urlHelper.userSubPath(user.username, 'posts')}/?skipUserSSR=true`}
            className={`${
              router.asPath === urlHelper.userSubPath(user.username, 'posts') ? 'tab-active' : 'tab-inactive'
            } tab`}
          >
            <span className="flex-1 font-semibold text-primary">Posts</span>
            <span className="font-mono text-sm text-primary">{user.meta.curatedCount?.toLocaleString()}</span>
          </Link>
          <Link
            as={urlHelper.userSubPath(user.username, 'following')}
            href={`${urlHelper.userSubPath(user.username, 'following')}/?skipUserSSR=true`}
            className={`${
              router.asPath === urlHelper.userSubPath(user.username, 'following') ? 'tab-active' : 'tab-inactive'
            } tab`}
          >
            <span className="flex-1 font-semibold text-primary">Following</span>
            <span className="font-mono text-sm text-primary">{user.meta.followingCount?.toLocaleString()}</span>
          </Link>
          {isOwnProfile && (
            <>
              <Link
                as={urlHelper.userSubPath(user.username, 'followers')}
                href={`${urlHelper.userSubPath(user.username, 'followers')}/?skipUserSSR=true`}
                className={`${
                  router.asPath === urlHelper.userSubPath(user.username, 'followers') ? 'tab-active' : 'tab-inactive'
                } tab`}
              >
                <span className="flex-1 font-semibold text-primary">Followers</span>
                <span className="font-mono text-sm text-primary">{user.meta.followersCount?.toLocaleString()}</span>
              </Link>
              <Link
                as={urlHelper.userSubPath(user.username, 'saved')}
                href={`${urlHelper.userSubPath(user.username, 'saved')}/?skipUserSSR=true`}
                className={`${
                  router.asPath === urlHelper.userSubPath(user.username, 'saved') ? 'tab-active' : 'tab-inactive'
                } tab`}
              >
                <span className="flex-1 font-semibold text-primary">Saved</span>
                <span className="font-mono text-sm text-primary">{user.meta.savedCount?.toLocaleString()}</span>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default UserSidePanel
