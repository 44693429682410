import axios from 'axios'
import { getCookie } from 'cookies-next'
import { useCallback, useEffect } from 'react'

import { useCurrentUserContext } from '../context/CurrentUserContext'
import MixWeb from '../services/MixWeb'

export const useTestGroupPublisher = () => {
  const { currentUserID } = useCurrentUserContext()

  const publishTestGroup = useCallback(
    async (testName: string) => {
      try {
        await axios.request(
          MixWeb.experiments.joinTestGroup({
            userId: currentUserID,
            testName,
          })
        )
      } catch {
        /* Do nothing */
      }
    },
    [currentUserID]
  )

  useEffect(() => {
    if (!currentUserID) return
    const testGroup = getCookie('test_group')
    if (!testGroup) return
    void publishTestGroup(testGroup)
  }, [publishTestGroup, currentUserID])
}
