import axios from 'axios'

import { AxiosRequestInterceptorExtended } from '../../@types/extend-axios'
import { APIRequest } from '../../hooks/useRequest'
import { commands } from '../../lib/messages/protobuf'

import CommandContext = commands.CommandContext
import ICommandContext = commands.ICommandContext

// Output of the axios interceptors
export interface IMessageWithContext {
  context: CommandContext
}

interface SerializableWithContext {
  // type from protobufjs
  toJSON: () => ISerializedMessageWithContext
}

// An un-serialized protobuf message that can later be serialized to JSON.
export type ISerializableMessageWithContext = SerializableWithContext & { context?: ICommandContext | null | undefined }

// A serialized message that includes serialized CommandContext and other fields required for the request.
export type ISerializedMessageWithContext = { context?: Record<string, unknown> } & Record<string, unknown>

export const processAxiosInterceptors = (config: APIRequest) => {
  // Process the config via the axios interceptors
  const requestInterceptors = (axios.interceptors.request as AxiosRequestInterceptorExtended).handlers
  for (const interceptor of requestInterceptors) {
    if (interceptor?.fulfilled) {
      config = interceptor.fulfilled(config)
    }
  }
  return config as APIRequest<unknown, IMessageWithContext>
}
