import { PayloadAction, createSelector, createSlice, prepareAutoBatched } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { keyForResource } from '../../models/APIResource'
import { IntoNode } from '../../models/IntoNode'
import RecommendResponse from '../../models/RecommendResponse'
import { AppState } from '../store/store'

export const feedConstants = {
  GRID_PAGE_SIZE: 10,
  FULL_SCREEN_PAGE_SIZE: 5,
  RELATED_GRID_PAGE_SIZE: 6,
  NODE_GRID_PAGE_SIZE: 6,
  LOAD_MORE_THRESHOLD: 3,
}

interface FeedSlice {
  _metadata: Metadata
  _instanceId: string | null
  streamUrlId: string | number | null
  currentFeedItemKey: string | null
  hasReachedEnd: boolean
  identifier: IntoNode
  isAutoplayEnabled: boolean
  page: number
  pageSize: number
  showSidePanel: boolean
  isChangingFeed: boolean
}

interface Metadata {
  updatedFields: (keyof FeedSlice)[]
}

const initialState: FeedSlice = {
  _metadata: { updatedFields: [] },
  _instanceId: null,
  page: 1,
  pageSize: 10,
  currentFeedItemKey: null,
  identifier: IntoNode.FEATURED,
  hasReachedEnd: false,
  isAutoplayEnabled: false,
  showSidePanel: false,
  isChangingFeed: false,
  streamUrlId: null,
}

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    incrementPage: {
      reducer: (state, action: PayloadAction<number | undefined>) => {
        state.page = action.payload ? state.page + action.payload : state.page + 1
        state._metadata.updatedFields.push('page')
      },
      prepare: prepareAutoBatched<number | undefined>(),
    },
    setIdentifier: {
      reducer: (state, action: PayloadAction<IntoNode>) => {
        state.identifier = action.payload
        state._metadata.updatedFields.push('identifier')
      },
      prepare: prepareAutoBatched<IntoNode>(),
    },
    setCurrentFeedItemKey: {
      reducer: (state, action: PayloadAction<string | null>) => {
        state.currentFeedItemKey = action.payload
        state._metadata.updatedFields.push('currentFeedItemKey')
      },
      prepare: prepareAutoBatched<string | null>(),
    },
    setIsAutoplayEnabled: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isAutoplayEnabled = action.payload
        state._metadata.updatedFields.push('isAutoplayEnabled')
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setPageSize: {
      reducer: (state, action: PayloadAction<number>) => {
        state.pageSize = action.payload
        state._metadata.updatedFields.push('pageSize')
      },
      prepare: prepareAutoBatched<number>(),
    },
    setShowSidePanel: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.showSidePanel = action.payload
        state._metadata.updatedFields.push('showSidePanel')
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setPageNumber: {
      reducer: (state, action: PayloadAction<number>) => {
        state.page = action.payload
        state._metadata.updatedFields.push('page')
      },
      prepare: prepareAutoBatched<number>(),
    },
    setHasReachedEnd: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.hasReachedEnd = action.payload
        state._metadata.updatedFields.push('hasReachedEnd')
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setInstanceId: {
      reducer: (state, action: PayloadAction<string>) => {
        state._instanceId = action.payload
        state._metadata.updatedFields.push('_instanceId')
      },
      prepare: prepareAutoBatched<string>(),
    },
    setStreamUrlId: {
      reducer: (state, action: PayloadAction<string | number | null>) => {
        state.streamUrlId = action.payload
        state._metadata.updatedFields.push('streamUrlId')
      },
      prepare: prepareAutoBatched<string | number | null>(),
    },
    setIsChangingFeed: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isChangingFeed = action.payload
        state._metadata.updatedFields.push('isChangingFeed')
      },
      prepare: prepareAutoBatched<boolean>(),
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverFeed = action.payload.feed
        const fieldsToCheck: (keyof FeedSlice)[] = [
          '_instanceId',
          'currentFeedItemKey',
          'hasReachedEnd',
          'identifier',
          'isAutoplayEnabled',
          'page',
          'pageSize',
          'showSidePanel',
          'streamUrlId',
          'isChangingFeed',
        ]

        function updateField<K extends keyof FeedSlice>(state: FeedSlice, field: K, value: FeedSlice[K]): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (serverFeed._metadata.updatedFields.includes(field) && serverFeed[field] !== state[field]) {
            updateField(state, field, serverFeed[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

const selectFeedKey = createSelector(
  (state: AppState) => state.feed._instanceId,
  (state: AppState) => state.feed.identifier,
  (state: AppState) => state.user.userId,
  (_instanceId, identifier, userId) => {
    return _instanceId === 'likes'
      ? `getLikedUrls:${userId}`
      : _instanceId === 'posts'
        ? `getPostedUrls:${userId}`
        : `getFeedData:${identifier.slug}:${_instanceId}`
  }
)

export const selectCurrentFeed = (state: AppState) => {
  const {
    api: { queries },
  } = state
  const queriesKey = selectFeedKey(state)
  const query = queries[queriesKey]
  const data = query?.data as RecommendResponse | undefined
  if (!data || data?.items === undefined) {
    return undefined
  }
  return data
}

export const selectCurrentFeedStatus = (state: AppState) => {
  const {
    api: { queries },
  } = state
  const queriesKey = selectFeedKey(state)
  const query = queries[queriesKey]
  return query?.status
}

export const selectFilteredIds = createSelector(
  (state: AppState) => selectCurrentFeed(state),
  currentFeed => {
    return currentFeed?.items.reduce((acc, item) => {
      if (item.type === 'url') {
        acc.push(item.url_id)
      }
      return acc
    }, [] as string[])
  }
)

export const selectCurrentItem = createSelector(
  (state: AppState) => selectCurrentFeed(state),
  (state: AppState) => state.feed.currentFeedItemKey,
  (feed, currentItemKey) => {
    if (!feed || !currentItemKey) {
      return undefined
    }

    const currentItemIndex = feed.items.findIndex(item => {
      const key = keyForResource(item)
      return key === currentItemKey
    })

    if (currentItemIndex === -1) {
      return undefined
    }

    const currentItem = feed.items[currentItemIndex]

    if (!currentItem) {
      return undefined
    }

    return currentItem
  }
)

export const selectNextItem = createSelector(
  (state: AppState) => selectCurrentFeed(state),
  (state: AppState) => state.feed.currentFeedItemKey,
  (feed, currentItemKey) => {
    if (!feed || !currentItemKey) {
      return undefined
    }

    const currentItemIndex = feed.items.findIndex(item => {
      const key = keyForResource(item)
      return key === currentItemKey
    })

    if (currentItemIndex === -1) {
      return undefined
    }

    const nextIndex = currentItemIndex + 1

    const nextItem = nextIndex < feed.items?.length ? feed.items[nextIndex] : undefined

    if (!nextItem) {
      return undefined
    }

    return nextItem
  }
)

type FeedItem = RecommendResponse['items'][number]
type FeedItemType = FeedItem['type']
type TypeToItem<T extends FeedItemType> = Extract<FeedItem, { type: T }>

export const selectNextNItemsOfType = <T extends FeedItemType>(n: number, type: T) =>
  createSelector(
    (state: AppState) => selectCurrentFeed(state),
    (state: AppState) => state.feed.currentFeedItemKey,
    (feed, currentItemKey): TypeToItem<T>[] | undefined => {
      if (!feed || !currentItemKey) return undefined

      let currentItemIndex = feed.items.findIndex(item => {
        const key = keyForResource(item)
        return key === currentItemKey
      })

      if (currentItemIndex === -1) return undefined

      const itemsToReturn: TypeToItem<T>[] = []

      while (itemsToReturn?.length < n && currentItemIndex < feed.items?.length - 1) {
        currentItemIndex++
        const item = feed.items[currentItemIndex]
        if (item.type === type) {
          itemsToReturn.push(item as TypeToItem<T>)
        }
      }

      return itemsToReturn?.length > 0 ? itemsToReturn : undefined
    }
  )

export const {
  incrementPage,
  setPageSize,
  setIsAutoplayEnabled,
  setShowSidePanel,
  setCurrentFeedItemKey,
  setIdentifier,
  setPageNumber,
  setHasReachedEnd,
  setInstanceId,
  setStreamUrlId,
  setIsChangingFeed,
} = feedSlice.actions

export default feedSlice.reducer
