import React, { useCallback, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import useIntersectionObserver from '../../hooks/useInteractionObserver'
import IconCheck from '../../icons/IconChecked.svg'
import UrlHelper from '../../lib/UrlHelper'
import notificationsApi from '../../redux/api/notificationsApi'
import { incrementNotificationsPage } from '../../redux/slices/notificationsSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store/store'
import { HoverMenu } from '../HoverMenu'
import { Notification, NotificationImage } from './Notifications'

const NotificationsMenuHeader = ({ isLoading, isEmpty }: { isLoading: boolean; isEmpty: boolean }) => {
  const { data } = notificationsApi.useGetUnreadCountQuery()
  const [markAllRead] = notificationsApi.useMarkAllReadMutation()
  const isFullyRead = data?.notifications === 0

  return (
    <div className="sticky p-4 text-center font-bold tracking-wider light:bg-menu dark:bg-white/[.16]">
      <div className="relative flex justify-center">
        <div>Notifications</div>
        {!isLoading && !isEmpty && (
          <button
            disabled={isFullyRead}
            className=":not(disabled):hover:rounded-full :not(disabled):hover:text-white :not(disabled):hover:opacity-100 absolute right-0 size-6 shrink-0 opacity-50 transition-all duration-150 light:text-menu dark:text-primary"
            onClick={markAllRead}
          >
            <IconCheck />
          </button>
        )}
      </div>
    </div>
  )
}

export const NotificationsMenu = () => {
  const dispatch = useAppDispatch()
  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  const { page, hasReachedEnd } = useAppSelector(state => state.notifications)
  const { data, isLoading, isFetching, isUninitialized } = notificationsApi.useGetNotificationsQuery(
    { page },
    { skip: !inView }
  )

  const loadMore = useCallback(() => {
    if (hasReachedEnd || isLoading || isFetching || isUninitialized) return
    dispatch(incrementNotificationsPage())
  }, [dispatch, hasReachedEnd, isLoading, isFetching, isUninitialized])

  const sentinelRef = useRef<HTMLDivElement>(null)
  useIntersectionObserver({ sentinelRef, loadMore, isLoading, page })

  return (
    <HoverMenu position="topRight">
      <div ref={ref} className="relative overflow-hidden rounded-xl bg-primary light:bg-menu">
        <NotificationsMenuHeader isLoading={isLoading} isEmpty={data?.items?.length === 0} />
        {!isLoading && data?.items?.length === 0 && (
          <div className="flex h-24 w-96 items-center justify-center overflow-x-hidden whitespace-break-spaces text-sm font-light light:bg-menu dark:bg-contrast/[.16]">
            <div>No Notifications Yet</div>
          </div>
        )}
        <div className="scrollbar-menu max-h-96 min-w-96 overflow-x-hidden whitespace-break-spaces">
          <div className="relative flex flex-col text-lg outline-none">
            {data?.items.map(notification => {
              switch (notification.type) {
                case 'marketing':
                  return (
                    <Notification
                      href={notification.content.destination_url}
                      notification={notification}
                      bgColorUnread="bg-[#FF8004]/30"
                      hideTimestamp={notification.content.hide_timestamp}
                      // eslint-disable-next-line @next/next/no-img-element
                      badge={<img src={notification.content.badge_image_url} alt={''} className="w-10" />}
                    >
                      <NotificationImage
                        src={notification.content.image_url}
                        alt={notification.title}
                        shape="square"
                        secondaryImage={{
                          src: notification.content.secondary_image_url,
                          alt: '',
                          hideBorder: true,
                        }}
                      />
                      <div className="leading-tight">
                        <div className="text-sm font-medium">{notification.title}</div>
                        <div className="text-xs text-[#C6C6C6]">{notification.content.description}</div>
                      </div>
                    </Notification>
                  )
                case 'user_follow':
                  return (
                    <Notification
                      key={notification.id}
                      notification={notification}
                      href={UrlHelper.userPath(notification.content.follower.username)}
                    >
                      <NotificationImage
                        src={notification.content?.follower?.photo_url ?? '/avatar-dark.svg'}
                        alt={notification.content?.follower?.username}
                        shape="round"
                      />
                      <span className="text-sm leading-tight">{notification.title}</span>
                    </Notification>
                  )
                case 'share':
                  return (
                    <Notification
                      key={notification.id}
                      notification={notification}
                      href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    >
                      <NotificationImage
                        src={notification.content.url?.thumbnail ?? ''}
                        alt={notification.content.url.title}
                        shape="square"
                        secondaryImage={{
                          src: notification.content.sender?.photo_url ?? '/avatar-dark.svg',
                          alt: notification.content.sender?.username ?? '',
                        }}
                      />
                      <div className="flex flex-col space-y-1">
                        <span className="whitespace-nowrap text-sm leading-tight">{notification.title}</span>
                        <span className="text-xs font-light leading-tight">{notification.content.caption}</span>
                      </div>
                    </Notification>
                  )
                case 'share_comment':
                  return (
                    <Notification
                      key={notification.id}
                      notification={notification}
                      href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    >
                      <NotificationImage
                        src={notification.content.comment_creator?.photo_url ?? '/avatar-dark.svg'}
                        alt={notification.content.comment_creator?.username ?? ''}
                        shape="round"
                        secondaryImage={{
                          src: notification.content.url?.thumbnail ?? '',
                          alt: notification.content.url.title,
                        }}
                      />
                      <span className="text-sm leading-tight">{notification.title}</span>
                    </Notification>
                  )
                case 'share_reaction':
                  return (
                    <Notification
                      key={notification.id}
                      notification={notification}
                      href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    >
                      <NotificationImage
                        src={notification.content.reaction_creator?.photo_url ?? '/avatar-dark.svg'}
                        alt={notification.content.reaction_creator?.username ?? ''}
                        shape="round"
                        secondaryImage={{
                          src: notification.content.url?.thumbnail ?? '',
                          alt: notification.content.url.title,
                        }}
                      />
                      <span className="text-sm leading-tight">{notification.title}</span>
                    </Notification>
                  )
                case 'comment_mention':
                  return (
                    <Notification
                      key={notification.id}
                      notification={notification}
                      href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    >
                      <NotificationImage
                        src={notification.content.comment_creator?.photo_url ?? '/avatar-dark.svg'}
                        alt={notification.content.comment_creator?.username ?? ''}
                        shape="round"
                        secondaryImage={{
                          src: notification.content.url?.thumbnail ?? '',
                          alt: notification.content.url.title,
                        }}
                      />
                      <span className="text-sm leading-tight">{notification.title}</span>
                    </Notification>
                  )
                case 'comment_reply':
                  return (
                    <Notification
                      key={notification.id}
                      notification={notification}
                      href={UrlHelper.urlIDPath({ urlID: notification.content.url.url_id })}
                    >
                      <NotificationImage
                        src={notification.content.comment_creator?.photo_url ?? '/avatar-dark.svg'}
                        alt={notification.content.comment_creator?.username ?? ''}
                        shape="round"
                        secondaryImage={{
                          src: notification.content.url?.thumbnail ?? '',
                          alt: notification.content.url.title,
                        }}
                      />
                      <div className="flex flex-col space-y-1">
                        <span className="whitespace-nowrap text-sm leading-tight">{notification.title}</span>
                        <span className="text-xs font-light leading-tight">{notification.content.text}</span>
                      </div>
                    </Notification>
                  )
                default:
                  return null
              }
            })}
            {!hasReachedEnd && (
              <div
                ref={sentinelRef}
                className="flex items-center justify-between space-x-4 p-4 text-left hover:bg-primary/80 light:bg-menu dark:bg-white/[.16]"
              >
                <div className="flex animate-pulse items-center space-x-4">
                  <span className="inline-block aspect-square w-10 shrink-0 rounded-full bg-contrast/10"></span>
                  <span className="block h-4 w-[150px] rounded bg-contrast/10" />
                </div>
                <span className="block h-4 w-[40px] rounded bg-contrast/10" />
              </div>
            )}
          </div>
        </div>
      </div>
    </HoverMenu>
  )
}
