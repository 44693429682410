import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { AppState } from '../store/store'

const INITIAL_VOLUME = 0.8

interface InitialState {
  isMuted: boolean
  hasEverUnmuted: boolean
  volume: number
  _metadata: { updatedFields: (keyof InitialState)[] }
}

const initialState: InitialState = {
  isMuted: true,
  hasEverUnmuted: false,
  volume: INITIAL_VOLUME,
  _metadata: { updatedFields: [] },
}

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setIsMuted(state, action: PayloadAction<boolean>) {
      state.isMuted = action.payload
      state._metadata.updatedFields.push('isMuted')
    },
    setHasEverUnmuted(state, action: PayloadAction<boolean>) {
      state.hasEverUnmuted = action.payload
      state._metadata.updatedFields.push('hasEverUnmuted')
    },
    setVolume(state, action: PayloadAction<number>) {
      state.volume = action.payload
      state._metadata.updatedFields.push('volume')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.video

        const fieldsToCheck: (keyof InitialState)[] = ['isMuted', 'hasEverUnmuted', 'volume']

        function updateField<K extends keyof InitialState>(
          state: InitialState,
          field: K,
          value: InitialState[K]
        ): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (serverApp._metadata.updatedFields.includes(field) && serverApp[field] !== state[field]) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const { setIsMuted, setHasEverUnmuted, setVolume } = videoSlice.actions
export default videoSlice.reducer
