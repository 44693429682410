import React, { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react'

import { createDefaultCommandContext } from '../lib/commands/CommandContextUtils'
import { commands, globals } from '../lib/messages/protobuf'
import { useGlobalContext } from './GlobalContexts'

import CommandContext = commands.CommandContext
import PageContext = globals.PageContext

interface CommandContextProps {
  commandContext: CommandContext
  changePageContext: (pageContext: PageContext) => void
}

const CommandContextContext = createContext<CommandContextProps | undefined>(undefined)

export const CommandContextProvider = ({ children }: { children: ReactNode }) => {
  const { browserInfo } = useGlobalContext()
  const [commandContext, setCommandContext] = useState(createDefaultCommandContext(browserInfo))

  const changePageContext = useCallback(
    (pageContext: PageContext) => {
      if (JSON.stringify(pageContext) === JSON.stringify(commandContext.currentContext)) return

      setCommandContext(
        new CommandContext({
          ...commandContext,
          referrerContext: new PageContext({ ...commandContext.currentContext }),
          currentContext: pageContext,
        })
      )
    },
    [commandContext]
  )

  const value = useMemo(
    () => ({
      commandContext,
      changePageContext,
    }),
    [commandContext, changePageContext]
  )

  return <CommandContextContext.Provider value={value}>{children}</CommandContextContext.Provider>
}

export const useCommandContext = () => {
  const result = useContext(CommandContextContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}
