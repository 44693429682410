import { useTrackClick } from '../../../hooks/analytics/useTrackClick'
import { IntoUser } from '../../../models/IntoUser'
import userApi from '../../../redux/api/userApi'

export const useToggleUserFollow = (user: IntoUser | undefined) => {
  const [followUser] = userApi.useFollowUserMutation()
  const [unfollowUser] = userApi.useUnfollowUserMutation()
  const trackClick = useTrackClick()

  return async () => {
    if (!user) return
    try {
      if (user.meta.isLiked) {
        await unfollowUser({ userId: user.user_id, username: user.username })
        trackClick('userUnfollow')
      } else {
        await followUser({ userId: user.user_id, username: user.username })
        trackClick('userFollow')
      }
    } catch (_e) {
      return
    }
  }
}
